import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const DragDropContextWrapper = (props) => {
  return (
    <DragDropContext {...props}>
      {props.children}
    </DragDropContext>
  );
};

export const DroppableWrapper = (props) => {
  return (
    <Droppable droppableId={props.droppableId} {...props}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="droppable-wrapper"
          style={{minHeight: "100%", ...props.style}}
        >
          {props.children}
        </div>
      )}
    </Droppable>
  );
};

export const DraggableWrapper = (props) => {
  return (
    <Draggable key={props.index} draggableId={props.draggableId} index={props.index} isDragDisabled={props.isDragDisabled}>
      {(provided, snapshot) => (
        <div className="draggable-elem-container">
          <div ref={provided.innerRef} {...provided.draggableProps} className="draggable-item">
            {React.cloneElement(props.children, { provided, isDragging: snapshot.isDragging, isDragDisabled: props.isDragDisabled })}
          </div>
        </div>
      )}
    </Draggable>
  );
};
