import React from 'react';
import Helmet from 'react-helmet';

/**
 * Browser title component
 *
 * @param {string} title
 * @returns
 */
const TitleComponent = ({ title }) => {
  const defaultTitle = 'ePublishing CMS';

  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

export { TitleComponent };