import styled from 'styled-components';
import Colors from 'styles/colors';

export const Container = styled.div`
  display: block;
  font-family: 'Lato', sans-serif;
`;

export const Wrapper = styled.div`
  background-color: #fff;
  display: inline-block;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  position: relative;
  max-width: ${(props) => props.width ? props.width : "450px"};
  width: 100%;

  .-dark-theme & {
    background-color: ${Colors.whiteSmoke};
    box-shadow: none;
    border-bottom: 1px solid ${Colors.mercury};
  }

  .-has-error & {
    background-color: ${Colors.roseWhite};
    border-bottom: 1px solid ${Colors.sunGlow};
  }
  
  textarea {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 18px 20px 18px 10px;
    width: 100%;
    height: ${(props) => props.height ? props.height : "150px"};
    position: relative;
    z-index: 3;
    font-size: 16px;

    .-has-placeholder & {
      padding: 27px 20px 9px 10px;
    }
  }
`;

export const Placeholder = styled.span`
  position: absolute;
  left: 10px;
  right: 0;
  top: 30px;
  transform: translateY(-50%);
  color: ${Colors.cadetBlue};
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  z-index: 2;

  .-is-focused & {
    top: 0;
    left: 0;
    padding-top: 4px;
    padding-left: 10px;
    padding-bottom: 2px;
    transform: translateY(0);
    font-size: 12px;
    font-weight: bold;
    color: #222;
    background-color: #fff;
  }

  .-is-focused.-dark-theme & {
    background-color: ${Colors.whiteSmoke};
  }

  .-has-error & {
    color: ${Colors.sunGlow};
  }
`;

export const Hint = styled.span`
  color: ${Colors.cadetBlue};
  font-size: 12px;
  display: block;
  margin-top: 2px;
`;

export const ErrorMsg = styled.span`
  color: ${Colors.sunGlow};
  margin-top: 3px;
  font-size: 12px;
  display: block;
`;