import React from 'react';
import { Tooltip } from 'antd';
import { FAL } from "icons/fa";
import { Item, List, PopConfirmWrapper } from './style';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import Popconfirm from 'components/popconfirm/index';

type ActionItemProps = {
  icon: IconName,
  onClickHandler: () => {},
  tooltip: string,
  askForConfirmation?: boolean,
  setActive: (value:boolean) => void,
  className: string,
};

const ActionItem = ({ icon, onClickHandler, tooltip, askForConfirmation, setActive, className }: ActionItemProps) => (
  <Tooltip title={tooltip} overlayClassName="outline-menu-tooltip">
    {askForConfirmation ? (<PopConfirmWrapper>
      <Popconfirm
        title="Woah! Are you sure you wish to delete this?"
        placement="rightTop" onConfirm={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
        onVisibleChange={(value) => setActive(value)}
        onCancel={(e) => e.stopPropagation()}
      >
        <Item className={className} onClick={e => e.stopPropagation()}>
          <FAL icon={icon} color="fiord" />
        </Item>
      </Popconfirm>
    </PopConfirmWrapper>) : (<Item onClick={onClickHandler} className={className}>
      <FAL icon={icon} color="fiord" />
    </Item>)}
  </Tooltip>
);

type ActionsProps = {
  menuPosition?: 'inner' | 'outer',
  callbacks: Array<{
    icon: IconName,
    callback: any,
    tooltip: string,
    askForConfirmation?: boolean,
    setActive: (value: boolean) => void,
    className: string,
  }>,
  isHovering: any
};

const Actions = ({ menuPosition, callbacks, isHovering }: ActionsProps) => (
  <List menuPosition={menuPosition} isHovering={isHovering}>
    {callbacks.map((item) => (
      <ActionItem
        key={item.icon}
        icon={item.icon}
        onClickHandler={item.callback}
        tooltip={item.tooltip}
        askForConfirmation={item.askForConfirmation}
        setActive={item.setActive}
        className={item.className}
      />
    ))}
  </List>
);

export default Actions;
