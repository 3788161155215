import gql from 'graphql-tag';

export const KEYWORDS_LIST = gql`
  query keywords($filterString: [Str], $perPage: Integer, $page: Integer) {
    _childStats: _keywordStats(filterString: $filterString, onlyLive:true) {
      count
    }

    keywords(filterString: $filterString, perPage: $perPage, page: $page) {
      id
      tag
    }
  }
`;

export const KEYWORD_CREATE = gql`
  mutation CreateNewKeyword($input: KeywordCreateInput!) {
    keywordCreate(input: $input){
      keyword {
        id
        tag
      }
      success
      errors
    }
  }
`;
