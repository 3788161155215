//TODO : generate one array for handle keys
export const HEADERS = [
    'FILE TITLE',
    'FILE NAME',
    'DOWNLOAD DATE',
    'FILE TAXONOMIES',
    'USER ID',
    'EMAIL',
    'FIRST NAME',
    'LAST NAME',
    'COMPANY',
    'JOB TITLE',
    'ADDRESS1',
    'ADDRESS2',
    'CITY',
    'STATE',
    'PROVINCE',
    'COUNTRY',
    'POSTAL CODE',
    'PHONE'
];

export const FILE_KEYS = [
    'title',
    'absoluteUrl'
]

export const DOWNLOAD_DATE = [
    'downloadedAt',
    'taxonomies',
]

export const USER_KEYS = [
    'oid',
    'email',
    'firstName',
    'lastName',
    'company',
    'jobTitle',
    'address1',
    'address2',
    'city',
    'stateName',
    'province',
    'countryName',
    'postalCode',
    'defaultPhone',
]