import gql from 'graphql-tag';

export const PAGES_QUERY = gql`
  query PagesQuery($perPage: Integer!, $page: Integer) {
    _pageStats {
      count
    }

    pages(perPage: $perPage, page: $page, sortString: "createdAt desc") {
      oid
      id
      title
      url
      createdAt
    }
  }
`;

export const TAXONOMY_PAGES = gql`
  query TaxonomyPages ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _pageStats {
        count
      }
      pages (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const PAGE_EDIT = gql`
 query PageEdit($oid: Integer!) {
   page(oid: $oid) {
   oid
   id
   title
   body
   live
   active
   url
   searchable
   createdAt
   topics {
    id
    title
    parent {
      id
      title
    }
  }
   sitePlacements {
     id
     title
     parent {
      id
      title
    }
   }
   classifications {
    id
    title
    parent {
      id
      title
    }
   }
   taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
    id
    title
    parent {
      id
      title
    }
  }
  taxonomies {
    id
    title
  }
   meta {
    id
    slug
    canonicalUrl
    browserTitle
    keywords
    description
    bottomText
    headers {
      id
      name
      value
    }
   }
  }
 }
`;

export const PAGE_CREATE = gql`
  mutation PageCreate ($input: PageCreateInput!) {
    pageCreate(input: $input)
    {
      page {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const PAGE_UPDATE = gql`
  mutation PageUpdate ($input: PageUpdateInput!) {
    pageUpdate(input: $input)
    {
      pages {
        id
        oid
        meta {
          id
          oid
        }
      }
      success
      errors
    }
  }
`;

export const PAGE_ADD_TAXONOMIES = gql`
  mutation PageAddTaxonomies ($input: PageAddTaxonomiesInput!) {
    pageAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const PAGE_REMOVE_TAXONOMIES = gql`
  mutation PageRemoveTaxonomies ($input: PageRemoveTaxonomiesInput!) {
    pageRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const PAGE_DELETE = gql`
  mutation PageDelete ($input: PageDeleteInput!) {
    pageDelete(input: $input)
    {
      success
      errors
      pages { id }
    }
  }
`;