import gql from 'graphql-tag';

export const EDITION_PRINT_PAGE_ARTICLES = gql`
  query EditionPrintPageArticles($id: IDType!, $sectionId: IDType, $perPage:Integer!, $page:Integer!, $sortString: [Str]) {
    edition(id: $id) {
      id
      oid
      id
      title
      publication {
        id
      }
      _printPageStats{
        count
      }
      printPages(perPage: $perPage, page: $page, sortString: $sortString) {
        id
        oid
        name
        status
        notes
        image {
          id
          absoluteUrl
        }
        items(sectionId: $sectionId) {
          id
          oid
          printPageSortingOrder
          section {
            id
            title
          }
          item {
            ... on Article {
              id
              oid
              title
            }
          }
        }
        _itemStats(sectionId: $sectionId) {
          count
        }
      }
      _printPageStats {
        count
      }
    }
  }
`;

export const EDITION_ITEM_CREATE = gql`
  mutation EditionItemCreate($input: EditionItemCreateInput!) {
    editionItemCreate(input: $input)
    {
      editionItem { id }
      success
      errors
    }
  }
`;

export const EDITION_ITEM_UPDATE = gql`
  mutation EditionItemUpdate($input: EditionItemUpdateInput!) {
    editionItemUpdate(input: $input)
    {
      editionItems { id }
      success
      errors
    }
  }
`;

export const EDITION_ITEM_DELETE = gql`
  mutation EditionItemDelete($input: EditionItemDeleteInput!) {
    editionItemDelete(input: $input)
    {
      editionItems { id }
      success
      errors
    }
  }
`;

export const PRINT_PAGE = gql`
  query PrintPage ($id:  IDType!) {
    printPage(id: $id) {
      id
      notes
    }
  }
`;


export const PRINT_PAGE_NOTES = gql`
  mutation PrintPageNotes($input: PrintPageUpdateInput!) {
    printPageUpdate (input: $input)
    {
      success
      errors 
      printPages {
        oid
        id
        notes
      }
    }
  }
`;