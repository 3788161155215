export const AUTH_TOKEN = 'auth-token';
export const LOCAL_STORAGE = {
  X_ENV: "x-environment",
};

export const TAXONOMY_DEFAULT_PER_PAGE = 100;
export const TAXONOMY_TOPIC_CATEGORY_TITLE = "Categories";
export const TAXONOMY_DEFAULT_PAGE = 1;

export const IDTYPE = 'IDType!';
export const RELATED_ARTICLE = 'relatedArticle';
export const RELATED_ARTICLES = 'relatedArticles';
export const RELATED_ARTICLE_STATS = '_relatedArticleStats';

export const ARTICLE = 'article';
export const ARTICLES = 'articles';
export const ARTICLE_STATS = '_articleStats';

export const CLASSIFIED = 'classified';
export const CLASSIFIEDS = 'classifieds';
export const CLASSIFIED_STATS = '_classifiedStats';

export const COMPANY = 'company';
export const COMPANIES = 'companies';
export const COMPANY_STATS = '_companyStats';

export const ACCOUNT = 'account';
export const ACCOUNTS = 'accounts';
export const ACCOUNT_STATS = '_accountStats';

export const AGENCY = 'agency';
export const AGENCIES = 'agencies';
export const AGENCY_STATS = '_agencyStats';

export const EVENT = 'event';
export const EVENTS = 'events';
export const EVENT_STATS = '_eventStats';

export const RELATED_EVENT = 'relatedEvent';
export const RELATED_EVENTS = 'relatedEvents';
export const RELATED_EVENT_STATS = '_relatedEventStats';

export const GALLERY = 'gallery';
export const GALLERIES = 'galleries';
export const GALLERY_STATS = '_galleryStats';

export const RELATED_GALLERY = 'relatedGallery';
export const RELATED_GALLERIES = 'relatedGalleries';
export const RELATED_GALLERY_STATS = '_relatedGalleryStats';

export const PAGE = 'page';
export const PAGES = 'pages';
export const PAGE_STATS = '_pageStats';

export const POLL = 'poll';
export const POLLS = 'polls';
export const POLL_STATS = '_pollStats';

export const RELATED_POLL = 'relatedPoll';
export const RELATED_POLLS = 'relatedPolls';
export const RELATED_POLL_STATS = '_relatedPollStats';

export const PRODUCT = 'product';
export const PRODUCTS = 'products';
export const PRODUCT_STATS = '_productStats';
export const BOOK = 'book';

export const RELATED_PRODUCT = 'relatedProduct';
export const RELATED_PRODUCTS = 'relatedProducts';
export const RELATED_PRODUCT_STATS = '_relatedProductStats';

export const USER = 'user';
export const USERS = 'users';
export const USER_STATS = '_userStats';

export const EDITORIAL_CONTENT = 'editorialContent';
export const EDITORIAL_CONTENTS = 'editorialContents';
export const EDITORIAL_CONTENT_STATS = '_editorialContentStats';

export const PUBLICATION = 'publication';
export const PUBLICATIONS = 'publications';
export const PUBLICATION_STATS = '_publicationStats';

export const NEWSLETTER_SHORTCODE_ID = "<!--NEWSLETTER_MANAGER: DO NOT EDIT-->";
export const NEWSLETTER = 'newsletter';
export const NEWSLETTERS = 'newsletters';
export const NEWSLETTER_STATS = '_newsletterStats';

export const NEWSLETTER_ISSUE = 'newsletterIssue';
export const NEWSLETTER_ISSUES = 'newsletterIssues';
export const NEWSLETTER_ISSUE_STATS = '_newsletterIssuesStats';

export const FILE = 'file';
export const FILES = 'files';
export const FILE_STATS = '_fileStats';

export const EDITION = 'edition';
export const EDITIONS = 'editions';
export const EDITION_STATS = '_editionStats';

export const VIEW = 'view';
export const VIEWS = 'views';
export const VIEW_STATS = '_viewStats';

export const VARIATION = 'variation';
export const VARIATIONS = 'depthOneVariations';
export const VARIATIONS_STATS = '_depthOneVariationsStats';

export const BUNDLED_PRODUCT = 'bundledProduct';
export const BUNDLED_PRODUCTS = 'bundledProducts';
export const BUNDLED_PRODUCTS_STATS = '_bundledProductStats';

export const CONTENT_PRODUCT = 'contentProduct';
export const CONTENT_PRODUCTS = 'contentProducts';
export const CONTENT_PRODUCTS_STATS = '_contentProductStats';

export const RELATED_COMPANY = 'relatedCompany';
export const RELATED_COMPANIES = 'relatedCompanies';
export const RELATED_COMPANIES_STATS = '_relatedCompanyStats';

export const TAXONOMY = 'taxonomy';
export const AUTHOR = "author";
export const BLOG = "blog";
export const NAVIGATION = "menu";

export const RELATED_TYPES = {
  [RELATED_ARTICLE]: {
    state: RELATED_ARTICLE_STATS,
    query: RELATED_ARTICLES,
  },
  [ARTICLE]: {
    state: ARTICLE_STATS,
    query: ARTICLES,
  },
  [CLASSIFIED]: {
    state: CLASSIFIED_STATS,
    query: CLASSIFIEDS,
  },
  [COMPANY]: {
    state: COMPANY_STATS,
    query: COMPANIES,
  },
  [AGENCY]: {
    state: AGENCY_STATS,
    query: AGENCIES,
  },
  [ACCOUNT]: {
    state: ACCOUNT_STATS,
    query: ACCOUNTS,
  },
  [EVENT]: {
    state: EVENT_STATS,
    query: EVENTS,
  },
  [GALLERY]: {
    state: GALLERY_STATS,
    query: GALLERIES,
  },
  [PAGE]: {
    state: PAGE_STATS,
    query: PAGES,
  },
  [POLL]: {
    state: POLL_STATS,
    query: POLLS,
  },
  [RELATED_POLL]: {
    state: RELATED_POLL_STATS,
    query: RELATED_POLLS,
  },
  [PRODUCT]: {
    state: PRODUCT_STATS,
    query: PRODUCTS,
  },
  [RELATED_PRODUCT]: {
    state: RELATED_PRODUCT_STATS,
    query: RELATED_PRODUCTS,
  },
  [EDITORIAL_CONTENT]: {
    state: EDITORIAL_CONTENT_STATS,
    query: EDITORIAL_CONTENTS,
  },
  [PUBLICATION]: {
    state: PUBLICATION_STATS,
    query: PUBLICATIONS,
  },
  [NEWSLETTER]: {
    state: NEWSLETTER_STATS,
    query: NEWSLETTERS,
  },
  [FILE]: {
    state: FILE_STATS,
    query: FILES,
  },
  [RELATED_GALLERY]: {
    state: RELATED_GALLERY_STATS,
    query: RELATED_GALLERIES,
  },
  [RELATED_EVENT]: {
    state: RELATED_EVENT_STATS,
    query: RELATED_EVENTS,
  },
  [VARIATION]: {
    state: VARIATIONS_STATS,
    query: VARIATION,
  },
  [BUNDLED_PRODUCT]: {
    state: BUNDLED_PRODUCTS_STATS,
    query: BUNDLED_PRODUCT,
  },
  [CONTENT_PRODUCT]: {
    state: CONTENT_PRODUCTS_STATS,
    query: CONTENT_PRODUCTS,
  },
  [RELATED_COMPANY]: {
    state: RELATED_COMPANIES_STATS,
    query: RELATED_COMPANIES,
  }
};

export const HEADER_HEIGHT = 140;

export const KEY = {
  ENTER: 13,
  UP: 38,
  DOWN: 40,
};

export const ERRORS = {
  DUPLICATE: 'Mysql2::Error: Duplicate',
};

export const URLS = {
  GALLERIES: 'media/photos',
  MEDIA: 'ext/resources',
};

export const GALLERY_IMAGES_PER_PAGE = 100;

export const ASCII = {
  CONTROL_END_RANGE: 31,
  DIGIT_START_RANGE: 48,
  DIGIT_END_RANGE: 57,
  PERIOD: 46,
  PLUS: 43,
  HYPHEN: 45,
};

export const IMAGE_FALLBACK_SRC = "https://via.placeholder.com/767x511";

export const DATE_FORMAT = 'MM-dd-yyyy';

export const PER_PAGE_RECORDS = 100;

export const SHIPPING_ZONE_POSTAL_RANGE_PER_PAGE = 100;

export const COLUMN_BREAKPOINTS_TABLET = [
  { media: [0, 800], columns: "1fr" },
];

export const GENERATE_NEWSLETTER_ISSUE_COPY_URL = "https://:domainName/newsletter-issues/:issueID/copy.html";

export const MEDIA_TYPES = {
  IMAGE: "Image",
  AUDIO: "Audio",
  VIDEO: "Video",
  FILE: "File",
};

export const USER_TYPE = {
  SITE_LICENSE: "Site License Group",
  INDIVIDUAL: "Individual",
};

export const VIDEO_EXTENSIONS = ["avi", "mov", "mpg", "mpeg", "mp4", "wmv", "flv", "wmv", "f4v", "webm"];
export const IMAGE_EXTENSIONS = ["png", "jpeg", "jpg", "gif", "bmp", "webp"];
export const AUDIO_EXTENSIONS = ["mp3", "wma", "wav", "acc", "m4a", "flac"];

export const DOMAIN_NAME = 'epublishing.com';

export const TAXONOMIES_WITH_FIXED_NAME = ["DIRECTORY_CATEGORIES", "DIRECTORY_LISTING_TIERS", "TOPICS", "CLASSIFICATION", "SITE_PLACEMENT", "CLASSIFIED_CUSTOM_FIELDS_ROOT", "DIRECTORY_LISTING_CUSTOM_FIELDS_ROOT", "EVENT_CUSTOM_FIELDS_ROOT", "PRODUCT_CUSTOM_FIELDS_ROOT", "USER_CUSTOM_FIELDS_ROOT", "PRODUCT_CATEGORIES"];

export const BASE_TIMEZONE = "America/New_York";

export const AVAILABLE_SHORTCODES = [
  'blurb',
  'cinchcast',
  'current-year',
  'form-authenticity-token',
  'iframe-embed',
  'image-with-caption',
  'newsletter-form',
  'recaptcha',
  'responsive-image',
  'setting',
  'newsletter-ad',
  'newsletter-article',
  'newsletter-articles',
  'newsletter-events',
  'newsletter-galleries',
  'newsletter-products',
  'newsletter-table',
  'newsletter-videos',
  'newsletter-classifieds',
  'newsletter-classified',
];

export const ARTICLE_FEEDS_ACCESS_ALLOWED_TO = ["newsletterclarivate", "localhost:3000", "continuum"];

export const OVERRIDE_PRIVILEGE = "workflow.override";
export const VIEW_ASSIGNMENT_PRIVILEGE = "workflow.view.assignments";

export const LOCK_OVERRIDE_PRIVILEGE = "lock.override";

export const DEFAULT_COOKIE_DOMAIN = ".continuum.epublishing.com";

export const ADD_KEYWORD = 'keyword.write';

export const ACCESS_RESTRICTED_SYSTEM_SETTINGS = 'access.restricted.system.settings';

export const COUNTRY_US = "United States";

export const BULK_EDITING_OPTION = "admin.bulk.editing.option";

export const ADMIN_NEWSLETTER_DELETE = "admin.newsletter.delete";

export const ADMIN_CACHE = 'admin.cache';

export const OPENAI_CHAT_API_BASE_URL = 'https://api.openai.com/v1/chat/completions';