import styled, { css } from 'styled-components';
import Colors from 'styles/colors';

const headerSection = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  border-right: 1px solid ${Colors.cadetBlue};
`;

export const Header = styled.header`
  background-color: ${Colors.iron};
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: ${Colors.cadetBlue};

  .fa-search {
    height: 24px;
    width: 24px;

    path {
      fill: ${Colors.nileBlue};
    }
  }
`;

export const Search = styled.div`
  ${headerSection}
  flex: 1;

  input {
    border: none;
    padding: 20px;
    width: 100%;
    background-color: transparent;
    outline: none;
    color: ${Colors.nevada};
  }
`;

export const Environment = styled.div`
  ${headerSection}
  justify-content: flex-end;
`;

export const EnvLinks = styled.div`
  background-color: ${Colors.cadetBlue};
  line-height: 1;
  border-radius: 3px;
`;

export const EnvLink = styled.div`
  color: ${Colors.nevada};
  cursor: pointer;
  display: inline-block;
  line-height: 1.65;
  padding: 5px 15px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  transition: all 500ms ease-in-out;

  ${(props) => props.active && css`
    background-color: #B33A3A;
    box-shadow: 0 0 8px hsla(0, 61%, 60%, 1);
    border: 1px solid hsla(0, 51%, 40%, 1);
    color: #fff;
    border-radius: 0px;
  `}
`;

export const CacheButton = styled.div`
  margin-left: auto;

  button {
    margin-left: 20px;
    color: ${Colors.nevada};
    border: 1px solid ${Colors.greyChateau};
    cursor: pointer;
    display: inline-block;
    line-height: 1.65;
    padding: 5px 15px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: all 500ms ease-in-out;
    background-color: transparent;

    &:hover {
      background-color: rgba(255,255,2555,0.4);
    }
  }
`;


export const HelpLinks = styled.div`
  ${headerSection}
  padding: 0 10px;
`;

export const HelpLink = styled.div`
  margin: 0 10px;
  cursor: pointer;

  .svg-inline--fa {
    width: 20px;
    height: 20px;

    path {
      fill: ${Colors.bigStone};
    }
  }
`;

export const User = styled.div`
  ${headerSection}
  border-right: none;
  justify-self: end;
  display: flex;
  align-items: center;

  .signout-link {
    border: none;
    padding: 0;
    background-color: transparent;
    color: ${Colors.curiousBlue};
    font-size: 14px;
    border-bottom: 1px solid ${Colors.curiousBlue};
    cursor: pointer;
  }
`;

export const UserName = styled.div`
  display: inline-block;
  color: ${Colors.bigStone};
  margin-right: 10px;
  font-size: 14px;
`;

export const Photo = styled.div`
  display: inline-block;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const Image = styled.div`
  overflow: hidden;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${Colors.cadetBlue};
  display: flex;
  align-items: center;

  .svg-inline--fa {
    width: 20px;
    height: 20px;
    margin: 0 auto;

    path {
      fill: #fff;
    }
  }
`;

export const UserMenuIcon = styled.div`
  position: absolute;
  bottom: -3px;
  right: -1px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${Colors.chateauGreen};
  color: #fff;
  display: flex;
  align-items: center;

  .svg-inline--fa {
    width: 16px;
    height: 16px;
    margin: 0 auto;

    path {
      fill: #fff;
    }
  }
`;

export const WarningText = styled.div`
`;

export const HeaderLinks = styled.ul`
  list-style-type: none;
  color: ${Colors.bigStone};
  padding: 0px;
  margin: 0px;

  span {
    font-size:12px;
  }
`;