import gql from 'graphql-tag';

export const AUTHORS_QUERY = gql`
  query AuthorsQuery( $perPage: Integer!, $page: Integer) {

    _authorStats {
      count
    }

    authors(perPage: $perPage, page: $page) {
      id
      oid
      email
      firstName
      middleName
      lastName
    }
  }
`;
export const AUTHOR_EDIT_QUERY = gql`
  query AuthorEditQuery($oid: Integer!) {
    author(oid: $oid) {
      oid
      id
      active
      firstName
      middleName
      lastName
      suffix
      prefix
      email
      website
      phone
      twitterName
      description
      position
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      meta {
        id
        slug
        browserTitle
        keywords
        description
      }
    }
  }
`;

export const AUTHOR_EDIT_MUTATION = gql`
  mutation AuthorUpdate ($input: AuthorUpdateInput!) {
    authorUpdate(input: $input)
    {
      authors {
        oid
        id
        firstName
        middleName
        lastName
        email
        position
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const AUTHOR_DELETE_MUTATION = gql`
  mutation AuthorDelete ($input: AuthorDeleteInput!) {
    authorDelete(input: $input)
    {
      success
      errors
      authors { id }
    }
  }
`;

export const AUTHOR_CREATE_MUTATION = gql`
  mutation AuthorCreate ($input: AuthorCreateInput!) {
    authorCreate(input: $input)
    {
      author { oid, id, firstName, lastName }
      success
      errors
    }
  }
`;

export const AUTHOR_ADD_TAXONOMIES = gql`
mutation AuthorAddTaxonomies ($input: AuthorAddTaxonomiesInput!) {
  authorAddTaxonomies (input: $input)
  {
    success
    errors
  }
}
`;

export const AUTHOR_REMOVE_TAXONOMIES = gql`
mutation AuthorRemoveTaxonomies ($input: AuthorRemoveTaxonomiesInput!) {
  authorRemoveTaxonomies (input: $input)
  {
    success
    errors
  }
}
`;