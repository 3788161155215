import React, { useEffect, useState } from 'react';
import NavList from './navList';
import SitesSelect from 'components/forms/select/sites';
import * as Style from './style';
import { ReactComponent as Logo } from 'svg/continuum-logo-white.svg';
import { FAL } from 'icons/fa';
import Data from './data';

function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  const value = localStorage.getItem(key);
  return value;
}

function mouseEnter() {
  document.body.classList.add('-nav-mouseenter');
}

function mouseLeave() {
  document.body.classList.remove('-nav-mouseenter');
}

const Navigation = () => {
  const [data, setData] = useState(Data);
  const isNavCollapsed = getLocalStorage('-nav-collapsed');

  useEffect(() => {
    if (isNavCollapsed === "true") {
      document.body.classList.add('-nav-collapsed');
    }
  }, [isNavCollapsed]);

  /**
   * Fire custom event on nav size change
   * @param {string} value
   */
  function fireCustomEvent(value: string) {
    const customEvent = new CustomEvent("continuum.onNavSizeChange", { "detail": { "isNavCollapsed": value}  } as unknown);
    document.dispatchEvent(customEvent);
  }

  function toggleMenu() {
    const classList = document.body.classList;
    const name = '-nav-collapsed';

    classList.toggle(name);

    if (classList.contains(name)) {
      setLocalStorage(name, 'true');
      fireCustomEvent('true');
    } else {
      setLocalStorage(name, 'false');
      fireCustomEvent('false');
    }
  }

  function toggleSubNav(e, index) {
    e.preventDefault();
    const openParentID = data.openParentID === index ? '' : index;
    setData({ ...data, openParentID });
  }

  /**
   * Manage to redirect and open sub navigation at a same time when click on parent navigation item
   */
  function toggleNavList(e, index) {
    const openParentID = data.openParentID === index ? '' : index;
    setData({ ...data, openParentID });
  }

  return (
    <Style.Nav onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <Style.Header>
        <Style.MenuIconWrapper onClick={toggleMenu}>
          <FAL icon="bars" />
        </Style.MenuIconWrapper>
        <Style.LogoWrapper><Logo /></Style.LogoWrapper>
      </Style.Header>

      <NavList data={data} toggleSubNav={(e, index) => toggleSubNav(e, index)} toggleNavList={(e, index) => toggleNavList(e, index)}/>
      <SitesSelect />
    </Style.Nav>
  );
}

export default Navigation;
