import { useAuthPrivilege } from 'hooks/useAuthPrivilege';
import { ADD_KEYWORD } from 'constants/index';

//Common hook for write new keyword privilege
export function useKeywordPrivilege() {

  const { hasPrivilege, loading, error } = useAuthPrivilege({ privilege: ADD_KEYWORD });

  return {
    hasPrivilege,
    loading,
    error
  };
}