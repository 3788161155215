import pluralize from 'pluralize';

export const RELATED_MUTATION = (mutationType: string, ids: Array<string>, addRemove: string, rootName: string, rootIdType: string, sortingOrder?: boolean) => {
  let query = '';
  //First char convert in uppercase
  const mutationTypeName = (mutationType === 'audio') ? 'audios' : pluralize.plural(mutationType);
  const mutationTypeNameUpperCase = mutationTypeName.charAt(0).toUpperCase() + mutationTypeName.slice(1);
  const IdsList = `${mutationType}Ids`;
  const variables = {};
  variables[IdsList] = ids;
  const param = `$${IdsList}:[${rootIdType}] ,`;
  const mutationName = `${rootName}${addRemove}${mutationTypeNameUpperCase}`;
  if (sortingOrder) {
    query = `${mutationName}(input: { ${IdsList}: $${IdsList}, ${rootName}Id: $${rootName}Id, sortingOrders: $sortingOrders }) {
      ${commonRelatedBody(mutationTypeName)}
    }`;
  } else {
    query = `${mutationName}(input: { ${IdsList}: $${IdsList}, ${rootName}Id: $${rootName}Id }) {
      ${commonRelatedBody(mutationTypeName)}
    }`;
  }

  return { mutationName, variables, param, query }
}

export const commonRelatedBody = (type: string) => {
  let title = 'title';
  if (['companies', 'accounts', 'agencies', 'products', 'variations', 'bundledProducts', 'contentProducts', 'relatedCompanies', 'relatedProducts'].includes(type)) {
    title = 'title:name';
  }
  return `
    ${type} {
      oid,
      id
      ${title}
    }
    errors
    success`;
}
