import gql from 'graphql-tag';

export const REDIRECT_EDIT_QUERY = gql`
  query RedirectEditQuery($oid: Integer!) {
    redirect(oid: $oid) {
      id
      oid
      friendlyUrl
      actualUrl
      redirectType
    }
  }
`;

export const REDIRECT_EDIT_MUTATION = gql`
  mutation RedirectUpdate ($input: RedirectUpdateInput!) {
    redirectUpdate(input: $input)
    {
      redirects { id, friendlyUrl, actualUrl, redirectType }
      success
      errors
    }
  }
`;

export const REDIRECT_DELETE_MUTATION = gql`
  mutation RedirectDelete ($input: RedirectDeleteInput!) {
    redirectDelete(input: $input)
    {
      success
      errors
      redirects { id }
    }
  }
`;

export const REDIRECT_CREATE_MUTATION = gql`
  mutation RedirectCreate ($input: RedirectCreateInput!) {
    redirectCreate(input: $input)
    {
      redirect { id, oid, friendlyUrl, actualUrl, live, redirectType, __typename }
      success
      errors
    }
  }
`;