import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PER_PAGE_RECORDS } from 'constants/index';
import { Context as OrderContext } from "components/order/context";
import openNotificationWithIcon from 'utils/notification';


interface Props {
  query: string,
  queryVariables: any,
  totalRecords: number,
  returnData: (data: unknown, selected?: boolean, hasError?: boolean) => void,
  exportDataKey: string,
  setCurrentPage: Function,
}

/**
 * FetchData will call api and return result of all or filtered data of table
 * @param {Props.query} query query to be executed
 * @param {Props.queryVariables} queryVariables queryVariables contains variable for run query
 * @param {Props.totalRecords} totalRecords indicates how many records needs to be fetched from api
 * @param {Props.returnData} returnData callback to return api data
 * @param {Props.exportDataKey} exportDataKey data fetch key to retrive record
 */

export function FetchData({ totalRecords, returnData, query, queryVariables, exportDataKey, setCurrentPage } : Props) {
  const [state, setState] = useState({
    isAllDataReceived: false,
    page: 1
  });
  const { isAllDataReceived, page } = state;
  const totalPages = Math.ceil(totalRecords / PER_PAGE_RECORDS);
  const { loading, error, data, fetchMore } = useQuery(query, { variables: { perPage: PER_PAGE_RECORDS, page: 1, ...queryVariables }, fetchPolicy: 'cache-first',
  onError: (e) => {
    setState((prevState) => ({
      ...prevState,
      isAllDataReceived: false,
      page: 1,
    }));

    returnData([], true, true);

    openNotificationWithIcon({
      type: "error",
      title: e.message,
    }, () => {});

  } });

  /**
   * Fetch next page
   * @param {number} nextPage
   * @returns
   */
  function fetchNextPage(nextPage: number) {
    if (page <= 1) return;

    fetchMore({
      variables: { perPage: PER_PAGE_RECORDS, ...queryVariables, page: nextPage },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...fetchMoreResult,
          [exportDataKey]: [ ...prev[exportDataKey], ...fetchMoreResult[exportDataKey] ],
        };
      },
    });
  }

  /**
   * Update parent component with data once all pages received
   * Till that increment page by 1
   */
  useEffect(() => {
   // debugger;
    if (!data) return;
    // Return first page data if totalpages are 1
    if (totalPages === 1 && page === 1) {
      setState((prevState) => ({
        ...prevState,
        isAllDataReceived: true,
      }));
      returnData(data[exportDataKey], true);
      return;
    }
  //  debugger;
    // Increment page by 1 until page will be greater than totalPages
    if (totalPages > page) {
      setState((prevState) => ({
        ...prevState,
        page: prevState.page + 1
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isAllDataReceived: true,
      }));
      returnData(data[exportDataKey], true);
    }
  }, [data]);

  /**
   * Fetch next page until page will be greater than totalPages
   */
  useEffect(() => {
    if (totalPages < page || isAllDataReceived) return;
    fetchNextPage(page);

    if (totalPages === page) {
      setCurrentPage(100);
      return;
    }
    const progress = Math.round((page*100)/totalPages);
    if (isFinite(progress)) {
      setCurrentPage(progress);
      return;
    }
    setCurrentPage(1);

  }, [page]);

  if (error) return <div>{error.message}</div>;
  if (loading || !isAllDataReceived) return null;
  if (!queryVariables) return null;
  return null;
};