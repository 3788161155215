//TODO : generate one array for handle keys
export const Headers = [
    'ID',
    'TITLE',
    'IMAGE PATH',
    'DATE CREATED',
    'DATE MODIFIED',
    'DIMENSIONS',
    'SIZE',
    'ALT TEXT',
    'CREDIT',
    'CAPTION',
    'SEARCHABLE',
    'DESCRIPTION',
    'TOPICS',
    'SITE PLACEMENT',
    'CLASSIFICATION',
    'CATEGORIES',
    'ASSIGNED TO'
  ];
  
  export const ImageKeys = [
    'oid',
    'title',
    'url',
    'createdAt',
    'updatedAt',
    'heightwidth',
    'fileSize',
    'altText',
    'credit',
    'caption',
    'searchable',
    'description',
    'topics',
    'sitePlacements',
    'classifications',
    'taxonomiesDescendedFrom',
    'assignedTo',
  ];
  

  
