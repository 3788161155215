import React, { useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import classNames from 'classnames';
import { SITES } from 'graphql/entry';
import { environmentOptions } from 'utils/helpers';
import * as css from "../style";
import Auth from 'auth';
import { setCookie } from 'utils/cookiesHelper';

const Option = Select.Option;

export interface LabeledValue {
  key: string;
  label: React.ReactNode;
}

type Props = {
  maxWidth?: string,
};

const SiteSelect = ({ maxWidth="220px", ...rest }: Props) => {
  const {data, error, loading} = useQuery(SITES);
  const popUpContainer = useRef();

  const onClickHandler = (val) => {
    if (val && val.key) {
      // Set auth token on cookie such that it will be available to siblings
      setTokenInCookie();

      const newPageUrl = 'https://'+val.key+'.continuum.epublishing.com';
      window.open(newPageUrl, "_blank");
    }
  }

  /**
   * Set auth token on cookie
   * and make it available on siblings site
   */
  function setTokenInCookie() {
    const token = Auth.getFullToken();
    const ENV_OPTIONS = environmentOptions();
    const sites = data?.context?.siblings?.map((sibling) => {
      return sibling;
    });
    sites.push(ENV_OPTIONS.HOST);

    /* example of cookie data
      epub-multisite-session: {
        sites: Array<hostnames>,
        token: {tokenCreate: <token>, expires: <time>},
        env: <environment>  // environment can be: production/stage
      }
    */
    setCookie("epub-multisite-session", JSON.stringify({sites, token, env: ENV_OPTIONS.X_ENV}));
  }

  if (!data) return null;
  if (error) return (<> `Error! ${error.message}`</>);
  const childrenExist = (typeof data?.context?.siblings !== 'undefined' && data?.context?.siblings.length > 0);

  const classes = classNames({
    'epub-react-select': true,
    'sites-select': true,
    '-is-dark': true,
    '-is-light': false,
    '-is-mini': false,
  });

  return (
    (childrenExist) ? (<css.Wrapper maxWidth={maxWidth}>
      <css.PopoverContainer className={'-is-dark'} ref={popUpContainer} maxWidth={maxWidth}></css.PopoverContainer>
      <Select
        loading={loading}
        onChange={onClickHandler}
        placeholder="Sites"
        className={classes}
        getPopupContainer={() => popUpContainer.current}
        allowClear={true}
        labelInValue={true}
        {...rest}
      >
        {childrenExist && data?.context?.siblings.map((p, ind) => (
          <Option
            key={ind}
            value={p}
          >{p}</Option>
        ))}
      </Select>
    </css.Wrapper>) : (<div></div>)
  );
};

export default SiteSelect;
