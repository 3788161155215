import { css } from 'styled-components';
import { Align } from './align';

export const Iframe = css`
  .epub-iframe-embed {
    ${Align}
    position: relative;

    iframe { display: block }

    &.-responsive {
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.-align-center {
      iframe { margin-left: auto; margin-right: auto; }
    }
  }
`;
