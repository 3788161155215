import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { isFunction } from 'utils/helpers';
import * as css from './style';

interface Props {
  ptext?: string, // Placeholder
  theme?: 'light' | 'dark',
  hint?: string,
  defaultValue?: string,
  width?: string,
  height?: string,
  name?: string,
  error?: boolean,
  errorMsg?: string,
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void,
}

interface State {
  focused: boolean,
  changed: boolean,
  fieldValue: string,
};

const Textarea: React.FC<Props> = ({ theme='light',  error=false, errorMsg='This field is required.', ...props }) => {
  const [state, setState] = useState<State>({
    focused: false,
    changed: false,
    fieldValue: '',
  });
  let inputField = useRef(null);

  function focusHandler() {
    if (!inputField) return;

    if (inputField.current.value === '') {
      setState({ ...state, focused: true, changed: true });
    }
  }

  function blurHandler(e) {
    if (!inputField) return;

    if (inputField.current.value === '') {
      setState({ ...state, focused: false, changed: true });
    }

    if (isFunction(props.onBlur)) {
      props.onBlur(e);
    }
  }

  /**
   * Apply the onChange prop callback, then set the state of the field value.
   *
   * @param {*} e The textarea event.
   */
  function onChangeHandler(e) {
    onChange(e);
    setState({ ...state, fieldValue: e.target.value });
  }

  const { focused, changed, fieldValue } = state;
  const { hint, ptext, defaultValue, width, height, onChange, name } = props;

  const classes = classNames({
    '-is-focused': focused || (defaultValue && !changed),
    '-dark-theme': theme === 'dark',
    '-has-placeholder': typeof ptext !== 'undefined',
    '-has-error': error,
  });

  return (
    <css.Container className={classes}>
      <css.Wrapper width={width} height={height}>
        {ptext && <css.Placeholder>{ptext}</css.Placeholder>}
        <textarea
          ref={(input) => inputField.current = input}
          name={name}
          onFocus={focusHandler}
          onBlur={blurHandler}
          defaultValue={fieldValue || defaultValue}
          onChange={onChangeHandler} />
      </css.Wrapper>
      {hint && <css.Hint dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(hint)}}></css.Hint>}
      {error && <css.ErrorMsg>{errorMsg}</css.ErrorMsg>}
    </css.Container>
  );
};

export default Textarea;
