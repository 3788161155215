import { css } from 'styled-components';

export const BASE_FONT_SIZE = 16;

export const shadow = css`
  box-shadow: 1px 2px 1px 0 rgba(0,0,0,0.06);
`;

export const dropShadow = css`
  box-shadow: 0 1px 3px rgba(0,0,0,0.10);
`;

export const sansSerif = css`
  font-family: 'Lato', sans-serif;
`;

export const smoothAnimation = css`
  300ms cubic-bezier(0.4, 0, 0.2, 1)
`;

/**
 * A mixin style for adding a media query style. Converts pixels to ems for you based on the base font size.
 * @param min The size of the min-width in pixels.
 * @param max The size of the max-width in pixels.
 * @example
 *  // min and max
 *  ${breakpoint(0, 900)(css` display: block; `)}
 *  // only min
 *  ${breakpoint(400)(css` display: block; `)}
 *  // only max
 *  ${breakpoint(null, 900)(css` display: block; `)}
 */
export const breakpoint = (min?: string | number | null, max?: string | number | null,) => (cssContent: Array<string>) => {
  const pxToEM = (val) => `${(val / BASE_FONT_SIZE)}em`;
  const isNotDefined = (val) => typeof val === 'undefined' || val === null;
  let query: string;

  if (min >= 0 && isNotDefined(max)) {
    query = `(min-width: ${pxToEM(min)})`;
  } else if (isNotDefined(min) && max >= 0) {
    query = `(max-width: ${pxToEM(max)})`;
  } else if (min >= 0 && max >= 0) {
    query = `(min-width: ${pxToEM(min)}) and (max-width: ${pxToEM(max)})`;
  }

  return css`
    @media only screen and ${query} {
      ${cssContent}
    }
  `;
};
