import { css } from 'styled-components';

export const Align = css`
  &.-align-left {
    float: left;
    margin-right: 10px;
  }

  &.-align-right {
    float: right;
    margin-left: 10px;
  }

  &.-align-center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;
