import { useContext } from 'react';
import { format } from 'date-fns';
import { useApolloClient } from '@apollo/react-hooks';
import { Context } from './context';
import { deepFlatten } from "utils/helpers";
import { SEARCH_AUDIO_RECORDS_EXPORT } from 'graphql/entry';
import { HEADERS, FILE_KEYS, DOWNLOAD_DATE, USER_KEYS } from 'views/reports/fileReport/constants';
import { SearchAudioRecordsExport_search_item_Audio } from 'graphql/types/SearchAudioRecordsExport';

/**
 * A React Hook to handle to duplicate Event
 * @return {object.duplicateLoading} duplicateLoading - Boolean value to show loading state while till process completed
 * @return {object.duplicateEvent} duplicateEvent - A callback that handles event input data to duplicate
  */
const useAudioExportData = ({ setCompleted }) => {
  const { state: { queryVariables } } = useContext(Context);
  const client = useApolloClient();

  /**
   * manipulateData customise and reorder row data as per csv column order
   * @param {SearchAudioRecordsExport_search_item_Audio} row contains particular table row data
   * @param {number} fileDownLoadInd contains index of file download item
   */
  function manipulateData(row: SearchAudioRecordsExport_search_item_Audio, fileDownLoadInd?: number) {
    // Fetch title and filename column data
    const fileData = FILE_KEYS.map((key: string) => {
      if (key === 'absoluteUrl') {
        const Url = row[key] && String(row[key]);
        const fileName = Url.substring(Url?.lastIndexOf('/') + 1, Url?.lastIndexOf('?'));
        return (fileName) ? fileName : '';
      }
      return (row && row[key]) ? row[key] : '';
    });

    // Fetch userid and other users column data i.e firstname, lastname etc
    const userData = USER_KEYS.map((key: string) => {
      return (row.fileDownloads[fileDownLoadInd]?.user && row.fileDownloads[fileDownLoadInd]?.user[key]) ? row.fileDownloads[fileDownLoadInd]?.user[key] : '';
    });

    // Fetch download date and taxonomies data
    const downloadDate = DOWNLOAD_DATE.map((key: string) => {
      if (key === 'taxonomies') {
        const tax = row[key]?.map((item) => item.title).join(", ");
        return tax;
      }
      return (row.fileDownloads[fileDownLoadInd]?.['downloadedAt']) ? format(new Date(String(row.fileDownloads[fileDownLoadInd]?.['downloadedAt'])), 'LLLL dd, yyyy') : '';
    });

    return [...fileData, ...downloadDate, ...userData];
  }

  async function mergeData(totalPages, indexData, id) {
    let dynamicData = [];
    let obj = {}
    for (let index = 1; index <= totalPages; index++) {
      const variableCopy = {
        ...queryVariables,
        downloadPage: index
      }
      try {
        const { data } = await client.query({
          query: SEARCH_AUDIO_RECORDS_EXPORT,
          variables: variableCopy,
        });
        dynamicData.push(...data.search[indexData]['item']['fileDownloads'])
      } catch (err) {
        console.log(err)
      }
    }
    obj[id] = dynamicData;
    return obj;
  }
  async function iterateOverDownload(recordData) {
    const y = await Promise.all(recordData.map(async (element, index) => {
      if (element.item._fileDownloadStats.count > 100) {
        const totalPages = Math.ceil(element.item._fileDownloadStats.count / 100);
        return await mergeData(totalPages, index, element.item.id)
      }
    }));
    return y
  }
  //works as callback function into export
  async function getData(recordData) {
    const allFileDownloads = await iterateOverDownload(recordData);
    recordData.map((element) => {
      for (const [key, fileDownloadList] of Object.entries(allFileDownloads)) {
        if (fileDownloadList) {
          for (const [fileId, fileDownloads] of Object.entries(fileDownloadList)) {
            if (element.item.id === fileId) {
              element['item']['fileDownloads'] = fileDownloads;
            }
          }
        }
      }
    });
    const data = recordData.map((value) => (value.item.fileDownloads?.length > 0) ? value.item.fileDownloads?.map((v, ind) => manipulateData(value.item, ind)) : manipulateData(value.item));

    const exportData = deepFlatten(data, HEADERS.length);

    return [HEADERS, exportData];
  }

  return {
    getData
  }
}

export default useAudioExportData;