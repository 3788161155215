import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Colors from 'styles/colors';
import { smoothAnimation } from 'styles/mixins';

export const Nav = styled.nav`
  background-color: ${Colors.nileBlue};
  overflow-y: auto;
  width: 240px;
  height: 100%;
  min-height: 100vh;
  grid-row: 1;
`;

export const NavList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const SubNavListWrapper = styled.div`
  overflow: hidden;
  transition: height ${smoothAnimation};
  height: 0;

  &.-is-expanded {
    height: ${(props) => props.childHeight}px
  }
`;

export const SubNavList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LI = styled.li`
  list-style: none;

  .-nav-collapsed & {
    width: 59px;
  }

  &:hover {
    z-index: 2;
    position: relative;
    width: 240px;
  }
`;

export const Link = styled(NavLink).attrs({
  activeClassName: '-is-current',
})`
  color: ${Colors.zumthor};
  font-size: 16px;
  text-decoration: none;
  display: flex;
  width: 240px;
  border-left: 10px solid ${Colors.nileBlue};
  transition: all 200ms ease-in-out;
  background-color: ${Colors.nileBlue};

  &:hover {
    background-color: ${Colors.pictonBlue};
    color: #fff;
    border-left: 10px solid ${Colors.curiousBlue};
  }

  &.-is-current {
    background-color: ${Colors.pictonBlue};
    color: #fff;
    border-left: 10px solid ${Colors.curiousBlue};
  }

  ${(props) => props.highlight === 'true' && css`
    color: ${Colors.pictonBlue};
  `}

  svg.svg-inline--fa {
    margin-right: 19px;
    display: inline-block;
    height: 20px;
    width: 22px;
  }
`;

export const LISub = styled.li`
  list-style: none;
`;

export const SubLink = styled(NavLink).attrs({
  activeClassName: '-is-current',
})`
  padding: 5px 30px;
  color: ${Colors.zumthor};
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-left: 10px solid ${Colors.nileBlue};
  background-color: ${Colors.nileBlue};
  transition: all 200ms ease-in-out;

  &:hover {
    border-left: 10px solid ${Colors.chateauGreen};
    filter: brightness(115%);
  }

  &.-is-current {
    border-left: 10px solid ${Colors.chateauGreen};
    filter: brightness(115%);
    color: ${Colors.pictonBlue};
  }
`;

export const LinkInner = styled.span`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  flex: 1;
`;

export const Arrow = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: ${Colors.pickledBlueWood};
  color: ${Colors.nevada};
  width: 37px;
  text-align: center;

  svg.svg-inline--fa {
    margin: 0 auto;
  }

  ${Link}:hover & {
    background-color: ${Colors.curiousBlue};
    color: #fff;
  }

  ${Link}.-is-current & {
    background-color: ${Colors.curiousBlue};
    color: #fff;
  }
`;

export const Header = styled.section`
  padding: 20px 15px;
  position: relative;

  display: flex;
  align-items: center;
`;

export const MenuIconWrapper = styled.i`
  cursor: pointer;
  line-height: 1;

  .bars {
    width: 29px;
    height: 33px;

    path {
      fill: ${Colors.nevada};
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  svg {
    margin-left: 5px;
  }
`;
