import gql from 'graphql-tag';

export const LOCK_CREATE = gql`
  mutation LockCreate ($input: LockCreateInput!) {
    lockCreate(input: $input)
    {
      lockExpiresAt
      success
      errors
    }
  }
`;

export const LOCK_DELETE = gql`
  mutation LockDelete ($input: LockDeleteInput!) {
    lockDelete(input: $input)
    {
      success
      errors
    }
  }
`;