import React, { useReducer, useState, useEffect } from 'react';
import openNotificationWithIcon from 'utils/notification';
import { Image } from 'components/forms/uploader/types';
import { reducer } from './reducer';

export interface Parent {
  id: string,
  __type: string,
}

export interface MediaVideo_meta {
  id: IDType;
  browserTitle: string | null;
  keywords: string | null;
  description: string | null;
}
export interface valueTypes {
  id?: string,
  title?: string,
  url?: string,
  name?: string,
  description?: string,
  active?: boolean,
  selectable?: boolean,
  parent?: Parent,
  parentId?: string,
  oid?: IDType,
  sortingOrder?: string,
  taxonomyNameForSelect?: string,
  taxonomyPlaceholderForSelect?: string,
  allowMultipleSelection?: boolean,
  onlyLive?: boolean,
  taxonomies?: Array<string>,
  filterStrings?: Array<string>,
  updateTaxonomy?: Function,
  onUpdate?: Function,
  children?: Array<valueTypes>,
  _childStats?: {
    count: number
  },
  type?: 'add' | 'edit' | 'delete',
  hidden?: boolean,
  fromLeftPanel?: boolean,
  toggleShow?: Function,
  sitePlacements?: Array<string>,
  oldParentId?: string,
  existSitePlacements?: Array<string>,
  itemTypes?: Array<string>,
  image?: Image,
  legacyId?: string,
  newWindow?: boolean,
  isPlanDate?: boolean,
  metaId?: IDType,
  meta?: MediaVideo_meta | null,
  taxonomyColor?: any,
  uiStyle?: string,
};

interface UseFormFunc {
  errors: { [key: string ]: any },
  values: any,
  setValues: React.Dispatch<React.SetStateAction<any>>,
  setErrors: React.Dispatch<React.SetStateAction<any>>,
  setSubmitting: React.Dispatch<React.SetStateAction<any>>,
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>,onSuccessCallback:() => void) => void,
  onSuccessCallback: () => void,
  handleChange: (e: any) => void,
}

/**
 * A form hook to handle form field values, validation, onChange, and submit callback.
 *
 * @param initialValue Set the initial value of your form fields
 * @param callback The submit handler that is called after form submission, originating from a button element.
 * @param validate Custom validation for the form.
 * @example
 *  const {
 *    errors,
 *    values,
 *    setValues,
 *    setSubmitting,
 *    handleSubmit,
 *    onSuccessCallback,
 *    handleChange,
 *  } = useForm({
 *    title: '',
 *  },
 *  submitHandler,
 *  (values) => {
 *    let errors = {};
 *    if (!values.title) errors['title'] = "The title is required";
 *    return errors;
 *  });
 *
 * @type {useFormFunc}
 * @property {object} errors Field names and values (key/value) are added to this object as an error is detected.
 * @property {any} values On the change of each field state is set by adding a value to this object.
 * @property {SetStateAction} setValues Optional, if you need to trigger setState internal of useForm hook.
 * @property {SetStateAction} setSubmitting Optional, set submitting true/false
 * @property {function} handleSubmit Trigger this function on a button when you are ready to submit the form.
 * @property {function} handleChange Trigger this function with onChange of a form element field.
 * @returns {useFormFunc} The object you will want to spread in your component to hook into this useForm React Hook.
 * @returns {shouldMergeValues} merge values of state or replace values of state
 */
const useForm = (initialValue: any, callback: (values: any) => void, validate: (values: any) => { title?: string }, shouldMergeValues=true): UseFormFunc => {
  const [state, dispatch] = useReducer(reducer, {
    values: initialValue,
    errors: {},
    isSubmitting: false,
    onSuccessCallback: null,
  });
  const { values, errors, isSubmitting, onSuccessCallback } = state;

  // Call the passed callback if no errors are present and the form was submitted
  useEffect(() => {
    const errorLength = Object.keys(errors).length;

    if (errorLength === 0 && isSubmitting) {
      callback(values);
    } else if (errorLength > 0 && isSubmitting && errors.hasOwnProperty('slug')) {
      openNotificationWithIcon({
        type: 'error',
        title: "Slug field is required",
      }, () => { });
    } else if (errorLength > 0 && isSubmitting) {
      openNotificationWithIcon({
        type: 'error',
        title: "Some form fields are required.",
      }, () => { });
    }
  }, [errors]);

  /**
   * The submit handler that checks for validation on form field values, and sets a flag that form is submitting.
   */
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>,onSuccessCallback:() => void) => {
    e.preventDefault();
    dispatch({ type: "submit", payload: {
      errors: validate(values),
      onSuccessCallback,
    }});
  };

  /**
   * On the field change, set the value state using the field name and value.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.persist) {
      e.persist();
    }

    let value: string | boolean = e.target.value;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    const action = {
      type: !shouldMergeValues ? "values" : "mergeValues",
      payload: {
        values: !shouldMergeValues ? { ...values, [e.target.name]: value } : { [e.target.name]: value }
      },
    }

    dispatch(action);

    if (errors[e.target.name]) {
      delete errors[e.target.name];
      dispatch({ type: "errors", payload: { errors }});
    }
  };

  function setErrors(errors) {
    dispatch({ type: "errors", payload: { errors }});
  }

  function setValues(values) {
    dispatch({ type: "values", payload: { values }});
  }

  function setSubmitting(value) {
    dispatch({ type: "submitting", payload:  value });
  }

  return {
    errors,
    setErrors,
    values,
    setValues,
    setSubmitting,
    handleSubmit,
    onSuccessCallback,
    handleChange,
  };
};

export default useForm;
