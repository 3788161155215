interface State {
  values: any,
  errors: { [key: string ]: any },
  isSubmitting: boolean,
  onSuccessCallback: () => void,
}

export function reducer(state: State, action) {
  switch (action.type) {
    case "submit":
      const { errors, onSuccessCallback } = action.payload;

      return {
        ...state,
        errors,
        onSuccessCallback,
        isSubmitting: true,
      };
    case "values":
      const { values } = action.payload;

      return {
        ...state,
        values,
      };
    case "errors":
      return {
        ...state,
        errors: action.payload.errors,
      };
    case "submitting":
        return {
          ...state,
          isSubmitting: action.payload.submitting,
        };
    case "mergeValues":
      const { values: actionPayloadValues } = action.payload;

      return {
        ...state,
        values: { ...state.values, ...actionPayloadValues },
      }
    default:
      throw Error(`The action.type ${action.type} does not exist in the Layout Context.`);
  }
}
