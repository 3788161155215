const addTypename = (list, depth = 0) => list.map((item) => {
  item['__typename'] = `navigation-${item.id}-${depth}`;
  item.highlight = item.highlight ? item.highlight : 'false';
  item.icon = item.icon || '';
  item.children = Array.isArray(item.children) ? addTypename(item.children, depth + 1) : [];
  return item;
});

export default {
  __typename: 'navigation',
  openParentID: '',
  subNavHeight: '',
  links: addTypename([
    { id: 2, to: '/dashboard', icon: 'inbox', text: 'Dashboard' },
    {
      id: 3, to: '/editorial', icon: 'copy', text: 'Editorial', children: [
        { id: 301, to: '/workflow/articles', text: 'Workflow' },
        { id: 302, to: '/articles', text: 'Articles' },
        { id: 303, to: '/authors', text: 'Authors' },
        { id: 304, to: '/blogs', text: 'Blogs' },
        { id: 305, to: '/comments', text: 'Comments' },
        { id: 306, to: '/editorial-content', text: 'Editorial Content' },
        { id: 307, to: '/galleries', text: 'Image Galleries' },
        { id: 308, to: '/navigation', text: 'Navigation' },
        { id: 309, to: '/newsletters', text: 'Newsletters' },
        { id: 310, to: '/pages', text: 'Pages' },
        { id: 311, to: '/publications', text: 'Publications' },
        { id: 312, to: '/redirects', text: 'Redirects' },
        { id: 313, to: '/rss', text: 'RSS' },
      ],
    },
    { id: 4, to: '/taxonomy', icon: 'tags', text: 'Taxonomy' },
    { id: 5, to: '/siteplacement', icon: 'tags', text: 'Site Placement' },
    {
      id: 6, to: '/media', icon: 'cloud-upload', text: 'Media',
    },
    { id: 8, to: '/revenue', icon: 'box-usd', text: 'Revenue', children: [
      { id: 801, to: '/classifieds', text: 'Classifieds' },
      { id: 802, to: '/directory', text: 'Directories' },
      { id: 803, to: '/events', text: 'Events' },
      { id: 804, to: '/offers', text: 'Offers' },
      { id: 805, to: '/orders', text: 'Orders' },
      { id: 806, to: '/products', text: 'Products' },
      { id: 807, to: '/sales-tax', text: 'Sales Tax' },
      { id: 808, to: '/shipping-rates', text: 'Shipping Rate' },
      { id: 809, to: '/shipping-zones', text: 'Shipping Zones' },
    ]},
    {
      id: 9, to: '/marketing', icon: 'bullhorn', text: 'Marketing', children: [
        { id: 901, to: '/polls', text: 'Polls' },
        { id: 902, to: '/reports', text: 'Reports' },
      ],
    },
    {
      id: 10, to: '/advanced', icon: 'cog', text: 'Advanced', children: [
        { id: 1001, to: '/custom-fields', text: 'Custom Fields' },
        { id: 1002, to: '/metadata', text: 'Metadata' },
        { id: 1003, to: '/settings', text: 'Settings' },
      ],
    },
    { id: 11, to: '/users', icon: 'users', text: 'Users' },
    { id: 12, to: '/roles-privileges', icon: 'user-cog', text: 'Roles & Privileges'},
    { id: 13, to: '/import/article-feeds', icon: 'box-up', text: 'Article Feeds' },
  ]),
};
