import styled from 'styled-components';
import Colors from "styles/colors";

export const Notice = styled.section`
  border-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  &.-success {
    background-color: ${Colors.blueRomance};
    border: 1px solid ${Colors.fringyFlower};

    .svg-inline--fa { color: ${Colors.hippieGreen}; }
  }

  &.-error {
    background-color: ${Colors.cherub};
    border: 1px solid ${Colors.cavernPink};

    .svg-inline--fa { color: ${Colors.roseTaupe}; }
  }

  &.-caution {
    background-color: ${Colors.drover};
    border: 1px solid ${Colors.arylideYellow};

    .svg-inline--fa { color: ${Colors.sahara}; }
  }

  &.-notice {
    background-color: ${Colors.iceburg};
    border: 1px solid ${Colors.beaublue};

    .svg-inline--fa { color: ${Colors.pictonBlue}; }
  }

  .svg-inline--fa {
    margin-right: 15px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 1.35;
`;
