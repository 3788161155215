export const DBConfig = {
  name: 'SearchDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'user',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
        { name: 'lastLoginAt', keypath: 'lastLoginAt', options: { unique: false } },
        { name: 'status', keypath: 'status', options: { unique: false } },
        { name: 'expires', keypath: 'expires', options: { unique: false } },
        { name: 'trial', keypath: 'trial', options: { unique: false } },
        { name: 'publication', keypath: 'publication', options: { unique: false } },
        { name: 'newsletter', keypath: 'newsletter', options: { unique: false } },
        { name: 'customField', keypath: 'customField', options: { unique: false } },
      ]
    }
  ]
};