//TODO : generate one array for handle keys
export const Headers = [
  'SITE',
  'CUSTNUM',
  'LASTNAME',
  'FIRSTNAME',
  'COMPANY',
  'ADDRESS1',
  'ADDRESS2',
  'CITY',
  'STATE',
  'COUNTRY',
  'ZIPCODE',
  'PHONE',
  'PHONE2',
  'PHONE3',
  'EMAIL',
  'ORDER_DATE',
  'ORDER_NUM',
  'ORDER_STATUS',
  'ORDER_TOTAL',
  'TRANS_ID',
  'CC_NUM',
  'PROMO CODE',
  'SHIPPING',
  'SHIPPING_METHOD',
  'TAX',
];

export const ProductExportHeader = [
  'PRODUCT ID', 
  'PRODUCT NAME', 
  'PRODUCT QTY', 
  'PRODUCT COST', 
  'PRODUCT WEIGHT LBS', 
  'PRODUCT INTERNAL ID',
  'PRODUCT EXTERNAL ID',
  'PRODUCT WEIGHT OZ',
]
export const ShippingHeader = [
  'SLASTNAME',
  'SFIRSTNAME',
  'SCOMPANY',
  'SADDRESS1',
  'SADDRESS2',
  'SCITY',
  'SSTATE',
  'SCOUNTRY',
  'SZIPCODE',
  'SPHONE',
  'SEMAIL',
];

export const ProductHeader = [
  'ORDER NOTES',
];

export const UserKeys = [
  'oid',
];

export const OrderKeys = [
  'lastName',
  'firstName',
  'company',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postalCode',
  'phone',
  'phone2',
  'phone3',
  'email',
  'createdAt',
  'oid',
  'status',
  'amountPaid',
];

export const OrderTypeCodeTotalKey = [
  'totalShippingCost',
  'shippingType'
];

export const ShippingKeys = [
  'lastName',
  'firstName',
  'company',
  'address1',
  'address2',
  'city',
  'state',
  'country',
  'postalCode',
  'phone',
  'email'
];

export const taxKey = [
  'totalSalesTax'
];

export const PaymentKeys = [
  'transactionId',
  'cardNumberLastFourDigits',
];
export const OfferKeys = [
  'name'
]

export const ProductDetail = [
  'note',
]