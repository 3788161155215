import styled from 'styled-components';
import Colors from 'styles/colors';
import { em } from 'polished';

export const Wrapper = styled.div`
  font-family: 'Lato', sans-serif;
  flex: 1;

  .epub-react-select.sites-select {
    padding-left: 16px;
  }
  .epub-react-select.sites-select .ant-select-selection {
    background-color: ${Colors.pickledBlueWood};
  }
  .epub-react-select.sites-select .ant-select-selection .ant-select-selection__rendered {
    min-width:unset;
    border-right: 1px solid ${Colors.nevada};
    width: 75% !important;
  }
  .epub-react-select.sites-select .ant-select-selection .ant-select-arrow {
    margin-right: 5px !important;
    color: ${Colors.nevada};
  }
  .epub-react-select.sites-select .ant-select-selection__rendered .ant-select-selection__placeholder {
    color: ${Colors.zumthor};
  }
  .epub-react-select.sites-select .ant-select-selection .ant-select-selection-selected-value {
    color: ${Colors.zumthor};
  }
  .epub-react-select {
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '300px'};

    &__control {
      border-color: transparent;
      box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    }

    &.-is-light .epub-react-select__control {
      background-color: rgba(255,255,255,0.8);
    }

    &.-is-dark .ant-select-selection__control {
      background-color: ${Colors.whiteSmoke};
      border-bottom: 1px solid ${Colors.mercury};
      box-shadow: none;
      border-radius: 0;
      padding: 8px 5px;
    }

    &.-is-mini .epub-react-select__control {
      background-color: rgba(255,255,255,0.8);
      box-shadow: none;
      border-radius: 0;
    }

    &__value-container {
      padding: 10px;
    }

    &.-is-mini .epub-react-select__value-container  {
      padding: 0 ${em('10px', '13px')};
    }

    &__multi-value {
      background-color: ${Colors.curiousBlue};
      color: #fff;

      &__label {
        color: #fff;
      }
    }
  }
`;

export const SearchSelectWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : "300px"};
  min-width: 200px;
  position: relative;

  .ant-input-lg {
    font-size: 14px;
  }

  > span {
    max-width: unset;
  }

  .epub-react-select {
    max-width: unset;

    &.-is-light {
      background-color: rgba(255,255,255,0.8);
    }

    &.-is-dark {
      background-color: ${Colors.whiteSmoke};
      border-bottom: 1px solid ${Colors.mercury};
      box-shadow: none;
    }

    &.-is-mini {
      background-color: rgba(255,255,255,0.8);
      box-shadow: none;
      border-radius: 0;
      padding: 0 ${em('10px', '13px')};
    }
  }
`;

export const PopoverContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : "300px"};

  .epub-select-search-popover {
    width: 100%;
  }

  .ant-tree li {
    padding: 6px 0;
  }

  li.epub-highlight {
    background-color: ${Colors.fadedGlaciers};
  }

  .ant-popover-arrow { display: none }

  .ant-popover-content {
    position: relative;
  }

  .ant-popover-placement-top .ant-popover-content {
    top: 12px;
  }

  .ant-popover-placement-bottom .ant-popover-content {
    top: -12px;
  }

  .ant-popover-inner-content {
    max-height: 300px;
    overflow: scroll;
  }

  .item-italic {
    font-style: italic;
  }
  .item-disable-italic {
    font-style: normal;
    .search-tax-result {
      cursor: not-allowed;
      div {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
      }
    }
  }
  .item-disable-italic > .ant-tree-node-content-wrapper, .item-italic.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper {
    pointer-events: none;
  }
  .item-italic.ant-tree-treenode-disabled {
    font-style: italic;
  }

  .already-selected-item > span:not(.ant-tree-switcher),
  .already-selected-item > .ant-tree-node-content-wrapper,
  .already-selected-item > .ant-tree-node-content-wrapper span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    background: ${Colors.lightGrey};
  }
`;

export const Hint = styled.span`
  color: ${Colors.cadetBlue};
  font-size: 12px;
  display: block;
  margin-top: 5px;
`;

export const ErrorMsg = styled.span`
  color: ${Colors.sunGlow};
  margin-top: 3px;
  font-size: 12px;
  display: block;
`;

export const FieldLabel = styled.div`
  color: #222;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

export const NoResult = styled.div`
  padding: 15px;
`;

export const FieldLabelTaxonomy = styled.div`
  font-weight: bold;
  margin-bottom:5px;
`;

export const KeywordSuggestionLink = styled.div`
  margin: 10px 0;
  float: right;
`;