import { useQuery } from '@apollo/react-hooks';
import { USER_AUTH_ROLES_PRIVILEGES } from 'graphql/entry';

interface Props {
  privilege: string | string[],
}

interface Args {
  privilegesFromUser: Array<{ name: string }>,
  matchTo: string | string[],
}

interface HookReturn {
  loading: boolean,
  hasPrivilege: boolean,
  privileges: string[],
  error: any,
}

/**
 * Match a single or list of privileges to the user.
 * @param privilegesFromUser User privileges array.
 * @param matchTo A single or list of privilages to match against the user.
 */
function matchPrivilegeToUser({ privilegesFromUser = [], matchTo }: Args): string[] {
  return privilegesFromUser
    .filter((p) => Array.isArray(matchTo) ? matchTo.includes(p.name) : p.name === matchTo)
    .map((n) => n.name);
}

/**
 * @typedef {HookReturn} HookReturn
 * @property {boolean} loading - The useQuery loading state.
 * @property {boolean} hasPrivilege - Are there matching privilege(s).
 * @property {boolean} privileges - The list of matching privileges. Useful for handling multiple checks on
 * a single tool or component.
 * @property {boolean} error - The useQuery error state.
 *
 *
 * Get the privileges for the currently logged in user.
 * - Cache-first policy, no need to show a loading screen on every privilege check.
 * @param {string|string[]} privilege A privilage name or list of names.
 * @returns {HookReturn}
 *
 */
export const useAuthPrivilege = ({ privilege }: Props): HookReturn => {
  const { data, loading, error } = useQuery(USER_AUTH_ROLES_PRIVILEGES, { fetchPolicy: 'network-only' });
  const privilegesFromUser = data?.context?.user?.allPrivileges;
  const privileges = matchPrivilegeToUser({ privilegesFromUser, matchTo: privilege });

  return {
    loading,
    hasPrivilege: privileges.length > 0,
    privileges,
    error,
  }
}
