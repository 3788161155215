import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SETTINGS } from 'graphql/entry';
import { ReactComponent as BeamLockSVG } from 'svg/beam-graphic.svg';
import { CssPermissionsContainer, CssSvgContainer } from 'auth/index';

interface Props {
  children: React.ReactNode,
}

export const Context = React.createContext<{ [key: string]: any }>({
  settings: {},
})

const SYSTEM_SETTINGS = [
  "site.url.base",
  "official.site.name",
  "workflow.enabled",
  "topics.top.level",
  "lock.service.enabled",
  "default.post.date.enabled",
  "hyperlinks.new.tab.value",
  "newsletter.issue.articles.insert.position",
  "integration.duet.tabs.enabled",
  "classifieds.media.tab.enabled",
  "article.pin.show.more.fields",
  "article.templates.enabled",
  "article.add.from.templates.enabled",
  "newsletter.article.use.source.url.enabled",
  "article.days.to.show.newsletter.issues",
  "article.days.to.show.publication.editions",
  "password.requirement.message.displayed",
  "password.digit.count",
  "password.length",
  "password.lowercase.count",
  "password.special.character.count",
  "password.uppercase.count",
  "dashboard.widget.ga.enabled",
  "dashboard.widget.content.enabled",
  "dashboard.widget.message.enabled",
  "classified.required.fields",
  "articles.display.export.button",
  "product.pin.show.more.fields",
  "user.impersonation.activated",
  "user.fulfillment.report",
  "newsletter.issue.date.from.postat.enabled",
  'froala.image.gallery.shortcode.enabled',
  'workflow.all.articles.excludes.unclaimed',
  "media.large.image.warning.size",
  "bulk.action.default.position",
  "keyword.typeahead.enabled",
  "openai.api.enabled",
  "openai.api.key",
  "openai.headlines.enabled",
  "openai.keywords.enabled",
  "openai.images.enabled",
  "openai.body.text.enabled",
  "openai.meta.description.enabled",
  "multipub.integration.enabled",
  "article.taxonomy.keyword.autocreate.enabled",
  "author.create.from.article.enabled",
  "slack.notifications.enabled",
  "subscription.gifts.enabled",
  "media.credit.field.required",
];
const systemSettingsStr = SYSTEM_SETTINGS.map((setting) => (`name = ${setting}`)).join(" %OR% ");
const defaultVariables = {
  "filterString": `${systemSettingsStr}`,
}

/**
 * Get site settings
 * @param {React.ReactNode} children
 */
const SettingsProvider = ({ children }: Props) => {
  const [state, setState] = useState({
    settings: {
      "official.site.name": {},
      "site.url.base": {},
      "workflow.enabled": {
        name: "workflow.enabled",
        stringValue: "false",
      },
      "topics.top.level": {
        name: "topics.top.level",
        stringValue: "true",
      },
      "lock.service.enabled": {
        name: "lock.service.enabled",
        stringValue: "false",
      },
      "default.post.date.enabled": {
        name: "default.post.date.enabled",
        stringValue: "false",
      },
      "hyperlinks.new.tab.value": {
        name: "hyperlinks.new.tab.value",
        stringValue: "false",
      },
      "newsletter.issue.articles.insert.position": {
        name: "newsletter.issue.articles.insert.position",
        stringValue: "first",
      },
      "integration.duet.tabs.enabled": {
        name: "integration.duet.tabs.enabled",
        stringValue: "false",
      },
      "classifieds.media.tab.enabled": {
        name: "classifieds.media.tab.enabled",
        stringValue: "false",
      },
      "articles.display.export.button": {
        name: "articles.display.export.button",
        stringValue: "false",
      },
      "article.pin.show.more.fields": {
        name: "article.pin.show.more.fields",
        stringValue: "",
      },
      "dashboard.widget.ga.enabled": {
        name: "dashboard.widget.ga.enabled",
        stringValue: "false",
      },
      "dashboard.widget.content.enabled": {
        name: "dashboard.widget.content.enabled",
        stringValue: "true",
      },
      "dashboard.widget.message.enabled": {
        name: "dashboard.widget.message.enabled",
        stringValue: "true",
      },
      "article.days.to.show.newsletter.issues": {
        name: "article.days.to.show.newsletter.issues",
        stringValue: "3",
      },
      "article.days.to.show.publication.editions": {
        name: "article.days.to.show.publication.editions",
        stringValue: "30",
      },
      "newsletter.article.use.source.url.enabled": {
        name: "newsletter.article.use.source.url.enabled",
        stringValue: "false",
      },
      "article.templates.enabled": {
        name: "article.templates.enabled",
        stringValue: "false",
      },
      "article.add.from.templates.enabled": {
        name: "article.add.from.templates.enabled",
        stringValue: "false",
      },
      "password.requirement.message.displayed": {
        name: "password.requirement.message.displayed",
        stringValue: "false",
      },
      "password.digit.count": {
        name: "password.digit.count",
        stringValue: "1",
      },
      "password.length": {
        name: "password.length",
        stringValue: "6",
      },
      "password.lowercase.count": {
        name: "password.lowercase.count",
        stringValue: "1",
      },
      "password.special.character.count": {
        name: "password.special.character.count",
        stringValue: "0",
      },
      "password.uppercase.count": {
        name: "password.uppercase.count",
        stringValue: "0",
      },
      "product.pin.show.more.fields": {
        name: "product.pin.show.more.fields",
        stringValue: "",
      },
      "user.impersonation.activated": {
        name: "user.impersonation.activated",
        stringValue: "true",
      },
      "user.fulfillment.report": {
        name: "user.fulfillment.report",
        stringValue: "false",
      },
      "newsletter.issue.date.from.postat.enabled": {
        name: "newsletter.issue.date.from.postat.enabled",
        stringValue: "false",
      },
      "froala.image.gallery.shortcode.enabled": {
        name: "froala.image.gallery.shortcode.enabled",
        stringValue: "false",
      },
      "workflow.all.articles.excludes.unclaimed": {
        name: "workflow.all.articles.excludes.unclaimed",
        stringValue: "false",
      },
      "media.large.image.warning.size": {
        name: "media.large.image.warning.size",
        stringValue: 2,
      },
      "bulk.action.default.position": {
        name: "bulk.action.default.position",
        stringValue: "collapsed",
      },
      "keyword.typeahead.enabled": {
        name: "keyword.typeahead.enabled",
        stringValue: "true",
      },
      "openai.api.enabled": {
        name: "openai.api.enabled",
        stringValue: "false",
      },
      "openai.api.key": {
        name: "openai.api.key",
        stringValue: "",
      },
      "openai.headlines.enabled": {
        name: "openai.headlines.enabled",
        stringValue: "false",
      },
      "openai.keywords.enabled": {
        name: "openai.keywords.enabled",
        stringValue: "false",
      },
      "openai.images.enabled": {
        name: "openai.images.enabled",
        stringValue: "false",
      },
      "openai.body.text.enabled": {
        name: "openai.body.text.enabled",
        stringValue: "false",
      },
      "openai.meta.description.enabled": {
        name: "openai.meta.description.enabled",
        stringValue: "false",
      },
      "multipub.integration.enabled": {
        name: "multipub.integration.enabled",
        stringValue: "false",
      },
      "article.taxonomy.keyword.autocreate.enabled": {
        name: "article.taxonomy.keyword.autocreate.enabled",
        stringValue: "false",
      },
      "newsletter.select.all.search.results": {
        name: "newsletter.select.all.search.results",
        stringValue: "true",
      },
      "author.create.from.article.enabled": {
        name: "author.create.from.article.enabled",
        stringValue: "false",
      },
      "slack.notifications.enabled": {
        name: "slack.notifications.enabled",
        stringValue: "false",
      },
      "subscription.gifts.enabled": {
        name: "subscription.gifts.enabled",
        stringValue: "false",
      },
      "media.credit.field.required": {
        name: "media.credit.field.required",
        stringValue: "false",
      },
    },
  });
  const { loading, data, error } = useQuery(SETTINGS, { variables: { ...defaultVariables }, notifyOnNetworkStatusChange: true });
  const { settings } = state;

  useEffect(() => {
    if (!data || !data?.settings || loading) return;

    const updatedState: any = {};

    data.settings.forEach((item) => {
      if (!SYSTEM_SETTINGS.includes(item.name)) return;

      updatedState[item.name] = item;
    });

    setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        ...updatedState,
      },
    }));
  }, [data]);

  if (loading) {
    return (
      <CssPermissionsContainer>
        <CssSvgContainer>
          <BeamLockSVG />
          {loading && <p>Checking permissions...</p>}
        </CssSvgContainer>
      </CssPermissionsContainer>
    );
  }

  return (
    <Context.Provider value={{ settings }}>{children}</Context.Provider>
  );
}

export default SettingsProvider;