import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

export const PRODUCTS = gql`
  query Products($sortString: [Str], $page: Integer!, $perPage: Integer!) {
    _productStats {
      count
    }
    products(sortString: $sortString, page: $page, perPage: $perPage) {
      id
      name
    }
  }
`;

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'articles',
    'events',
    'galleries',
    'polls',
    'companies',
    'products',
    'bundledProducts',
    'depthOneVariations',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';
    if (q === 'companies' || q === 'bundledProducts' || q === 'depthOneVariations') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const PRODUCT_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query ProductEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      product(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
    pick: related_Item.query,
    vars: 'page: $page, perPage: $perPage',
  })}
      }
    }`;
}

export const TAXONOMY_PRODUCTS = gql`
  query TaxonomyProducts ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy (id: $id) {
      id
      _productStats {
        count
      }
      products (perPage: $perPage, page: $page) {
        id
        oid
        title:name
      }
    }
  }
`;

export const PRODUCT_TYPES = gql`
  query ProductTypes {
    products {
      id
      productType
    }
  }
`;

export const PRODUCT_ADD = gql`
  mutation ProductAdd ($input: ProductCreateInput!) {
    productCreate (input: $input) {
      product {
        id
        oid
        name
        teaser
        title
      }
      success
      errors
    }
  }
`;

export const PRODUCT_UPDATE = gql`
  mutation ProductUpdate ($input: ProductUpdateInput!) {
    productUpdate (input: $input)
    {
      products {
        id
        title
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const PRODUCT_DELETE = gql`
  mutation ProductDelete ($input: ProductDeleteInput!) {
    productDelete(input: $input)
    {
      products { id }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_COST = gql`
  mutation ProductAddCost($input: ProductCostCreateInput!) {
      productCostCreate (input: $input) {
        productCost { id }
        success
        errors
      }
  }
`;

export const PRODUCT_EDIT_COST = gql`
  mutation ProductEditCost($input: ProductCostUpdateInput!) {
      productCostUpdate (input: $input) {
        productCosts { id }
        success
        errors
      }
  }
`;

export const PRODUCT_DELETE_COST = gql`
  mutation ProductDeleteCost($input: ProductCostDeleteInput!) {
      productCostDelete (input: $input) {
        success
        errors
      }
  }
`;

export const PRODUCT_ADD_SUBSCRIPTION = gql`
  mutation ProductAddSubscription ($input: SubscriptionProductCreateInput!) {
    subscriptionProductCreate (input: $input) {
      subscriptionProduct {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_BOOK = gql`
  mutation ProductAddBook ($input: BookCreateInput!) {
    bookCreate (input: $input) {
      book {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const PRODUCT_SET_AUTHORS = gql`
  mutation ProductEditAuthors($input: ProductSetAuthorsInput!) {
    productSetAuthors (input: $input) {
       success
       errors
    }
  }
`;

export const PRODUCT_SET_KEYWORDS = gql`
  mutation ProductSetKeywords ($input: ProductSetKeywordsInput!) {
    productSetKeywords(input: $input) {
      success
      errors
    }
  }
`;

export const PRODUCT_EDIT = gql`
  query ProductEdit($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    product (oid: $oid) {
      id
      oid
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      name
      title
      externalId
      internalId
      active
      allowComments
      shippable
      teaser
      description
      emailContent
      weight
      productType
      taxable
      availability
      maxQuantity
      searchable
      authors {
        id
        oid
        firstName
        lastName
      }
      productCategories: categories {
        id
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      keywords {
        key: id
        oid
        title: tag
      }
      costs {
        id
        cost
        originalCost
        minQuantity
        maxQuantity
        description
        classifications {
          id
          title
        }
      }
      meta {
        id
        slug
        browserTitle
        canonicalUrl
        keywords
        description
        bottomText
        headers {
          id
          name
          value
        }
      }
      _commentStats {
        count
      }
      bundle
      book {
        id
        isbn
        publicationDate
        pageCount
        digitalVersion {
          id
          oid
          url
          absoluteUrl
          title
        }
        tableOfContents {
          id
          oid
          url
          absoluteUrl
          title
        }
      }
      subscriptionProduct {
        id
        licenseType
        duration
        durationUnit
        resource
        type
        itemId
      }
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
      ${relatedContentFragments()}
    }
  }
`;

export const PRODUCT_COST_SET_TAXONOMIES = gql`
  mutation ProductCostSetTaxonomies ($input: ProductCostSetTaxonomiesInput!) {
    productCostSetTaxonomies(input: $input) {
      success
      errors
    }
  }
`;

export const PRODUCT_EDIT_COMMENTS = gql`
  query ProductEditComments($oid: Integer!, $filterString: [Str!]) {
    product(oid: $oid) {
      id
      _commentStats(filterString: $filterString) {
        count
        __typename
      }
      comments(filterString: $filterString) {
        id
        oid
        createdAt
        title
        body
        userEmail
        userName
        userAgent
        remoteAddr
        offensive
        spam
        treePath
        active
        user {
          id
          oid
          email
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const PRODUCTS_SET_VARIATIONS = gql`
  mutation ProductSetVariations ($input: ProductSetVariationsInput!) {
    productSetVariations(input: $input){
      success
      errors
    }
  }
`;

export const PRODUCTS_ADD_VARIATIONS = gql`
  mutation ProductAddVariations ($input: ProductAddVariationsInput!) {
    productAddVariations(input: $input){
      success
      errors
    }
  }
`;

export const BOOK_UPDATE = gql`
  mutation BookUpdate ($input: BookUpdateInput!) {
    bookUpdate (input: $input)
    {
      books {
        id
        digitalVersion {
          id
          oid
          url
          absoluteUrl
          title
        }
        tableOfContents {
          id
          oid
          url
          absoluteUrl
          title
        }
      }
      success
      errors
    }
  }
`;

export const SUBSCRIPTION_PRODUCT_UPDATE = gql`
  mutation SubscriptionProductUpdate ($input: SubscriptionProductUpdateInput!) {
    subscriptionProductUpdate (input: $input) {
      subscriptionProducts {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_BUNDLED_PRODUCTS = gql`
  mutation ProductAddBundledProducts ($input: ProductAddBundledProductsInput!) {
    productAddBundledProducts (input: $input) {
      bundledProducts {
        oid
        id
        title: name
        __typename
      }
      errors
      success
    }
  }
`;

export const PRODUCT_ADD_TAXONOMIES = gql`
  mutation ProductAddTaxonomies ($input: ProductAddTaxonomiesInput!) {
    productAddTaxonomies(input: $input) {
      taxonomies { id }
      product { id, classifications { id } }
      success
      errors
    }
  }
`;

export const PRODUCT_REMOVE_TAXONOMIES = gql`
  mutation ProductRemoveTaxonomies ($input: ProductRemoveTaxonomiesInput!) {
    productRemoveTaxonomies(input: $input) {
      taxonomies { id }
      product { id }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_AUDIOS = gql`
  mutation ProductAddAudios ($input: ProductAddAudiosInput!) {
    productAddAudios(input: $input) {
      product { id }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_VIDEOS = gql`
  mutation ProductAddVideos ($input: ProductAddVideosInput!) {
    productAddVideos(input: $input) {
      product { id }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_FILES = gql`
  mutation ProductAddFiles ($input: ProductAddFilesInput!) {
    productAddFiles(input: $input) {
      product { id }
      success
      errors
    }
  }
`;

export const PRODUCT_ADD_ITEMS = gql`
  mutation ProductAddItems ($input: ProductAddItemsInput!) {
    productAddItems(input: $input)
    {
      product {
        id
        name
        sellableFiles {
          oid
          id
          url
        }
      }
      success
      errors
    }
  }
`;

export const PRODUCT_SUBSCRIPTIONS = gql`
  query ProductSubscriptions($filterString: [Str], $page: Integer, $perPage: Integer, $sortString: [Str]) {
    _productStats(subscriptionProductExists: true, filterString: $filterString) {
      count
    }
    products(subscriptionProductExists: true, filterString: $filterString, page: $page, perPage: $perPage, sortString: $sortString) {
      id
      oid
      title: name
      active
      productType
      internalId
      externalId
      updatedAt
      subscriptionProduct {
        id
        type
        licenseType
        resource
        newsletter{
          id
          oid
          title
        }
        publication{
          id
          oid
          title
        }
      }
    }
  }
`;

export const PRODUCT_SET_ITEMS = gql`
  mutation ProductSetItems ($input: ProductSetItemsInput!) {
    productSetItems(input: $input)
    {
      product {
        id
        oid
        name
      }
      success
      errors
    }
  }
`;
