// ^ Optimized moment package to greatly reduce size imported. There's possibly other options.
// Better alternative would be to use this webpack plugin https://github.com/gilmoreorless/moment-timezone-data-webpack-plugin
import { format, parseISO } from 'date-fns';

function dateTimeSplit(datetime, formatDate="yyyy-MM-dd") {
  let dateObj = null;
  let formatted = '';

  const isoDatetimeRegx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.?\d{0,3}[+-]?\d{2}:\d{2}$/;
  if (datetime && isoDatetimeRegx.test(datetime)) {
    const datetimeStr = datetime.replace(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(.?\d{0,3})([+-]?)(.*)$/, "$1");
    const dateParts = datetimeStr.split('T');
    const date = dateParts[0].split('-');
    const time = dateParts[1].split(":");
    dateObj = new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2]);
    dateObj = parseISO(dateObj.toISOString());
    formatted = format(dateObj, formatDate);
  } else if (datetime) {
    // Date objects output values in UTC
    // Date can parse a string containing a numeric UTC offset from any time zone.
    // It uses this to adjust the value being parsed, and stores the UTC equivalent.
    // The original local time and offset are not retained in the resulting Date object
    // This quick replace basically converts the date into yyyy/mm/dd which is the date format
    // that is being used here in this function and removes any other data about timezones
    const replacedString = typeof datetime === "string" ? datetime.replace(/-/g, '\/').replace(/T.+/, '') : '';
    dateObj = new Date(replacedString.length > 0 ? replacedString : datetime);
    formatted = format(dateObj, formatDate);
  }

  return [
    dateObj,
    formatted,
  ];
}

export default dateTimeSplit;