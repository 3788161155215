import React, { useContext } from 'react';
import { Tree, Empty } from 'antd';
import { useApolloClient } from '@apollo/react-hooks';
import { QueryOptions } from 'apollo-client';
import { renderTreeNodes, buildTree, mergeChildrenInTree } from './utils';
import * as css from '../style';
import openNotificationWithIcon from 'utils/notification';
import { sortByDateDescending } from 'utils/sorters';
import { checkIsDate } from 'utils/helpers';
import { getPastDate } from 'utils/dateTimeHelper';
import { Context as SettingsContext } from 'components/setting/context';

export const NoData = () => {
  return (
    <css.NoResult>
      <Empty />
    </css.NoResult>
  )
}

/**
 * The tree of children, building tree nodes to represent expected structure of data,
 * and handle the loading of dynamic data.
 */
const TreeContainer = ({
  onExpand,
  onTreeSelect,
  expandedKeysRef,
  autoExpandParent,
  name,
  queryName,
  searchResult,
  queryDataType,
  state,
  setState,
  notAllowedIdForSelection,
  querySearchField,
  queryStatsType,
  fetchInitialData,
  fetchSearchData,
  onTitleRightClick,
  perPage,
  children = null,
  revertExpandedKey = null,
  loadedKeys = null,
  loadChildrens = null,
  fieldName = "id",
  processData = null,
  allowDisabledNode = false,
  sortByPlanDates = false,
  selectedKeys = [],
}) => {
  const client = useApolloClient();
  const treeNodes = buildTree({ children: searchResult, notAllowedIdForSelection, titleField: querySearchField });
  const { settings } = useContext(SettingsContext);

  /**
   * Load the data of the next set of children by the parent ID. Merge data into
   * original Tree data. Update the state for the new data.
   *
   * @param {*} treeNode
   * @param {number} page - page to access in query
   * @param {boolean} isRefresh - indicates about is it called to refresh node or not
   * @returns The new search results containing merged data. This resolves the callback,
   * but the actual data used is set by setState.
   */
  async function loadData(treeNode: any, page = 1, isRefresh = false) {
    const key = treeNode.props.eventKey;
    if (!key) return;
    // set loading true
    if (page > 1) {
      setState((prevState) => ({
        ...prevState,
        expandedKeysPagination: {
          ...prevState.expandedKeysPagination,
          [key]: {
            ...prevState.expandedKeysPagination[key],
            loader: true,
          },
        }
      }));
    }

    let queryVariables = {
      [fieldName]: key,
      page,
      perPage,
    };

    if (queryDataType === "newsletter") {
      const daysToShowNewsletterIssues = settings['article.days.to.show.newsletter.issues'].hasOwnProperty('stringValue') ? settings['article.days.to.show.newsletter.issues']?.stringValue : '3';
      const pastDays = parseInt(daysToShowNewsletterIssues) <= 0 ? 3 : parseInt(daysToShowNewsletterIssues);
      const pastDate = getPastDate(pastDays);
      queryVariables = {
        ...queryVariables,
        filterString: [`postAt >= ${pastDate}`]
      }
    }

    try {
      const queryData: QueryOptions = {
        query: queryName,
        variables: queryVariables,
        fetchPolicy: isRefresh ? "network-only" : "cache-first",
      };
      const { data } = await client.query(queryData);
      let finalChildren = [];
      const prevTree = searchResult;

      if (processData) {
        finalChildren = processData(data);
      } else {
        const nextChildren = data[queryDataType].children;
        if (nextChildren.length > 0) {
          const isDate = checkIsDate(nextChildren[0].title);

          finalChildren = nextChildren.filter((item) => item);
          finalChildren = (sortByPlanDates && isDate) ? finalChildren.sort(sortByDateDescending('title')) : finalChildren;
        }
      }

      const newResult = mergeChildrenInTree({ key, tree: prevTree, children: finalChildren, isFirstPage: page === 1 });

      if (fieldName === "path") {
        setState((prevState) => ({
          ...prevState,
          searchResult: newResult,
        }));
        return;
      }

      let countTotalPages;
      if (queryDataType === "newsletter") {
        countTotalPages = queryStatsType && data?.newsletter[queryStatsType] ? Math.ceil(data.newsletter[queryStatsType].count / perPage) : 1;
      } else {
        countTotalPages = queryStatsType && data?.taxonomy[queryStatsType] ? Math.ceil(data.taxonomy[queryStatsType].count / perPage) : 1;
      }

      setState((prevState) => ({
        ...prevState,
        searchResult: newResult,
        expandedKeysPagination: {
          ...prevState.expandedKeysPagination,
          [key]: {
            ...prevState.expandedKeysPagination[key],
            currentPage: page,
            totalPages: countTotalPages,
            loader: false,
          },
        }
      }));
    } catch(e: any) {
      openNotificationWithIcon({
        type: "error",
        title: "Something went wrong, please try again",
      }, () => { });
      if (revertExpandedKey) revertExpandedKey(key);
    }
  }

  /**
   * fetch next page when user click on load more
   * @param {number} page
   * @param {string} key
   */
  function fetchNextPage(page: number, key?: string) {
    if (key) {
      const treeNode = {
        props: {
          eventKey: key,
        },
      };

      if (loadChildrens) {
        loadChildrens(treeNode, page);
        return;
      }

      // Load data for tree
      loadData(treeNode, page);
      return;
    }

    if (state.isSearching) {
      fetchSearchData(page);
      return;
    }

    fetchInitialData(page);
  }

  /**
   * A callback called to maintain loadedKeys of tree and converted tree's use to controlled component.
   * LoadedKeys are for keeping track of which node's data are already fetched.
   * When user try to expand any node and if loadedKeys contains that node's key, api call will be not fired to get sub-branches.
   *
   * @param {string[]} loadedKeys contains all loaded keys till the moment
   * @param {any} obj contains event and actual node data
   */
  function onLoad(loadedKeys, { node }) {
    // check if node is expanded, and contains node key, then update loadedKeys.
    if (expandedKeysRef.current && expandedKeysRef.current.includes(node.props.eventKey)) {
      setState((prevState) => ({ ...prevState, loadedKeys }));
      return;
    }

    const index = loadedKeys.indexOf(node.props.eventKey);

    // if newly expanded node's key is not in loadedKeys, then update it.
    if (index < 0) {
      setState((prevState) => ({ ...prevState, loadedKeys }));
      return;
    }

    // when data does not return from API, it will remove node's key from loadedKeys
    const updatedLoadedKeys = [...loadedKeys.slice(0, index), ...loadedKeys.slice(index + 1)];

    setState((prevState) => ({ ...prevState, loadedKeys: updatedLoadedKeys }));
  }

  if (!treeNodes.length) return <NoData />;

  return (
    <>
      <Tree
        onExpand={onExpand}
        onSelect={onTreeSelect}
        expandedKeys={expandedKeysRef.current}
        autoExpandParent={autoExpandParent}
        loadData={loadChildrens ? loadChildrens : loadData}
        onRightClick={onTitleRightClick}
        loadedKeys={loadedKeys}
        onLoad={onLoad}
      >
        {renderTreeNodes({ data: treeNodes, pagination: state.pagination, expandedKeysPagination: state.expandedKeysPagination, fetchNextPage, children, client, loadData, isSearching: state.isSearching, name, allowDisabledNode: allowDisabledNode, selectedKeys })}
      </Tree>
    </>
  );
}

export default TreeContainer;
