import { css } from 'styled-components';
import { breakpoint } from './mixins';

const defaultGapSize = "15px";
const gap = css`
  grid-column-gap: ${(props) => props.gap ? props.gap : defaultGapSize};
`;

const offsetPerLevel = css`
  margin-left: ${(props) => props.level ? props.level*45 - 2 : 0}px;
`;

type ColumnProps = {
  columns?: string,
  grid: number,
  level?: number
  columnsBreakpoints?: Array<{
    media: Array<number>,
    columns: string,
  }>,
};

/**
 * Create a grid style based on CSS Grid.
 * @param {number} grid The number of grid columns.
 * @param {string} columns The grid template columns style value.
 * @param {ColumnProps.columnsBreakpoints} columnBreakpoints Defines css media queries to be set on this style.
 * @param {columnBreakpoints.media} media An array of the min and/or max query.
 * @param {columnBreakpoints.columns} columns The grid-template-columns css style value.
 * for the given grid style.
 * @returns A css styled-component block.
 */
const columns = ({ grid, columns, columnsBreakpoints=[] }: ColumnProps) => {
  if (!grid) return;
  const parts = !columns ? [] : columns.split('|');
  const template = [] as string[];

  if (columns && parts.length < grid)
    throw new Error('The columns attribute split by | must equal the grid attribute.');

  for (let i = 0, len = grid; i < len; i++) {
    if (parts.length) {
      template.push(parts[i]);
    } else {
      template.push('1fr');
    }
  }

  /**
   * Build breakpoints based on a predefined structure.
   */
  const breakpoints = () => {
    const result = [];

    for (const { media, columns } of columnsBreakpoints) {
      if (media.length === 2) {
        result.push(css`
          ${breakpoint(media[0], media[1])(css`
            grid-template-columns: ${columns};
          `)}
        `);
      } else {
        result.push(css`
          ${breakpoint(media[0])(css`
            grid-template-columns: ${columns};
          `)}
        `);
      }
    }

    return result;
  }

  return css`
    display: grid;
    grid-template-columns: ${template.join(' ')};
    ${gap}
    ${offsetPerLevel}
    ${columnsBreakpoints.length > 0 && breakpoints()}
  `;
}

export const grid = css`
  ${(props) => columns(props)}
`;
