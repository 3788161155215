import gql from 'graphql-tag';

export const META_CREATE = gql`
  mutation metaCreate ($input: MetaCreateInput!){
    metaCreate(input: $input) {
      meta {
        id
        oid
        associatedUrl
      }
      success
      errors
    }
  }
`;

export const META_UPDATE = gql`
  mutation metaUpdate ($input: MetaUpdateInput!){
    metaUpdate(input: $input) {
      metas {
        id
      }
      success
      errors
    }
  }
`;

export const META_HEADER_CREATE = gql`
  mutation metaHeaderCreate ($input: MetaHeaderCreateInput!){
    metaHeaderCreate(input: $input) {
      metaHeader{
        id
      }
      success
      errors
    }
  }
`;

export const META_HEADER_DELETE = gql`
  mutation metaHeaderDelete ($input: MetaHeaderDeleteInput!){
    metaHeaderDelete(input: $input) {
      success
      errors
    }
  }
`;

export const META_HEADER_UPDATE = gql`
  mutation metaHeaderUpdate ($input: MetaHeaderUpdateInput!){
    metaHeaderUpdate(input: $input) {
      success
      errors
    }
  }
`;

export const META_DELETE = gql`
  mutation MetaDelete ($input: MetaDeleteInput!){
    metaDelete(input: $input) {
      metas {
        id
      }
      success
      errors
    }
  }
`;

export const METAS_NAV_QUERY = gql`
  query MetasNavQuery ($page: Integer!, $perPage: Integer!) {
    metas (filterString:["associatedUrl isNotNull"], page: $page, perPage: $perPage) {
      associatedUrl
      browserTitle
      id
      oid
      live
    }
  }
`;

export const META_STATS = gql`
  query MetaStats {
    _metaStats(filterString:["associatedUrl isNotNull"]) {
      count
    }
  }
`;

export const META_EDIT_QUERY = gql`
  query MetaEditQuery ($oid: Integer!) {
    meta (oid: $oid) {
      id
      oid
      associatedUrl
      browserTitle
      keywords
      description
      bottomText
      headers {
        id
        name
        value
      }
    }
  }
`;