import useSettings from "hooks/useSettings";
import React, { createContext, useContext, useCallback } from "react";

// Create a context for notifications
const NotificationContext = createContext(null);

// Custom hook to use the NotificationContext
export const useNotification = () => {
  return useContext(NotificationContext);
};

const notifySlack = async ({ message = "Hello, World!", url }) => {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "no-cors",
    body: JSON.stringify({
      text: message,
    }),
  })
    .then((response) => {
      if (response.ok || response.type == "opaque") {
        console.log("Message posted successfully!");
      } else {
        console.error("Failed to post message:", response.statusText);
      }
    })
    .catch((error) => {
      console.error("Error posting message:", error);
    });
};

function formatDateWithTimezone(dateStr) {
  const date = new Date(dateStr);

  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

const articleLink = (article, url) =>
  `<https://${url}${article.canonicalPath}|${article.title}>`;

// Provider component that provides the triggerNotification function to its children
const NotificationProvider = ({ children }) => {
  const { loading: loadingSettings, settings } = useSettings(
    ["slack.notifications.url", "site.url.base", "slack.notifications.enabled"],
    "network-only"
  );

  // Define the triggerNotification function using useCallback for memoization
  const triggerNotification = useCallback(
    async (a, b) => {
      const urlBase = settings["site.url.base"]?.stringValue;
      const url = settings["slack.notifications.url"]?.stringValue;
      const enabled =
        settings["slack.notifications.enabled"]?.stringValue == "true" ?? false;

      if (!enabled) return;

      // Create sets of keys from both objects
      const keysA = new Set(Object.keys(a));
      const keysB = new Set(Object.keys(b));

      // Create a set of all unique keys
      const allKeys = new Set([...keysA, ...keysB]);


      // Iterate over all unique keys
      allKeys.forEach((key) => {
        // Skip keys that start with an underscore
        if (key.startsWith("_")) return;

        // Check if the values for the current key are different in both objects
        if (a[key] !== b[key]) {
          // Handle specific key cases
          switch (key) {
            case "active":
              if (b.active === true) {
                const message = `${articleLink(b, urlBase)}: has been published.`;
                notifySlack({ message, url: url });
              }
              break;

            case "postAt":
              // only trigger postAt notifications if active is false
              if (b.active === false) {
                const message = `${articleLink(b, urlBase)}: has been scheduled to go live at ${formatDateWithTimezone(
                  b.postAt
                )}`;
                notifySlack({ message, url: url });
              }
              break;

            default:
              // Handle other keys if needed
              break;
          }
        }
      });
    },
    [settings]
  );

  // Provide the triggerNotification function to children components
  return (
    <NotificationContext.Provider value={triggerNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
