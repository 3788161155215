import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import { chunk } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Context } from './context';
import { htmlToText, deepFlatten } from 'utils/helpers';
import { SELECTED_ORDER_MUTATION, SITES } from 'graphql/entry';
import { Headers, UserKeys, OrderKeys, OrderTypeCodeTotalKey, ShippingKeys, PaymentKeys, taxKey, OfferKeys, ShippingHeader, ProductHeader, ProductDetail, ProductExportHeader } from './constants';
import { SearchExportOrders_search_item_Order, SearchExportOrders_search_item_Order_items as OrderItems } from 'graphql/types/SearchExportOrders';
/**
 * A React Hook to handle to duplicate Event
 * @return {object.duplicateLoading} duplicateLoading - Boolean value to show loading state while till process completed
 * @return {object.duplicateEvent} duplicateEvent - A callback that handles event input data to duplicate
  */
const useExportData = () => {
  const { state: { exportVariables } } = useContext(Context);
  const client = useApolloClient();
  const { data, error, loading } = useQuery(SITES);
  const { isAllSelect } = exportVariables;
  /**
   * manipulateData customise and reorder row data as per csv column order
   * @param {SearchExportOrders_search_item_Order} row contains particular table row data
   * @param {OrderItems} orderitem contains paticular order item data
   * @param {number} hasMultipleItem contains index of order item
   */
  function manipulateData(row: SearchExportOrders_search_item_Order, productItems?: number) {
    // Fetch userid and customer number column data
    const orderUserData = UserKeys.map((key: string) => {
      return (row.user && row.user[key]) ? row.user[key] : '';
    });
    // Fetch order data e.g firstname, postalcode, address etc.
    const orderBasicData = OrderKeys.map((key: string) => {
      if (key === 'country' && row[key]) {
        return row[key].iso;
      }
      if (key === 'state' && row[key]) {
        return row[key].name;
      }
      if (key === 'createdAt' && row[key]) {
        return format(new Date(String(row[key])), 'LLLL dd, yyyy');
      }
      if (key === 'amountPaid' && row[key]) {
        var res = row[key].replace("USD", "");
        return `$${res}`;
      }
      return (row[key]) ? row[key] : '';
    });
    // Fetch transactionid and card number digit column data
    const payments = PaymentKeys.map((key: string) => {
      return (row.payment && row.payment[key]) ? row?.payment?.[key] : '';
    });
    // Fetch refferal code, totalShippingCost and shippingtype
    const orderTypeCodeTotal = OrderTypeCodeTotalKey.map((key: string) => {
      if (key === 'totalShippingCost' && row[key]) {
        var res = row[key].replace("USD", "");
        return `$${res}`;
      }
      return (row[key]) ? row[key] : '';
    });
    // Fetch shipping data e.g firstname, postalcode, addresses etc column data
    const shipping = ShippingKeys.map((key: string, index: number) => {
      return (row.shippingAddress) ? row.shippingAddress[key] : '';
    });
    // Fetch sales tax column data
    const tax = taxKey.map((key: string) => {
      const tax = row[key] && row[key] || '';
      var res = tax.replace("USD", "");
      return (res) ? `$${res}` : '';
    });
    let products = [];
    // Fetch product detail e.g productid, product name, cost and quantity column data
    if (productItems > 0) {
      for (let i = 0; i < productItems; i++) {
        let res = row?.items[i]?.cost.replace("USD", "") || '';
        const cost = (res) ? `$${res}` : '';
        products.push(
          row?.items[i]?.product?.oid || '',
          row?.items[i]?.product?.name || '',
          row?.items[i]?.quantity || '',
          cost || '',
          (row?.items[i]?.product?.weight) ? `${row?.items[i]?.product?.weight} ${row?.items[i]?.product?.weightUnit}` : '',
          row?.items[i]?.product?.internalId || '',
          row?.items[i]?.product?.externalId || '',
        )
      }
    }
    // Fetch promocode column data
    const promoCode = OfferKeys.map((key: string) => {
      return row.offers?.map((itemKey) => {
        return itemKey.code || '';
      });
    });
    const notes = ProductDetail.map((key: string) => {
      return (row[key]) ? htmlToText(row[key]) : '';
    });
    return [data?.context?.host, ...orderUserData, ...orderBasicData, ...payments, ...promoCode, ...orderTypeCodeTotal, ...tax, ...products, ...shipping, ...notes];
  }
  function manipulateOrderDetail(row: SearchExportOrders_search_item_Order, orderItem?: any, bundleProductItem?: any, bundleProduct?: boolean, bundleProductItemQty?: number) {
    // Fetch userid and customer number column data
    const orderUserData = UserKeys.map((key: string) => {
      return (row.user && row.user[key]) ? row.user[key] : '';
    });
    // Fetch order data e.g firstname, postalcode, address etc.
    const orderBasicData = OrderKeys.map((key: string) => {
      if (key === 'country' && row[key]) {
        return row[key].iso;
      }
      if (key === 'state' && row[key]) {
        return row[key].name;
      }
      if (key === 'createdAt' && row[key]) {
        return format(new Date(String(row[key])), 'LLLL dd, yyyy');
      }
      if (key === 'amountPaid' && row[key]) {
        const res = row[key].replace("USD", "");
        return `$${res}`;
      }
      return (row[key]) ? row[key] : '';
    });
    // Fetch transactionid and card number digit column data
    const payments = PaymentKeys.map((key: string) => {
      return (row.payment && row.payment[key]) ? row?.payment?.[key] : '';
    });
    // Fetch refferal code, totalShippingCost and shippingtype
    const orderTypeCodeTotal = OrderTypeCodeTotalKey.map((key: string) => {
      if (key === 'totalShippingCost' && row[key]) {
        const res = row[key].replace("USD", "");
        return (bundleProductItem) ? `$0` : `$${res}`;
      }
      return (bundleProductItem) ? '' : (row[key]) ? row[key] : '';
    });
    // Fetch shipping data e.g firstname, postalcode, addresses etc column data
    const shipping = ShippingKeys.map((key: string, index: number) => {
      if (row.shippingAddress == null) {
        if (key === 'country' && row[key]) {
          return row[key].iso;
        }
        if (key === 'state' && row[key]) {
          return row[key].name;
        }
        return (row[key]) ? row[key] : '';
      } else {
        return (row.shippingAddress) ? row.shippingAddress[key] : '';
      }
    });
    // Fetch sales tax column data
    const tax = taxKey.map((key: string) => {
      const tax = row[key] && row[key] || '';
      const res = tax.replace("USD", "");
      return (bundleProductItem) ? `$0` : (res) ? `$${res}` : '';
    });
    let products = [];
    // Fetch product detail e.g productid, product name, cost and quantity column data
    const res = (bundleProductItem) ? '0' : orderItem?.cost.replace("USD", "") || '';
    const cost = (res) ? `$${res}` : '';
    const oz = (bundleProduct) ? 0 : (bundleProductItem) ? `${(orderItem?.weight * 16)}` : (orderItem?.product?.weight) ? `${(orderItem?.product?.weight * 16)}` : '';
    products.push(
      (bundleProductItem) ? orderItem?.oid : orderItem?.product?.oid || '',
      (bundleProductItem) ? orderItem?.name : orderItem?.product?.name || '',
      (bundleProductItem) ? bundleProductItemQty : orderItem?.quantity || '',
      cost || '',
      (bundleProductItem) ? (orderItem?.weight) ? orderItem?.weight : '' : (orderItem?.product?.weight) ? `${orderItem?.product?.weight}` : '',
      (bundleProductItem) ? orderItem?.internalId : orderItem?.product?.internalId || '',
      (bundleProductItem) ? orderItem?.externalId : orderItem?.product?.externalId || '',
      oz,
    )
    // Fetch promocode column data
    const promoCode = OfferKeys.map((key: string) => {
      return row.offers?.map((itemKey) => {
        return itemKey.code || '';
      });
    });
    const notes = ProductDetail.map((key: string) => {
      return (row[key]) ? htmlToText(row[key]) : '';
    });
    return [data?.context?.host, ...orderUserData, ...orderBasicData, ...payments, ...promoCode, ...orderTypeCodeTotal, ...tax, ...products, ...shipping, ...notes];
  }
  type DataType = string[] | string[][];
  /**
   * runDataQuery handles run time query generated by seleted rows ids
   * @param {Array<{[key: string]: unknown }>} finalData contains query, parameters and variable to get data
   * @returns An array list to export into csv
   */
  async function runDataQuery(finalData) {
    let queryData = [];
    try {
      const mutatestring = gql`
          query orderQuery(${finalData.param}){
          ${finalData.query}
          } `;
      const { data } = await client.query({
        query: mutatestring,
        variables: finalData.variables,
      });
      for (let index = 0; index < finalData.counter; index++) {
        let object = data[`order${index}`]
        queryData.push(object);
      }
    } catch (err) {
      console.log(err)
    }
    return queryData;
  }
  //works as callback function into export
  async function getData(recordData, selectedRow) {
    let dynamicData = [];
    if ((selectedRow && isAllSelect) || (selectedRow && !isAllSelect && recordData.length > 10)) {
      const chunkedList = chunk(recordData, 10);
      for (let i = 0; i < chunkedList.length; i++) {
        const finalData = SELECTED_ORDER_MUTATION(chunkedList[i], {}, '', 0, '');
        dynamicData = [...dynamicData, ...await runDataQuery(finalData)];
        const progress = Math.round((i * 100) / chunkedList.length);
      }
    } else if (selectedRow && !isAllSelect) {
      const finalData = SELECTED_ORDER_MUTATION(recordData, {}, '', 0, '');
      dynamicData = await runDataQuery(finalData);
      // setCompleted(100);
    } else { }
    const exportDataLength = (selectedRow) ? dynamicData : recordData;
    const productItems = Math.max(...exportDataLength.map(item => (selectedRow) ? item['items'].length : item['item']['items'].length));
    // generate dynamic header name of product fields
    let dynamicHeader = [];
    for (let i = 1; i <= productItems; i++) {
      let headerString = `PRODUCT `;
      dynamicHeader.push(`${headerString}ID ${i}`, `${headerString}NAME ${i}`, `${headerString}QTY ${i}`, `${headerString}COST ${i}`, `${headerString} WEIGHT ${i}`, `${headerString} INTERNAL ID ${i}`, `${headerString} EXTERNAL ID ${i}`);
    }
    const data: DataType = (selectedRow) ? (
      dynamicData.map((value) => manipulateData(value, productItems))
    ) : (
      recordData.map((value) => manipulateData(value.item, productItems))
    );
    const headersState = [...Headers, ...dynamicHeader, ...ShippingHeader, ...ProductHeader];
    const exportData = deepFlatten(data, headersState.length);
    return [headersState, exportData];
  }
  async function getOrderExportDetail(recordData, selectedRow) {
    let dynamicData = [];
    if ((selectedRow && isAllSelect) || (selectedRow && !isAllSelect && recordData.length > 10)) {
      const chunkedList = chunk(recordData, 10);
      for (let i = 0; i < chunkedList.length; i++) {
        const finalData = SELECTED_ORDER_MUTATION(chunkedList[i], {}, '', 0, '');
        dynamicData = [...dynamicData, ...await runDataQuery(finalData)];
      }
    } else if (selectedRow && !isAllSelect) {
      const finalData = SELECTED_ORDER_MUTATION(recordData, {}, '', 0, '');
      dynamicData = await runDataQuery(finalData);
    } else { }
    let collectedCSVROW = [];
    const data: DataType = (selectedRow) ? (
      dynamicData.map((value) => value.items?.map(orderItem => {
        if (orderItem.product.productType == 'Bundle') {
          collectedCSVROW.push(manipulateOrderDetail(value, orderItem, false, true))
        }
        if (orderItem.product?.bundledProducts.length > 0) {
          return orderItem.product?.bundledProducts.map(bP => {
            const bPL = manipulateOrderDetail(value, bP, true, false, orderItem.quantity)
            collectedCSVROW.push(bPL)
          })
        } else {
          collectedCSVROW.push(manipulateOrderDetail(value, orderItem, false, false))
        }
      }))
    ) : (
      recordData.map((value) => value.item.items?.map(orderItem => {
        if (orderItem.product.productType == 'Bundle') {
          collectedCSVROW.push(manipulateOrderDetail(value.item, orderItem, false, true))
        }
        if (orderItem.product?.bundledProducts.length > 0) {
          return orderItem.product?.bundledProducts.map(bP => {
            const bPL = manipulateOrderDetail(value.item, bP, true, false, orderItem.quantity)
            collectedCSVROW.push(bPL)
          })
        } else {
          collectedCSVROW.push(manipulateOrderDetail(value.item, orderItem, false, false))
        }
      }))
    );
    const headersState = [...Headers, ...ProductExportHeader, ...ShippingHeader, ...ProductHeader];
    const exportData = deepFlatten(collectedCSVROW, headersState.length);
    return [headersState, exportData];
  }
  return {
    getData,
    getOrderExportDetail,
  }
}
export default useExportData;