import React, { useReducer, useMemo } from 'react';

interface State {
  activeID: string,
}

interface ProviderValue {
  state: State,
  dispatch: any,
}

export const OutlineMenuContext = React.createContext<ProviderValue>({
  state: {
    activeID: null,
  },
  dispatch: () => {},
});

function reducer(state, action) {
  switch (action.type) {
    case "setActiveID":
      return {
        ...state,
        activeID: action.activeID,
      };
    default:
      throw Error(`The action.type ${action.type} does not exist in the Layout Context.`);
  }
}

interface Props {
  children: React.ReactChild,
}

/**
 * A provider to track the active ID, set by hover state tracking of children outlineMenu components.
 */
export const OutlineMenuProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    activeID: null,
  });

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <OutlineMenuContext.Provider value={providerValue}>
      {children}
    </OutlineMenuContext.Provider>
  );
};
