import gql from 'graphql-tag';

export const TIMEZONES = gql`
  query FetchTimeZones {
    timeZones {
      id:name
      friendlyName
    }
  }
`;

export const FILTER_TIMEZONES_BY_COUNTRY = gql`
  query FilterTimezonesByCountry($countryId: IDType!) {
    country(id: $countryId) {
      timeZones {
        id:name
        friendlyName
      }
    }
  }
`;