import React, { forwardRef } from 'react';
import { CSVLink } from "react-csv";
import * as css from "./style";

interface Props {
  data: unknown[],
  fileName?: string,
  separator?: string,
}

/**
 * Export csv component wrapper
 *
 * @param {Array<string | string[])[]} data
 * @param {React.Ref} ref
 * @param {string} fileName
 */
const ExportCsv = forwardRef(({ data, fileName = "export-report.csv", separator = "," }: Props, ref) => (
  <css.Csvlink>
    <CSVLink
      separator={separator}
      data={data}
      filename={fileName}
      className="hidden"
      ref={ref}
      target="_blank"
      uFEFF={false}
    />
  </css.Csvlink>
));

export default ExportCsv;