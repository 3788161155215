import moment from 'moment';
/*
* Higher order functions to sort data of various forms. Used primarily for antd data tables.
* The returned function implements the compare function required by Array.sort
* see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

* for each example, provide a string corsponding to the field that will be referenced in the comparison.
* Example: `sortByAlpha('title')` returns a function that compares two objects using their `title` attribute
*/

/* sort strings alphanumerically */
export const sortByAlpha = (field: string) => (a: Object, b: Object) => a[field] && b[field] && a[field].localeCompare(b[field]);

export const sortByBoolean = (field: string | number) => (a: Object, b: Object) => a[field] - b[field];

/* sort strings chronologically*/
export const sortByDate = (field: string) => (a: Object, b: Object) => moment(a[field]).isAfter(b[field]) ? 1 : -1;

/* sort nested list strings alphanumerically */
export const sortByNestedListAlpha = (parentField: string, field: string) => (a: Object, b: Object) => a[parentField] && a[parentField][field].localeCompare(b[parentField] && b[parentField][field]);

/* sort by date in descending order */
export const sortByDateDescending = (field: string) => (a: Object, b: Object) => moment(a[field]).isBefore(b[field]) ? 1 : -1;