import styled from 'styled-components';
import Colors from 'styles/colors';

export const TagContainer = styled.div`
  display: ${ (props) => !props.isDragDisabled ? "auto" : "inline-block" };

  .draggable-elem-container {
    display : ${ (props) => !props.isDragDisabled ? "auto" : "inline-block" };
    width : ${ (props) => !props.isDragDisabled ? "auto" : "fit-content" };
  }

  .epub-tag-grid {
    display: grid;
    grid-template-columns: ${ (props) => !props.isDragDisabled ? "auto auto auto" : "auto auto"};
    align-items: center;
    width: fit-content;
  }
`;

export const DragDropButton = styled.button.attrs({
  tabIndex: -1,
})`
  background: transparent;
  font-size: 12px;
  cursor: pointer;
  border: none;
  padding-left: 0;
`;

export const TagLabel = styled.div`
  padding-bottom: ${(props) => !props.isDragDisabled ? "2px" : 0};
`;

export const ParentTitle = styled.div`
  display:block;
  font-size: 10px;
`;

export const Container = styled.div`
  display:grid;
  height:30px;

  & div:first-child {
    height: 15px;
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

export const LoadMoreText = styled.div`
  margin-left: 10px;
  display: inline-block;
  color: ${Colors.curiousBlue};
`;

export const LoadMoreIcon = styled.div`
  display: inline-block;
  color: ${Colors.curiousBlue};
`;