import React from 'react';
import { Icon } from 'antd';

const ClearButton = ({ allowMultipleSelection, inputValue, onClick }) => {
  if (allowMultipleSelection || !inputValue) return null;

  return (
    <span onClick={onClick} style={{ cursor: "pointer" }}>
      <Icon type="close-circle" />
    </span>
  );
};

export default ClearButton;
