import gql from 'graphql-tag';

export const PRIVILEGES = gql`
  query Privileges($page: Integer!, $perPage: Integer!) {
    privileges(page: $page, perPage: $perPage) {
      id
      oid
      displayName
      name
      roles {
        id
      }
    }
  }
`;

export const PRIVILEGE_CREATE = gql`
  mutation PrivilegeCreate($input: PrivilegeCreateInput!) {
    privilegeCreate(input: $input) {
      privilege {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const PRIVILEGE_UPDATE = gql`
  mutation PrivilegeUpdate($input: PrivilegeUpdateInput!) {
    privilegeUpdate(input: $input) {
      privileges {
        id
      }
      success
      errors
    }
  }
`;

export const PRIVILEGE_ADD_ROLES = gql`
  mutation PrivilegeAddRoles($input: PrivilegeAddRolesInput!) {
    privilegeAddRoles(input: $input) {
      roles {
        id
      }
      success
      errors
    }
  }
`;

export const PRIVILEGE_REMOVE_ROLES = gql`
  mutation PrivilegeRemoveRoles($input: PrivilegeRemoveRolesInput!) {
    privilegeRemoveRoles(input: $input) {
      roles {
        id
      }
      success
      errors
    }
  }
`;

export const PRIVILEGE_STATS = gql`
  query PrivilegeStats {
    _privilegeStats {
      count
    }
  }
`;