/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Names of commentable types
 */
export enum CommentableEnum {
  ARTICLE = "ARTICLE",
  COMPANY = "COMPANY",
  GALLERY = "GALLERY",
  POLL = "POLL",
  PRODUCT = "PRODUCT",
}

/**
 * Types of custom field input components
 */
export enum CustomFieldInputTypeEnum {
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  RICHTEXTAREA = "RICHTEXTAREA",
  SELECTION = "SELECTION",
  TEXTAREA = "TEXTAREA",
  TEXTFIELD = "TEXTFIELD",
}

/**
 * Types of custom field values
 */
export enum CustomFieldValueTypeEnum {
  DATE = "DATE",
  DECIMAL = "DECIMAL",
  INT = "INT",
  STRING = "STRING",
  TEXT = "TEXT",
}

/**
 * Names of the types of owners of customizable fields
 */
export enum CustomizableEnum {
  ARTICLE = "ARTICLE",
  CLASSIFIED = "CLASSIFIED",
  COMPANY = "COMPANY",
  EDITION = "EDITION",
  EVENT = "EVENT",
  PRODUCT = "PRODUCT",
  USER = "USER",
}

/**
 * The states of a dispatch status
 */
export enum DispatchStatusEnum {
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
  POLLING = "POLLING",
  PROCESSED = "PROCESSED",
  UNSENT = "UNSENT",
}

/**
 * Names of the FileUnion types
 */
export enum FileEnum {
  AUDIO = "AUDIO",
  FILE = "FILE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

/**
 * The accepted values for an action in a mailing list dispatch
 */
export enum MailingListDispatchActionEnum {
  ADD = "ADD",
  DELETE = "DELETE",
  SIGNUP = "SIGNUP",
  UPDATE = "UPDATE",
}

/**
 * The accepted values for a media file durationUnit
 */
export enum MediaDurationUnitEnum {
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  SECOND = "SECOND",
}

/**
 * The accepted values for an orderItem status
 */
export enum OrderItemStatusEnum {
  CANCELLED = "CANCELLED",
  FILLED = "FILLED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

/**
 * The accepted values for an order status
 */
export enum OrderStatusEnum {
  cancelled = "cancelled",
  filled = "filled",
  rejected = "rejected",
  submitted = "submitted",
}

/**
 * The valid states of PrintPage#status
 */
export enum PrintPageStatusEnum {
  CREATED = "CREATED",
  DONE = "DONE",
  PROOF = "PROOF",
  READY = "READY",
  WORKING = "WORKING",
}

/**
 * A permanent or temporary HTTP redirect
 */
export enum RedirectTypeEnum {
  PERMANENT = "PERMANENT",
  TEMPORARY = "TEMPORARY",
}

/**
 * Names of all searchable types
 */
export enum SearchableTypeEnum {
  ALL = "ALL",
  ARTICLE = "ARTICLE",
  AUDIO = "AUDIO",
  AUTHOR = "AUTHOR",
  CLASSIFIED = "CLASSIFIED",
  COMMENT = "COMMENT",
  COMPANY = "COMPANY",
  EDITION = "EDITION",
  EDITORIAL_CONTENT = "EDITORIAL_CONTENT",
  EVENT = "EVENT",
  FILE = "FILE",
  GALLERY = "GALLERY",
  IMAGE = "IMAGE",
  META = "META",
  NEWSLETTER = "NEWSLETTER",
  NEWSLETTER_ISSUE = "NEWSLETTER_ISSUE",
  OFFER = "OFFER",
  ORDER = "ORDER",
  PAGE = "PAGE",
  POLL = "POLL",
  PRODUCT = "PRODUCT",
  PUBLICATION = "PUBLICATION",
  REDIRECT = "REDIRECT",
  SETTING = "SETTING",
  TAXONOMY = "TAXONOMY",
  USER = "USER",
  VIDEO = "VIDEO",
}

/**
 * The valid subscription duration units
 */
export enum SubscriptionDurationUnitEnum {
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

/**
 * The valid states of subscription license type
 */
export enum SubscriptionLicenseTypeEnum {
  FREE = "FREE",
  INDIVIDUAL = "INDIVIDUAL",
  SITE = "SITE",
}

/**
 * The valid states of subscription status
 */
export enum SubscriptionStatusEnum {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  ORDERED = "ORDERED",
}

/**
 * The valid states of subscription type
 */
export enum SubscriptionTypeEnum {
  DONATION = "DONATION",
  NEWSLETTER = "NEWSLETTER",
  PUBLICATION = "PUBLICATION",
  SITE = "SITE",
}

/**
 * Indicates individual or site-license user
 */
export enum UserTypeEnum {
  INDIVIDUAL = "INDIVIDUAL",
  SITE_LICENSE = "SITE_LICENSE",
}

/**
 * Autogenerated input type of AgencyAccountCreate
 */
export interface AgencyAccountCreateInput {
  clientMutationId?: string | null;
  agencyId: IDType;
  accountId: IDType;
  beginDate?: Date | null;
  endDate?: Date | null;
}

/**
 * Autogenerated input type of AgencyAccountDelete
 */
export interface AgencyAccountDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of AgencyAccountUpdate
 */
export interface AgencyAccountUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  beginDate?: Date | null;
  endDate?: Date | null;
  agencyId?: IDType | null;
  accountId?: IDType | null;
}

/**
 * Autogenerated input type of ArticleAddKeywords
 */
export interface ArticleAddKeywordsInput {
  clientMutationId?: string | null;
  articleId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleAddTaxonomies
 */
export interface ArticleAddTaxonomiesInput {
  clientMutationId?: string | null;
  articleId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ArticleCopyToSites
 */
export interface ArticleCopyToSitesInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  sites?: (Str | null)[] | null;
}

/**
 * Autogenerated input type of ArticleCreate
 */
export interface ArticleCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  voteCount?: Integer | null;
  subTitle?: Str | null;
  kicker?: Str | null;
  teaser?: Str | null;
  source?: Str | null;
  sourceUrl?: URL | null;
  legacyId?: Str | null;
  guid?: Str | null;
  version?: Str | null;
  directAccess?: Bool | null;
  provider?: Str | null;
  rightsInfo?: Str | null;
  publicationAt?: Time | null;
  notes?: Str | null;
  endnotes?: Str | null;
  dueAt?: Time | null;
  exportedAt?: Time | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  blogId?: IDType | null;
  currentEditionId?: IDType | null;
  workflowId?: IDType | null;
  sponsorId?: IDType | null;
  versionAtId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of ArticleDelete
 */
export interface ArticleDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticlePageCreate
 */
export interface ArticlePageCreateInput {
  clientMutationId?: string | null;
  body?: Str | null;
  sortingOrder?: Integer | null;
  footnotes?: Str | null;
  articleId?: IDType | null;
}

/**
 * Autogenerated input type of ArticlePageDelete
 */
export interface ArticlePageDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticlePageUpdate
 */
export interface ArticlePageUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  body?: Str | null;
  sortingOrder?: Integer | null;
  footnotes?: Str | null;
  articleId?: IDType | null;
}

/**
 * Autogenerated input type of ArticleRemoveKeywords
 */
export interface ArticleRemoveKeywordsInput {
  clientMutationId?: string | null;
  articleId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleRemoveTaxonomies
 */
export interface ArticleRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  articleId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleSetAuthors
 */
export interface ArticleSetAuthorsInput {
  clientMutationId?: string | null;
  articleId: IDType;
  authorIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleSetKeywords
 */
export interface ArticleSetKeywordsInput {
  clientMutationId?: string | null;
  articleId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleSetProducts
 */
export interface ArticleSetProductsInput {
  clientMutationId?: string | null;
  articleId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleSetTaxonomies
 */
export interface ArticleSetTaxonomiesInput {
  clientMutationId?: string | null;
  articleId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ArticleUpdate
 */
export interface ArticleUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  voteCount?: Integer | null;
  title?: Str | null;
  subTitle?: Str | null;
  kicker?: Str | null;
  teaser?: Str | null;
  source?: Str | null;
  sourceUrl?: URL | null;
  legacyId?: Str | null;
  guid?: Str | null;
  version?: Str | null;
  directAccess?: Bool | null;
  provider?: Str | null;
  rightsInfo?: Str | null;
  publicationAt?: Time | null;
  notes?: Str | null;
  endnotes?: Str | null;
  dueAt?: Time | null;
  exportedAt?: Time | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  blogId?: IDType | null;
  currentEditionId?: IDType | null;
  workflowId?: IDType | null;
  sponsorId?: IDType | null;
  versionAtId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of AudioAddTaxonomies
 */
export interface AudioAddTaxonomiesInput {
  clientMutationId?: string | null;
  audioId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of AudioCreate
 */
export interface AudioCreateInput {
  clientMutationId?: string | null;
  duration?: number | null;
  durationUnit?: MediaDurationUnitEnum | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  releaseDate?: Date | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of AudioDelete
 */
export interface AudioDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of AudioRemoveTaxonomies
 */
export interface AudioRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  audioId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of AudioSetProducts
 */
export interface AudioSetProductsInput {
  clientMutationId?: string | null;
  audioId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of AudioUpdate
 */
export interface AudioUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  duration?: number | null;
  durationUnit?: MediaDurationUnitEnum | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  releaseDate?: Date | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of AuthorAddTaxonomies
 */
export interface AuthorAddTaxonomiesInput {
  clientMutationId?: string | null;
  authorId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of AuthorCreate
 */
export interface AuthorCreateInput {
  clientMutationId?: string | null;
  lastName: Str;
  active?: Bool | null;
  email?: Email | null;
  description?: Str | null;
  twitterName?: Str | null;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  suffix?: Str | null;
  position?: Str | null;
  phone?: Str | null;
  website?: URL | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of AuthorDelete
 */
export interface AuthorDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of AuthorRemoveTaxonomies
 */
export interface AuthorRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  authorId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of AuthorUpdate
 */
export interface AuthorUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  email?: Email | null;
  description?: Str | null;
  twitterName?: Str | null;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  lastName?: Str | null;
  suffix?: Str | null;
  position?: Str | null;
  phone?: Str | null;
  website?: URL | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of BlogAddTaxonomies
 */
export interface BlogAddTaxonomiesInput {
  clientMutationId?: string | null;
  blogId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of BlogCreate
 */
export interface BlogCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  teaser?: Str | null;
  description?: Str | null;
  twitterEnabled?: Bool | null;
  twitterConsumerKey?: Str | null;
  twitterConsumerSecret?: Str | null;
  twitterAccountToken?: Str | null;
  twitterAccountSecret?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
  authorId?: IDType | null;
}

/**
 * Autogenerated input type of BlogDelete
 */
export interface BlogDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of BlogRemoveTaxonomies
 */
export interface BlogRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  blogId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of BlogUpdate
 */
export interface BlogUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  twitterEnabled?: Bool | null;
  twitterConsumerKey?: Str | null;
  twitterConsumerSecret?: Str | null;
  twitterAccountToken?: Str | null;
  twitterAccountSecret?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
  authorId?: IDType | null;
}

/**
 * Autogenerated input type of BookCreate
 */
export interface BookCreateInput {
  clientMutationId?: string | null;
  productId: IDType;
  isbn?: Str | null;
  pageCount?: Integer | null;
  publicationDate?: Str | null;
  tableOfContentsId?: IDType | null;
  digitalVersionId?: IDType | null;
}

/**
 * Autogenerated input type of BookUpdate
 */
export interface BookUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  isbn?: Str | null;
  pageCount?: Integer | null;
  publicationDate?: Str | null;
  productId?: IDType | null;
  tableOfContentsId?: IDType | null;
  digitalVersionId?: IDType | null;
}

/**
 * Autogenerated input type of ClassifiedAddTaxonomies
 */
export interface ClassifiedAddTaxonomiesInput {
  clientMutationId?: string | null;
  classifiedId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ClassifiedCreate
 */
export interface ClassifiedCreateInput {
  clientMutationId?: string | null;
  title: Str;
  body: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  contactName?: Str | null;
  contactEmail?: Email | null;
  company?: Str | null;
  legacyId?: Str | null;
  purchased?: Bool | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of ClassifiedDelete
 */
export interface ClassifiedDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ClassifiedRemoveTaxonomies
 */
export interface ClassifiedRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  classifiedId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ClassifiedSetKeywords
 */
export interface ClassifiedSetKeywordsInput {
  clientMutationId?: string | null;
  classifiedId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ClassifiedUpdate
 */
export interface ClassifiedUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  title?: Str | null;
  contactName?: Str | null;
  contactEmail?: Email | null;
  body?: Str | null;
  company?: Str | null;
  legacyId?: Str | null;
  purchased?: Bool | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of CommentDelete
 */
export interface CommentDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of CommentUpdate
 */
export interface CommentUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  title?: Str | null;
  body?: Str | null;
  userEmail?: Email | null;
  userName?: Str | null;
  userAgent?: Str | null;
  remoteAddr?: IPAddress | null;
  offensive?: Bool | null;
  spam?: Bool | null;
  treePath?: Str | null;
  ownerId?: IDType | null;
  userId?: IDType | null;
  voteId?: IDType | null;
}

/**
 * Autogenerated input type of CompanyAddAgencies
 */
export interface CompanyAddAgenciesInput {
  clientMutationId?: string | null;
  companyId: IDType;
  agencyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanyAddTaxonomies
 */
export interface CompanyAddTaxonomiesInput {
  clientMutationId?: string | null;
  companyId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of CompanyCreate
 */
export interface CompanyCreateInput {
  clientMutationId?: string | null;
  name: Str;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  contact?: Str | null;
  legacyId?: Str | null;
  email?: Email | null;
  url?: URL | null;
  description?: Str | null;
  body?: Str | null;
  phone?: Str | null;
  tollFreePhone?: Str | null;
  fax?: Str | null;
  purchasedListing?: Bool | null;
  contactJobTitle?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of CompanyDelete
 */
export interface CompanyDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanyRemoveAgencies
 */
export interface CompanyRemoveAgenciesInput {
  clientMutationId?: string | null;
  companyId: IDType;
  agencyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanyRemoveTaxonomies
 */
export interface CompanyRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  companyId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanySetAgencies
 */
export interface CompanySetAgenciesInput {
  clientMutationId?: string | null;
  companyId: IDType;
  agencyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanySetUsers
 */
export interface CompanySetUsersInput {
  clientMutationId?: string | null;
  companyId: IDType;
  userIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CompanyUpdate
 */
export interface CompanyUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  name?: Str | null;
  contact?: Str | null;
  legacyId?: Str | null;
  email?: Email | null;
  url?: URL | null;
  description?: Str | null;
  body?: Str | null;
  phone?: Str | null;
  tollFreePhone?: Str | null;
  fax?: Str | null;
  purchasedListing?: Bool | null;
  contactJobTitle?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of CustomFieldCreate
 */
export interface CustomFieldCreateInput {
  clientMutationId?: string | null;
  typeId: IDType;
  optionSelectionPath?: Str | null;
  value?: Str | null;
  ownerId?: IDType | null;
}

/**
 * Autogenerated input type of CustomFieldDelete
 */
export interface CustomFieldDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of CustomFieldOptionCreate
 */
export interface CustomFieldOptionCreateInput {
  clientMutationId?: string | null;
  default: Bool;
  label: Str;
  value: Str;
  typeId: IDType;
  sortingOrder?: Integer | null;
}

/**
 * Autogenerated input type of CustomFieldOptionDelete
 */
export interface CustomFieldOptionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of CustomFieldOptionUpdate
 */
export interface CustomFieldOptionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  default?: Bool | null;
  label?: Str | null;
  value?: Str | null;
  sortingOrder?: Integer | null;
  typeId?: IDType | null;
}

/**
 * Autogenerated input type of CustomFieldTypeCreate
 */
export interface CustomFieldTypeCreateInput {
  clientMutationId?: string | null;
  ownerType: CustomizableEnum;
  name: Str;
  active?: Bool | null;
  displayName?: Str | null;
  displayMessage?: Str | null;
  description?: Str | null;
  required?: Bool | null;
  valueType?: CustomFieldValueTypeEnum | null;
  inputType?: CustomFieldInputTypeEnum | null;
  maximumValues?: Integer | null;
  inputLength?: Integer | null;
  validationPattern?: Str | null;
  failureMessage?: Str | null;
  defaultValue?: Str | null;
}

/**
 * Autogenerated input type of CustomFieldTypeDelete
 */
export interface CustomFieldTypeDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of CustomFieldTypeSetTaxonomies
 */
export interface CustomFieldTypeSetTaxonomiesInput {
  clientMutationId?: string | null;
  customFieldTypeId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of CustomFieldTypeUpdate
 */
export interface CustomFieldTypeUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  ownerType?: CustomizableEnum | null;
  active?: Bool | null;
  name?: Str | null;
  displayName?: Str | null;
  displayMessage?: Str | null;
  description?: Str | null;
  required?: Bool | null;
  valueType?: CustomFieldValueTypeEnum | null;
  inputType?: CustomFieldInputTypeEnum | null;
  maximumValues?: Integer | null;
  inputLength?: Integer | null;
  validationPattern?: Str | null;
  failureMessage?: Str | null;
  defaultValue?: Str | null;
}

/**
 * Autogenerated input type of CustomFieldUpdate
 */
export interface CustomFieldUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  optionSelectionPath?: Str | null;
  value?: Str | null;
  typeId?: IDType | null;
  ownerId?: IDType | null;
}

/**
 * Autogenerated input type of DirectoryAddCompanies
 */
export interface DirectoryAddCompaniesInput {
  clientMutationId?: string | null;
  directoryId: IDType;
  companyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of EditionAddTaxonomies
 */
export interface EditionAddTaxonomiesInput {
  clientMutationId?: string | null;
  editionId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of EditionArticleCreate
 */
export interface EditionArticleCreateInput {
  clientMutationId?: string | null;
  sortingOrder: Integer;
  editionId: IDType;
  sectionId: IDType;
  articleId: IDType;
}

/**
 * Autogenerated input type of EditionArticleDelete
 */
export interface EditionArticleDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EditionArticleUpdate
 */
export interface EditionArticleUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  sortingOrder?: Integer | null;
  editionId?: IDType | null;
  sectionId?: IDType | null;
  articleId?: IDType | null;
}

/**
 * Autogenerated input type of EditionCreate
 */
export interface EditionCreateInput {
  clientMutationId?: string | null;
  title: Str;
  publicationId: IDType;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  subTitle?: Str | null;
  teaser?: Str | null;
  volume?: Str | null;
  editionNumber?: Str | null;
  publishAt?: Time | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
  leadArticleId?: IDType | null;
  printVersionId?: IDType | null;
}

/**
 * Autogenerated input type of EditionDelete
 */
export interface EditionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EditionItemCreate
 */
export interface EditionItemCreateInput {
  clientMutationId?: string | null;
  sectionSortingOrder: Integer;
  editionId: IDType;
  sectionId: IDType;
  itemId: IDType;
  printPageSortingOrder?: Integer | null;
  printPageId?: IDType | null;
}

/**
 * Autogenerated input type of EditionItemDelete
 */
export interface EditionItemDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EditionItemUpdate
 */
export interface EditionItemUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  sectionSortingOrder?: Integer | null;
  printPageSortingOrder?: Integer | null;
  editionId?: IDType | null;
  sectionId?: IDType | null;
  itemId?: IDType | null;
  printPageId?: IDType | null;
}

/**
 * Autogenerated input type of EditionRemoveTaxonomies
 */
export interface EditionRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  editionId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EditionUpdate
 */
export interface EditionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  title?: Str | null;
  subTitle?: Str | null;
  teaser?: Str | null;
  volume?: Str | null;
  editionNumber?: Str | null;
  publishAt?: Time | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  publicationId?: IDType | null;
  imageId?: IDType | null;
  leadArticleId?: IDType | null;
  printVersionId?: IDType | null;
}

/**
 * Autogenerated input type of EditorialContentAddTaxonomies
 */
export interface EditorialContentAddTaxonomiesInput {
  clientMutationId?: string | null;
  editorialContentId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of EditorialContentCreate
 */
export interface EditorialContentCreateInput {
  clientMutationId?: string | null;
  name: Str;
  active?: Bool | null;
  title?: Str | null;
  subTitle?: Str | null;
  body?: Str | null;
  linkUrl?: URL | null;
  linkTitle?: Str | null;
  audioId?: IDType | null;
  imageId?: IDType | null;
  videoId?: IDType | null;
  fileId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of EditorialContentDelete
 */
export interface EditorialContentDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EditorialContentRemoveTaxonomies
 */
export interface EditorialContentRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  editorialContentId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EditorialContentUpdate
 */
export interface EditorialContentUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  title?: Str | null;
  name?: Str | null;
  subTitle?: Str | null;
  body?: Str | null;
  linkUrl?: URL | null;
  linkTitle?: Str | null;
  audioId?: IDType | null;
  imageId?: IDType | null;
  videoId?: IDType | null;
  fileId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of EventAddProducts
 */
export interface EventAddProductsInput {
  clientMutationId?: string | null;
  eventId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EventAddTaxonomies
 */
export interface EventAddTaxonomiesInput {
  clientMutationId?: string | null;
  eventId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of EventAttendantCreate
 */
export interface EventAttendantCreateInput {
  clientMutationId?: string | null;
  eventId: IDType;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  lastName?: Str | null;
  suffix?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  phone?: Str | null;
  altPhone?: Str | null;
  email?: Email | null;
  company?: Str | null;
  jobTitle?: Str | null;
  groupReference?: Str | null;
  externalId?: Str | null;
  orderedAt?: Time | null;
  cost?: Money | null;
  contactEmail?: Email | null;
  contactCompany?: Str | null;
  contactFirstName?: Str | null;
  contactLastName?: Str | null;
  contactPhone?: Str | null;
  orderId?: IDType | null;
}

/**
 * Autogenerated input type of EventAttendantDelete
 */
export interface EventAttendantDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EventAttendantUpdate
 */
export interface EventAttendantUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  lastName?: Str | null;
  suffix?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  phone?: Str | null;
  altPhone?: Str | null;
  email?: Email | null;
  company?: Str | null;
  jobTitle?: Str | null;
  groupReference?: Str | null;
  externalId?: Str | null;
  orderedAt?: Time | null;
  cost?: Money | null;
  contactEmail?: Email | null;
  contactCompany?: Str | null;
  contactFirstName?: Str | null;
  contactLastName?: Str | null;
  contactPhone?: Str | null;
  eventId?: IDType | null;
  orderId?: IDType | null;
}

/**
 * Autogenerated input type of EventCreate
 */
export interface EventCreateInput {
  clientMutationId?: string | null;
  active: Bool;
  searchable?: Bool | null;
  title?: Str | null;
  legacyId?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  contactName?: Str | null;
  location?: Str | null;
  phone?: Str | null;
  email?: Email | null;
  url?: URL | null;
  registrationUrl?: URL | null;
  beginAt?: Time | null;
  endAt?: Time | null;
  timeZone?: Str | null;
  timeZoneIdentifier?: Str | null;
  mailingListId?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  companyId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of EventDelete
 */
export interface EventDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of EventRemoveProducts
 */
export interface EventRemoveProductsInput {
  clientMutationId?: string | null;
  eventId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EventRemoveTaxonomies
 */
export interface EventRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  eventId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EventSetKeywords
 */
export interface EventSetKeywordsInput {
  clientMutationId?: string | null;
  eventId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EventSetProducts
 */
export interface EventSetProductsInput {
  clientMutationId?: string | null;
  eventId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of EventUpdate
 */
export interface EventUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  searchable?: Bool | null;
  title?: Str | null;
  active?: Bool | null;
  legacyId?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  contactName?: Str | null;
  location?: Str | null;
  phone?: Str | null;
  email?: Email | null;
  url?: URL | null;
  registrationUrl?: URL | null;
  beginAt?: Time | null;
  endAt?: Time | null;
  timeZone?: Str | null;
  timeZoneIdentifier?: Str | null;
  mailingListId?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  companyId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of FeedAddTaxonomies
 */
export interface FeedAddTaxonomiesInput {
  clientMutationId?: string | null;
  feedId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of FeedCreate
 */
export interface FeedCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  sortingOrder?: Integer | null;
  teaser?: Str | null;
  timeToLive?: Integer | null;
  copyright?: Str | null;
  editorEmail?: Email | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of FeedDelete
 */
export interface FeedDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of FeedRemoveTaxonomies
 */
export interface FeedRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  feedId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of FeedUpdate
 */
export interface FeedUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  sortingOrder?: Integer | null;
  teaser?: Str | null;
  timeToLive?: Integer | null;
  copyright?: Str | null;
  editorEmail?: Email | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of FileAddTaxonomies
 */
export interface FileAddTaxonomiesInput {
  clientMutationId?: string | null;
  fileId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of FileCreate
 */
export interface FileCreateInput {
  clientMutationId?: string | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of FileDelete
 */
export interface FileDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of FileRemoveTaxonomies
 */
export interface FileRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  fileId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of FileSetProducts
 */
export interface FileSetProductsInput {
  clientMutationId?: string | null;
  fileId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of FileUpdate
 */
export interface FileUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of FolderCreate
 */
export interface FolderCreateInput {
  clientMutationId?: string | null;
  path: URL;
}

/**
 * Autogenerated input type of FolderDelete
 */
export interface FolderDeleteInput {
  clientMutationId?: string | null;
  path: URL;
}

/**
 * Autogenerated input type of GalleryAddTaxonomies
 */
export interface GalleryAddTaxonomiesInput {
  clientMutationId?: string | null;
  galleryId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of GalleryCreate
 */
export interface GalleryCreateInput {
  clientMutationId?: string | null;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  displayType?: Str | null;
  title?: Str | null;
  description?: Str | null;
  fileUrl?: URL | null;
  refreshable?: Bool | null;
  thumbsPerRow?: Integer | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of GalleryDelete
 */
export interface GalleryDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of GalleryItemCreate
 */
export interface GalleryItemCreateInput {
  clientMutationId?: string | null;
  galleryId: IDType;
  imageId: IDType;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  sortingOrder?: Integer | null;
  description?: Str | null;
  altText?: Str | null;
  caption?: Str | null;
  credits?: Str | null;
  mediumImageId?: IDType | null;
  thumbnailImageId?: IDType | null;
}

/**
 * Autogenerated input type of GalleryItemDelete
 */
export interface GalleryItemDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of GalleryItemUpdate
 */
export interface GalleryItemUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  sortingOrder?: Integer | null;
  description?: Str | null;
  altText?: Str | null;
  caption?: Str | null;
  credits?: Str | null;
  galleryId?: IDType | null;
  imageId?: IDType | null;
  mediumImageId?: IDType | null;
  thumbnailImageId?: IDType | null;
}

/**
 * Autogenerated input type of GalleryRemoveTaxonomies
 */
export interface GalleryRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  galleryId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of GallerySetKeywords
 */
export interface GallerySetKeywordsInput {
  clientMutationId?: string | null;
  galleryId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of GalleryUpdate
 */
export interface GalleryUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  displayType?: Str | null;
  title?: Str | null;
  description?: Str | null;
  fileUrl?: URL | null;
  refreshable?: Bool | null;
  thumbsPerRow?: Integer | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of ImageAddTaxonomies
 */
export interface ImageAddTaxonomiesInput {
  clientMutationId?: string | null;
  imageId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ImageCreate
 */
export interface ImageCreateInput {
  clientMutationId?: string | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  width?: Integer | null;
  height?: Integer | null;
  altText?: Str | null;
  caption?: Str | null;
  parentImageId?: IDType | null;
  childImageId?: IDType | null;
}

/**
 * Autogenerated input type of ImageDelete
 */
export interface ImageDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ImageRemoveTaxonomies
 */
export interface ImageRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  imageId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ImageSetContributors
 */
export interface ImageSetContributorsInput {
  clientMutationId?: string | null;
  imageId: IDType;
  contributorIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ImageUpdate
 */
export interface ImageUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  width?: Integer | null;
  height?: Integer | null;
  altText?: Str | null;
  caption?: Str | null;
  parentImageId?: IDType | null;
  childImageId?: IDType | null;
}

/**
 * Autogenerated input type of ImpersonateTokenCreate
 */
export interface ImpersonateTokenCreateInput {
  clientMutationId?: string | null;
  userId: IDType;
}

/**
 * Autogenerated input type of KeywordCreate
 */
export interface KeywordCreateInput {
  clientMutationId?: string | null;
  tag: Str;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of LockCreate
 */
export interface LockCreateInput {
  clientMutationId?: string | null;
  objectId: IDType;
  userId: IDType;
  overrideUserId?: IDType | null;
}

/**
 * Autogenerated input type of LockDelete
 */
export interface LockDeleteInput {
  clientMutationId?: string | null;
  objectId: IDType;
  userId?: IDType | null;
  overrideUserId?: IDType | null;
}

/**
 * Autogenerated input type of MailingListDispatchDelete
 */
export interface MailingListDispatchDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of MenuCreate
 */
export interface MenuCreateInput {
  clientMutationId?: string | null;
  active?: Bool | null;
  url?: FragmentURL | null;
  title?: Str | null;
  name?: Str | null;
  sortingOrder?: Integer | null;
  newWindow?: Bool | null;
  imageId?: IDType | null;
  parentId?: IDType | null;
}

/**
 * Autogenerated input type of MenuDelete
 */
export interface MenuDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of MenuNameDelete
 */
export interface MenuNameDeleteInput {
  clientMutationId?: string | null;
  name: Str;
}

/**
 * Autogenerated input type of MenuNameUpdate
 */
export interface MenuNameUpdateInput {
  clientMutationId?: string | null;
  nameFrom: Str;
  nameTo: Str;
}

/**
 * Autogenerated input type of MenuSetTaxonomies
 */
export interface MenuSetTaxonomiesInput {
  clientMutationId?: string | null;
  menuId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of MenuUpdate
 */
export interface MenuUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  url?: FragmentURL | null;
  title?: Str | null;
  name?: Str | null;
  sortingOrder?: Integer | null;
  newWindow?: Bool | null;
  imageId?: IDType | null;
  parentId?: IDType | null;
}

/**
 * Autogenerated input type of MetaCreate
 */
export interface MetaCreateInput {
  clientMutationId?: string | null;
  associatedUrl?: URL | null;
  slug?: Str | null;
  canonicalUrl?: URL | null;
  browserTitle?: Str | null;
  description?: Str | null;
  keywords?: Str | null;
  bottomText?: Str | null;
  itemId?: IDType | null;
}

/**
 * Autogenerated input type of MetaDelete
 */
export interface MetaDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of MetaHeaderCreate
 */
export interface MetaHeaderCreateInput {
  clientMutationId?: string | null;
  name: Str;
  value: Str;
  metaId: IDType;
}

/**
 * Autogenerated input type of MetaHeaderDelete
 */
export interface MetaHeaderDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of MetaHeaderUpdate
 */
export interface MetaHeaderUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  name?: Str | null;
  value?: Str | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of MetaUpdate
 */
export interface MetaUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  associatedUrl?: URL | null;
  slug?: Str | null;
  canonicalUrl?: URL | null;
  browserTitle?: Str | null;
  description?: Str | null;
  keywords?: Str | null;
  bottomText?: Str | null;
  itemId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterAddTaxonomies
 */
export interface NewsletterAddTaxonomiesInput {
  clientMutationId?: string | null;
  newsletterId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of NewsletterCreate
 */
export interface NewsletterCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  legacyId?: Str | null;
  externalId?: Str | null;
  testExternalId?: Str | null;
  internalId?: Str | null;
  automate?: Bool | null;
  autoPublish?: Bool | null;
  teaser?: Str | null;
  description?: Str | null;
  publicationFrequency?: Str | null;
  frequencyDescription?: Str | null;
  frequency?: Integer | null;
  frequencyUnit?: Str | null;
  htmlXslt?: Str | null;
  textXslt?: Str | null;
  notificationEmail?: Email | null;
  lastGeneratedAt?: Time | null;
  adminEmails?: Str | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterDelete
 */
export interface NewsletterDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueAddArticles
 */
export interface NewsletterIssueAddArticlesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  articleIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueAddFiles
 */
export interface NewsletterIssueAddFilesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  fileIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueAddTaxonomies
 */
export interface NewsletterIssueAddTaxonomiesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueCreate
 */
export interface NewsletterIssueCreateInput {
  clientMutationId?: string | null;
  title: Str;
  subject: Str;
  newsletterId: IDType;
  searchable?: Bool | null;
  postAt?: Time | null;
  textBody?: Str | null;
  creativeBody?: Str | null;
  volume?: Str | null;
  issueNumber?: Str | null;
  legacyID?: Str | null;
  published?: Bool | null;
  preheader?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterIssueDelete
 */
export interface NewsletterIssueDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueDispatchUpdate
 */
export interface NewsletterIssueDispatchUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  status?: DispatchStatusEnum | null;
  sendCount?: Integer | null;
  errorMessage?: Str | null;
  externalId?: Str | null;
  subject?: Str | null;
  htmlBody?: Str | null;
  textBody?: Str | null;
  postAt?: Time | null;
  issueId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterIssueRemoveArticles
 */
export interface NewsletterIssueRemoveArticlesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  articleIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueRemoveTaxonomies
 */
export interface NewsletterIssueRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueSetContentProducts
 */
export interface NewsletterIssueSetContentProductsInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  contentProductIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueSetFiles
 */
export interface NewsletterIssueSetFilesInput {
  clientMutationId?: string | null;
  newsletterIssueId: IDType;
  fileIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueUpdate
 */
export interface NewsletterIssueUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  searchable?: Bool | null;
  title?: Str | null;
  subject?: Str | null;
  postAt?: Time | null;
  textBody?: Str | null;
  creativeBody?: Str | null;
  volume?: Str | null;
  issueNumber?: Str | null;
  legacyID?: Str | null;
  published?: Bool | null;
  preheader?: Str | null;
  metaId?: IDType | null;
  newsletterId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterIssueZoneValueCreate
 */
export interface NewsletterIssueZoneValueCreateInput {
  clientMutationId?: string | null;
  newsletterZoneId: IDType;
  newsletterIssueId: IDType;
  value?: Str | null;
}

/**
 * Autogenerated input type of NewsletterIssueZoneValueDelete
 */
export interface NewsletterIssueZoneValueDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterIssueZoneValueUpdate
 */
export interface NewsletterIssueZoneValueUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  value?: Str | null;
  newsletterZoneId?: IDType | null;
  newsletterIssueId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterRemoveTaxonomies
 */
export interface NewsletterRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  newsletterId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterRenderingOptionCreate
 */
export interface NewsletterRenderingOptionCreateInput {
  clientMutationId?: string | null;
  name: Str;
  newsletterId: IDType;
  value?: Str | null;
}

/**
 * Autogenerated input type of NewsletterRenderingOptionDelete
 */
export interface NewsletterRenderingOptionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterRenderingOptionUpdate
 */
export interface NewsletterRenderingOptionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  name?: Str | null;
  value?: Str | null;
  newsletterId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterSetEditors
 */
export interface NewsletterSetEditorsInput {
  clientMutationId?: string | null;
  newsletterId: IDType;
  editorIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterSetProducts
 */
export interface NewsletterSetProductsInput {
  clientMutationId?: string | null;
  newsletterId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterUpdate
 */
export interface NewsletterUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  legacyId?: Str | null;
  externalId?: Str | null;
  testExternalId?: Str | null;
  internalId?: Str | null;
  automate?: Bool | null;
  autoPublish?: Bool | null;
  teaser?: Str | null;
  description?: Str | null;
  publicationFrequency?: Str | null;
  frequencyDescription?: Str | null;
  frequency?: Integer | null;
  frequencyUnit?: Str | null;
  htmlXslt?: Str | null;
  textXslt?: Str | null;
  notificationEmail?: Email | null;
  lastGeneratedAt?: Time | null;
  adminEmails?: Str | null;
  metaId?: IDType | null;
}

/**
 * Autogenerated input type of NewsletterZoneCreate
 */
export interface NewsletterZoneCreateInput {
  clientMutationId?: string | null;
  newsletterId: IDType;
  xsltName?: Str | null;
  refTitle?: Str | null;
}

/**
 * Autogenerated input type of NewsletterZoneDelete
 */
export interface NewsletterZoneDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of NewsletterZoneUpdate
 */
export interface NewsletterZoneUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  xsltName?: Str | null;
  refTitle?: Str | null;
  newsletterId?: IDType | null;
}

/**
 * Autogenerated input type of OfferCreate
 */
export interface OfferCreateInput {
  clientMutationId?: string | null;
  code: Str;
  active?: Bool | null;
  name?: Str | null;
  description?: Str | null;
  dependencyLogic?: Str | null;
  discountType?: Str | null;
  discountValue?: number | null;
  discountLogic?: Str | null;
  quantity?: Integer | null;
  startAt?: Time | null;
  endAt?: Time | null;
  offerType?: Str | null;
  minThreshold?: Money | null;
  confirmationText?: Str | null;
}

/**
 * Autogenerated input type of OfferDelete
 */
export interface OfferDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of OfferSetCountries
 */
export interface OfferSetCountriesInput {
  clientMutationId?: string | null;
  offerId: IDType;
  countryIds?: IDType[] | null;
}

/**
 * Autogenerated input type of OfferUpdate
 */
export interface OfferUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  code?: Str | null;
  name?: Str | null;
  description?: Str | null;
  dependencyLogic?: Str | null;
  discountType?: Str | null;
  discountValue?: number | null;
  discountLogic?: Str | null;
  quantity?: Integer | null;
  startAt?: Time | null;
  endAt?: Time | null;
  offerType?: Str | null;
  minThreshold?: Money | null;
  confirmationText?: Str | null;
}

/**
 * Autogenerated input type of OfferedProductCreate
 */
export interface OfferedProductCreateInput {
  clientMutationId?: string | null;
  dependencyAssociation?: Bool | null;
  discountAssociation?: Bool | null;
  productId?: IDType | null;
  offerId?: IDType | null;
}

/**
 * Autogenerated input type of OfferedProductDelete
 */
export interface OfferedProductDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of OfferedProductUpdate
 */
export interface OfferedProductUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  dependencyAssociation?: Bool | null;
  discountAssociation?: Bool | null;
  productId?: IDType | null;
  offerId?: IDType | null;
}

/**
 * Autogenerated input type of OrderCreate
 */
export interface OrderCreateInput {
  clientMutationId?: string | null;
  multipubId?: Str | null;
  externalId?: Str | null;
  externalUserId?: Str | null;
  note?: Str | null;
  status?: OrderStatusEnum | null;
  firstName?: Str | null;
  lastName?: Str | null;
  jobTitle?: Str | null;
  company?: Str | null;
  phone?: Str | null;
  email?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  subTotal?: Money | null;
  total?: Money | null;
  amountPaid?: Money | null;
  campaignCode?: Str | null;
  trackingCode?: Str | null;
  referralCode?: Str | null;
  batchExport?: Bool | null;
  exportedAt?: Time | null;
  invoicedAt?: Time | null;
  submittedAt?: Time | null;
  filledAt?: Time | null;
  userId?: IDType | null;
  shippingAddressId?: IDType | null;
  paymentId?: IDType | null;
}

/**
 * Autogenerated input type of OrderItemCreate
 */
export interface OrderItemCreateInput {
  clientMutationId?: string | null;
  orderId: IDType;
  multipubId?: Str | null;
  type?: Str | null;
  description?: Str | null;
  quantity?: Integer | null;
  taxable?: Bool | null;
  externalId?: Str | null;
  cost?: Money | null;
  salesTax?: Money | null;
  shippingCost?: Money | null;
  discount?: Money | null;
  filledAt?: Time | null;
  sortingOrder?: Integer | null;
  status?: OrderItemStatusEnum | null;
  productId?: IDType | null;
  offerId?: IDType | null;
  shippingAddressId?: IDType | null;
  subscriptionId?: IDType | null;
}

/**
 * Autogenerated input type of OrderItemDelete
 */
export interface OrderItemDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of OrderItemUpdate
 */
export interface OrderItemUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  multipubId?: Str | null;
  type?: Str | null;
  description?: Str | null;
  quantity?: Integer | null;
  taxable?: Bool | null;
  externalId?: Str | null;
  cost?: Money | null;
  salesTax?: Money | null;
  shippingCost?: Money | null;
  discount?: Money | null;
  filledAt?: Time | null;
  sortingOrder?: Integer | null;
  status?: OrderItemStatusEnum | null;
  orderId?: IDType | null;
  productId?: IDType | null;
  offerId?: IDType | null;
  shippingAddressId?: IDType | null;
  subscriptionId?: IDType | null;
}

/**
 * Autogenerated input type of OrderUpdate
 */
export interface OrderUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  multipubId?: Str | null;
  externalId?: Str | null;
  externalUserId?: Str | null;
  note?: Str | null;
  status?: OrderStatusEnum | null;
  firstName?: Str | null;
  lastName?: Str | null;
  jobTitle?: Str | null;
  company?: Str | null;
  phone?: Str | null;
  email?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  subTotal?: Money | null;
  total?: Money | null;
  amountPaid?: Money | null;
  campaignCode?: Str | null;
  trackingCode?: Str | null;
  referralCode?: Str | null;
  batchExport?: Bool | null;
  exportedAt?: Time | null;
  invoicedAt?: Time | null;
  submittedAt?: Time | null;
  filledAt?: Time | null;
  userId?: IDType | null;
  shippingAddressId?: IDType | null;
  paymentId?: IDType | null;
}

/**
 * Autogenerated input type of PageAddTaxonomies
 */
export interface PageAddTaxonomiesInput {
  clientMutationId?: string | null;
  pageId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of PageCreate
 */
export interface PageCreateInput {
  clientMutationId?: string | null;
  url: URL;
  body: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  searchSummary?: Str | null;
  layout?: Str | null;
  templateName?: Str | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of PageDelete
 */
export interface PageDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PageRemoveTaxonomies
 */
export interface PageRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  pageId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of PageUpdate
 */
export interface PageUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  url?: URL | null;
  title?: Str | null;
  searchSummary?: Str | null;
  body?: Str | null;
  layout?: Str | null;
  templateName?: Str | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of PollAddTaxonomies
 */
export interface PollAddTaxonomiesInput {
  clientMutationId?: string | null;
  pollId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of PollAnswerCreate
 */
export interface PollAnswerCreateInput {
  clientMutationId?: string | null;
  pollQuestionId: IDType;
  sortingOrder?: Integer | null;
  body?: Str | null;
}

/**
 * Autogenerated input type of PollAnswerDelete
 */
export interface PollAnswerDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PollAnswerUpdate
 */
export interface PollAnswerUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  sortingOrder?: Integer | null;
  body?: Str | null;
  pollQuestionId?: IDType | null;
}

/**
 * Autogenerated input type of PollCreate
 */
export interface PollCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  teaser?: Str | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of PollDelete
 */
export interface PollDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PollQuestionCreate
 */
export interface PollQuestionCreateInput {
  clientMutationId?: string | null;
  title: Str;
  pollId: IDType;
  sortingOrder?: Integer | null;
  body?: Str | null;
  teaser?: Str | null;
}

/**
 * Autogenerated input type of PollQuestionDelete
 */
export interface PollQuestionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PollQuestionUpdate
 */
export interface PollQuestionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  title?: Str | null;
  sortingOrder?: Integer | null;
  body?: Str | null;
  teaser?: Str | null;
  pollId?: IDType | null;
}

/**
 * Autogenerated input type of PollRemoveTaxonomies
 */
export interface PollRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  pollId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of PollUpdate
 */
export interface PollUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  postAt?: Time | null;
  pullAt?: Time | null;
  title?: Str | null;
  teaser?: Str | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of PostalRangeCreate
 */
export interface PostalRangeCreateInput {
  clientMutationId?: string | null;
  begin: Str;
  end: Str;
  shippingZoneId?: IDType | null;
}

/**
 * Autogenerated input type of PostalRangeDelete
 */
export interface PostalRangeDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PostalRangeUpdate
 */
export interface PostalRangeUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  begin?: Str | null;
  end?: Str | null;
  shippingZoneId?: IDType | null;
}

/**
 * Autogenerated input type of PrintPageUpdate
 */
export interface PrintPageUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  name?: Str | null;
  status?: PrintPageStatusEnum | null;
  sortingOrder?: Integer | null;
  notes?: Str | null;
  imageId?: IDType | null;
  editionId?: IDType | null;
}

/**
 * Autogenerated input type of PrivilegeAddRoles
 */
export interface PrivilegeAddRolesInput {
  clientMutationId?: string | null;
  privilegeId: IDType;
  roleIds?: IDType[] | null;
}

/**
 * Autogenerated input type of PrivilegeCreate
 */
export interface PrivilegeCreateInput {
  clientMutationId?: string | null;
  displayName: Str;
  name: Str;
  selectable?: Bool | null;
}

/**
 * Autogenerated input type of PrivilegeRemoveRoles
 */
export interface PrivilegeRemoveRolesInput {
  clientMutationId?: string | null;
  privilegeId: IDType;
  roleIds?: IDType[] | null;
}

/**
 * Autogenerated input type of PrivilegeUpdate
 */
export interface PrivilegeUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  displayName?: Str | null;
  name?: Str | null;
  selectable?: Bool | null;
}

/**
 * Autogenerated input type of ProductAddAudios
 */
export interface ProductAddAudiosInput {
  clientMutationId?: string | null;
  productId: IDType;
  audioIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductAddBundledProducts
 */
export interface ProductAddBundledProductsInput {
  clientMutationId?: string | null;
  productId: IDType;
  bundledProductIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductAddFiles
 */
export interface ProductAddFilesInput {
  clientMutationId?: string | null;
  productId: IDType;
  fileIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductAddItems
 */
export interface ProductAddItemsInput {
  clientMutationId?: string | null;
  productId: IDType;
  unionIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductAddTaxonomies
 */
export interface ProductAddTaxonomiesInput {
  clientMutationId?: string | null;
  productId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductAddVariations
 */
export interface ProductAddVariationsInput {
  clientMutationId?: string | null;
  productId: IDType;
  variationIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductAddVideos
 */
export interface ProductAddVideosInput {
  clientMutationId?: string | null;
  productId: IDType;
  videoIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of ProductCostCreate
 */
export interface ProductCostCreateInput {
  clientMutationId?: string | null;
  productId: IDType;
  sortingOrder?: Integer | null;
  description?: Str | null;
  minQuantity?: Integer | null;
  maxQuantity?: Integer | null;
  cost?: Money | null;
  originalCost?: Money | null;
}

/**
 * Autogenerated input type of ProductCostDelete
 */
export interface ProductCostDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductCostSetTaxonomies
 */
export interface ProductCostSetTaxonomiesInput {
  clientMutationId?: string | null;
  productCostId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductCostUpdate
 */
export interface ProductCostUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  sortingOrder?: Integer | null;
  description?: Str | null;
  minQuantity?: Integer | null;
  maxQuantity?: Integer | null;
  cost?: Money | null;
  originalCost?: Money | null;
  productId?: IDType | null;
}

/**
 * Autogenerated input type of ProductCreate
 */
export interface ProductCreateInput {
  clientMutationId?: string | null;
  name: Str;
  active: Bool;
  taxable: Bool;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  voteCount?: Integer | null;
  title?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  weight?: number | null;
  weightUnit?: Str | null;
  shippable?: Bool | null;
  maxQuantity?: Integer | null;
  legacyId?: Str | null;
  internalId?: Str | null;
  externalId?: Str | null;
  productType?: Str | null;
  availability?: Str | null;
  emailContent?: Str | null;
  directAccess?: Bool | null;
  variation?: Bool | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  bookId?: IDType | null;
  subscriptionProductId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of ProductDelete
 */
export interface ProductDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductRemoveTaxonomies
 */
export interface ProductRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  productId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductSetAuthors
 */
export interface ProductSetAuthorsInput {
  clientMutationId?: string | null;
  productId: IDType;
  authorIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductSetItems
 */
export interface ProductSetItemsInput {
  clientMutationId?: string | null;
  productId: IDType;
  unionIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductSetKeywords
 */
export interface ProductSetKeywordsInput {
  clientMutationId?: string | null;
  productId: IDType;
  keywordIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductSetVariations
 */
export interface ProductSetVariationsInput {
  clientMutationId?: string | null;
  productId: IDType;
  variationIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ProductUpdate
 */
export interface ProductUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  allowComments?: Bool | null;
  searchable?: Bool | null;
  voteCount?: Integer | null;
  name?: Str | null;
  active?: Bool | null;
  title?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  weight?: number | null;
  weightUnit?: Str | null;
  shippable?: Bool | null;
  maxQuantity?: Integer | null;
  legacyId?: Str | null;
  internalId?: Str | null;
  externalId?: Str | null;
  productType?: Str | null;
  availability?: Str | null;
  taxable?: Bool | null;
  emailContent?: Str | null;
  directAccess?: Bool | null;
  variation?: Bool | null;
  imageId?: IDType | null;
  metaId?: IDType | null;
  bookId?: IDType | null;
  subscriptionProductId?: IDType | null;
  templateId?: IDType | null;
}

/**
 * Autogenerated input type of PublicationAddTaxonomies
 */
export interface PublicationAddTaxonomiesInput {
  clientMutationId?: string | null;
  publicationId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of PublicationCreate
 */
export interface PublicationCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  searchable?: Bool | null;
  teaser?: Str | null;
  description?: Str | null;
  editionCount?: Integer | null;
  publicationFrequency?: Str | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  currentEditionId?: IDType | null;
}

/**
 * Autogenerated input type of PublicationDelete
 */
export interface PublicationDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PublicationRemoveTaxonomies
 */
export interface PublicationRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  publicationId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of PublicationSectionCreate
 */
export interface PublicationSectionCreateInput {
  clientMutationId?: string | null;
  title: Str;
  sortingOrder: Integer;
  publicationId: IDType;
  subTitle?: Str | null;
  teaser?: Str | null;
  taxonomyId?: IDType | null;
}

/**
 * Autogenerated input type of PublicationSectionDelete
 */
export interface PublicationSectionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of PublicationSectionUpdate
 */
export interface PublicationSectionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  title?: Str | null;
  subTitle?: Str | null;
  teaser?: Str | null;
  sortingOrder?: Integer | null;
  publicationId?: IDType | null;
  taxonomyId?: IDType | null;
}

/**
 * Autogenerated input type of PublicationUpdate
 */
export interface PublicationUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  searchable?: Bool | null;
  title?: Str | null;
  teaser?: Str | null;
  description?: Str | null;
  editionCount?: Integer | null;
  publicationFrequency?: Str | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  currentEditionId?: IDType | null;
}

/**
 * Autogenerated input type of RedirectCreate
 */
export interface RedirectCreateInput {
  clientMutationId?: string | null;
  friendlyUrl: URL;
  actualUrl: URL;
  redirectType: RedirectTypeEnum;
  dispatch?: Bool | null;
  objectId?: IDType | null;
}

/**
 * Autogenerated input type of RedirectDelete
 */
export interface RedirectDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of RedirectUpdate
 */
export interface RedirectUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  friendlyUrl?: URL | null;
  actualUrl?: URL | null;
  redirectType?: RedirectTypeEnum | null;
  dispatch?: Bool | null;
  objectId?: IDType | null;
}

/**
 * Autogenerated input type of RoleAddPrivileges
 */
export interface RoleAddPrivilegesInput {
  clientMutationId?: string | null;
  roleId: IDType;
  privilegeIds?: IDType[] | null;
}

/**
 * Autogenerated input type of RoleCreate
 */
export interface RoleCreateInput {
  clientMutationId?: string | null;
  displayName: Str;
  name: Str;
}

/**
 * Autogenerated input type of RoleRemovePrivileges
 */
export interface RoleRemovePrivilegesInput {
  clientMutationId?: string | null;
  roleId: IDType;
  privilegeIds?: IDType[] | null;
}

/**
 * Autogenerated input type of RoleUpdate
 */
export interface RoleUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  displayName?: Str | null;
  name?: Str | null;
}

/**
 * Autogenerated input type of SettingCreate
 */
export interface SettingCreateInput {
  clientMutationId?: string | null;
  name: Str;
  restricted?: Bool | null;
  description?: Str | null;
  stringValue?: Str | null;
}

/**
 * Autogenerated input type of SettingDelete
 */
export interface SettingDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of SettingUpdate
 */
export interface SettingUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  name?: Str | null;
  restricted?: Bool | null;
  description?: Str | null;
  stringValue?: Str | null;
}

/**
 * Autogenerated input type of ShippingAddressCreate
 */
export interface ShippingAddressCreateInput {
  clientMutationId?: string | null;
  orderId: IDType;
  recipient?: Str | null;
  prefix?: Str | null;
  firstName?: Str | null;
  lastName?: Str | null;
  suffix?: Str | null;
  jobTitle?: Str | null;
  company?: Str | null;
  email?: Str | null;
  phone?: Str | null;
  fax?: Str | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  state?: Str | null;
  province?: Str | null;
  country?: Str | null;
  postalCode?: Str | null;
}

/**
 * Autogenerated input type of ShippingRateAddTaxonomies
 */
export interface ShippingRateAddTaxonomiesInput {
  clientMutationId?: string | null;
  shippingRateId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ShippingRateCreate
 */
export interface ShippingRateCreateInput {
  clientMutationId?: string | null;
  displayName: Str;
  active?: Bool | null;
  type?: Str | null;
  rate?: Money | null;
  description?: Str | null;
  name?: Str | null;
  minThreshold?: Money | null;
  maxThreshold?: Money | null;
  weight?: Integer | null;
  externalId?: Str | null;
  externalType?: Str | null;
}

/**
 * Autogenerated input type of ShippingRateDelete
 */
export interface ShippingRateDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ShippingRateRemoveTaxonomies
 */
export interface ShippingRateRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  shippingRateId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of ShippingRateUpdate
 */
export interface ShippingRateUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  type?: Str | null;
  rate?: Money | null;
  displayName?: Str | null;
  description?: Str | null;
  name?: Str | null;
  minThreshold?: Money | null;
  maxThreshold?: Money | null;
  weight?: Integer | null;
  externalId?: Str | null;
  externalType?: Str | null;
}

/**
 * Autogenerated input type of ShippingZoneCreate
 */
export interface ShippingZoneCreateInput {
  clientMutationId?: string | null;
  domestic?: Bool | null;
  externalId?: Str | null;
  externalGroundId?: Str | null;
  country?: Str | null;
}

/**
 * Autogenerated input type of ShippingZoneDelete
 */
export interface ShippingZoneDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of ShippingZoneUpdate
 */
export interface ShippingZoneUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  domestic?: Bool | null;
  externalId?: Str | null;
  externalGroundId?: Str | null;
  country?: Str | null;
}

/**
 * Autogenerated input type of SubscriptionCreate
 */
export interface SubscriptionCreateInput {
  clientMutationId?: string | null;
  userId: IDType;
  beginAt?: Time | null;
  endAt?: Time | null;
  trial?: Bool | null;
  renewal?: Bool | null;
  recurring?: Bool | null;
  externalId?: Str | null;
  gatewayId?: Str | null;
  qfieOrdNum?: Str | null;
  resource?: Str | null;
  type?: SubscriptionTypeEnum | null;
  licenseType?: SubscriptionLicenseTypeEnum | null;
  productId?: IDType | null;
  itemId?: IDType | null;
  orderItemId?: IDType | null;
}

/**
 * Autogenerated input type of SubscriptionDelete
 */
export interface SubscriptionDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of SubscriptionProductCreate
 */
export interface SubscriptionProductCreateInput {
  clientMutationId?: string | null;
  type: SubscriptionTypeEnum;
  productId: IDType;
  duration?: Integer | null;
  durationUnit?: SubscriptionDurationUnitEnum | null;
  resource?: Str | null;
  licenseType?: SubscriptionLicenseTypeEnum | null;
  itemId?: IDType | null;
}

/**
 * Autogenerated input type of SubscriptionProductUpdate
 */
export interface SubscriptionProductUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  duration?: Integer | null;
  durationUnit?: SubscriptionDurationUnitEnum | null;
  resource?: Str | null;
  licenseType?: SubscriptionLicenseTypeEnum | null;
  type?: SubscriptionTypeEnum | null;
  itemId?: IDType | null;
  productId?: IDType | null;
}

/**
 * Autogenerated input type of SubscriptionUpdate
 */
export interface SubscriptionUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  beginAt?: Time | null;
  endAt?: Time | null;
  trial?: Bool | null;
  renewal?: Bool | null;
  recurring?: Bool | null;
  externalId?: Str | null;
  gatewayId?: Str | null;
  qfieOrdNum?: Str | null;
  resource?: Str | null;
  type?: SubscriptionTypeEnum | null;
  licenseType?: SubscriptionLicenseTypeEnum | null;
  userId?: IDType | null;
  productId?: IDType | null;
  itemId?: IDType | null;
  orderItemId?: IDType | null;
}

/**
 * Autogenerated input type of TaxRateAddTaxonomies
 */
export interface TaxRateAddTaxonomiesInput {
  clientMutationId?: string | null;
  taxRateId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxRateCreate
 */
export interface TaxRateCreateInput {
  clientMutationId?: string | null;
  states: Str;
  rate: number;
  active?: Bool | null;
  name?: Str | null;
  description?: Str | null;
  includeShipping?: Bool | null;
}

/**
 * Autogenerated input type of TaxRateDelete
 */
export interface TaxRateDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxRateRemoveTaxonomies
 */
export interface TaxRateRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  taxRateId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxRateUpdate
 */
export interface TaxRateUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  states?: Str | null;
  rate?: number | null;
  name?: Str | null;
  description?: Str | null;
  includeShipping?: Bool | null;
}

/**
 * Autogenerated input type of TaxonomyAddArticles
 */
export interface TaxonomyAddArticlesInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  articleIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddClassifieds
 */
export interface TaxonomyAddClassifiedsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  classifiedIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddCompanies
 */
export interface TaxonomyAddCompaniesInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  companyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddCustomFieldTypes
 */
export interface TaxonomyAddCustomFieldTypesInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  customFieldTypeOwner: CustomizableEnum;
  customFieldTypeIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddEvents
 */
export interface TaxonomyAddEventsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  eventIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddProducts
 */
export interface TaxonomyAddProductsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  productIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of TaxonomyAddUsers
 */
export interface TaxonomyAddUsersInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  userIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyCreate
 */
export interface TaxonomyCreateInput {
  clientMutationId?: string | null;
  title: Str;
  active?: Bool | null;
  name?: Str | null;
  sortingOrder?: Integer | null;
  description?: Str | null;
  selectable?: Bool | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
  parentId?: IDType | null;
}

/**
 * Autogenerated input type of TaxonomyDelete
 */
export interface TaxonomyDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

export interface TaxonomyInput {
  id: number;
}

/**
 * Autogenerated input type of TaxonomyRemoveArticles
 */
export interface TaxonomyRemoveArticlesInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  articleIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyRemoveClassifieds
 */
export interface TaxonomyRemoveClassifiedsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  classifiedIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyRemoveCompanies
 */
export interface TaxonomyRemoveCompaniesInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  companyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyRemoveEvents
 */
export interface TaxonomyRemoveEventsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  eventIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyRemoveProducts
 */
export interface TaxonomyRemoveProductsInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyRemoveUsers
 */
export interface TaxonomyRemoveUsersInput {
  clientMutationId?: string | null;
  taxonomyId: IDType;
  userIds?: IDType[] | null;
}

/**
 * Autogenerated input type of TaxonomyUpdate
 */
export interface TaxonomyUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  title?: Str | null;
  name?: Str | null;
  sortingOrder?: Integer | null;
  description?: Str | null;
  selectable?: Bool | null;
  legacyId?: Str | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
  parentId?: IDType | null;
}

/**
 * Autogenerated input type of UiStyleSet
 */
export interface UiStyleSetInput {
  clientMutationId?: string | null;
  objectId: IDType;
  uiStyle?: Str | null;
  column?: Str | null;
}

/**
 * Autogenerated input type of UserAddNewsletters
 */
export interface UserAddNewslettersInput {
  clientMutationId?: string | null;
  userId: IDType;
  newsletterIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserAddTaxonomies
 */
export interface UserAddTaxonomiesInput {
  clientMutationId?: string | null;
  userId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserCreate
 */
export interface UserCreateInput {
  clientMutationId?: string | null;
  email: Email;
  active?: Bool | null;
  multipubId?: Str | null;
  lastName?: Str | null;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  suffix?: Str | null;
  userType?: UserTypeEnum | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  homePhone?: Str | null;
  workPhone?: Str | null;
  cellPhone?: Str | null;
  fax?: Str | null;
  jobTitle?: Str | null;
  company?: Str | null;
  companyContact?: Str | null;
  legacyId?: Str | null;
  externalId?: Str | null;
  notes?: Str | null;
  password?: Str | null;
  securityQuestion?: Str | null;
  securityAnswer?: Str | null;
  emailOpted?: Bool | null;
  mailOpted?: Bool | null;
  thirdPartyOpted?: Bool | null;
  optedOutAt?: Time | null;
  token?: Str | null;
}

/**
 * Autogenerated input type of UserDelete
 */
export interface UserDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of UserIpRangeCreate
 */
export interface UserIpRangeCreateInput {
  clientMutationId?: string | null;
  userId: IDType;
  beginIpAddress?: IPAddress | null;
  endIpAddress?: IPAddress | null;
}

/**
 * Autogenerated input type of UserIpRangeDelete
 */
export interface UserIpRangeDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of UserIpRangeUpdate
 */
export interface UserIpRangeUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  beginIpAddress?: IPAddress | null;
  endIpAddress?: IPAddress | null;
  userId?: IDType | null;
}

/**
 * Autogenerated input type of UserRemoveNewsletters
 */
export interface UserRemoveNewslettersInput {
  clientMutationId?: string | null;
  userId: IDType;
  newsletterIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserRemoveTaxonomies
 */
export interface UserRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  userId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserSetCompanies
 */
export interface UserSetCompaniesInput {
  clientMutationId?: string | null;
  userId: IDType;
  companyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserSetPrivileges
 */
export interface UserSetPrivilegesInput {
  clientMutationId?: string | null;
  userId: IDType;
  privilegeIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserSetRoles
 */
export interface UserSetRolesInput {
  clientMutationId?: string | null;
  userId: IDType;
  roleIds?: IDType[] | null;
}

/**
 * Autogenerated input type of UserUpdate
 */
export interface UserUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  multipubId?: Str | null;
  email?: Email | null;
  lastName?: Str | null;
  prefix?: Str | null;
  firstName?: Str | null;
  middleName?: Str | null;
  suffix?: Str | null;
  userType?: UserTypeEnum | null;
  address1?: Str | null;
  address2?: Str | null;
  city?: Str | null;
  stateName?: Str | null;
  province?: Str | null;
  countryName?: Str | null;
  postalCode?: Str | null;
  homePhone?: Str | null;
  workPhone?: Str | null;
  cellPhone?: Str | null;
  fax?: Str | null;
  jobTitle?: Str | null;
  company?: Str | null;
  companyContact?: Str | null;
  legacyId?: Str | null;
  externalId?: Str | null;
  notes?: Str | null;
  password?: Str | null;
  securityQuestion?: Str | null;
  securityAnswer?: Str | null;
  emailOpted?: Bool | null;
  mailOpted?: Bool | null;
  thirdPartyOpted?: Bool | null;
  optedOutAt?: Time | null;
  token?: Str | null;
}

/**
 * Autogenerated input type of VideoAddTaxonomies
 */
export interface VideoAddTaxonomiesInput {
  clientMutationId?: string | null;
  videoId: IDType;
  taxonomyIds?: IDType[] | null;
  sortingOrders?: (Integer | null)[] | null;
}

/**
 * Autogenerated input type of VideoCreate
 */
export interface VideoCreateInput {
  clientMutationId?: string | null;
  duration?: number | null;
  durationUnit?: MediaDurationUnitEnum | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  releaseDate?: Date | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of VideoDelete
 */
export interface VideoDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of VideoRemoveTaxonomies
 */
export interface VideoRemoveTaxonomiesInput {
  clientMutationId?: string | null;
  videoId: IDType;
  taxonomyIds?: IDType[] | null;
}

/**
 * Autogenerated input type of VideoSetProducts
 */
export interface VideoSetProductsInput {
  clientMutationId?: string | null;
  videoId: IDType;
  productIds?: IDType[] | null;
}

/**
 * Autogenerated input type of VideoUpdate
 */
export interface VideoUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  duration?: number | null;
  durationUnit?: MediaDurationUnitEnum | null;
  searchable?: Bool | null;
  url?: URL | null;
  remote?: Bool | null;
  title?: Str | null;
  description?: Str | null;
  mimeType?: Str | null;
  externalId?: Str | null;
  credit?: Str | null;
  releaseDate?: Date | null;
  metaId?: IDType | null;
  imageId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowCreate
 */
export interface WorkflowCreateInput {
  clientMutationId?: string | null;
  articleId: IDType;
  completedAt?: Time | null;
  latestEditId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowEditCreate
 */
export interface WorkflowEditCreateInput {
  clientMutationId?: string | null;
  workflowId: IDType;
  currentStepId: IDType;
  active?: Bool | null;
  comment?: Str | null;
  completedAt?: Time | null;
  claimedAt?: Time | null;
  nextStepId?: IDType | null;
  originatingUserId?: IDType | null;
  assignedUserId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowEditDelete
 */
export interface WorkflowEditDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of WorkflowEditUpdate
 */
export interface WorkflowEditUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  active?: Bool | null;
  comment?: Str | null;
  completedAt?: Time | null;
  claimedAt?: Time | null;
  workflowId?: IDType | null;
  currentStepId?: IDType | null;
  nextStepId?: IDType | null;
  originatingUserId?: IDType | null;
  assignedUserId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowRuleCreate
 */
export interface WorkflowRuleCreateInput {
  clientMutationId?: string | null;
  currentStepId: IDType;
  targetStepId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowRuleDelete
 */
export interface WorkflowRuleDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of WorkflowStepCreate
 */
export interface WorkflowStepCreateInput {
  clientMutationId?: string | null;
  name: Str;
  roleId: IDType;
  terminal?: Bool | null;
  sortingOrder?: Integer | null;
}

/**
 * Autogenerated input type of WorkflowStepDelete
 */
export interface WorkflowStepDeleteInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
}

/**
 * Autogenerated input type of WorkflowStepUpdate
 */
export interface WorkflowStepUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  name?: Str | null;
  terminal?: Bool | null;
  sortingOrder?: Integer | null;
  roleId?: IDType | null;
}

/**
 * Autogenerated input type of WorkflowUpdate
 */
export interface WorkflowUpdateInput {
  clientMutationId?: string | null;
  ids?: IDType[] | null;
  completedAt?: Time | null;
  articleId?: IDType | null;
  latestEditId?: IDType | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
