import { notification } from 'antd';
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification';

export interface Message {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  description?: any;
  placement?: NotificationPlacement,
  duration?: number,
}

/**
 * Show Notification
 *
 * @param {Message} message
 * @param {()=>void} onClose
 */
const openNotificationWithIcon = (
  message: Message,
  onClose?: () => void,
  config?: ArgsProps,
) => {
  const baseConfig: ArgsProps = {
    message: message.title,
    description: message.description,
    onClose,
    duration: message.duration,
    placement: message?.placement ?? "bottomRight",
    ...config
  };
  notification[message.type]({
    ...baseConfig
  });
};

export default openNotificationWithIcon;
