import gql from 'graphql-tag';

export const COUNTRIES = gql`
  query Countries($sortString: [Str], $page: Integer!, $perPage: Integer!) {
    _countryStats {
      count
    }
    countries(sortString: $sortString, page: $page, perPage: $perPage) {
      id
      iso
      iso3
      isoName
      name
    }
  }
`;
