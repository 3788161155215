import React, { useState, useEffect, useRef, useContext } from 'react';
import { Input, Popover } from 'antd';
import { useApolloClient } from '@apollo/react-hooks';
import { QueryOptions } from 'apollo-client';
import classNames from 'classnames';
import { useDebounce, useMountedState } from 'react-use';
import reOrder from "utils/reOrder";
import OpenNotificationWithIcon from 'utils/notification';
import { useKeywordPrivilege } from 'hooks/useKeywordPrivilege';
import Tags from '../tree/tags';
import Tree, { NoData } from '../tree';
import ClearButton from '../tree/clearButton';
import { mapKeysToTitles, createNewTag } from '../tree/utils';
import * as css from '../style';
import { KEY } from 'constants/index';
import { TAXONOMY_BY_ID_NAME, TAXONOMY_HIERARCHY } from 'graphql/taxonomy';
import { Context as DrawerContext } from 'components/drawer/context';
import Drawer from 'components/drawer';
import KeywordSuggestionForm from './keywordSuggestion';

// TODO: Change search icon to caret to fake the dropdown style.

export interface RightClickProps {
  event: Event,
  node: React.ReactNode,
}

export interface Pagination {
  currentPage: number,
  totalPages: number,
  loader: boolean,
}

type SearchState = {
  expandedKeys: Array<string>,
  selectedKeys: Array<string>,
  selectedTags: Array<{
    key: string,
    title: string,
  }>,
  autoExpandParent: boolean,
  hasFocus: boolean,
  hasTreeVisible: boolean
  isSearching: boolean,
  searchValue: string,
  searchResult: any,
  allResult: any,
  hasSearchResult: boolean,
  pagination: Pagination,
  expandedKeysPagination: {
    [key: string]: Pagination,
  },
  loadedKeys: string[],
}

type Props = {
  allowEnterNewValue: boolean,
  name: string,
  defaultValue: Array<string>,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  defaultSelectedTags?: Array<{ key: string, title: string }>,
  theme?: 'light' | 'dark',
  placeholder?: string,
  notAllowedIdForSelection?: Array<string>,
  allowMultipleSelection?: boolean,
  queryName?: string,
  queryVariables?: object,
  queryDataType?: string,
  queryStatsType?: string,
  querySearchName: string,
  querySearchVariables: object,
  querySearchDataType: string,
  querySearchStatsType?: string,
  querySearchField: string,
  isDragDisabled?: boolean,
  hasTreeStructure?: boolean,
  isRemoteFilterEnabled?: boolean,
  maxWidth?: string,
  onCloseTagCallback?: (value: string) => void,
  filterStringProcessing?: (searchValue: string) => string[],
  perPage?: number,
  children?: Function,
  onTitleRightClick?: (rightClickProps) => void,
  showTree?: boolean,
  allowSelection?: boolean,
  allowTypeaheadSearch?: boolean,
  allowDisabledNode?: boolean,
  sortByPlanDates?: boolean,
  showHierarchyInTooltip?: boolean,
  allowKeywordSuggestion?: boolean,
  openAIAPIEnabled?: boolean,
}

/**
 * A select component with search enabled that utilizes a GraphQL query for search and one for the initial dropdown.
 *
 * @param {Object} Props
 * @param {number} props.id The query variable ID.
 * @param {Array} props.defaultValue The default value of the select field.
 * @param {Array} props.defaultSelectedTags Only needed to be passed if there are default values AND no initial query.
 * @param {Function} props.onChange The callback function when the selection changes.
 * @param {string} props.placeholder The placeholder value of the input field.
 * @param {sring} props.theme light or dark
 * @param {boolean} props.allowEnterNewValue Can a user onKeyDown hit enter and submit a new value from their input.
 * @param {boolean} props.allowMultipleSelection Can multiple values be selected?
 * @param {Array} props.notAllowedIdForSelection Exclude ID's from being selected.
 * @param {string} props.queryName The GraphQL Query that is loaded initially.
 * @param {string} props.queryVariables Pass any variables to the initial query.
 * @param {string} props.queryDataType The object name holding the data in the query.
 * @param {string} props.querySearchName The GraphQL Query for search.
 * @param {string} props.querySearchVariables Pass any additional variables to the search query.
 * @param {string} props.querySearchDataType The object name holding the data in the search query.
 * @param {string} props.querySearchField The field that the search term is compared against in the search query.
 * @param {boolean} props.isDragDisabled It gives indication for whether selected values draggable or not.
 * @param {boolean} props.hasTreeStructure If given data's type should be displayed as tree or not
 * @param {boolean} props.isRemoteFilterEnabled It will search remotely if its true or filter locally from state
 * @param {string}  props.maxWidth maximum width select box can have.
 * @param {(searchValue: string) => string[]} props.filterStringProcessing get filterStrings param value for search api
 * @param {string} props.queryStatsType the object's stats name holding count data in query result
 * @param {string} props.querySearchStatsType the object's stats name holding count data in search query result
 * @param {number} props.perPage number of records to access on each page query
 * @param {React.ReactNode | null} props.children a renderProps - title will be passed to this renderProp for further process and show final title
 * @param {Function} props.onTitleRightClick a callback of title right click,
 * @param {boolean} props.showTree control show tree from parent component
 * @param {boolean} props.allowSelection allow to select option or not
 * @param {boolean} props.allowDisabledNode allow to diable any item based on provided data->item->allowSelect attr
 * @param {boolean} props.allowKeywordSuggestion indicate allow keyword suggestion or not
 * @param {boolean} props.openAIAPIEnabled indicate open AI API setting is enabled
 * @returns React Component
 */
const SearchSelect = ({
  allowEnterNewValue = false,
  name,
  defaultValue = [],
  defaultSelectedTags = [],
  onChange,
  placeholder,
  theme = 'light',
  allowMultipleSelection = true,
  notAllowedIdForSelection = [],
  queryName = null,
  queryVariables = {},
  queryDataType = "",
  querySearchName,
  querySearchVariables,
  querySearchDataType,
  querySearchField,
  isDragDisabled = true,
  hasTreeStructure = true,
  isRemoteFilterEnabled = true,
  maxWidth = "300px",
  onCloseTagCallback = (value: string) => { },
  filterStringProcessing,
  queryStatsType,
  querySearchStatsType,
  perPage = 100,
  children = null,
  onTitleRightClick = null,
  showTree = false,
  allowSelection = true,
  allowTypeaheadSearch,
  allowDisabledNode = false,
  sortByPlanDates = false,
  showHierarchyInTooltip = false,
  allowKeywordSuggestion = false,
  openAIAPIEnabled = false,
}: Props) => {
  const client = useApolloClient();
  const isMounted = useMountedState();
  const { hasPrivilege } = useKeywordPrivilege();
  const { toggleShow, closeDrawer } = useContext(DrawerContext);

  const expandedKeysRef = useRef([]);
  const [state, setState] = useState<SearchState>({
    expandedKeys: expandedKeysRef.current,
    selectedKeys: defaultValue,
    selectedTags: defaultSelectedTags,
    autoExpandParent: false,
    hasFocus: false,
    hasTreeVisible: false,
    isSearching: false,
    searchValue: '',
    searchResult: [],
    allResult: [],
    hasSearchResult: false,
    pagination: {
      currentPage: 1,
      totalPages: 0,
      loader: false,
    },
    expandedKeysPagination: {},
    loadedKeys: [],
  });
  const searchInputRef = useRef(null);
  const searchValueRef = useRef(null);
  const popoverContainerRef = useRef(null);
  const {
    autoExpandParent,
    hasFocus,
    hasTreeVisible,
    isSearching,
    searchValue,
    searchResult,
    selectedKeys,
    selectedTags,
    allResult,
    hasSearchResult,
    loadedKeys,
  } = state;

  // Fetch initial data only when we have no results and a default query is available.
  useEffect(() => {
    if (!queryName || !hasFocus) return;

    // If data is loaded already once, do not fetch data again
    if (isMounted()) return;

    fetchInitialData();
  }, [queryName, hasFocus]);

  /**
   * Event listener of drawer close event
   * Keep popover open, when drawer closes
   */
  useEffect(() => {
    const handler = (event) => {
      if (event.detail?.id?.startsWith(`taxonomy-add-${name}`)) {
        setState((prevState) => ({
          ...prevState,
          hasTreeVisible: true,
        }));
      }
    };
    document.addEventListener('epub.drawer.close', handler);

    return () => document.removeEventListener('epub.drawer.close', handler);
  }, []);

  /**
   * Reset current page when user searching or removed search term
   */
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        currentPage: 1,
      },
    }));
  }, [isSearching]);

  // Keep the input in focus on rerenders while isSearching state is active
  useEffect(() => {
    if (isSearching && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  });

  // Listen for click events to handle if a user clicks outside of the dropdown.
  useEffect(() => {
    if (hasFocus || hasTreeVisible) document.addEventListener('mousedown', handleOutsideClick, false);

    return () => document.removeEventListener('mousedown', handleOutsideClick, false);
  });

  // Trigger the callback that calls the onChange prop; a parent container can handle value changes.
  useEffect(() => {
    if (defaultValue === selectedKeys) return;

    const value = () => {
      if (queryDataType === 'keywords' && !allowEnterNewValue) return selectedTags;
      if (allowEnterNewValue) return selectedTags;
      return !allowMultipleSelection ? selectedKeys.join(",") : selectedKeys;
    };

    const modifyEventForSelectType = {
      target: {
        name,
        value: value(),
      },
      persist: () => { }
    };

    onChange(modifyEventForSelectType as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [selectedKeys]);

  const timeToDebounce = 300;

  // Give the user time to type the search term into the input before fetching data.
  useDebounce(() => {
    if (!searchValue && !isRemoteFilterEnabled) {
      return setState({
        ...state,
        searchResult: [...allResult],
      });
    }

    if (!searchValue && queryName) return fetchInitialData();

    if (searchValue && isRemoteFilterEnabled) return fetchSearchData();

    filterSearchDataFromLocalState();
  }, timeToDebounce, [searchValue]);

  // Search data from allResult when user type search term and isRemoteSearchEnabled is false
  function filterSearchDataFromLocalState() {
    const fields = querySearchField.split(",");
    // filtering values and searchValue in lower case
    const searchValueLowerCase = searchValue.toLocaleLowerCase();
    const filteredResult = allResult.filter((item) => {

      const foundInAllValues = fields.map((field) => (item[field] && item[field].toLowerCase()) || '').filter((val) => val).join(' ').indexOf(searchValueLowerCase) !== -1;

      return foundInAllValues || fields.reduce((result: boolean, field) => {
        return result || (item[field] && item[field].toLowerCase().indexOf(searchValueLowerCase) >= 0)
      }, false)
    })

    setState({
      ...state,
      searchResult: filteredResult,
    })
  }

  // Fetch filtered data from GraphQL, set state with new results.
  // Separated from onChange event to be able to useDebounce.
  async function fetchSearchData(page = 1) {
    const filterFieldName = queryDataType === "authors" ? "terms" : querySearchDataType === "search" ? "filterStrings" : "filterString";
    const filterStringParamGeneralValue = (!searchValue) ? [] : [`${querySearchField} = ${searchValue} %OR% ${querySearchField} startsWith ${searchValue}`];
    const filterStringParamValue = filterStringProcessing ? filterStringProcessing(searchValue) : filterStringParamGeneralValue;

    if (page > 1) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          loader: true,
        }
      }));
    }

    const queryData: QueryOptions = {
      query: querySearchName,
      variables: {
        [filterFieldName]: filterStringParamValue,
        perPage,
        page,
        ...querySearchVariables,
      },
      fetchPolicy: 'network-only'
    };
    const { data } = await client.query(queryData);

    if (!isMounted()) return;

    // When search api for non-taxonomy type get called, result structure will be different
    if (data?.search) {
      const result = data.search?.map((item) => item.item);
      const statsResult = querySearchStatsType ? data[querySearchStatsType] : null;
      setState((prevState) => ({
        ...prevState,
        searchResult: page > 1 ? [...prevState.searchResult, ...result] : result,
        hasSearchResult: statsResult ? Boolean(statsResult.count) : false,
        pagination: {
          ...prevState.pagination,
          currentPage: page,
          totalPages: statsResult ? Math.ceil(statsResult.count / perPage) : 1,
          loader: false,
        }
      }));
      return;
    }

    const result = (querySearchDataType === 'taxonomy') ? data[querySearchDataType].children : data[querySearchDataType];
    const statsResult = querySearchDataType === 'taxonomy' ? data.taxonomy[queryStatsType] : data[queryStatsType];
    setState((prevState) => ({
      ...prevState,
      searchResult: page > 1 ? [...prevState.searchResult, ...result] : result,
      hasSearchResult: statsResult ? Boolean(statsResult.count) : false,
      pagination: {
        ...prevState.pagination,
        currentPage: page,
        totalPages: statsResult?.count ? Math.ceil(statsResult.count / perPage) : 1,
        loader: false,
      },
    }));
  }

  // Fetch filtered data from GraphQL, set state with new results.
  // Separated from onChange event to be able to useDebounce.
  async function fetchInitialData(page = 1) {
    if (page > 1) {
      setState({
        ...state,
        pagination: {
          ...state.pagination,
          loader: true,
        }
      });
    }
    const queryData: QueryOptions = {
      query: queryName,
      variables: {
        perPage,
        page,
        ...queryVariables,
      },
      fetchPolicy: "network-only",
    };
    const { data } = await client.query(queryData);
    const result = hasTreeStructure ? data[queryDataType].children : data[queryDataType];
    const resultFiltered = result.filter((item) => item);
    if (!isMounted()) return;

    const statsResult = querySearchDataType === 'taxonomy' ? data.taxonomy[queryStatsType] : data[queryStatsType];
    // Bail out if there's no result. Set state with empty array for other checks.
    if (!resultFiltered.length) {
      setState((prevState) => ({
        ...prevState,
        searchResult: page > 1 ? [...prevState.searchResult, ...resultFiltered] : resultFiltered,
        hasSearchResult: false,
        pagination: {
          ...prevState.pagination,
          currentPage: page,
          totalPages: statsResult ? Math.ceil(statsResult.count / perPage) : 1,
          loader: false,
        }
      }));
      return;
    }

    searchValueRef.current = result;

    // Map selectedKeys with initial data, to show already selected tags.
    const newSelectedTags = [];
    if (selectedKeys.length) {
      let newSelectedTagsIntermediate = mapKeysToTitles(querySearchField, selectedTags, selectedKeys, resultFiltered);

      if (showHierarchyInTooltip === true) {
        newSelectedTagsIntermediate = await Promise.all(newSelectedTagsIntermediate.map(async item => {
          const queryParams: QueryOptions = {
            query: TAXONOMY_HIERARCHY,
            variables: {
              id: item.key
            },
          };
          await client.query(queryParams).then((result) => {
            let taxonomyNameHierarchy = recursiveTaxonomiesHierarchy(result.data.taxonomy, true);
            item.parentTitle = taxonomyNameHierarchy;
          });

          return item;
        }));
      }

      // Note: the query used above results in a list of items in a certain order
      // in order for these to match the order of selectedKeys we need to order the resultant
      // tags by the order of the selectedKeys array
      selectedKeys.forEach((key) => {
        let found = newSelectedTagsIntermediate.find((tag) => tag.key === key);
        if (found) newSelectedTags.push(found);
      });
    }

    setState((prevState) => ({
      ...prevState,
      selectedTags: newSelectedTags,
      searchResult: page > 1 ? [...prevState.searchResult, ...resultFiltered] : resultFiltered,
      hasSearchResult: true,
      pagination: {
        ...prevState.pagination,
        currentPage: page,
        totalPages: statsResult ? Math.ceil(statsResult.count / perPage) : 1,
        loader: false,
      },
      allResult: page > 1 ? [...prevState.searchResult, ...resultFiltered] : resultFiltered,
    }));
  }

  /**
   * Close the popOver if the user clicks outside of either the input or popover.
   * By setting the focus state to false.
   *
   * @param {*} e Click event
   */
  function handleOutsideClick(e) {
    const isInput = searchInputRef.current.input.contains(e.target);
    const isPopover = popoverContainerRef.current.contains(e.target);
    const isTag = e.target.classList.contains('ant-tag');
    if (isInput || isPopover || isTag) return;

    setState((prevState) => ({
      ...prevState,
      hasFocus: false,
      hasTreeVisible: false,
      isSearching: false,
      searchValue: "",
    }));
  }

  /**
   * As each change is triggered in the input, we set the state based on
   * search occouring if there is a value. When there is no value we reset
   * the data back to the initial state using a Ref.
   *
   * Reset selectedTags if allowMultipleSelection is not enabled and no input
   * value. This clears the input value when that flag is disabled.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function onChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;

    if (!value) {
      setState({
        ...state,
        isSearching: false,
        searchValue: value,
        searchResult: searchValueRef.current || [],
        selectedTags: allowMultipleSelection ? selectedTags : [],
      });
      return;
    }

    setState({
      ...state,
      isSearching: true,
      searchValue: value,
      hasFocus: true,
      expandedKeys: [],
      loadedKeys: [],
    });
  }

  /**
   * Set expandedKeys in state so we can track which sub menu items are expanded.
   *
   * @param {Array} expandedKeys A list of keys that are expanded.
   */
  function onExpand(expandedKeys: Array<string>) {
    // For some reason the component gets re-rendered with empty keys after loading merged
    // data from Tree. Using a ref to keep track of all keys as they are expanded.
    expandedKeysRef.current = expandedKeys;
    setState({ ...state, expandedKeys });
  }

  /**
   * In case of internet does not work or api throw error during sub-branches load causes expand node remain exapnded without showing sub-branches.
   * To unexpand it, this callback will be called from error handler.
   *
   * @param {string} key
   */
  function revertExpandedKey(key: string) {
    const index = expandedKeysRef.current ? expandedKeysRef.current.indexOf(key) : -1;

    if (index < 0) return;

    const updatedExpandedKeys = [...expandedKeysRef.current.slice(0, index), ...expandedKeysRef.current.slice(index + 1)];
    expandedKeysRef.current = updatedExpandedKeys;
    setState((prevState) => ({ ...prevState, expandedKeys: updatedExpandedKeys }));
  }

  let taxonomyNames = '';
  /**
   * Recursive function for taxonomies hierarchy
   * @param taxonomies object of taxonomies
   * @param isSelectedTag if taxonomies array is of selected tag or not
   * @returns Taxonomy Name Hierarchy
   */
  function recursiveTaxonomiesHierarchy(taxonomies, isSelectedTag = false) {
    const hasParent = taxonomies && taxonomies.hasOwnProperty('parent') && (taxonomies.parent !== null);

    if (hasParent) {
      if (!isSelectedTag) {
        if (taxonomyNames !== '') {
          taxonomyNames = taxonomies?.title + ' > ' + taxonomyNames;
        } else {
          taxonomyNames = taxonomies?.title;
        }
      }
      return recursiveTaxonomiesHierarchy(taxonomies.parent);
    } else {
      let rootTaxonomyName;
      if (taxonomyNames != '') {
        rootTaxonomyName = taxonomies?.title + ' > ' + taxonomyNames;
        taxonomyNames = '';
      }
      return rootTaxonomyName;
    }
  }

  /**
   * The change event for each time a select item is clicked.
   *
   * @param {Array} keys The taxonomy id of the selected key.
   */
  async function onTreeSelect(keys: Array<string>) {
    if (!allowSelection) return;

    // Don't apply state changes if the key is already shown as selected.
    const isKeyAlreadySelected = selectedKeys.find((k) => keys.includes(k));
    if (isKeyAlreadySelected) return;

    // Apply added keys to the state.
    let newSelectedKeys = selectedKeys.slice();
    let newSelectedTags = selectedTags.slice();

    // Append keys if multiple selection is enabled, otherwise just replace
    // Reduce tags to only the latest one, since only one is considered selected.
    if (allowMultipleSelection) {
      newSelectedKeys.push(...keys);
    } else {
      newSelectedKeys = [...keys];
      newSelectedTags = newSelectedTags.slice(-1);
    }
    // Build a parrallel list of titles along with keys, so titles can be shown in the input.
    let mappedTags = mapKeysToTitles(querySearchField, newSelectedTags, newSelectedKeys, searchResult);

    if (showHierarchyInTooltip === true) {
      mappedTags = await Promise.all(mappedTags.map(async item => {
        const queryParams: QueryOptions = {
          query: TAXONOMY_HIERARCHY,
          variables: {
            id: item.key
          },
        };
        await client.query(queryParams).then((result) => {
          let taxonomyNameHierarchy = recursiveTaxonomiesHierarchy(result.data.taxonomy, true);
          item.parentTitle = taxonomyNameHierarchy;
        });

        return item;
      }));
    }

    if (allowTypeaheadSearch) {
      setState({ ...state, selectedKeys: newSelectedKeys, selectedTags: mappedTags, searchValue: searchValue });
    } else {
      setState({ ...state, selectedKeys: newSelectedKeys, selectedTags: mappedTags, searchValue: '' });
    }
  }

  /**
   * Change event for closing a Tag.
   *
   * @param {*} e The event
   * @param {string} removedKey The key of the tag to remove.
   */
  function onCloseTag(e, removedKey: string) {
    e.preventDefault();
    const newSelectedKeys = selectedKeys.filter((k) => k !== removedKey);
    const removedKeyIndex = selectedKeys.findIndex((k) => k === removedKey);
    selectedTags.splice(removedKeyIndex, 1);
    setState({ ...state, selectedKeys: newSelectedKeys, selectedTags });
    onCloseTagCallback(removedKey);
  }

  /**
   * If the input field is clicked, set the state for tracking focus.
   * We pass the client to apolloClient to make it available to the whole component.
   */
  function onInputClick() {
    setState({ ...state, hasFocus: true, hasTreeVisible: false });
  }

  /**
   * If allowEnterNewValue is enabled, take the input value and on the enter keydown event
   * create a new tag.
   *
   * @param {*} e The input event.
   */
  function handleInputKeyDown(e) {
    const treeSelector = popoverContainerRef.current.querySelector('.ant-tree.ant-tree-icon-hide');
    const nodes = (treeSelector) ? Array.prototype.slice.call(popoverContainerRef.current.querySelector('.ant-tree.ant-tree-icon-hide').children) : null;
    // down arrow key event
    if (e.keyCode === KEY.DOWN) {
      if (!nodes) return;
      const container = popoverContainerRef.current.querySelector(`.ant-popover-inner-content`);
      if (container) container.focus();
      // add highlight class from previous tree node
      const selectedNode = popoverContainerRef.current.querySelector(`.epub-highlight`);
      if (selectedNode) {
        const currentNode = nodes.indexOf(selectedNode);
        const treeNode = popoverContainerRef.current.querySelectorAll(`.epub-tree-node`)[currentNode + 1];
        if (treeNode) {
          selectedNode.classList.remove('epub-highlight');
          const nodeHeight = treeNode.offsetHeight;
          const p = popoverContainerRef.current.querySelector(`.ant-popover-inner-content`);
          p.scrollTop = (treeNode.offsetTop - nodeHeight);
          treeNode.classList.add('epub-highlight');
        }
      } else {
        const treeNode = popoverContainerRef.current.querySelector(`.epub-tree-node`);
        if (treeNode) {
          treeNode.classList.add('epub-highlight');
        }
      }
    }
    // up arrow key event
    if (e.keyCode === KEY.UP) {
      const selectedNode = popoverContainerRef.current.querySelector(`.epub-highlight`);
      if (selectedNode) {
        const currentNode = nodes.indexOf(selectedNode);
        const treeNode = popoverContainerRef.current.querySelectorAll(`.epub-tree-node`)[currentNode - 1];
        if (treeNode) {
          // remove highlight class from previous tree node
          selectedNode.classList.remove('epub-highlight');
          const nodeHeight = treeNode.offsetHeight;
          const p = popoverContainerRef.current.querySelector(`.ant-popover-inner-content`);
          p.scrollTop = (treeNode.offsetTop + nodeHeight - 50);
          // add highlight class from previous tree node
          treeNode.classList.add('epub-highlight');
        }
      } else {
        setState({ ...state, hasFocus: true, hasTreeVisible: false });
        searchInputRef.current.input.focus();
      }
    }
    // enter key event
    if (e.keyCode === KEY.ENTER) {
      if (queryDataType === 'keywords' && hasPrivilege === false) {
        OpenNotificationWithIcon({
          type: 'error',
          title: 'Permission to add new keywords has not been granted.',
        }, () => { });
      }

      // get current tree node
      const treeNodeText = popoverContainerRef.current.querySelector(`.epub-highlight .ant-tree-node-content-wrapper`);
      if (treeNodeText) {
        // press manually click to current tree node to select value
        treeNodeText.click();
        treeNodeText.classList.remove('ant-tree-node-selected');
      }
    }
    if ((e.key !== 'Enter' && allowEnterNewValue) || !allowEnterNewValue) return;
    // Take search value and apply as selectedKey or selectedTag

    const { shouldUpdate, tags, keys } = createNewTag({ selectedTags, selectedKeys, searchValue });

    let searchInputValue = searchValue;
    // Clear search value when user hit enter and it's a keyword field
    if (e.keyCode === KEY.ENTER && queryDataType === 'keywords') {
      searchInputValue = '';
    }

    if (shouldUpdate) {
      if (allowTypeaheadSearch) {
        setState({ ...state, selectedKeys: keys, selectedTags: tags, searchValue: searchInputValue });
      } else {
        setState({ ...state, selectedKeys: keys, selectedTags: tags, searchValue: '' });
      }
    }
  }

  /**
   * Clears the input field of any values when the clear button is pressed.
   */
  function clearButtonHandler() {
    setState({ ...state, searchValue: '', selectedTags: [], selectedKeys: [] });
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    setState({
      ...state,
      selectedTags: (reOrder(
        [...selectedTags],
        result.source.index,
        result.destination.index
      )),
      selectedKeys: (reOrder(
        [...selectedKeys],
        result.source.index,
        result.destination.index
      )),
    });
  }

  const classes = classNames({
    '-is-dark': theme === 'dark',
    '-is-light': theme === 'light',
    '-has-selected-tags': selectedTags.length,
    '-no-multi-select': !allowMultipleSelection,
  });


  let inputValue = searchValue;

  // In single select mode and no value, use selected tag.
  if (!allowMultipleSelection && !searchValue) {
    const latestSingleTag = selectedTags.slice(-1)[0];
    inputValue = selectedTags.length ? latestSingleTag["title"] : '';

    // When title is generated outside, use children function to get title
    if (children && selectedTags.length) {
      inputValue = children({ item: latestSingleTag["rest"] });
    }
  }

  const treeContainerProps = {
    onExpand,
    onTreeSelect,
    expandedKeysRef,
    autoExpandParent,
    name,
    queryName,
    searchResult,
    queryDataType,
    state,
    setState,
    notAllowedIdForSelection,
    querySearchField,
    queryStatsType,
    querySearchStatsType,
    fetchInitialData,
    fetchSearchData,
    perPage,
    children,
    onTitleRightClick,
    revertExpandedKey,
    loadedKeys,
    allowDisabledNode,
    sortByPlanDates,
    selectedKeys,
  };

  const clearButtonProps = {
    allowMultipleSelection,
    inputValue,
    onClick: clearButtonHandler,
  };

  const tagsProps = {
    selectedTags,
    selectedKeys,
    allowMultipleSelection,
    onCloseTag,
    onDragEnd,
    isDragDisabled,
  };

  function showKeywordSuggestionDrawer() {
    toggleShow('keyword-suggestion');
  }

  function handleUpdateKeywords(keywords) {
    let newSelectedTags = selectedTags;
    let newSelectedKeys = selectedKeys;

    if (queryDataType === 'keywords' && hasPrivilege === false) {
      OpenNotificationWithIcon({
        type: 'error',
        title: 'Permission to add new keywords has not been granted.',
      }, () => { });
      return;
    }

    keywords.forEach((keyword) => {
      const { shouldUpdate, tags, keys } = createNewTag({ selectedTags: newSelectedTags, selectedKeys: newSelectedKeys, searchValue: keyword });
      newSelectedTags = tags;
      newSelectedKeys = keys;
      if (shouldUpdate) {
        if (allowTypeaheadSearch) {
          setState({ ...state, selectedKeys: keys, selectedTags: tags, searchValue: searchValue });
        } else {
          setState({ ...state, selectedKeys: keys, selectedTags: tags, searchValue: '' });
        }
      }
    });

    closeDrawer('keyword-suggestion');
  }


  return (
    <>
      <css.PopoverContainer ref={popoverContainerRef} className={classes} maxWidth={maxWidth}></css.PopoverContainer>
      <css.SearchSelectWrapper maxWidth={maxWidth} className={`epub-search-select-wrapper ${classes}`}>
        <Popover
          visible={(hasFocus || hasTreeVisible || showTree)}
          content={hasSearchResult ? <Tree {...treeContainerProps} /> : <NoData />}
          placement="bottom"
          getPopupContainer={() => popoverContainerRef.current}
          overlayClassName="epub-select-search-popover"
          autoAdjustOverflow={true}
        >
          <Input
            name={name}
            prefix={<Tags {...tagsProps} />}
            suffix={<ClearButton {...clearButtonProps} />}
            value={inputValue}
            placeholder={placeholder}
            onChange={onChangeSearch}
            onClick={onInputClick}
            ref={searchInputRef}
            size="large"
            onKeyDown={handleInputKeyDown}
            autoComplete="off"
          />
        </Popover>
        {(allowKeywordSuggestion && openAIAPIEnabled) &&
        <css.KeywordSuggestionLink className="suggest-keyword-anchor">
          <a href="#" className="link" onClick={showKeywordSuggestionDrawer}>Keyword Suggestion</a>
        </css.KeywordSuggestionLink>}
      </css.SearchSelectWrapper>
      {(allowKeywordSuggestion && openAIAPIEnabled) &&
      <Drawer
        id="keyword-suggestion"
        title="AI Keyword Suggestion"
        size="medium"
      >
        <KeywordSuggestionForm handleUpdateKeywords={handleUpdateKeywords} />
      </Drawer>}
    </>
  )
};

export default SearchSelect;
