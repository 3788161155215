import React, { createContext, useReducer, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { AUTH_TOKEN } from 'constants/index';
import { environmentOptions } from 'utils/helpers';
import { LOCAL_STORAGE } from 'constants/index';
import Auth from 'auth';

export interface State {
  id: IDType,
  email: string,
  firstName: string,
}

interface ContextType {
  state: State,
  dispatch: React.Dispatch<any>,
}

const defaultReducerState = {
  id: null,
  email: null,
  firstName: null,
};

export const Context = createContext<ContextType>({
  state: defaultReducerState,
  dispatch: () => {},
});

/**
 * The reducer to convert state and dispatch actions.
 * @param state The state of this context API.
 * @param state.readyToAdd Custom fields that are newly saved to the content.
 * @param state.readyToUpdate Custom field values that already exist on the content, and just need updated.
 * @param state.customFieldTypes Set custom field types query remapped for state / mutation use.
 * @param state.customFieldTypesSource The entire field structure of all the custom field types associated to the content.
 * @param action The action which includes type and payload.
 */
function reducer(state, action) {
  switch (action.type) {
    case "setEmail":
      return {
        ...state,
        email: action.payload.email,
      };
    case "setUser":
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
      };
    case "logout":
      localStorage.removeItem(`${AUTH_TOKEN}:stage:${environmentOptions().X_HOST_SHORTNAME}`);
      localStorage.removeItem(`${AUTH_TOKEN}:production:${environmentOptions().X_HOST_SHORTNAME}`);
      localStorage.removeItem(`${LOCAL_STORAGE.X_ENV}:${environmentOptions().X_HOST_SHORTNAME}`);
      return defaultReducerState;
    default:
      throw Error(`The action.type ${action.type} does not exist in the Custom Field Context.`);
  }
}

export const AuthProvider = withRouter((props) => {
  const [state, dispatch] = useReducer(reducer, defaultReducerState);
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const isUserAuthenticated = Auth.isUserAuthenticated();

  // TODO: Get user based on auth token - due to loss of context state on refresh

  // useEffectQuery({
  //   query: AUTH_SET_USER,
  //   variables: { email: state.email },
  //   ready: (isUserAuthenticated && state.email && !state.firstName),
  //   callback: ({ data }) => {
  //     const { firstName, id } = data.user;
  //     dispatch({ type: "setUser", payload: { id, firstName }});
  //   },
  // });

  if (!isUserAuthenticated && props.location.pathname !== '/login') props.history.push('/login');

  return (
    <Context.Provider value={providerValue}>
      {props.children}
    </Context.Provider>
  );
});
