import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react';


export interface State {
  impersonateMode: boolean,
}

interface ContextType {
  state: State,
  dispatch: React.Dispatch<any>,
}

const defaultReducerState = {
  impersonateMode: false,
};

export const Context = createContext<ContextType>({
  state: {
    ...defaultReducerState,
  },
  dispatch: () => {},
});

/**
 * The reducer to convert state and dispatch actions.
 * @param state The state of this context API.
 * @param state.impersonateMode Contains Impersonate boolean value.
 * @param action The action which includes type and payload.
 */
function reducer(state, action) {
  switch (action.type) {
    case "setImpersonateMode":
      return {
        ...state,
        impersonateMode: action.payload,
      }
    default:
      throw Error(`The action.type ${action.type} does not exist in the Custom Field Context.`);
  }
}

const ImpersonateProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultReducerState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <Context.Provider value={providerValue}>
      {props.children}
    </Context.Provider>
  );
};

export default ImpersonateProvider;
