import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import { chunk, isEqual } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Context } from './context';
import { deepFlatten } from "utils/helpers";
import { SEARCH_FILE_RECORDS_EXPORT } from 'graphql/entry';
import { HEADERS, FILE_KEYS, DOWNLOAD_DATE, USER_KEYS } from 'views/reports/fileReport/constants';
import { SearchFileRecordsExport_search_item_File } from 'graphql/types/SearchFileRecordsExport';
import { dateFormater } from 'utils/dateTimeHelper';
/**
 * A React Hook to handle to duplicate Event
 * @return {object.duplicateLoading} duplicateLoading - Boolean value to show loading state while till process completed
 * @return {object.duplicateEvent} duplicateEvent - A callback that handles event input data to duplicate
  */
const useExportData = ({ setCompleted }) => {
  const { state: { exportVariables, queryVariables } } = useContext(Context);
  const client = useApolloClient();
  const { totalRecords } = exportVariables;

  /**
     * manipulateData customise and reorder row data as per csv column order
     * @param {SearchFileRecordsExport_search_item_File} row contains particular table row data
     * @param {number} fileDownLoadInd contains index of file download item
     */
  function manipulateData(row: SearchFileRecordsExport_search_item_File, fileDownLoadInd?: number) {

    // Fetch title and filename column data
    const fileData = FILE_KEYS.map((key: string) => {
      if (key === 'absoluteUrl') {
        const Url = row[key] && String(row[key]);
        const fileName = Url.substring(Url?.lastIndexOf('/') + 1, Url?.lastIndexOf('?'));
        return (fileName) ? fileName : '';
      }
      return (row && row[key]) ? row[key] : '';
    });

    // Fetch userid and other users column data i.e firstname, lastname etc
    const userData = USER_KEYS.map((key: string) => {
      return (row.fileDownloads?.[fileDownLoadInd]?.user && row.fileDownloads[fileDownLoadInd]?.user[key]) ? row.fileDownloads[fileDownLoadInd].user[key] : '';
    });

    // Fetch download date and taxonomies data
    const downloadDate = DOWNLOAD_DATE.map((key: string) => {
      if (key === 'taxonomies') {
        const tax = row[key]?.map((item) => item.title).join(", ");
        return tax;
      }
      return (row.fileDownloads?.[fileDownLoadInd]?.[key]) ? dateFormater(row.fileDownloads?.[fileDownLoadInd]?.[key], 'LLLL dd, yyyy HH:mm', true) : '';
    });

    return [...fileData, ...downloadDate, ...userData];
  }

  async function mergeData(totalPages, indexData, id) {
    let dynamicData = [];
    let obj = {}
    for (let index = 1; index <= totalPages; index++) {
      const variableCopy = {
        ...queryVariables,
        downloadPage: index
      }
      try {
        const { data } = await client.query({
          query: SEARCH_FILE_RECORDS_EXPORT,
          variables: variableCopy,
        });
        dynamicData.push(...data.search[indexData]['item']['fileDownloads'])
      } catch (err) {
        console.log(err)
      }
    }
    obj[id] = dynamicData;
    return obj;
  }
  async function iterateOverDownload(recordData) {
    const y = await Promise.all(recordData.map(async (element, index) => {
      if (element.item._fileDownloadStats.count > 100) {
        const totalPages = Math.ceil(element.item._fileDownloadStats.count / 100);
        return await mergeData(totalPages, index, element.item.id)
      }
    }));
    return y
  }
  //works as callback function into export
  async function getData(recordData) {
    const allFileDownloads = await iterateOverDownload(recordData);
    recordData.map((element) => {
      for (const [key, fileDownloadList] of Object.entries(allFileDownloads)) {
        if (fileDownloadList) {
          for (const [fileId, fileDownloads] of Object.entries(fileDownloadList)) {
            if (element.item.id === fileId) {
              element['item']['fileDownloads'] = fileDownloads;
            }
          }
        }
      }
    });
    const data1 = recordData.map((value) => (value.item.fileDownloads.length > 0) ? value.item.fileDownloads.map((v, ind) => manipulateData(value.item, ind)) : manipulateData(value.item));

    // Remove duplicate timestamp data to match with legacy csv report
    let dataList = [];
    let dataContains = [];
    const exportData = deepFlatten(data1, HEADERS.length);
    exportData.forEach((element, index, arr) => {
      if (isEqual(element, arr[index + 1])) {
        dataList.push(element)
        arr.splice(index, 1);
      } else {
        if (dataList.some(r => r.length == element.length && r.every((value, index) => element[index] == value)) === false) {
          dataList.push(element)
        }
      }
    });
    dataContains.push(dataList)

    return [HEADERS, dataList];
  }

  return {
    getData
  }
}

export default useExportData;