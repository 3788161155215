import gql from 'graphql-tag';
import {
  RELATED_TYPES
} from '../constants';
import pluralize from 'pluralize';

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = {
    vars: '',
    pick: null
  }) => {
  const queries = [
    'articles',
    'classifieds',
    'events',
    'galleries',
    'polls',
    'products',
    'agencies',
    'accounts',
    'relatedCompanies',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';
    if (q == 'products' || q == 'agencies' || q == 'accounts' || q == 'relatedCompanies') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};


export const TAXONOMY_COMPANIES = gql`
  query TaxonomyCompanies ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _companyStats {
        count
      }
      companies (perPage: $perPage, page: $page) {
        id
        oid
        title:name
      }
    }
  }
`;

export const COMPANY_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query CompanyEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      company(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
    pick: related_Item.query,
    vars: 'page: $page, perPage: $perPage',
  })}
      }
    }`;
}


export const COMPANY_EDIT = gql`
  query CompanyEdit($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    company(oid: $oid) {
      oid
      id
      name
      contactJobTitle
      url
      legacyId
      description
      body
      email
      phone
      tollFreePhone
      address1
      address2
      postalCode
      postAt
      pullAt
      active
      contact
      fax
      city
      countryName
      stateName
      province
      allowComments
      searchable
      directoryCategories:categories {
        id
        title
        parent {
          id
          title
        }
      }
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      directory {
        key:id
        label:title
      }
      _commentStats {
        count
      }
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      meta {
        id
        slug
        browserTitle
        keywords
        description
      }
      audios {
        id
      }
      videos {
        id
      }
      images {
        id
      }
      files {
        id
      }
    ${relatedContentFragments()}
  }
}
`;

export const COMPANY_EDIT_CUSTOM_FIELDS = gql`
  query CompanyEditCustomFields ($id: IDType!, $perPage:Integer!, $page:Integer!) {
    company(id: $id) {
      id
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
    }
  }
`;

export const COMPANY_EDIT_COMMENTS = gql`
  query CompanyEditComments($oid: Integer!) {
    company(oid: $oid) {
      id
      _commentStats {
        count
        __typename
      }
      comments {
        id
        oid
        createdAt
        title
        body
        userEmail
        userName
        userAgent
        remoteAddr
        offensive
        spam
        treePath
        active
        user {
          id
          oid
          email
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const COMPANY_UPDATE = gql`
  mutation CompanyUpdate ($input: CompanyUpdateInput!) {
    companyUpdate(input: $input) {
      companies {
        id
        oid
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const COMPANY_ADD_TAXONOMIES = gql`
  mutation CompanyAddTaxonomies ($input: CompanyAddTaxonomiesInput!) {
    companyAddTaxonomies(input: $input) {
      taxonomies { id }
      company { id }
      success
      errors
    }
  }
`;

export const COMPANY_REMOVE_TAXONOMIES = gql`
  mutation CompanyRemoveTaxonomies ($input: CompanyRemoveTaxonomiesInput!) {
    companyRemoveTaxonomies(input: $input) {
      taxonomies { id }
      company { id }
      success
      errors
    }
  }
`;

export const COMPANY_CREATE = gql`
  mutation CompanyCreate ($input: CompanyCreateInput!) {
    companyCreate(input: $input) {
      company {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const COMPANY_SET_USERS = gql`
  mutation CompanySetUsers ($input: CompanySetUsersInput!) {
    companySetUsers(input: $input) {
      company {
        id
      }
      users {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const COMPANY_USERS = gql`
  query CompanyUsers($id: IDType!) {
    company(id: $id) {
      id

      _userStats {
        count
      }

      users {
        id
        oid
        firstName
        lastName
        email
        city
        stateName
        countryName
        company
        userType
        subscriptions {
          id
          oid
          live
          beginAt
          endAt
          renewal
          recurring
          createdAt
          externalId
          type
          orderItem {
            id
            product {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const AGENCY_ACCOUNT_CREATE = gql`
  mutation AgencyAccountCreate ($input: AgencyAccountCreateInput!) {
    agencyAccountCreate (input: $input) {
      agencyAccount {
        id
      }
      success
      errors
    }
  }
`;

export const AGENCY_ACCOUNT_UPDATE = gql`
  mutation AgencyAccountUpdate ($input: AgencyAccountUpdateInput!) {
    agencyAccountUpdate (input: $input) {
      agencyAccounts {
        id
        beginDate
        endDate
      }
      success
      errors
    }
  }
`;

export const AGENCY_ACCOUNT_DELETE = gql`
  mutation AgencyAccountDelete ($input: AgencyAccountDeleteInput!) {
    agencyAccountDelete (input: $input) {
      agencyAccounts {
        id
      }
      success
      errors
    }
  }
`;

export const COMPANY_DELETE = gql`
  mutation CompanyDelete ($input: CompanyDeleteInput!) {
    companyDelete(input: $input) {
      success
      errors
      companies { id }
    }
  }
`;

export const COMPANY_SET_AGENCIES = gql`
  mutation CompanySetAgencies ($input: CompanySetAgenciesInput!) {
    companySetAgencies(input: $input) {
      company {
      id
    }
    agencies {
      id
    }
    success
    errors
  }
}
`;

export const COMPANY_ADD_AGENCIES = gql`
mutation CompanyAddAgencies ($input: CompanyAddAgenciesInput!) {
  companyAddAgencies(input: $input) {
    company {
      id
    }
    agencies {
      id
    }
    success
    errors
  }
}
`;

export const COMPANY_REMOVE_AGENCIES = gql`
mutation CompanyRemoveAgencies ($input: CompanyRemoveAgenciesInput!) {
  companyRemoveAgencies(input: $input) {
    company {
      id
    }
    agencies {
      id
    }
    success
    errors
  }
}
`;

export const COMPANY_ADD_TIER = gql`
mutation CompanyAddTier ($input: CompanyAddTaxonomiesInput!) {
  companyAddTaxonomies(input: $input) {
		taxonomies {
      id
    }
    success
    errors
  }
}
`;