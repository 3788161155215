import { MergeTreeOpts } from './types';

/**
 * Merge fetched taxonomies back into the main tree by finding its parent by key.
 */
export function mergeChildrenInTree({ key, tree, children, notAllowedIdForSelection=[], isFirstPage=true}: MergeTreeOpts) {
  let childrenFiltered = children;
  if (notAllowedIdForSelection.length) {
    childrenFiltered = children.filter((row) => notAllowedIdForSelection.indexOf(row.id) === -1);
  }

  // Filter out taxonomies that are not selectable
  childrenFiltered = children.filter((row) => !row.selectable);

  const result = tree.map((t) => {
    const hasChild = t.hasOwnProperty('children') && t.children.length;

    if (t.id === key) {
      t['children'] = isFirstPage ? childrenFiltered : [...t.children, ...childrenFiltered];
      t['_childStats']['count'] = t['children'].length;
    } else if (hasChild) {
      t.children = mergeChildrenInTree({ key, tree: t.children, children, isFirstPage });
    }

    return t;
  });

  return result;
}
