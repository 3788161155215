import React from 'react';
import { Popconfirm as PopconfirmAntd } from 'antd';
import { FAL } from 'icons/fa';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import * as css from './style';

type Props = {
  title?: string,
  okText?: string,
  cancelText?: string,
  onConfirm?: (e?: React.MouseEvent<any>) => void,
  onCancel?: (e?: React.MouseEvent<any>) => void,
  icon?: IconName,
  placement?: "left" | "right" | "top" | "bottom" | "bottomLeft" | "bottomRight" | "topLeft" | "topRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom" | undefined,
  children: React.ReactNode,
  onVisibleChange?: (visible: boolean, e?: React.MouseEvent<HTMLElement>) => void,
  className?: string,
  disabled?: boolean,
}

/**
 * It will show confirmation popover on some event like on "delete button click"
 * @param {Props} props
 */
const Popconfirm = (props: Props) => {
  const { title = "Woah! Are you sure you wish to delete this content?", okText = "I'm sure", cancelText = "Nevermind", onConfirm = (e) => { }, onCancel = (e) => { }, icon = "exclamation-triangle", placement = "top", onVisibleChange = (visible) => { }, className = "", disabled = false } = props;

  const overLayClassName = `epub-pop-confirm-overlay ${className}`;

  return (<css.PopconfirmWrapper>
    <PopconfirmAntd
      title={title}
      okText={okText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      icon={<css.IconWrapper><FAL icon={icon} /></css.IconWrapper>}
      placement={placement}
      overlayClassName={overLayClassName}
      onVisibleChange={onVisibleChange}
      disabled={disabled}
    >
      {props.children}
    </PopconfirmAntd>
  </css.PopconfirmWrapper>)
};

export default Popconfirm;