import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';
import Auth from 'auth';
import { AUTH_TOKEN, LOCAL_STORAGE } from 'constants/index';
import { LOGIN_MUTATION } from 'graphql/auth';
import {tokenCreate} from 'graphql/types/tokenCreate';
import Input from 'components/forms/input';
import Button from 'components/button/index';
import { environmentOptions, stripEnvFromHost } from 'utils/helpers';
import { ReactComponent as Logo } from 'svg/continuum-logo-dark.svg';
import { ReactComponent as LoginGraphic } from 'svg/login-graphic.svg';
import { ReactComponent as LoginEdge } from 'svg/login-edge.svg';
import * as css from './style';
import { sendMessage } from 'registerServiceWorker';
import Notice from 'components/notice';
import openNotificationWithIcon from 'utils/notification';
import { validateEmail } from 'utils/helpers';
import useForm from 'hooks/useForm';
import Toggle from 'components/toggle';
import Colors from 'styles/colors';

const LoginFailed = () => (
  <css.FormError>
    Oops, wrong email and password combination. Try again.
  </css.FormError>
);

interface Props {
  location: {
    from: {
      pathname: string,
    },
    pathname: string,
  },
  onSuccessLogin?: Function,
  isShowInModal?: boolean,
}

interface State {
  redirectToReferrer: boolean,
  isSubmitting: boolean,
  isProductionEnv: boolean,
  isMounted: boolean,
}

const initialValues = {
  email: '',
  password: '',
};

const Login = (props: Props) => {
  const ENV_OPTIONS = environmentOptions();
  const isStagingUrl = ENV_OPTIONS.IS_STAGE_URL;
  const [state, setState] = useState<State>({
    redirectToReferrer: false,
    isSubmitting: false,
    isMounted: false,
    isProductionEnv: ENV_OPTIONS.X_ENV === "production", // Only set environment to production if url is not having -stage and localstorage has default env production
  });

  const { values, handleChange, handleSubmit, errors } = useForm(initialValues, loginHandler, (values) => {
    const errorsData = {};
    if (!values.email) errorsData["email"] = "Please enter email";
    if (values?.email && !validateEmail(values.email)) errorsData["email"] = "Please enter valid email";
    if (!values.password) errorsData["password"] = "Please enter password";

    return errorsData;
  });

  const { email, password } = values;
  const { isSubmitting, isProductionEnv, isMounted } = state;
  const { location, onSuccessLogin, isShowInModal=false } = props;

  const [loginMutationStag, { error: error2, loading: loading2 }] = useMutation<tokenCreate>(LOGIN_MUTATION, {
    variables: { email, password },
    onCompleted(data) {
      localStorage.setItem(`${AUTH_TOKEN}:${ENV_OPTIONS.X_ENV}:${ENV_OPTIONS.X_HOST_SHORTNAME}`, JSON.stringify(data));

      const newData = { credentials: {environmentOptions: {...ENV_OPTIONS}, isUserAuthenticated: true, token: Auth.getToken() }};

      sendMessage(newData);
      setState({ ...state, isSubmitting: false, redirectToReferrer: true });

      // When user logged in from modal
      if (onSuccessLogin) {
        openNotificationWithIcon({
          type: 'success',
          title: "You are now logged in.",
        }, () => {});
        onSuccessLogin();
      }
    },
    onError(error) {
      setState({ ...state, isSubmitting: false });
    }
  });

  // SVG Graphic animation
  useEffect(() => {
    const sharedConfig = {
      easing: 'easeInOutSine',
      duration: 3000,
      delay: 0,
      direction: 'alternate', // Is not inherited
      loop: true, // Is not inherited
    };

    const particleConfig = {
      ...sharedConfig,
      translateY: -80,
      direction: 'normal',
      easing: 'easeInQuad',
      opacity: [0, 1, 0],
    };

    const userConfig = {
      ...sharedConfig,
      direction: 'alternate',
      easing: 'easeInOutSine',
    };

    [...Array(5).keys()].forEach((index) => anime({
      ...particleConfig,
      targets: `#particles${index + 1} #Oval`,
      delay: anime.stagger(Math.floor(Math.random() * (1000 - 100 + 1) + 100)),
    }));

    [
      { x: 158, y: 270 },
      { x: 216, y: 298 },
      { x: 284, y: 295 },
      { x: 384, y: 244 },
      { x: 390, y: 205 },
      { x: 344, y: 175 },
      { x: 256, y: 159 },
      { x: 192, y: 180 },
      { x: 321, y: 262 },
    ].forEach(({ x, y }, index) => anime({
      ...userConfig,
      targets: `#user${index + 1}`,
      delay: 0,
      translateX: [x, x],
      translateY: [y + 6, y - 6],
    }));

    anime({
      ...sharedConfig,
      targets: '#envelope',
      translateY: -5,
      translateX: [25.043, 25.043],
    });
    anime({
      ...sharedConfig,
      delay: 300,
      targets: '#envelope-outline',
      translateY: -5,
    });
  }, []);

  /**
   * Side-affects after changing the environment.
   * 1. Set new environment localstorage item
   * 2. Notify service-worker of auth and environment changes.
   * 3. Redirect to the next env URL
   */
   useEffect(() => {
    // url is having -stage then don't allow to change env in localstorage
    if (isStagingUrl || !isMounted) return;

     // define env based on state's boolean value
     const env = isProductionEnv ? "production" : "stage";

    // Set new environment localstorage item
    localStorage.setItem(`${LOCAL_STORAGE.X_ENV}:${ENV_OPTIONS.X_HOST_SHORTNAME}`, env);

    // Notify service-worker of auth and environment changes.
    // FIXME: Might need to be removed. Commenting out to change URL instead of notifying service worker.
    const newData = { credentials: {environmentOptions: {...ENV_OPTIONS, X_ENV: env}, isUserAuthenticated: Auth.isUserAuthenticated() }};
    if ('serviceWorker' in navigator) sendMessage(newData);
  }, [isProductionEnv]);

  /**
   * When user is on stage url and select production environment,
   * Redirect user to production url (removed -stage from url)
   */
  useEffect(() => {
    if (!(isStagingUrl && isProductionEnv) || !isMounted) return;

    const nextURL = `https://${stripEnvFromHost(ENV_OPTIONS.HOST)}.continuum.epublishing.com`;
    window.location = nextURL as unknown as Location;
  }, [isProductionEnv]);

  // Set mount status
  useEffect(() => {
    setState((prevState) => ({...prevState, isMounted: true}));
  }, []);

  /**
   * Notify user when user sign in using sibling site's token and
   * same user is not exist for current site(host)
   */
  useEffect(() => {
    const showNotification = localStorage.getItem('notify-expired-token');
    if (!showNotification) return;

    openNotificationWithIcon({
      type: "error",
      title: "You don't have same user account in this site."
    }, () => {});

    localStorage.removeItem('notify-expired-token');
  }, []);

  function loginHandler () {
    setState({ ...state, isSubmitting: true });
    // loginMutationProd()
    setTimeout(loginMutationStag, 2000); // Recommended set to 2000 with production (post-beta).
  }

  /**
   * Toggle environment
   */
  function toggleEnv() {
    setState((prevState) => ({...prevState, isProductionEnv: !prevState.isProductionEnv}))
  }

  if (Auth.isUserAuthenticated() && !isShowInModal) {
    const fromPath = 'from' in location ? location.from.pathname : undefined;
    const currPath = location.pathname;
    const redirectLoginTo = typeof fromPath !== 'undefined' && fromPath !== currPath ? fromPath : '/';

    return <Redirect to={{ pathname: redirectLoginTo }} />;
  }

  return (
    <css.Form  onSubmit={handleSubmit}>
      <css.Page isShowInModal={isShowInModal}>
        <css.Container isShowInModal={isShowInModal}>
          <css.FormWrapper loading={loading2.toString()} isShowInModal={isShowInModal}>
          { !isShowInModal && <css.LogoWrapper><Logo /></css.LogoWrapper> }
          { isShowInModal && <Notice text="You have entered invalid login information. Please try again." show={true}  type="error" />}
            <css.FormField>
              <Input
                type="email"
                name="email"
                ptext="email"
                onChange={handleChange}
                inputAttrs={{"tabIndex": 1, "autoFocus": true}}
                error={errors.hasOwnProperty('email') && errors['email']}
                errorMsg={errors && errors['email']}
              />
            </css.FormField>
            <css.FormField>
              <Input
                type="password"
                name="password"
                ptext="password"
                onChange={handleChange}
                inputAttrs={{"tabIndex": 2}}
                error={errors.hasOwnProperty('password') && errors['password']}
                errorMsg={errors && errors['password']}
              />
            </css.FormField>
            <css.FormField className="toggle-environment">
              <Toggle
                initial={isProductionEnv}
                innerLabelColor={`${Colors.pureWhite}`}
                wrapperPaddingTop={0}
                wrapperPaddingBottom={0}
                width={130}
                onToggle={toggleEnv}
                wrapperPadding={0}
                innerLabelLeft={isProductionEnv ? "PRODUCTION" : ""}
                innerLabelRight={!isProductionEnv ? "STAGING" : ""}
              />
            </css.FormField>
            {error2 && <LoginFailed />}
            <Button type="submit" loading={isSubmitting} icon="sign-in">Sign In</Button>
          </css.FormWrapper>
          {!isShowInModal && <css.RightPanel>
            <css.Edge><LoginEdge /></css.Edge>
            <css.GraphicContainer><LoginGraphic /></css.GraphicContainer>
          </css.RightPanel>
          }
        </css.Container>
      </css.Page>
    </css.Form>
  );
};

export default Login;
