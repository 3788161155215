import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import anime from 'animejs/lib/anime.es.js';
import { Link } from 'react-router-dom';

import { FAL } from 'icons/fa';
import * as css from './style';

type TypeOptions = 'success' | 'error' | 'caution' | 'notice';

type PlainType = {
  type: TypeOptions,
}

interface NoticeProps extends PlainType {
  text: string,
  show: boolean,
  style?: Object,
  linkTo?: string | false,
  target?: string,
}

const Icon = ({ type }: PlainType) => {
  let iconType;
  switch (type) {
    case "success":
      iconType = 'check';
      break;
    case "error":
      iconType = 'frown';
      break;
    case "caution":
      iconType = 'exclamation-triangle';
      break;
    case "notice":
      iconType = 'comment-alt-dots';
      break;
    default:
      return null;
  }

  return <FAL icon={iconType} />;
}

const animateIn = (node) => anime({
  targets: node,
  opacity: () => [0, 1],
  duration: 400,
  easing: 'linear',
});

const animateOut = (node) => anime({
  targets: node,
  opacity: () => [1, 0],
  duration: 400,
  easing: 'linear',
});

const Notice = ({ type = 'notice', text = 'Your submission was saved successfully.', show = false, style = {}, linkTo = false, target= "_self" }: NoticeProps) => {
  const nodeRef = useRef(null);

  const notice = (
    <css.Notice className={type ? `-${type}` : ''} ref={nodeRef} style={style}>
      <Icon type={type} />
      <css.Text>{text}</css.Text>
    </css.Notice>
  )

  const wrappedNotice = linkTo === false ? notice : <Link to={linkTo} target={target}>{notice}</Link>;
  
  return (
    <Transition
      nodeRef={nodeRef}
      in={show}
      appear={true}
      timeout={300}
      onEnter={animateIn}
      onExit={animateOut}
      mountOnEnter
      unmountOnExit
    >
      {wrappedNotice}
    </Transition>
  );
}

export default Notice;
