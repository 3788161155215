import { DEFAULT_COOKIE_DOMAIN } from 'constants/index';

/**
 * Set cookie
 * @param {string} name
 * @param {string} value
 * @param {string} domain domain name
 */
export function setCookie(name, value, domain = DEFAULT_COOKIE_DOMAIN) {
  document.cookie = `${name}=${value}; domain=${domain};`;
}

/**
 * Get cookie by name
 * @param {string} cname - key by which cookie is saved
 */
export function getCookieByName(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Delete cookie based on name
 * @param {string} name
 * @param {string} domain
 */
export function deleteCookie(name, domain = DEFAULT_COOKIE_DOMAIN) {
  document.cookie = `${name}=; domain=${domain}; max-age=0`;
}