import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconPrefix,
  IconName,
  SizeProp
} from '@fortawesome/fontawesome-svg-core';

type IconType = {
  type?: IconPrefix;
  icon: any;
  className?: string;
  size?: SizeProp;
  color?: string;
  spin?: boolean,
};

export const FAL = ({ type = 'fal', icon, className, size, spin=false }: IconType) => {
  const classes = className ? className : '';
  return (
    <FontAwesomeIcon
      icon={[type, icon]}
      className={`${icon} ${classes}`}
      size={size ? size : '1x' }
      spin={spin}
    />
  );
};
