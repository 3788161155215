import gql from 'graphql-tag';

export const WORKFLOW_STEPS = gql`
  query WorkflowSteps($page: Integer!, $perPage: Integer!) {
    _workflowStepStats {
      count
    }
    workflowSteps(page: $page, perPage: $perPage) {
      id
      oid
      name
      terminal
      role {
        id
        oid
        displayName
      }
      sortingOrder
    }
  }
`;

export const WORKFLOW_STEP_CREATE = gql`
  mutation WorkflowStepCreate($input: WorkflowStepCreateInput!) {
    workflowStepCreate (input: $input) {
      workflowStep {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_STEP_UPDATE = gql`
  mutation WorkflowStepUpdate($input: WorkflowStepUpdateInput!) {
    workflowStepUpdate(input: $input) {
      workflowSteps {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_STEP_EDIT = gql`
  query WorkflowStepEdit($oid: Integer!) {
    workflowStep(oid: $oid) {
      id
      name
      terminal
      role {
        id
        displayName
      }
      currentRules{
        id
        targetStep{
          id
          name
          terminal
          role {
            id
            oid
            displayName
          }
          sortingOrder
        }
      }
    }
  }
`;

export const WORKFLOW_STEP_DELETE = gql`
  mutation WorkflowStepDelete ($input: WorkflowStepDeleteInput!) {
    workflowStepDelete (input: $input)
    {
      success
      errors
      workflowSteps { id }
    }
  }
`;

export const WORKFLOW_RULE_CREATE = gql`
  mutation WorkflowRuleCreate($input: WorkflowRuleCreateInput!) {
    workflowRuleCreate (input: $input) {
      workflowRule {
        id
        targetStep {
          id
          oid
          name
          terminal
          role {
            id
            displayName
          }
          sortingOrder
        }
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_RULE_DELETE = gql`
  mutation WorkflowRuleDelete($input: WorkflowRuleDeleteInput!) {
    workflowRuleDelete (input: $input) {
      workflowRules {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_ARTICLE_READ_ONLY = gql`
  query WorkflowArticleReadOnly($oid: Integer!) {
    article(oid: $oid) {
      id
      title
      teaser
      isWorflow
      locked
      lockedBy {
        id
        firstName
        lastName
      }
      workflow {
        id
        latestEdit {
          id
          claimedAt
          createdAt
          assignedUser {
            id
            firstName
            lastName
            company
            userType
            email
          }
          originatingUser {
            id
            firstName
            lastName
            company
            userType
            email
          }
          currentStep {
            id
            name
          }
        }
      }
    }
  }
`;

export const WORKFLOW_ARTICLE = gql`
  query WorkflowArticle($id: IDType!) {
    article(id: $id) {
      id
      workflow {
        id
        _editStats {
          count
        }
        edits(sortString: ["createdAt desc"]) {
          id
          assignedUser {
            id
            firstName
            lastName
            company
            userType
          }
          originatingUser {
            id
            firstName
            lastName
            company
            userType
          }
          createdAt
          comment
          currentStep {
            id
            name
          }
        }
      }
    }
  }
`;

export const WORKFLOW_EDITS_INCOMPLETED = gql`
  query WorkflowEditsIncompleted($id: IDType!) {
    workflow(id: $id) {
      id
      edits(filterString: ["completedAt isNull"]) {
        id
      }
    }
  }
`;

export const WORKFLOW_CREATE = gql`
  mutation WorkflowCreate($input: WorkflowCreateInput!) {
    workflowCreate (input: $input) {
      workflow {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_UPDATE = gql`
  mutation WorkflowUpdate($input: WorkflowUpdateInput!) {
    workflowUpdate(input: $input) {
      workflows {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_EDIT_CREATE = gql`
  mutation WorkflowEditCreate($input: WorkflowEditCreateInput!) {
    workflowEditCreate (input: $input) {
      workflowEdit {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_EDIT_DELETE = gql`
  mutation WorkflowEditDelete($input: WorkflowEditDeleteInput!) {
    workflowEditDelete (input: $input) {
      workflowEdits {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_EDIT_UPDATE = gql`
  mutation WorkflowEditUpdate($input: WorkflowEditUpdateInput!) {
    workflowEditUpdate(input: $input) {
      workflowEdits {
        id
      }
      success
      errors
    }
  }
`;

export const WORKFLOW_LATEST_EDIT = gql`
  query WorkflowLatestEdit($id: IDType!) {
    article(id: $id) {
      id
      workflow {
        id
        completedAt
        latestEdit {
          id
          claimedAt
          createdAt
          completedAt
          comment
          assignedUser {
            id
            firstName
            lastName
            company
            userType
            email
          }
          originatingUser {
            id
            firstName
            lastName
            company
            userType
            email
          }
          currentStep {
            id
            oid
            name
            role {
              id
              oid
            }
          }
        }
      }
    }
  }
`;

const fragTaxBody = `
  oid
  id
  updatedAt
  createdAt
  articleDueAt
  articlePostAt
  workflowStepName
  assignedUserName
  currentStep {
    id
    name
  }
  originatingUser {
    id
    firstName
    lastName
  }
  assignedUser {
    id
    firstName
    lastName
  }
  workflow {
    id
    article {
      id
      oid
      title
      notes
      postAt
      dueAt
      teaser
      canonicalUrl
      locked
      lockedBy {
        id
        firstName
        lastName
      }
      pages {
        id
        body
      }
      bodyImages {
        id
        absoluteUrl
        url
        altText
      }
      image {
        id
        absoluteUrl
        url
        altText
      }
      newsletterIssues {
        id
        title
        newsletter {
          title
          id
        }
      }
      editions {
        id
        title
        publication {
          id
          title
        }
      }
      authors {
        id
        fullName
      }
      isBlog
      canonicalPath
      meta {
        id
        canonicalUrl
        slug
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      taxonomies {
        id
        name
        title
        uiStyle
        parent {
          id
          name
          title
          uiStyle
          parent{
            id
            name
            title
            uiStyle
            parent {
              id
              name
              title
              uiStyle
            }
          }
        }
      }
    }
  }
`;

export const WORKFLOW_MY_ARTICLES = gql`
  query WorkflowMyArticles($page: Integer, $perPage: Integer) {
    context {
      user {
        id
        _workflowStats {
          count
        }
        workflows(page: $page, perPage: $perPage) {
          id
          article {
            id
            oid
            title
            notes
            postAt
            dueAt
            teaser
            canonicalUrl
            authors {
              id
              fullName
            }
            pages {
              id
              body
            }
            newsletterIssues {
              id
              title
              newsletter {
                title
                id
              }
            }
            editions {
              id
              title
              publication {
                id
                title
              }
            }
          }
          latestEdit {
            oid
            id
            updatedAt
            createdAt
            currentStep {
              id
              name
            }
            originatingUser {
              id
              firstName
              lastName
            }
            assignedUser {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const WORKFLOW_AVAILABLE_ARTICLE = gql`
  query WorkflowAvailableArticle($sortString: [Str], $page: Integer, $perPage: Integer) {
    _workflowEditStats(onlyLive: true, assignedUserExists: false) {
      count
    }
    workflowEdits(onlyLive: true, assignedUserExists: false, sortString: $sortString, page: $page, perPage: $perPage) {
      ${fragTaxBody}
    }
  }
`;

export const WORKFLOW_ASSIGNMENT_ARTICLE = gql`
  query WorkflowAssignmentArticle($sortString: [Str], $page: Integer, $perPage: Integer, $filterString: [Str], $articleTaxonomies: [TaxonomyID], $authorIds: [IDType], $articleTaxonomyExpression: [[TaxonomyID]], $assignedUserExists: Bool) {
    _workflowEditStats(latestEditsOnly: true,filterString: $filterString, articleTaxonomies: $articleTaxonomies, authorIds: $authorIds, articleTaxonomyExpression: $articleTaxonomyExpression, assignedUserExists: $assignedUserExists) {
      count
    }
    workflowEdits(latestEditsOnly: true, sortString: $sortString, page: $page, perPage: $perPage, filterString: $filterString, articleTaxonomies: $articleTaxonomies,  authorIds: $authorIds, articleTaxonomyExpression: $articleTaxonomyExpression, assignedUserExists: $assignedUserExists) {
      ${fragTaxBody}
    }
  }
`;

export const WORKFLOW_ASSIGN_USER = gql`
  query WorkflowAssignUser($id: IDType, $sortString: [Str], $page: Integer, $perPage: Integer, $filterString: [Str],$articleTaxonomies: [TaxonomyID], $authorIds: [IDType], $articleTaxonomyExpression: [[TaxonomyID]]) {
    user(id: $id) {
      _workflowEditStats(latestEditsOnly: true, assignedUserExists: true, filterString: $filterString, articleTaxonomies: $articleTaxonomies, authorIds: $authorIds, articleTaxonomyExpression: $articleTaxonomyExpression, articleTaxonomyExpression: $articleTaxonomyExpression) {
        count
      }
      workflowEdits(latestEditsOnly: true, assignedUserExists: true, sortString: $sortString, page: $page, perPage: $perPage, filterString: $filterString, articleTaxonomies: $articleTaxonomies, authorIds: $authorIds, articleTaxonomyExpression: $articleTaxonomyExpression) {
        ${fragTaxBody}
      }
    }
  }
`;

export const USERS_WORKFLOW_PRIVILEGE = gql`
  query UsersWorkflowPrivilege($name: Str!, $perPage:Integer!, $page:Integer!) {
    privilege(name: $name) {
      roles {
        _userStats {
          count
        }
        users(perPage: $perPage, page: $page, sortString:["lastName asc"]) {
          oid
          id
          firstName
          lastName
          company
          userType
        }
      }
      _userStats {
        count
      }
      users(perPage: $perPage, page: $page, sortString:["lastName asc"]) {
        oid
        id
        firstName
        lastName
        company
        userType
      }
    }
  }
`;