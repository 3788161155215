import gql from 'graphql-tag';

export const SHIPPING_RATE_NAV = gql`
  query ShippingRateNav ($page: Integer!, $perPage: Integer!) {
    shippingRates (page: $page, perPage: $perPage, groupBy: ["displayName"]) {
      id
      oid
      displayName
      type
    }
  }
`;

export const SHIPPING_RATES_FOR_NAME = gql`
  query ShippingRatesForName($filterString: [Str]) {
    _shippingRateStats(filterString: $filterString) {
      count
    }
    shippingRates(filterString: $filterString) {
      id
      oid
      name
      displayName
      type
      externalId
      weight
      rate
      minThreshold
      maxThreshold
    }
  }
`;

export const SHIPPING_RATE_STATS = gql`
  query ShippingRateStats {
    _shippingRateStats(groupBy: ["displayName"]) {
      count
    }
  }
`;

export const SHIPPING_RATE_ADD = gql`
  mutation ShippingRateAdd ($input: ShippingRateCreateInput!) {
    shippingRateCreate (input: $input) {
      shippingRate {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const SHIPPING_RATE_UPDATE = gql`
  mutation ShippingRateUpdate ($input: ShippingRateUpdateInput!) {
    shippingRateUpdate (input: $input)
    {
      shippingRates { id }
      success
      errors
    }
  }
`;

export const SHIPPING_RATE_DELETE = gql`
  mutation ShippingRateDelete ($input: ShippingRateDeleteInput!) {
    shippingRateDelete (input: $input)
    {
      shippingRates {
        id
      }
      success
      errors
    }
  }
`;

export const SHIPPING_RATE_ADD_TAXONOMIES = gql`
  mutation ShippingRateAddTaxonomies ($input: ShippingRateAddTaxonomiesInput!) {
    shippingRateAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const SHIPPING_RATE_REMOVE_TAXONOMIES = gql`
  mutation ShippingRateRemoveTaxonomies ($input: ShippingRateRemoveTaxonomiesInput!) {
    shippingRateRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const SHIPPING_RATE_EDIT = gql`
  query ShippingRateEdit ($oid: Integer!) {
    shippingRate (oid: $oid) {
      id
      oid
      name
      displayName
      type
      description
      rate
      weight
      externalId
      externalType
      minThreshold
      maxThreshold
      active
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
    }
  }
`;