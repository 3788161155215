import { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';

const useBeamLockAnim = () => {
  // SVG Graphic animation
  useEffect(() => {
    const particleConfig = {
      loop: true,
      duration: 3000,
      translateY: -80,
      direction: 'normal',
      easing: 'easeInQuad',
      opacity: [0, 1, 0],
    };

    anime({
      ...particleConfig,
      duration: 1500,
      targets: '#Oval',
      delay: anime.stagger(Math.floor(Math.random() * (1000 - 100 + 1) + 100)),
    });

    anime({
      ...particleConfig,
      targets: '#ring',
      translateY: -70,
      duration: 4000,
      opacity: [0, 1, 0],
      delay: anime.stagger(Math.floor(Math.random() * (1000 - 200 + 1) + 200)),
    });
  }, []);
};

export default useBeamLockAnim;