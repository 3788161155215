/**
 * This is used for tracking existing selected taxonomies, and selected searched taxonomies.
 * These come from two state managed lists and must be merged for a single source of truth in
 * the UI showing the list of selected taxonomies.
 * 
 * This merge handles merging any duplicates together.
 *
 * @export
 * @param {string} name The key used to determine if a duplicate exists.
 * @param {Array<any>} origin The selected list before search is applied.
 * @param {Array<any>} dest The selected list of searched taxonomies.
 * @returns A merged list of searched and non-searched based list.
 */
export function mergeByKey(name: string, origin: Array<any>, dest: Array<any>) {
  // clone array to determine what's selected and concatenated onto the list.
  const remaining = dest.slice();

  // This loops through and prevents duplicates by merging selected states.
  const result = origin.map((aa) => {
    const index = remaining.findIndex((bb) => aa[name] === bb[name]);
    
    // If match was found, merge with origin and remove from remaining
    if (index !== -1) {
      const removed = remaining[index];
      remaining.splice(index, 1);
      return removed;
    }

    // No match found, no mutation of data.
    return aa;
  });

  // Append items to the array that were not duplicates.
  return result.concat(remaining);
};
