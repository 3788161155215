import gql from 'graphql-tag';

export const BLOGS_SELECT_ALL = gql`
  query Blogs_Select_All ($perPage:Integer!, $page:Integer!) {

    _blogStats {
      count
    }

    blogs(perPage: $perPage, page: $page) {
      oid
      id
      createdAt
      updatedAt
      teaser
      description
      title
    }
  }
`;

export const BLOG_STATS = gql`
  query BlogStats {
    _blogStats {
      count
    }
  }
`;

export const BLOG_NAV_QUERY = gql`
  query BlogNavQuery ($page: Integer!, $perPage: Integer!) {
    blogs (page: $page, perPage: $perPage) {
      id
      oid
      title
      canonicalUrl
    }
  }
`;

export const BLOG_SELECT = gql`
  query BlogSelect ($id: IDType!) {
    blog(id: $id) {
      oid
      id
      title
    }
  }
`;

export const BLOG_ADD_MUTATION = gql`
  mutation BlogAddMutation ($input: BlogCreateInput!) {
    blogCreate (input: $input) {
      blog {
        id
        oid
        title
        canonicalUrl
      }
      success
      errors
    }
  }
`;

export const BLOG_EDIT_MUTATION = gql`
  mutation BlogEditMutation ($input: BlogUpdateInput!) {
    blogUpdate (input: $input)
    {
      blogs {
        id
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const BLOG_DELETE_MUTATION = gql`
  mutation BlogDeleteMutation ($input: BlogDeleteInput!) {
    blogDelete (input: $input)
    {
      blogs {
        id
      }
      success
      errors
    }
  }
`;

export const BLOG_POSTS_QUERY = gql`
  query BlogPostsQuery ($oid: Integer!, $page: Integer!, $perPage: Integer!, $filterString: [Str], $taxonomies: [TaxonomyID], $onlyLive: Bool!) {
    blog (oid: $oid) {
      _postStats {
        count
      }

      posts (page: $page, perPage: $perPage, filterString: $filterString, taxonomies: $taxonomies, onlyLive: $onlyLive) {
        id
        oid
        title
        postAt
      }
    }
  }
`;

export const BLOG_EDIT_QUERY = gql`
  query BlogEditQuery ($oid: Integer!) {
    blog (oid: $oid) {
      id
      oid
      title
      description
      teaser
      author {
        id
        firstName
        lastName
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      blogCategories:categories {
        id
        title
        parent {
          id
          title
        }
      }
      searchable
      active
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
    }
  }
`;

export const BLOG_ADD_TAXONOMIES = gql`
  mutation BlogAddTaxonomies ($input: BlogAddTaxonomiesInput!) {
    blogAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const BLOG_REMOVE_TAXONOMIES = gql`
  mutation BlogRemoveTaxonomies ($input: BlogRemoveTaxonomiesInput!) {
    blogRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;