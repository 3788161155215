import gql from 'graphql-tag';

export const TAX_RATE_NAV = gql`
  query TaxRateNav ($page: Integer!, $perPage: Integer!) {
    taxRates (page: $page, perPage: $perPage, sortString: "name desc") {
      id
      oid
      name
    }
  }
`;

export const TAX_RATE_STATS = gql`
  query TaxRateStats {
    _taxRateStats {
      count
    }
  }
`;

export const TAX_RATE_ADD = gql`
  mutation TaxRateAdd ($input: TaxRateCreateInput!) {
    taxRateCreate (input: $input) {
      taxRate {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const TAX_RATE_UPDATE = gql`
  mutation TaxRateUpdate ($input: TaxRateUpdateInput!) {
    taxRateUpdate (input: $input)
    {
      taxRates { id }
      success
      errors
    }
  }
`;

export const TAX_RATE_DELETE = gql`
  mutation TaxRateDelete ($input: TaxRateDeleteInput!) {
    taxRateDelete (input: $input)
    {
      success
      errors
      taxRates { id }
    }
  }
`;

export const TAX_RATE_EDIT = gql`
  query TaxRateEdit ($oid: Integer!) {
    taxRate (oid: $oid) {
      id
      oid
      name
      active
      includeShipping
      rate
      states
      description
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
    }
  }
`;

export const TAX_RATE_ADD_TAXONOMIES = gql`
  mutation TaxRateAddTaxonomies ($input: TaxRateAddTaxonomiesInput!) {
    taxRateAddTaxonomies(input: $input) {
      success
      errors
    }
  }
`;

export const TAX_RATE_REMOVE_TAXONOMIES = gql`
  mutation TaxRateRemoveTaxonomies ($input: TaxRateRemoveTaxonomiesInput!) {
    taxRateRemoveTaxonomies(input: $input) {
      success
      errors
    }
  }
`;