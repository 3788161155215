import styled from 'styled-components';
import Colors from 'styles/colors';

export const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  color: ${Colors.sahara};
  font-size: 14px;
`;

export const PopconfirmWrapper = styled.div`
 .ant-popover-buttons{
    .ant-btn {
      background-color: ${Colors.chateauGreen}
    }
    .btn-primary {
      background-color: ${Colors.cadetBlue}
    }
  }
`;