import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation tokenCreate($email: Email!, $password: Str!) {
    tokenCreate(admin:true, email: $email, password: $password) {
      token,
      expiresAt
    }
  }
`;

export const IMPERSONATE_TOKEN_CREATE = gql`
  mutation impersonateTokenCreate($input: ImpersonateTokenCreateInput!) {
    impersonateTokenCreate(input: $input) {
      token,
      expiresAt,
      success,
      errors
    }
  }
`;