
/**
 * reOrder all items based on selected item drag and drop
 * @param {list} list to reorder index
 * @param {sourceIndex} drag index of item
 * @param {destinationIndex} drop index of item
 */
const reOrder = (list, sourceIndex, destinationIndex) => {
  const [removed] = list.splice(sourceIndex, 1);
  list.splice(destinationIndex, 0, removed);
  return list;
};

export default reOrder;