import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FAL } from 'icons/fa';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { Input as antdIn } from 'antd';

import * as css from './style';

const TextArea = antdIn.TextArea;
interface Props {
  type?: string,
  ptext: string, // Placeholder
  theme?: 'light' | 'dark',
  hint?: string,
  defaultValue?: string,
  width?: string,
  containerwidth?: string,
  name?: string,
  size?: number,
  error?: boolean,
  errorMsg?: string,
  disabled?: boolean,
  icon?: IconName,
  className?: string,
  optional?: boolean,
  min?: number,
  inputAttrs?: object,
  onChange?: any,
  onKeyUp?: (e: React.KeyboardEvent) => void,
  onKeyPress?: (e: React.KeyboardEvent) => void,
  onKeyDown?: (e: React.KeyboardEvent) => void,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  showCounter?: boolean,
  isTextareaInput?: boolean,
  isOpenAISuggested?: boolean,
}

interface State {
  focused: boolean,
  changed: boolean,
}

const Input = forwardRef(({ type = 'text', theme = 'light', size = null, error = false, errorMsg = 'This field is required.', disabled = false, optional = false, showCounter = false,isTextareaInput = false, isOpenAISuggested = false, ...rest }: Props, ref) => {
  const [state, setState] = useState<State>({
    focused: false,
    changed: false,
  });
  let inputField = useRef(null);
  const { focused, changed } = state;
  const { name, hint, ptext, defaultValue, containerwidth, width, onChange, onKeyUp, icon, className, onKeyDown, onKeyPress, min, inputAttrs, onBlur = (e: React.FocusEvent<HTMLInputElement>) => { } } = rest;

  // Make it possible to get the value of the input from a parent component, without a change event or callback having occurred.
  useImperativeHandle(ref, () => ({
    value: () => inputField.current.value,
  }));

  // Keep focus state and element focus state in sync, re-render component.
  // This makes sure that the blur of the input is reset with state.
  useEffect(() => {
    if (inputField.current !== document.activeElement) return;

    setState({ ...state, focused: true });
  }, [inputField.current]);

  function focusHandler() {
    if (!inputField) return;

    if (inputField?.current?.state?.value === '' && isTextareaInput) {
      setState({ ...state, focused: true, changed: true });
    } else if (inputField?.current?.value === '') {
      setState({ ...state, focused: true, changed: true });
    }
  }

  function blurHandler(e) {
    if (!inputField) return;

    if (inputField?.current?.state?.value === '' && isTextareaInput) {
      setState({ ...state, focused: false, changed: true });
    } else if (inputField?.current?.value === '') {
      setState({ ...state, focused: false, changed: true });
    }

    onBlur(e);
  }

  // If defaultValue is there show it with focused input
  const hasDefaultValue = (defaultValue || (defaultValue as unknown as Number) === 0);

  const classes = classNames({
    'form-input-field': true,
    '-is-focused': focused || (hasDefaultValue && !changed),
    '-is-hidden': type === 'hidden',
    '-is-disabled': disabled,
    '-dark-theme': theme === 'dark',
    '-has-placeholder': typeof ptext !== 'undefined',
    '-has-error': error,
    '-is-textarea': isTextareaInput ? true : false,
  });
  const wrapperClassName = (showCounter) ? `form-input-field__wrapper counter` : `form-input-field__wrapper`;
  const attrs = {
    'data-counter': (showCounter) ? (isTextareaInput) ? (isOpenAISuggested) ? `Character: ${defaultValue.length}` : `Character: ${inputField?.current?.state?.value?.length ?? 0}` : `Character: ${inputField?.current?.value?.length}` : null,
  }
  return (
    <css.Container className={`${classes} ${className}`} width={containerwidth}>
      <css.Wrapper width={width} className={wrapperClassName} {...attrs}>
        {ptext && <css.Placeholder>{icon && <FAL icon={icon} />}{ptext}</css.Placeholder>}
        {optional && <css.Optional>Optional</css.Optional>}
        {isTextareaInput ?  <TextArea 
          ref={(input) => inputField.current = input}
          name={name}
          onChange={(e) => onChange(e)}
          onKeyUp={onKeyUp}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          disabled={disabled}
          autoSize={true}
          {...inputAttrs}
          />: <input
          ref={(input) => inputField.current = input}
          size={Number(size)}
          name={name}
          onKeyUp={onKeyUp}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          defaultValue={defaultValue}
          disabled={disabled}
          type={type}
          min={min}
          onChange={(e) => onChange(e)}
          {...inputAttrs}
        /> }
      </css.Wrapper>
      {hint && <css.Hint dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(hint)}}></css.Hint>}
      {error && <css.ErrorMsg>{errorMsg}</css.ErrorMsg>}
    </css.Container>
  );
});

export default Input;
