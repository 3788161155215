import { keyframes } from 'styled-components';

export const spin = keyframes`
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`;

export const color = keyframes`
  0% {
    stroke: #fff;
  }
  40% {
    stroke: #fff;
  }
  66% {
    stroke: #fff;
  }
  80%, 90% {
    stroke: #fff;
  }
`;
