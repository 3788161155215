import React from 'react';
import { render } from 'react-dom';
import ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { split } from 'apollo-link';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { clearLoginFromSiblingSite, environmentOptions, isLoginFromSiblingSite } from 'utils/helpers';
import Auth from './auth';
import { defaults, resolvers } from './resolvers';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

import { AuthProvider } from 'components/auth/context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

const target = document.getElementById('root');

/**
 * An apollo link returned by onError, will work as interceptor to handle any 403 response
 */
const link = onError(({ networkError }) => {
  if (
    networkError?.hasOwnProperty('response') &&
    networkError['response']['status'] === 403
  ) {
    const customEvent = new CustomEvent('token_expired', { detail: {} });
    document.dispatchEvent(customEvent);

    // When token is used from cookie sent from sibling site and it is invalid for this host
    // and same user is not exist for this site
    if (isLoginFromSiblingSite()) {
      Auth.clearToken();
      clearLoginFromSiblingSite();

      // A flag to check for show notification at login time or not
      localStorage.setItem('notify-expired-token', "true");

      window.location.href = "/login";
      return;
    }
  } else if (
    networkError?.hasOwnProperty('message') &&
    networkError.message === 'Failed to fetch'
  ) {
    const customEvent = new CustomEvent('api_failed');
    document.dispatchEvent(customEvent);
  }
});

/**
 * Added this for removing browser console warnings of "Heuristic Fragment matcher warning!”
 * To make union and interface type works
 */
const introspectionQueryResultData = {
  __schema: {
    types: []
  }
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

// Create two clients, one for production and another for stage environments

const ENV_OPTIONS = environmentOptions();

const customFetch1 = (uri, options) => {
  const token = Auth.getToken();
  const ENV_OPTIONS_LATEST = environmentOptions();
  if (token) options.headers.authorization = `token ${token}`;
  options.headers['x-environment'] = ENV_OPTIONS_LATEST.X_ENV;
  options.headers['x-host-shortname'] = ENV_OPTIONS_LATEST.X_HOST_SHORTNAME;
  options.headers['source_url'] = window.location.origin;
  // TODO: Add ENV Headers
  return fetch(uri, options);
};

const optionsProd = {
  uri: ENV_OPTIONS.URI,
  fetch: customFetch1,
  connectToDevTools: true
};

// link to use if batching (default)
// also adds a `batch: true` header to the request to prove it's a different link
const batchHttpLink1 = new BatchHttpLink({
  ...optionsProd,
  fetch: (uri, options) => {
    const token = Auth.getToken();
    const ENV_OPTIONS_LATEST = environmentOptions();

    options.headers['authorization'] = `token ${token}`;
    options.headers['batch'] = true;
    options.headers['x-environment'] = ENV_OPTIONS_LATEST.X_ENV;
    options.headers['x-host-shortname'] = ENV_OPTIONS_LATEST.X_HOST_SHORTNAME;
    return fetch(uri, options);
  }
});
// link to use if not batching
const httpLink1 = new HttpLink(optionsProd);

export const client = new ApolloClient({
  // the link to use is a either the default or batch link
  // TODO: add queryDeDuplication: true to stop sending duplicate queries
  link: split(
    operation => !operation.getContext().important, // remove exclamatory sign to enable batch operation site wide
    ApolloLink.from([link, httpLink1]), // if the important is undefined or false -- debatch
    ApolloLink.from([link, batchHttpLink1]) // otherwise, batching is fine
  ),
  cache,
  resolvers,
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } }
});

cache.writeData({
  data: defaults
});

const renderApp = () => {
  render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>,
    target
  );
};

// Redirect user to https url if user visists url with http:
if (
  window.location.protocol === 'http:' &&
  window.location.hostname.indexOf('localhost') === -1
) {
  window.location.href = `https://${window.location.host}${window.location.pathname}`;
} else {
  renderApp();
}

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
  });
}

// @ts-ignore
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});
