import gql from 'graphql-tag';

export const SUBSCRIPTION_EDIT = gql`
  query SubcriptionEdit($id: IDType!) {
    subscription(id: $id) {
      id
      oid
      live
      beginAt
      resource
      endAt
      renewal
      recurring
      externalId
      licenseType
      trial
      type
      status
      name
      user {
        id
      }
      item {
        __typename
        ... on Newsletter {
          id
        }
        ... on Publication {
          id
        }
      }
    }
  }
`;
export const SUBSCRIPTION_CREATE = gql`
  mutation SubscriptionCreate ($input: SubscriptionCreateInput!) {
    subscriptionCreate(input: $input)
    {
      subscription {
        id
      }
      success
      errors
    }
  }
`;

export const SUBSCRIPTION_UPDATE = gql`
  mutation SubscriptionUpdate ($input: SubscriptionUpdateInput!) {
    subscriptionUpdate(input: $input)
    {
      subscriptions {
        id
      }
      success
      errors
    }
  }
`;

export const SUBSCRIPTION_DELETE = gql`
  mutation SubscriptionDelete ($input: SubscriptionDeleteInput!) {
    subscriptionDelete(input: $input)
    {
      subscriptions {
        id
      }
      success
      errors
    }
  }
`;