import React, { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import anime from 'animejs/lib/anime.es.js';
import Button from 'components/button/index';
import Portal from './portal';
import { Context } from './context';
import * as css from './style';

/* TODO: Unmount props.children when drawer is closed (once animation is finished). Should improve the JS Heap Performance (gargabe collection) */
/* Using CSSTransitions component performed worse than using CSS animation via styled-components and props */

const animateIn = (node) => anime({
  targets: node,
  translateX: () => ['110%', '0%'],
  duration: 600,
  easing: 'easeInOutQuart',
});

const animateOut = (node) => anime({
  targets: node,
  translateX: () => ['0%', '100%'],
  duration: 600,
  easing: 'easeInOutQuart',
});

const animateFadeIn = (node) => anime({
  targets: node,
  duration: 1,
  easing: 'linear',
  translateY: 0,
});

const animateFadeOut = (node) => anime({
  targets: node,
  duration: 1,
  easing: 'linear',
  translateY: 0,
});

const DrawerMount = ({ size, context, children, title, id, hasTabs, subTitle, showAsModal=false, showHeader=true, showButtonToggle=true, showOverlay=false }) => {
  const isOpen = context.show && context.show.indexOf(id) !== -1;
  const nodeRef = useRef(null);
  const overlayRef = useRef(null);

  /**
   * show blur effect in background when model is open in center
   */
  useEffect(() => {
    if (isOpen && showAsModal) {
      document.getElementById('root').style.filter = 'blur(2px)';
      return;
    }

    if (showAsModal) {
      document.getElementById('root').style.filter = 'none';
    }
  }, [isOpen]);

  /**
   * Hide body scroll when modal is open
   */
  useEffect(() => {
    if (context.show && context.show?.length) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "auto";
  }, [context.show?.length]);

  return (
    <>
    <Transition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={600}
      onEnter={(_node) => { showAsModal ? animateFadeIn(nodeRef.current) : animateIn(nodeRef.current) }}
      onExit={(_node) => { showAsModal ? animateFadeOut(nodeRef.current) : animateOut(nodeRef.current) }}
      unmountOnExit
      appear
    >
      <css.Container ref={nodeRef} size={size} showAsModal={showAsModal} show={isOpen} className="drawer-container" showOverlay={showOverlay}>
        {
          showHeader &&
          <css.Header>
            <span className="drawer-title">{title}</span>
            { subTitle && <css.subTitle>{subTitle}</css.subTitle>}
            { showButtonToggle && <Button icon="arrow-circle-right" className="button-close" onClick={context.toggleShow} /> }
          </css.Header>
        }

        <css.Body hasTabs={hasTabs} showAsModal={showAsModal}>
          {children}
        </css.Body>
      </css.Container>
    </Transition>
    {
      showOverlay && (
        <Transition
          in={isOpen}
          nodeRef={overlayRef}
          timeout={600}
          onEnter={(_node) => { showAsModal ? animateFadeIn(overlayRef.current) : animateIn(overlayRef.current) }}
          onExit={(_node) => { showAsModal ? animateFadeOut(overlayRef.current) : animateOut(overlayRef.current) }}
          unmountOnExit
          appear
        >
          <css.Overlay ref={overlayRef} />
        </Transition>
      )
    }
    </>
  );
};

interface Props {
  children: React.ReactNode,
  title: string,
  id: string,
  size?: 'default' | 'wide' | 'medium' | 'large',
  hasTabs?: boolean,
  subTitle?: string,
  showAsModal?: boolean,
  showHeader?: boolean,
  showButtonToggle?: boolean,
  showOverlay?: boolean,
}

const Drawer = (props: Props) => (
  <Portal>
    <Context.Consumer>
      {(context) => (
        <DrawerMount
          size={props.size}
          context={context}
          title={props.title}
          id={props.id}
          hasTabs={props.hasTabs}
          subTitle={props.subTitle}
          showAsModal={props.showAsModal}
          showHeader={props.showHeader}
          showButtonToggle={props.showButtonToggle}
          showOverlay={props.showOverlay}
        >
          {props.children}
        </DrawerMount>
      )}
    </Context.Consumer>
  </Portal>
);

Drawer.defaultProps = {
  size: 'default',
  hasTabs: false,
  subTitle: "",
};

export default Drawer;
