import styled from 'styled-components';
import Colors from 'styles/colors';
import { grid } from 'styles/grid';

export const UploadList = styled.div`
  display: flex;
  margin-top: 15px;
  max-width: 450px;
`;

export const UploadListItem = styled.div`
  ${grid};
  width: 100%;
  background-color: ${Colors.white};
  padding: 15px 15px;

  border: ${({hasError}) => hasError ? '1px solid #f00' : 'none'};
`;

export const ThumbnailContainer = styled.span`
  width: 44px;
  height: 44px;
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;

  img {
    width: 44px;
    height: 44px;
  }
`;

export const FileName = styled.div`
  width: 100%;
`;

export const Name = styled.div`
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;
export const RemoveIcon = styled.span`
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  ${grid};
  height: 14px;
  align-items: center;
  z-index: 999;
  width: 95%;
  display: inline-grid;
`;

export const ProgressBar = styled.div`
  background-color: ${Colors.pictonBlue};
  width: ${({xWidth}) => xWidth || 0};
  height: inherit;
  border-radius: 20px;
`;

export const Progress = styled.div`
  height: inherit;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .overwrite-content {
    margin-right: 8px;
  }
`;

export const ProgressText = styled.div`
`;

export const Container = styled.div`
  position: fixed;
  width: 23%;
  z-index: 999;
  padding: 5px 10px;
  color: white;
  z-index: 999;
  left: unset !important;
  right: 0;
  bottom: 0;
  background-color: ${Colors.nileBlue};
`;