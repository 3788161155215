import React, { useContext, useState, useEffect } from 'react';
import ExportCsv from 'components/export/csv';
import * as css from './style';
import { SEARCH_EXPORT_ORDERS } from 'graphql/entry';
import useExportData from './exportData';
import { Context } from './context';
import { FetchData } from './fetchData';


const ProgressBar = (props) => {

  const { state, dispatch } = useContext(Context);
  const [completed, setCompleted] = useState(0);
  const [records, setRecords] = useState([]);
  const { getData, getOrderExportDetail } = useExportData();
  const { exportClicked, exportVariables, exportType, fileName } = state;
  const csvLinkRef = React.useRef(null);

  useEffect(() => {
    const isCsvRefExist = csvLinkRef && csvLinkRef.current;
    if (isCsvRefExist && records.length > 0) {
      csvLinkRef.current['link'].click();
      setRecords([]);
    }
  }, [records]);

  useEffect(() => {
    if (exportClicked && exportVariables.selectedExportData.length > 0) {
      getSelectedRow();
    }
  }, [exportClicked]);

  async function getSelectedRow() {
    const translatedData = (exportType === 'orderDetail') ? await getOrderExportDetail(exportVariables.selectedExportData, true) : await getData(exportVariables.selectedExportData, true);
    setRecords( [...[translatedData[0]], ...translatedData[1]]);
    dispatch({ type: "reset", payload: false });
  }

  async function translatedData(data: unknown, isAllReceived: boolean, hasError: boolean = false) {
    const newData = (exportType === 'orderDetail') ? await getOrderExportDetail(data, (exportVariables.selectedExportData.length > 0) ? true : false) : await getData(data, (exportVariables.selectedExportData.length > 0) ? true : false);
    setRecords([
      ...records,
      ...[newData[0]],
      ...newData[1]]);
    dispatch({ type: "reset", payload: false });
  }

  return (
    <>
      {exportClicked &&
        <>
          <css.Container>
            <div>Exporting status</div>
            <css.ProgressBarContainer grid={2} columns="260px|10px" gap="10px">
              <css.Progress>
                <css.ProgressBar xWidth={`${completed}%`}></css.ProgressBar>
              </css.Progress>
              <css.ProgressText>{`${completed}%`}</css.ProgressText>
            </css.ProgressBarContainer>
          </css.Container>
          {exportVariables.selectedExportData.length === 0 && <FetchData totalRecords={exportVariables.totalRecords} returnData={translatedData} query={SEARCH_EXPORT_ORDERS} queryVariables={state.queryVariables} exportDataKey={'search'} setCurrentPage={setCompleted} />}
          <ExportCsv data={records} ref={csvLinkRef} fileName={fileName} />
        </>}
    </>
  );
};

export default ProgressBar;