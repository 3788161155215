import * as React from 'react';
import { useState, createContext } from 'react';

export const Context = createContext<{
  show: string[],
  toggleShow: Function,
  closeDrawer: (name: string) => void,
  closeDrawers: (names: string[]) => void,
}>({ show: [], toggleShow: () => {}, closeDrawer: () => {}, closeDrawers: () => {}});

interface Props {
  children: Node,
}

const DrawerProvider = (props: Props) => {
  const [show, setShow] = useState<string[]>([]);

  function closeDrawer(name: string) {
    if (!show.includes(name)) return;

    setShow([ ...show.filter((item) => item !== name) ]);
    dispatchDrawerCloseEvent(name);
  }

  function closeDrawers(names: string[]) {
    const filtered = [...show.filter((item) => names.indexOf(item) === -1 )];

    setShow(filtered);
  }

  /**
   * Dispatch close event of drawer
   * @param {string} id
   */
  function dispatchDrawerCloseEvent(id) {
    const customEvent = new CustomEvent('epub.drawer.close', {detail: {id}});
    document.dispatchEvent(customEvent);
  }

  /**
   *
   * @param {string | React.SyntheticEvent | undefined} id
   * @param  {boolean} allowMultiple
   */
  function toggleShow(id?: string | React.SyntheticEvent | undefined, allowMultiple?: boolean) {
    if (id && typeof id !== 'string') {
      id.stopPropagation();
    }

    if (typeof id !== 'string') {
      dispatchDrawerCloseEvent(show[0]);
      setShow([
        ...show.slice(0, show.length - 1),
      ]);
    } else if (show.indexOf(id) === -1 && allowMultiple && !show.includes(id)) {
      setShow([
        ...show,
        id,
      ]);
    } else if (show.indexOf(id) === -1 && !allowMultiple) {
      setShow([id]);
    }
  }

  return (
    <Context.Provider value={{ show, toggleShow, closeDrawer, closeDrawers }}>{props.children}</Context.Provider>
  );
};

export default DrawerProvider;
