import React, { ReactNode } from 'react';
import { Select } from 'antd';
import * as css from './style';

const Option = Select.Option;

type Props = {
  theme?: '-is-light' | '-is-dark' | '-is-mini',
  placeholder?: string,
  isSearchable?: boolean,
  onChange?: any,
  children?: ReactNode,
  options?: Array<{
    label: string,
    value: string,
  }>,
  defaultValue?: string | string[],
  name?: string,
  error?: boolean,
  errorMsg?: string,
  mode?: "tags" | "multiple" | "default" | "combobox" | "SECRET_COMBOBOX_MODE_DO_NOT_USE",
  value?: string | string[],
  maxWidth?: string,
  label?: string,
};

// TODO: Remove isSearchable from components now using antD.

const SelectList = ({ theme='-is-light', options, children, error, errorMsg, mode="default", maxWidth, label, name, onChange, ...rest}: Props) => {
  const Options = options && options.map((o) => <Option key={o.value} value={o.value}>{o.label}</Option>);

  /**
   * On select change event handler
   * @param {any} value
   */
  const onChangeHandler = (value: any) => {
    const event = {
      target: {
        name,
        value,
      },
      persist: () => {}
    };
    onChange(event as React.ChangeEvent<HTMLInputElement>);
  }

  return (
    <css.Wrapper className={theme} maxWidth={maxWidth}>
      { label && (<css.FieldLabel>{label}</css.FieldLabel>) }
      <Select
        className={`epub-react-select ${theme}`}
        mode={mode}
        onChange={onChangeHandler}
        {...rest}
      >
        {Options || children}
      </Select>
      {error && <css.ErrorMsg>{errorMsg}</css.ErrorMsg>}
    </css.Wrapper>
  );
};

export default SelectList;