import React, { useState, createContext } from 'react';

interface Props {
  children: Node,
}

export const Context = createContext<{
  show: boolean,
  setShow: Function,
}>({ show: false, setShow: () => {}});

/**
 * A warning prompt's context component for unsaved changes before changing route
 *
 * @param props
 * @returns
 */
const PromptProvider = (props: Props) => {
  const [show, setShow] = useState(false);

  return (
    <Context.Provider value={{ show, setShow }}>{props.children}</Context.Provider>
  );
}

export default PromptProvider;