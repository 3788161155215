import axios from "axios";
import { getGeneralPath } from 'utils/helpers';
import { getFormattedFileName } from "./helpers";
import { uploadHeaders, saveFileRecord, FileArgs } from './index';

/**
 * File upload handling for the ePublishing API.
 * @param {ApolloClient<unknown>} client apollo client instance
 * @param {RelaxedUploadFile} file The file to be uploaded.
 * multiple files upload progress and state of completion.
 * @param {string} path The upload path for existing files. New file paths get generated dynamically.
 * @param {(args: UploadTick) => void} callbackUploadProgress The callback function that handles progress ticks and any other state of the files.
 * @param {string} existingId Existing id when needs to replace file
 */
export async function uploadFile({ client, file, path = null, callbackUploadProgress, existingId }: FileArgs) {
  const fileName = getFormattedFileName(file.name);
  const dynamicPath = path ? `/media${path}` : `${getGeneralPath()}/`;
  const requestUri = `https://api.epublishing.com${encodeURI(dynamicPath)}${encodeURI(fileName)}`;

  callbackUploadProgress({ uploading: true, percent: 1 });

  const formData = new FormData();
  formData.append('upload', file.originFileObj);

  try {
    const response: any = await axios.request({
      method: "post",
      url: requestUri,
      headers: uploadHeaders(),
      data: formData,
      onUploadProgress: (p) => {
        callbackUploadProgress({ uploading: true, percent: ((p.loaded / p.total) * 100).toFixed(0) });
      },
    });

    const savePath = `${dynamicPath}${fileName}`;
    const path = savePath.replace("/media/", "/");

    if (existingId) {
      return Promise.resolve();
    }

    return await saveFileRecord({ client, file, path, callbackUploadProgress: callbackUploadProgress, existingId, iptc: response?.data?.image });
  } catch(error: any) {
    callbackUploadProgress({  uploading: false, percent: 100, error: true });
    return Promise.resolve(null);
  }
}
