import React, { ReactNode } from 'react';
import { Tree } from 'antd';
import { ParentTitle, Container, LoadMoreButtonWrapper, LoadMoreIcon, LoadMoreText } from '../style';
import { FAL } from 'icons/fa';
import ApolloClient from 'apollo-client';

interface LoadMoreButtonProps {
  loader: boolean,
  currentPage: number,
  fetchNextPage: (page: number) => void,
}

export interface Pagination {
  currentPage: number,
  totalPages: number,
  loader: boolean,
}

interface RenderTreeNodesProps {
  data: any,
  pagination: Pagination,
  expandedKeysPagination: {
    [key: string] : Pagination,
  },
  fetchNextPage: (page: number, key?: string) => void,
  key?: string,
  children?: any,
  client?: ApolloClient<any>,
  loadData: (treeNode: any, page: number, isRefresh?: boolean) => void,
  isSearching: boolean,
  name: string,
  allowDisabledNode?: boolean,
  selectedKeys?: any,
}

const { TreeNode } = Tree;

/**
 * Load more button componenet
 * @param {boolean} loader - indicator of new page loading
 * @param {number} currentPage - currentPage which is last loaded
 * @param {(page: number) => void} fetchNextPage callback to load next page
 */
function LoadMoreButton ({loader, currentPage, fetchNextPage}: LoadMoreButtonProps) {

  /**
   * load next page records
   */
  function loadMoreRecords(e) {
    e.stopPropagation();
    fetchNextPage(currentPage + 1);
  }

  return (
    <LoadMoreButtonWrapper>
      <a onClick={loadMoreRecords}>
        <LoadMoreIcon><FAL icon={`${loader ? 'spinner' : 'arrow-down'}`} spin={loader} /></LoadMoreIcon>
        <LoadMoreText>LOAD MORE</LoadMoreText>
      </a>
    </LoadMoreButtonWrapper>
  );
}

/**
 * Render tree nodes recursively
 * @param {any} data
 * @param {Pagination} pagination
 * @param {{[key: string]: Pagination}} expandedKeysPagination
 * @param {() => void} fetchNextPage
 * @param {string} key
 * @param {() => void} addTaxonomy a callback for add new taxonomy open drawer
 * @param {boolean} isRightClickEnabled indicates on right click, show menu or not
 * @param {(visible: boolean) => void} onMenuVisibleChange
 * @param {ApolloClient<any>} client apollo client
 * @param {(treeNode: any, page: number, isRefresh?: boolean) => void} loadData load any page of taxonomy
 * @param {boolean} isSearching indicates input has search value or not
 * @param {string} name select box name
 * @param {boolean} allowDisabledNode allow to diable any item based on provided data->item->allowSelect attr
 * @param {array} selectedKeys array of selected keys
 */
export function renderTreeNodes({data, pagination, expandedKeysPagination, fetchNextPage, key, children, client, loadData, isSearching, name, allowDisabledNode, selectedKeys}: RenderTreeNodesProps) {
  if (!data.length) return;

  /**
   * Title wrapped by Dropdown overlay which allow to trigger menu on right click
   * @param {ReactNode} title
   */
  function getTitle(title: ReactNode, key: string, item: any) {
    if (!children) {
      return title;
    }

    return children({title, client, isSearching, key, name, loadData, item});
  }

  function renderImediateParent (title, parentTitle) {
    return <Container  className={'search-tax-result'}>
      <div>{title}</div>
      <ParentTitle>{parentTitle}</ParentTitle>
    </Container>;
  }
  const preparedData = data.map((item) => {
    const hiddenItem = item.rest.active;
    const selectable = item.rest.hasOwnProperty('allowSelect') ? item.rest.allowSelect :  (item.rest.hasOwnProperty('selectable')) ? item.rest.selectable  : '';
    const applyClass = (name === 'TOPICS' || name === 'CLASSIFICATION' || name === 'SITE_PLACEMENT' || name === 'categories' || name === 'PRODUCT_CATEGORIES' || name === 'EVENT_CATEGORIES' || name === 'DIRECTORY_CATEGORIES' || name === 'CLASSIFIED_CATEGORIES' || name === 'BLOG_CATEGORIES') ? true : false;
    const classHiddenItem = (applyClass && !hiddenItem) ? `item-italic` : ``;
    const classSelectableItem = (applyClass && !selectable) ? `item-disable-italic` : ``;
    const classItem = `${classHiddenItem} ${classSelectableItem}`;
    let isAlreadySelected = selectedKeys.includes(item.key);
    const treeItemClass = (isAlreadySelected) ? `${classItem} already-selected-item` : classItem;

    if (item.children) {
      const itemPagination = expandedKeysPagination[item.key] ? expandedKeysPagination[item.key] : null;
      const title = (item.parent) ? renderImediateParent(item.title, item.parent) : item.title;
      return (
        <>
          <TreeNode title={getTitle(title, item.key, item.rest)} key={item.key} dataRef={item} isLeaf={item.isLeaf} className={`epub-tree-node ${treeItemClass}`} disabled={item.disabled && allowDisabledNode}>
            {renderTreeNodes({data: item.children, pagination: itemPagination, expandedKeysPagination, fetchNextPage, key: item.key, children, client, loadData, isSearching, name, allowDisabledNode: allowDisabledNode, selectedKeys})}
          </TreeNode>
        </>
      );
    }
    return <TreeNode title={getTitle(item.title, item.key, item.rest)} {...item} dataRef={item} disabled={item.disabled && allowDisabledNode} className={`${treeItemClass}`}/>;
  });

  if (pagination && pagination.currentPage < pagination.totalPages) {
    preparedData.push(<TreeNode title={<LoadMoreButton {...pagination} fetchNextPage={(currentPage) => fetchNextPage(currentPage, key)} />} dataRef={pagination} isLeaf={true}/>);
  }

  return preparedData;
}
