import * as React from 'react';
import ReactDOM from 'react-dom';

const drawerRoot = document.getElementById('drawer');

type Props = {
  children: React.ReactNode,
};

export default class Portal extends React.Component<Props,{}> {
  el: Element

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    if (drawerRoot !== null) {
      drawerRoot.appendChild(this.el);
    }
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}
