import styled from 'styled-components';
import Colors from 'styles/colors';
import { grid } from 'styles/grid';

export const Box = styled.legend`
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid ${Colors.mercury};
  margin-bottom: 20px;

  &.-theme-dark {
    background-color: ${Colors.whiteSmoke};
    border: 1px solid ${Colors.mercury};
  }

  label {
    font-size: 14px;
  }
`;

/**
 * A field style meant to wrap a form field. Adds a margin and optional grid props.
 * @example
    <css.Field
      grid={2}
      columns="2fr|1fr"
      gap="30px"
      columnsBreakpoints={[
        { media: [0, 800], columns: "1fr" }
      ]}
    >
    <Component />
    </css.Field>
 */
export const Field = styled.div`
  margin-bottom: 20px;
  ${grid}
`;

export const FieldTitle = styled.h1`
  color: ${Colors.fiord};
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  margin-bottom: 10px;
`;
