import React, { useState, useEffect } from 'react';
import { FAL } from 'icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faVolumeUp } from '@fortawesome/pro-solid-svg-icons';
import useIsInViewport from 'use-is-in-viewport'
import { useImage } from 'hooks/useImage';
import { MEDIA_TYPES } from 'constants/index';
import { getIconBasedOnExtension } from 'utils/helpers';
import * as css from './style';


/* TODO: Optimize to prevent double render. e.g. when setSelectedRowsCount is called */

interface ImageWrapperProps {
  url: string,
  imageSize: number,
  height?: number,
  width?: number,
  withPlay?: boolean,
  maxWidth?: string,
  maxHeight?: string,
  altText?: string,
  type?: string,
}

/**
 * Image wrapping component
 * @param {ImageWrapperProps} props
 */
export const ImageWrapper = ({ url, imageSize, height = 50, withPlay = false, maxWidth = "100px", maxHeight = "80px", altText = "Image not found", type = '' }: ImageWrapperProps) => {
  // lazy load image
  const { currentSrc } = useImage({ src: String(url), width: imageSize });

  // @TODO Make image resizing work on WEBP
  const imgSrc = currentSrc.includes('.webp') ? currentSrc.replace(/\?\d+&?&width=\d+/g, '') : currentSrc

  return (
    <>
      <css.ImageWrapper maxWidth={maxWidth} maxHeight={maxHeight}>
        {withPlay &&
          <css.ImageFile maxWidth={maxWidth} maxHeight={maxHeight}>
            <FontAwesomeIcon icon={(type === MEDIA_TYPES.AUDIO) ? faVolumeUp : faPlay} />
            <img width="auto" height={height} loading="lazy" src={currentSrc} alt={altText} />
          </css.ImageFile>}
        {!withPlay &&
          <img width="auto" height={height} loading="lazy" src={imgSrc} alt={altText} />
        }
      </css.ImageWrapper>
    </>
  );
};


/**
 * Thumbnail column reusable component
 * @param {string} url Url to be used in thumb
 * @param {Image} record record of image/audio/video/file
 * @param {number} size width of image for thumb
 * @param {number} height height of image for thumb
 * @param {boolean} withTitle show image/icon with name or not
 * @param {string} title name of the file to be show with video/audio/file icon
 * @param {() => void} showPreview on click of this function preview will be displayed in modal
 * @param {string} maxWidth thumb's maximum width
 * @param {string} maxHeight thumb's maximum height
 */
const ThumbnailColumn = ({ url, record, size = 80, height = 50, withTitle = false, title = "", showPreview = undefined, maxWidth = '100px', maxHeight = '80px' }) => {
  let elementToRender = null;
  if (record.__typename === MEDIA_TYPES.IMAGE) {
    elementToRender = (<ImageWrapper url={url} imageSize={size} width={size} height={height} maxWidth={maxWidth} maxHeight={maxHeight} altText={record?.altText} />);
  } else if ((record.__typename === MEDIA_TYPES.AUDIO || record.__typename === MEDIA_TYPES.VIDEO) && record.image) {
    elementToRender = (<ImageWrapper url={record.image.absoluteUrl} imageSize={size} withPlay={true} height={height} maxWidth={maxWidth} maxHeight={maxHeight} type={record.__typename} altText={record?.image?.altText} />);
  } else if ((record.__typename === MEDIA_TYPES.FILE) && record.image) {
    elementToRender = (<ImageWrapper url={record.image.absoluteUrl} imageSize={size} withPlay={false} height={height} maxWidth={maxWidth} maxHeight={maxHeight} type={record.__typename} altText={record?.image?.altText} />);
  } else if (record.__typename === MEDIA_TYPES.VIDEO) {
    elementToRender = (<css.IconWrapper className="video-icon">{withTitle && <css.Title>{title}</css.Title>}<FAL icon="file-video" /></css.IconWrapper>);
  } else if (record.__typename === MEDIA_TYPES.AUDIO) {
    elementToRender = (<css.IconWrapper className="audio-icon">{withTitle && <css.Title>{title}</css.Title>}<FAL icon="file-audio" /></css.IconWrapper>);
  } else {
    const iconName = record.absoluteUrl ? getIconBasedOnExtension(record.absoluteUrl) : 'file';
    elementToRender = (<css.IconWrapper className="file-icon">{withTitle && <css.Title>{title}</css.Title>}<FAL icon={`${iconName}`} /></css.IconWrapper>);
  }

  return (
    <div
      onClick={() => {
        if (typeof showPreview === 'function') {
          showPreview();
        }
      }}
    >
      {elementToRender}
    </div>
  );
};

export default ThumbnailColumn;