import React, { useEffect, useContext, useState } from 'react';
import useHover from 'hooks/useHoverCallback';
import Actions from "./actions";
import { OutlineMenuContext, OutlineMenuProvider } from './context';
import * as css from './style';

/*
 * Use this OutlineMenuProvider as a nested provider around any outlineMenu component that will have
 * nested outlineMenu's. An ID is required on each outlineMenu when using the provider for nested.
 * @example
    <OutlineMenuProvider>
      <OutlineMenu actions={{ edit: () => {}}} id="1">
        Child1
        <OutlineMenu actions={{ edit: () => {}}} id="2">
          Child2
        </OutlineMenu>
      </OutlineMenu>
    </OutlineMenuProvider>
 */
export { OutlineMenuProvider };

interface ActionsProps {
  delete?: (e?: React.MouseEvent) => any,
  edit?: (e?: React.MouseEvent) => void,
  refresh?: () => any,
  hide?: () => any,
  report?: () => any,
  spam?: () => any,
  list?: () => any,
  disassociate?: () => any,
  add?: (e?: React.MouseEvent) => any,
  removeConfirmation?: () => void,
  move?: () => void,
  clone?: () => void,
  view?: () => void,
}

interface Props {
  children: React.ReactNode,
  actions: ActionsProps,
  borderSize?: string,
  borderPosition?: 'inner' | 'outer',
  menuPosition?: 'inner' | 'outer',
  id?: string,
  oid?: any,
}

/**
 * Shows a menu on the hover of any component wrapped with this.
 * NOTE: If you are having trouble getting this to work, make sure NO ID is provided when working with a single instance.
 * @param {string} borderSize The px width of the border outlining the wrapped component.
 * @param {string} borderPosition Does the border align to the inside or outside of the wrapped component.
 * @param {string} menuPosition Shows the menu on the inside or outside of the wrapped component.
 * @param {object} actions Each action and function that corresponds with a menu icon.
 * @param {React.Node} children The child component which this menu will outline.
 * @param {string} id The optional ID, used ONLY if multiple outlineMenu's are nested.
 */
const OutlineMenu = ({
  borderSize = "1",
  borderPosition = "outer",
  menuPosition = "outer",
  actions,
  children,
  id,
  oid
}: Props) => {
  const { dispatch, state: contextState } = useContext(OutlineMenuContext);
  const [hoverRef, isHovered] = useHover();
  const [active, setActive] = useState<boolean>(false); // To handle delete action popconfirm

  const iconsMap = {
    delete: "times-circle",
    edit: "edit",
    refresh: "sync",
    hide: "eye-slash",
    report: "flag",
    list: "list-ul",
    spam: "shield-alt",
    move: "arrows",
    disassociate: "times-circle",
    add: "plus-circle",
    removeConfirmation: "times-circle",
    clone: "clone",
    view: "eye",
  };
  const tooltipMap = {
    delete: "Delete",
    edit: oid ? `Edit id: ${oid}` : "Edit",
    refresh: "Refresh",
    hide: "Hide",
    report: "Report Abuse",
    list: "List",
    spam: "Report Spam",
    move: "Move",
    disassociate: "Disassociate",
    add: "Add",
    removeConfirmation: "Remove",
    clone: "move",
    view: "View",
  };
  // Map each action function with a menu icon to be displayed.
  const mapActionsCallbacks = Object.keys(actions).map((a) => ({
    icon: iconsMap[a],
    callback: actions[a],
    tooltip: tooltipMap[a],
    askForConfirmation: actions[a].name === 'removeConfirmation',
    setActive,
    className: `epub-outline-action-${a}`,
  }));
  const isHoveredOrActive = (id ? contextState.activeID === id && isHovered : isHovered) || active;

  // Set the activeID from context with the value of the currently hovered menu.
  useEffect(() => {
    if (!isHovered || !id) return;
    dispatch({ type: 'setActiveID', activeID: id });
  }, [isHovered]);

  return (
    <css.Wrapper
      ref={hoverRef}
      className="outline-menu"
      isHovering={isHoveredOrActive}>
      <Actions menuPosition={menuPosition} callbacks={mapActionsCallbacks} isHovering={isHoveredOrActive} />
      <css.Outline className="outline-menu-item" borderSize={borderSize} borderPosition={borderPosition} isHovering={isHoveredOrActive}>{children}</css.Outline>
    </css.Wrapper>
  )
};

export default OutlineMenu;
