import { environmentOptions } from 'utils/helpers';
import Auth from './auth';

/**
 * Send message to service worker
 * @param {any} message
 */
export function sendMessage(message) {
  return new Promise((resolve, reject) => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = event => {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };

    if (!navigator.serviceWorker?.controller) {
      window.location.reload();
      return;
    }

    navigator.serviceWorker.controller.postMessage(message, [
      messageChannel.port2
    ]);
  });
}

/**
 * Register service worker
 */
export function register() {
  if (!('serviceWorker' in navigator)) {
    console.log('Service worker not supported');
    return;
  }

  const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
  navigator.serviceWorker
    .register(swUrl)
    .then(() => navigator.serviceWorker.ready)
    .then(function(reg) {
      const envOptions = environmentOptions();
      const newData = {
        credentials: {
          environmentOptions: { ...envOptions },
          isUserAuthenticated: Auth.isUserAuthenticated(),
          token: Auth.getToken()
        }
      };
      sendMessage(newData);
    })
    .catch(function(error) {
      console.log('Registration failed:', error);
    });

  navigator.serviceWorker.addEventListener('message', function(event) {
    console.log(`DOM Received Message: ${event.data}`);
  });
}
