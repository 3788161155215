import { SearchableTypeEnum } from 'graphql/types/globalTypes';
import pluralize from 'pluralize';
import { IMAGE_CREATE, FILE_CREATE, AUDIO_CREATE, VIDEO_CREATE, FILE_UPDATE, VIDEO_UPDATE, AUDIO_UPDATE, IMAGE_UPDATE } from "graphql/entry";
import { IMAGE_EXTENSIONS, AUDIO_EXTENSIONS, VIDEO_EXTENSIONS } from "constants/index";
import { getExtension } from 'utils/helpers';
import { FileArgs } from './index';
import { uploadFile } from './uploadFile';

/**
 * Add or create a file record on the Database.
 * @param client Apollo client
 * @param file The file to add or create.
 * @param path The path of the file that was saved or already exists.
 * @param existingId If already existing, the Id of that file record.
 * @param callbackUploadProgress a callback to update upload progress on uploader state.
 */
export async function saveFileRecord({ client, file, path, existingId, callbackUploadProgress, iptc = null }: FileArgs) {
  const extension = getExtension(file.name);
  const extLower = (extension || '').toLowerCase();
  let uploadQuery = existingId ? FILE_UPDATE : FILE_CREATE;
  let type = SearchableTypeEnum.FILE;
  const fileName = file.name;
  let newPath = path;

  if (existingId) {
    newPath = `${path}${fileName}`;
  }

  const variables: any = {
    url: encodeURI(newPath),
  };

  // Determine specific query based on file type.
  // Set type based on file type.
  if (IMAGE_EXTENSIONS.indexOf(extLower) !== -1) {
    uploadQuery = existingId ? IMAGE_UPDATE : IMAGE_CREATE;
    type = SearchableTypeEnum.IMAGE;
  } else if (AUDIO_EXTENSIONS.indexOf(extLower) !== -1) {
    uploadQuery = existingId ? AUDIO_UPDATE : AUDIO_CREATE;
    type = SearchableTypeEnum.AUDIO;
  } else if (VIDEO_EXTENSIONS.indexOf(extLower) !== -1) {
    uploadQuery = existingId ? VIDEO_UPDATE : VIDEO_CREATE;
    type = SearchableTypeEnum.VIDEO;
  }

  let operation = "Create";
  // Add existingId to graphQL variables id.
  if (existingId) {
    variables.ids = [existingId];
    operation = "Update";
  } else {
    variables.title = fileName;
    variables.remote = false;
    variables.searchable = true;
  }

  // Set image related variable values that get saved to the database.
  if (type === SearchableTypeEnum.IMAGE && file.url) {
    let credit, description, width, height, caption;
    if (iptc && typeof iptc === "object") {
      Object.keys(iptc).forEach((key) => {
        if (key.indexOf("photoshop:Credit") !== -1 || key.indexOf("iptc:Credit") !== -1 ) {
          credit = iptc[key] ? iptc[key] : credit;
        } else if (key.indexOf("exif:ImageDescription") !== -1) {
          description = iptc[key];
          caption = iptc[key];
        } else if (key.indexOf("width") !== -1) {
          width = iptc[key];
        } else if (key.indexOf("height") !== -1) {
          height = iptc[key];
        }
      })
    }

    variables.width = width ? width : null;
    variables.height = height ? height : null;

    if (!existingId) {
      variables.credit = credit ? credit : null;
      variables.description = description ? description : null;
      // set alt text if not set, defaults to title of the file
      variables.altText = variables.altExt ?? variables.title;
      variables.caption = caption ? caption : null;
    }

    if (file.altText) {
      variables.altText = file.altText;
    }

    if (file.title) {
      variables.title = file.title;
    }
  }

  try {
    const response = await client.mutate({
      mutation: uploadQuery,
      variables: { input: variables },
    });

    const lowerCaseType = type.toLowerCase();
    let typeToAccessInRes = !existingId ? lowerCaseType : pluralize(lowerCaseType);
    let id;

    if (typeToAccessInRes === "audio" && existingId) {
      typeToAccessInRes = "audios";
    }

    if (operation === "Update") {
      id = response.data[`${lowerCaseType}${operation}`][typeToAccessInRes][0].id;
    } else {
      id = response.data[`${lowerCaseType}${operation}`][typeToAccessInRes].id;
    }

    if (existingId) {
      await uploadFile({ client, file, path, existingId, callbackUploadProgress });
    }

    callbackUploadProgress({ uploading: false, percent: 100 });

    return Promise.resolve({
      id,
      type,
      row: response.data[`${lowerCaseType}${operation}`][typeToAccessInRes],
    });

  } catch(error: any) {
    callbackUploadProgress({ uploading: false, error: true });
    return Promise.resolve(null);
  }
}
