import { QueryOptions } from 'apollo-client';
import { SearchableTypeEnum } from 'graphql/types/globalTypes';
import { SEARCH_MEDIA } from 'graphql/entry';
import { FetchFileArgs } from './types';

/**
 * Get the existing file database record.
 * @param {Args.client} client The Apollo Client, to make a query.
 * @param {Args.file} file The file object.
 * @param path The path of the existing file.
 * @param callbackUploadProgress a callback to update upload progress on uploader state.
 */
export async function fetchExistingFile({ client, path, callbackUploadProgress }: FetchFileArgs) {
  const url = `${encodeURI(path)}`;
  const options: QueryOptions = {
    query: SEARCH_MEDIA,
    fetchPolicy: 'network-only',
    variables: {
      page: 1,
      perPage: 1,
      filterStrings: [`url = ${url}`],
      terms: [],
      itemTypes: [SearchableTypeEnum.IMAGE, SearchableTypeEnum.AUDIO, SearchableTypeEnum.VIDEO, SearchableTypeEnum.FILE],
    },
  };

  try {
    const result = await client.query(options);

    if (result.data._searchStats.count > 0) {
      const item = result.data.search[0].item;
      return Promise.resolve(item);
    }

    return Promise.resolve(null);
  } catch(error: any) {
    callbackUploadProgress({ uploading: false, error: true });
    return Promise.resolve(null);
  }
}
