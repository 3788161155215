import styled from "styled-components";

export const IconWrapper = styled.div`
  font-size: 50px;
`;

export const ImageWrapper = styled.div`
  img {
    max-width: ${({maxWidth}) => maxWidth };
    max-height: ${({maxHeight}) => maxHeight };
  }
`;

export const ImageFile = styled.div`
  svg {
    position: absolute;
    top: 40%;
    left: 45%;
    font-size: 20px;

    path {
      fill: #fff;
    }
  }
`;

export const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 14px;
  background: #fff;
  padding: 0 5px 0 5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
`;
