import { mergeByKey } from './index';

/**
 * Selected taxonomies from the select only returns keys of the ID. The title is needed to be shown in the select list.
 * We map the keys to the titles from the taxonomy list in the dropdown.
 *
 * @export
 * @param {Array<{ key: string, title: string }>} selectedTags Existing selected tags.
 * @param {Array<string>} selectedKeys Newly selected taxonomies by just the key ID.
 * @param {*} taxonomies
 * @returns The merged list of selectedTags.
 */
export function mapKeysToTitles(keyName="title", selectedTags: Array<{ key: string, title: string}>, selectedKeys: Array<string>, taxonomies) {
  let withTitles = findNestedChildren({
    data: taxonomies,
    dictCompare: selectedKeys,
    matchBy: 'id',
    keyName,
    selectedTags
  });

  // removing duplicated tags found in children
  withTitles = withTitles.filter((item, index, self) => self.findIndex((oitem) => oitem.key === item.key) === index);

  // Handle searched and non-searched selected lists by merging them together.
  // Without this there are two problems; duplicates, and only having selected taxonomies show that match your search.
  const merged = mergeByKey('key', selectedTags, withTitles);

  return merged;
}

type NestedChildrenArgs = {
  data: any,
  dictCompare: Array<string>,
  matchBy: string,
  keyName: string,
  selectedTags?: Array<{ key: string, title: string, parentTitle?:string}>,
}

/**
 * Go through all of the taxonomies available in the list and find those that match the key, returning the title.
 *
 * @param {NestedChildrenArgs} { data, dictCompare, matchBy='id', keyName }
 * @param {NestedChildrenArgs.data} data The list of taxonomies
 * @param {NestedChildrenArgs.dictCompare} dictCompare The keys to find matches for.
 * @param {NestedChildrenArgs.matchBy} matchBy What field to match by. e.g. The ID is matched to "key".
 * @param {NestedChildrenArgs.keyName} keyName The data field to return once a match is found.
 * @returns The new object that includes the new keyName matching the matchBy value.
 */
function findNestedChildren({ data, dictCompare, matchBy='id', keyName, selectedTags }: NestedChildrenArgs) {
  if (typeof data === 'undefined') return [];

  const matches = [];

  // Merge multiple fields value to title when titleField contains more than one field
  const keyParts = keyName.split(",");

  data.map((item) => {
    const matchingDict = dictCompare.findIndex((k) => k === item[matchBy]);
    const keyArr = keyParts.map((field) => (item[field]));
    const containParentTitle = selectedTags?.find(tag => tag.title === keyArr[0]);

    if (matchingDict !== -1)
      matches.push({ key: item[matchBy], title: keyArr.join(" "), parentTitle: (containParentTitle?.parentTitle) ? containParentTitle.parentTitle : '', rest: item });

      matches.push(...findNestedChildren({
        data: item.children,
        dictCompare,
        matchBy,
        keyName,
      }));
  });

  return matches;
}
