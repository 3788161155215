import styled from 'styled-components';
import { Field } from "styles/forms";

export { Field };

export const KeywordSuggestWarpper = styled.div`
  .generate-button {
    margin-bottom: 20px;
  }
  .epub-react-select {
    border: 1px solid #E2E5E8;
  }
`;

export const AddButtonWrapper = styled.div`
  margin-top: 20px;
`;