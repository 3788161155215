import * as React from 'react'
import { Link } from 'react-router-dom';
import { FAL } from 'icons/fa';
import { IconName, SizeProp } from  '@fortawesome/fontawesome-svg-core'

import * as css from './styles';

interface ButtonProps {
  color?: string,
  icon?: IconName,
  iconColor?: string,
  children?: any,
  styles?: {},
  className?: string,
  outline?: boolean,
  onClick?: Function,
  onFocus?: Function,
  onBlur?: Function,
  to?: string,
  iconSize?: SizeProp,
  iconFontSize?: number,
  iconPadding?: string,
  loading?: boolean,
  disabled?: boolean,
  tabIndex?: number,
  refButton?: React.RefObject<HTMLButtonElement>,
  type?: "button" | "submit",
  title?: string,
  openNewTab?: boolean,
};

const CircleSVG = ({ loading = false }) => (
  <svg className={`circular-loader ${loading ? "-is-loading" : ""}`} viewBox="25 25 50 50" width="16" height="16">
    <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="4" />
  </svg>
);

/**
 * Create a Button Component.
 * @example
 * <Button icon="search" color="fiord" />
 */
const Button = ({
  color='chateauGreen',
  icon,
  iconColor='',
  children,
  styles={},
  className='',
  onClick=() => {},
  onFocus=() => {},
  onBlur=() => {},
  outline=false,
  to='',
  iconSize='1x',
  iconFontSize = 20,
  loading = false,
  disabled = false,
  tabIndex = 0,
  refButton,
  type="button",
  title="",
  openNewTab = false,
}: ButtonProps)  => {
  const hasProps = {
    hasIcon: !!icon,
    hasChildren: !!children,
  };

  const Child = () => (
    <css.Button
      color={color}
      {...hasProps}
      styles={styles}
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      outline={outline}
      iconColor={iconColor}
      loading={loading ? String(true) : undefined /* suppress console warning with undefined instead of false on styled-components */}
      disabled={disabled}
      tabIndex={tabIndex}
      ref={refButton}
      type={type}
      iconFontSize={iconFontSize}
      title={title}
    >
      <CircleSVG loading={loading} />
      {icon && <FAL icon={icon} size={iconSize} />}
      {children}
    </css.Button>
  );

  return (
    <React.Fragment>
      {to !== '' ? (
        <Link to={to} target={openNewTab && !disabled ? "_blank" : ''}><Child /></Link>
      ) : (
        <Child />
      )}
    </React.Fragment>
  );
};

export default Button;
