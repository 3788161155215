import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'articles',
    'classifieds',
    'events',
    'polls',
    'products',
    'relatedGalleries',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const GALLERY_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];

  return gql`
    query GalleryEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      gallery(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
          pick: related_Item.query,
          vars: 'page: $page, perPage: $perPage',
        })}
      }
    }`;
}

export const GALLERY_QUERY = gql`
  query GalleryQuery($perPage: Integer!, $page: Integer) {
    _galleryStats {
      count
    }

    galleries(perPage: $perPage, page: $page, sortString: "createdAt desc") {
      oid
      id
      live
      title
      createdAt
      fileUrl
    }
  }
`;

export const GALLERY_EDIT = gql`
  query GalleryEdit($oid: Integer!) {
    _galleryStats {
      count
    }

    gallery(oid: $oid) {
      id
      oid
      active
      title
      description
      searchable
      fileUrl
      keywords {
        id
        tag
      },
      topics {
        id
        title
        parent {
          id
          title
        }
      },
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      categories {
        id
        title
      }
      taxonomiesDescendedFrom(taxonomyId: "TOPIC_CATEGORY") {
        id
        title
      }
      _galleryItemStats {
        count
      }
      ${relatedContentFragments()}
    }
  }
`;

export const GALLERY_ITEMS_EDIT = gql`
  query GalleryItemsEdit ($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    gallery(oid: $oid) {
      id
      galleryItems (page: $page, perPage: $perPage) {
        id
        image {
          id
          oid
          url
          absoluteUrl
          height
          width
          fileSize
          altText
          remote
        }
      }
    }
  }
`;

export const GALLERIES_NEWSLETTER = gql`
  query GalleriesNewsletter ($perPage: Integer!, $taxonomies: [TaxonomyID]) {
    galleries(taxonomies: $taxonomies, perPage: $perPage, page: 1) {
      oid
      id
      title
      teaser: description
      fileUrl
      galleryItems (page: 1, perPage: 1) {
        id
        image {
          oid
          url
          absoluteUrl
        }
      }
    }
  }
`;

export const TAXONOMY_GALLERIES = gql`
  query TaxonomyGalleries ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _galleryStats {
        count
      }
      galleries (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const GALLERIES_MULTI = (ids: string[]) => {
  return gql`
    query GalleriesMulti {
      ${ids.map((id, index) => `
        gallery${index}: gallery(oid: ${id}) {
          id
          oid
          title
          teaser: description
          fileUrl
          galleryItems(perPage: 1) {
            image {
              url
              absoluteUrl
            }
          }
        }`).join('\n')}
    }
  `;
};

export const GALLERY_CREATE = gql`
  mutation GalleryCreate($input: GalleryCreateInput!) {
    galleryCreate (input: $input) {
      gallery {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const GALLERY_UPDATE = gql`
  mutation GalleryUpdate($input: GalleryUpdateInput!) {
    galleryUpdate (input: $input) {
      galleries {
        id
      }
      success
      errors
    }
  }
`;

export const GALLERY_SET_KEYWORDS = gql`
  mutation GallerySetKeywords($input: GallerySetKeywordsInput!) {
    gallerySetKeywords (input: $input) {
      success
      errors
    }
  }
`;

export const GALLERY_ITEM_DELETE = gql`
  mutation GalleryItemDelete($input: GalleryItemDeleteInput!) {
    galleryItemDelete (input: $input) {
      galleryItems {
        id
      }
      success
      errors
    }
  }
`;

export const GALLERY_ITEM_UPDATE = gql`
  mutation GalleryItemUpdate($input: GalleryItemUpdateInput!) {
    galleryItemUpdate(input: $input) {
      galleryItems {
        id
      }
      success
      errors
    }
  }
`;

export const GALLERY_ITEM_CREATE = gql`
  mutation GalleryItemCreate($input: GalleryItemCreateInput!) {
    galleryItemCreate(input: $input) {
      galleryItem {
        id
      }
      success
      errors
    }
  }
`;

export const GALLERY_ADD_TAXONOMIES = gql`
  mutation GalleryAddTaxonomies ($input: GalleryAddTaxonomiesInput!) {
    galleryAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const GALLERY_REMOVE_TAXONOMIES = gql`
  mutation GalleryRemoveTaxonomies ($input: GalleryRemoveTaxonomiesInput!) {
    galleryRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const GALLERY_DELETE = gql`
  mutation GalleryDelete ($input: GalleryDeleteInput!) {
    galleryDelete(input: $input)
    {
      success
      errors
      galleries { id }
    }
  }
`;