import gql from 'graphql-tag';

/**
 * A fragment for Taxonomy Children, which can except
 * nested attributes.
 * @param {Function} nested A function that returns a Graphql string.
 */
const fragmentTaxChildren = (nested?: Function) => {
  const next = () => {
    return (typeof nested === 'function') ? nested() : '';
  };

  return `
    children {
      oid
      id
      name
      title
      parent {
        id
      }
      children{
        id
        oid
        title
      }
      ${next()}
    }
  `;
};

/**
 * A fragment for Taxonomy Children, which has taxonomy attributes and counter of children
 */
const fragmentTaxChildrenWithStats = () => {
  return `
    children (perPage:$perPage,page:$page){
      oid
      id
      parent {
        id
      }
      _childStats{
        count
      }
      active
      selectable
      title
      name
      description
      sortingOrder
      legacyId
    }
  `;
};

const fragTaxBody = `
  oid
  id
  parent {
    id
  }
  _childStats{
    count
  }
  active
  selectable
  title
  name
  description
  sortingOrder
  legacyId
  uiStyle
  image {
    id
    url
    absoluteUrl
    oid
    height
    width
    fileSize
    altText
  }
  meta {
    id
    browserTitle
    description
    keywords
    slug
  }
`;

const fragTaxBodyParents = `
  parent {
    id
    name
    parent{
      id
      name
      parent {
        id
        name
      }
    }
  }
`;

const frgTaxNavBody = `
  id
  name
  title
  allowSelect: selectable
  active
  _childStats{
    count
  }
  parent{
    id
  }
`;

export const taxonomyQuery = gql`
  query TaxonomyQuery {
    _taxonomyStats {
      count
    }

    taxonomies {
      id
      oid
      title
      live
    }
  }
`;

// TODO: DO NOT HARDCODE TOPICS OID. It varies, use internal_name instead.
export const TAXONOMY_NAV_QUERY = gql`
  query TaxonomyNavQuery($perPage:Integer,$page:Integer) {
    categories: taxonomy(oid: 1) {
      ${fragTaxBody}
      ${fragmentTaxChildrenWithStats()}
    }

    sitePlacement: taxonomy(oid: 2) {
      ${fragTaxBody}
    }

    classification: taxonomy(oid: 3) {
      ${fragTaxBody}
    }

    topics: taxonomy(name: "TOPICS") {
      ${fragTaxBody}
    }
  }
`;

export const TAXONOMY_BY_ID = gql`
  query TaxonomyById($id: IDType!) {
    taxonomy(id: $id) {
      ${fragTaxBody}
      ${fragTaxBodyParents}
      ${fragmentTaxChildren(fragmentTaxChildren)}
    }
  }
`;

export const TAXONOMY_BY_ID_NAME = gql`
  query TaxonomyByIdName($id: IDType!) {
    taxonomy(id: $id) {
      id
      title
      parent {
        id
        title
      }
    }
  }
`;

export const TAXONOMY_BY_NAME = gql`
  query TaxonomyById($name: Str!) {
    taxonomy(name: $name) {
      ${fragTaxBody}
      ${fragmentTaxChildren(fragmentTaxChildren)}
    }
  }
`;

export const taxonomiesListQuery = gql`
  query taxonomiesListQuery {
    managers @client {
      taxonomies {
        draggableList {
          holder {
            id
            title
            oid
            name
          }
          list {
            id
            title
            oid
            selectable
            active
            _childStats{
              count
            }
            children{
              id
              oid
              title
              name
              selectable
              active
              _childStats{
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const TAXONOMY_BY_OID_LAZY_LOAD = gql`
  query taxonomyByOrderId($oid: Integer,$perPage: Integer,$page: Integer) {
    taxonomy(oid: $oid) {
      ${fragTaxBody}
      ${fragmentTaxChildrenWithStats()}
    }
  }
`;

export const TAXONOMY_ID_BY_NAME = gql`
  query TaxonomyIdByName($name: Str!) {
    taxonomy(name: $name) {
      id
    }
  }
`;

export const TAXONOMY_SELECT_LIST = gql`
  query TaxonomySelectList($id: IDType!, $page: Integer, $perPage: Integer) {
    taxonomy(id: $id) {
      id
      _childStats{
        count
      }
      children(page: $page, perPage: $perPage) {
        id
        name
        title
        active
        allowSelect:selectable
        _childStats{
          count
        }
        parent{
          id
        }
      }
    }
  }
`;

export const SITE_PLACEMENT_SELECT_LIST = gql`
  query SitePlacementSelectList ($oid:  Integer!) {
    taxonomy(oid: $oid) {
      oid
      id
      title
      description
        children {
          oid
          id
          description,
          title
          children {
            oid
          }
        }
      }
    }
`;

export const ADD_TO_LIST = gql`
  mutation addToList($list: [TaxonomyInput]!) {
    addToList(list: $list) @client {
      list { id }
    }
  }
`;

export const taxonomiesListMutation = gql`
  mutation reorderList($list: [TaxonomyInput]!) {
    reorderList(list: $list) @client {
      list { id }
    }
  }
`;

export const TAXONOMY_EDIT_MUTATION = gql`
  mutation TaxonomyUpdate ($input: TaxonomyUpdateInput!) {
    taxonomyUpdate(input: $input)
    {
      taxonomies {
        id
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_MUTATION = gql`
  mutation TaxonomyCreate ($input: TaxonomyCreateInput!) {
    taxonomyCreate(input: $input)
    {
      taxonomy {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const TAXONOMY_DELETE_MUTATION = gql`
  mutation TaxonomyDelete ($input: TaxonomyDeleteInput!) {
    taxonomyDelete(input: $input)
    {
      taxonomies { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_FILTER_QUERY = gql`
  query filterTaxonomyQuery ($filterString:[Str!],$perPage:Integer!,$page:Integer!){
    taxonomies(filterString: $filterString,perPage:$perPage,page:$page){
      oid
      id
      title
      name
      active
      description
      parent{
        id
        title
      }
      _childStats{
        count
      }
      children {
        oid
        id
        title
        name
        active
        description
        _childStats{
          count
        }
      }
    }
  }
`;

export const SITE_PLACEMENT_FILTER_QUERY = gql`
  query filterSitePlacementQuery ($oid:  Integer!,$filterString:[Str!]){
    taxonomy(oid: $oid) {
      oid
      id
      title
      description
      children:descendants(filterString:$filterString) {
        oid
        id
        title
        description
      }
    }
  }
`;

export const FILTER_SEARCH_QUERY = gql`
  query filterSearchQuery ($id: IDType, $filterString:[Str!], $perPage:Integer!, $page:Integer!){
    taxonomy(id: $id) {
      id
      active
      selectable
      children:descendants(filterString:$filterString,perPage:$perPage,page:$page) {
        oid
        id
        title
        active
        selectable
        parent {
          id
          title
        }
      }
      _childStats:_descendantStats(filterString:$filterString) {
        count
      }
    }
  }
`;

export const TAXONOMY_ADD_CLASSIFIEDS = gql`
  mutation TaxonomyAddClassifieds ($input: TaxonomyAddClassifiedsInput!) {
    taxonomyAddClassifieds(input: $input) {
      classifieds { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_CLASSIFIEDS = gql`
  mutation TaxonomyRemoveClassifieds ($input: TaxonomyRemoveClassifiedsInput!) {
    taxonomyRemoveClassifieds(input: $input) {
      classifieds { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_USERS = gql`
  mutation TaxonomyAddUsers ($input: TaxonomyAddUsersInput!) {
    taxonomyAddUsers(input: $input) {
      users { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_USERS = gql`
  mutation TaxonomyRemoveUsers ($input: TaxonomyRemoveUsersInput!) {
    taxonomyRemoveUsers(input: $input) {
      users { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_COMPANIES = gql`
  mutation TaxonomyAddCompanies ($input: TaxonomyAddCompaniesInput!) {
    taxonomyAddCompanies(input: $input) {
      companies { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_COMPANIES = gql`
  mutation TaxonomyRemoveCompanies ($input: TaxonomyRemoveCompaniesInput!) {
    taxonomyRemoveCompanies(input: $input) {
      companies { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_EVENTS = gql`
  mutation TaxonomyAddEvents ($input: TaxonomyAddEventsInput!) {
    taxonomyAddEvents(input: $input) {
      events { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_EVENTS = gql`
  mutation TaxonomyRemoveEvents ($input: TaxonomyRemoveEventsInput!) {
    taxonomyRemoveEvents(input: $input) {
      events { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_PRODUCTS = gql`
  mutation TaxonomyAddProducts ($input: TaxonomyAddProductsInput!) {
    taxonomyAddProducts(input: $input) {
      products { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_PRODUCTS = gql`
  mutation TaxonomyRemoveProducts ($input: TaxonomyRemoveProductsInput!) {
    taxonomyRemoveProducts(input: $input) {
      products { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_REMOVE_ARTICLES = gql`
  mutation TaxonomyRemoveArticles ($input: TaxonomyRemoveArticlesInput!) {
    taxonomyRemoveArticles(input: $input) {
      articles { id }
      taxonomy { id }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_ARTICLES = gql`
  mutation TaxonomyAddArticles ($input: TaxonomyAddArticlesInput!) {
    taxonomyAddArticles(input: $input) {
      articles { id }
      taxonomy { id }
      success
      errors
    }
  }
`;


export const TAXONOMY_CATEGORIES_NAV_QUERY = (cats) => {
  if (!cats.length) return null;

  const aliases = cats.map((scat, index) => {

    const scat_id = scat.oid.toString().replace('-', '_'); // scaffolding content ids are negative. this turns the name into category_-1 which is invalid and breaks gql

    return `category_${scat_id}: taxonomy(id: "${scat.id}"){ ${frgTaxNavBody} }`;
  });

  return gql`query TaxonomyCategoriesNavQuery{ ${aliases.join(" ")} }`;
};

export const TAXONOMY_HIERARCHY = gql`query TaxonomyHierarchy($id: IDType!) {
  taxonomy(id: $id) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
      }
    }
  }
}`;

export const TAXONOMY_BY_INTERNAL_NAME = gql`
  query TaxonomyByInternalName($name: Str!) {
    taxonomy(name: $name) {
      id
      title
      parent {
        id
        title
      }
    }
  }
`;

export const TAXONOMY_BY_NAME_WITH_CHILDREN = gql`
  query TaxonomyByNameWithChildren($name: Str!) {
    taxonomy(name: $name) {
      oid
      id
      active
      title
      name
      selectable
      description
      children {
        oid
        id
        active
        selectable
        title
        name
        description
        sortingOrder
        __typename
      }
      __typename
    }
  }
`;