import { format } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { toDate, format as formatWithTz } from 'date-fns-tz';

/**
 * convert given date-time to given format
 *
 * @param {string} format ex: 'MM/dd/yy HH:mm:ss'
 * @param {any} dateTime it can be date/time/dateTime
 * @return {string} formatted string
 */
export function formatDateTime(formatString = 'MM/dd/yyyy HH:mm:ss', dateTime = new Date()) {
  return format(dateTime, formatString);
}

/**
 * Remove timezone offset, pass that datetime to format function
 * Extract date from datetime
 * @param {string} text
 * @returns {string} date
 */
export const dateFormater = (text: string, format: string = "MM-dd-yyyy", keepTime: boolean = false) => {
  const newText = (keepTime) ? text.replace(/T/, ' ').replace(/.000.*/,'') : text.replace(/-/g, '\/').replace(/T.+/, '')
  return formatDateTime(format, new Date(newText));
};

/**
 * Get formatted date time based on given timezone
 * @param {string | Date} datetime
 * @param {string} formatString
 * @param {string} timeZone
 */
export function formatDateTimeInGivenTimeZone(datetime: string | Date, formatString = "yyyy-MM-dd'T'HH:mm:ssXXX", timeZone = "America/New_York") {
  const datObj = toDate(datetime, { timeZone: timeZone });
  return formatWithTz(datObj, formatString, { timeZone });
}

/**
 * get splitted values of date object
 * @param {Date} dateTime
 */
export function getSplittedDateTimeValues(dateTime: Date) {
  let hours: number | string = dateTime.getHours();
  hours = hours <= 9 ? `0${hours}` : `${hours}`;
  let minutes: number | string = dateTime.getMinutes();
  minutes = minutes <= 9 ? `0${minutes}` : `${minutes}`;
  let seconds: number | string = dateTime.getSeconds();
  seconds = seconds <= 9 ? `0${seconds}` : `${seconds}`;
  const year: string = dateTime.getFullYear().toString();
  let month: number | string = dateTime.getMonth();
  month = month + 1 <= 9 ? `0${month + 1}` : `${month + 1}`;
  let day: number | string = dateTime.getDate();
  day = day <= 9 ? `0${day}` : `${day}`;

  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
  };
}

/**
 * Get timezone offset from seconds
 * @param {number} seconds
 */
export function getOffsetFromSeconds(seconds: number) {
  const time = seconds / 60;
  const hour = Math.floor(time / 60);
  const min = Math.floor(Math.abs(time % 60));
  let hrStr = '';
  if (hour > 0 && hour < 10) {
    hrStr = `+0${String(hour).substring(0, 1)}`;
  } else if (hour >= 10) {
    hrStr = `+${String(hour)}`;
  } else if (hour < 0 && hour > -10) {
    hrStr = `-0${String(hour).substring(1)}`;
  } else if (hour === 0) {
    hrStr = "+00";
  } else {
    hrStr = String(hour);
  }
  let minStr = String(min).substring(0, 2);
  if (min < 10) {
    minStr = `0${String(min).substring(1)}`;
  }

  if (minStr === "0") {
    minStr = "00";
  }
  return `${hrStr}:${minStr}`;
}

/**
 * Get current date with EST timezone
 */
export function getCurrentDate() {
  const newDate = new Date();
  const newDateFormatted = format(newDate, "yyyy-MM-dd");
  const newDateFormattedTime = format(newDate, "HH:mm:ss");
  const timeZoneOffset = formatTz(new Date(newDateFormatted), 'XXX', { timeZone: 'America/New_York' });
  return `${newDateFormatted}T${newDateFormattedTime}${timeZoneOffset}`;
}

/**
 * Get EST timezone's offset
 */
export function getESTOffset() {
  return formatTz(new Date(), 'XXX', { timeZone: 'America/New_York' });
}

/**
 * Handle change event of video/audio duration field
 * Format date value to hh:mm:ss
 *
 * @param {string} formValue contains form duration field value
 * @param {string} value contains selected date value ex: 'MM/dd/yy HH:mm:ss'
 * @param {string} key contains name of field
 * @param {function} onDateUpdate update value in form data
 */
export function handleDurationFormatChange(formValue: Number, value: string | Date, key: string, onDurationUpdate: Function) {

  if (!value) {
    onDurationUpdate(key, null);
    return;
  }

  const { day, month, year, hours, minutes, seconds } = getSplittedDateTimeValues(new Date(value));
  const newTime = `${hours}:${minutes}:${seconds}`;
  onDurationUpdate(key, newTime);
}

/**
 * get seconds based on given time
 *
 * @param {string} value contains duration(HH:mm:ss) of video/audio type
 */
export function convertTimeToSeconds(value) {
  if (value) {
    const hms = value.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const seconds = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2]);

    return seconds;
  } else {
    return 0;
  }
}

/**
* Format given seconds to hh:mm:ss
*
* @param {string} value contains seconds of video/audio type
*/
export function convertSecondsToTime(value) {

  value = Number(value);

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor(value % 3600 / 60);
  const seconds = Math.floor(value % 3600 % 60);

  const hDisplay = hours > 0 ? hours : 0;
  const mDisplay = minutes > 0 ? minutes : 0;
  const sDisplay = seconds > 0 ? seconds : 0;

  const todayDate = new Date();
  const fullDateTime = todayDate.setHours(hDisplay, mDisplay, sDisplay);

  return fullDateTime;
}

/**
* get past date from the provided days
*
* @param {string} days number of days
*/
export function getPastDate(days) {
  let date = new Date();
  date.setDate(date.getDate() - Number(days));
  const newDateFormatted = format(date, "yyyy-MM-dd");

  return newDateFormatted;
}