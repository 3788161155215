import { useState, useCallback, useRef } from "react";

/**
 * Tracks the hover of any element, by using a ref of that element. Works with nested
 * hovered elements, by removing the event listeners if the current ref does not match what is hovered.
 * https://usehooks.com/useHover/
 */
export default function useHover() {
  const [value, setValue] = useState(false);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback((e) => {
    e.stopPropagation();
    setValue(true)
  }, []);
  const handleMouseOut = useCallback((e) => {
    setValue(false)
  }, []);

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref = useRef(null);

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    node => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
      }

      ref.current = node;

      if (ref.current) {
        ref.current.addEventListener("mouseover", handleMouseOver);
        ref.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, value];
}
