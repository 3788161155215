import styled, { css } from 'styled-components';
import Colors from 'styles/colors';
import { breakpoint } from 'styles/mixins';

export const Form = styled.form``;

export const Page = styled.div`
  background: rgb(79,109,136);
  background: linear-gradient(153deg, rgba(79,109,136,1) 0%, rgba(39,59,80,1) 100%);
  display: flex;
  align-items: center;

  ${({isShowInModal}) => !isShowInModal && css`
    height: 100vh;

    &:before {
      background-color: rgba(39, 59, 80, 0.9);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  `};
`;

export const Container = styled.div`
  max-width: ${({isShowInModal}) => isShowInModal ? '400px' : '881px'};
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  box-shadow: 0 0 50px rgba(0,0,0,0.2);

  ${breakpoint(0, 767)(css`
    flex-direction: column;
    margin: 20px;
  `)}
`;

export const RightPanel = styled.div`
  background: rgb(80,109,136);
  background: linear-gradient(144deg, rgba(80,109,136) 0%, rgba(44,68,93,1) 100%);
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
`;

export const GraphicContainer = styled.div`
  text-align: right;
  margin-right: 20px;
  margin-left: auto;
  display: flex;

  svg {
    margin-top: auto;
    width: 100%;
    max-width: 462px;
    height: auto;

    #Oval { opacity: 0 }
  }
`;

export const Edge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  ${breakpoint(0, 767)(css`
    display: none;
  `)}
`;

export const FormField = styled.div`
  margin-bottom: 20px;

  .epub-toggle-wrapper {
    align-items: flex-start;
  }
`;

export const FormWrapper = styled.div`
  background-color: ${Colors.whiteSmoke};
  padding: ${({isShowInModal}) => isShowInModal ? '20px' : '80px 30px'};

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border-color: inherit;
    box-shadow: 0 0 0 30px white inset, 0 1px 3px rgba(0,0,0,0.05) !important;
    -webkit-text-fill-color: #222;
    font-size: 16px;
  }

  ${breakpoint(0, 767)(css`
    padding: 30px;
  `)}

  ${(props) => props.loading === "true" && css`
    button { padding-left: 20px; }

    .circular-loader {
      opacity: 1;
      position: absolute;
      left: 12px;
    }

    button svg.fa-sign-in { opacity: 0 }
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loader = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${Colors.chateauGreen};
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin: auto;

  .svg-inline--fa {
    margin: auto;
    animation: fa-spin 0.5s infinite linear;
    width: 40px;
    height: 40px;

    path {
      fill: #fff;
    }
  }
`;

export const FormError = styled.div`
  background-color: ${Colors.sunGlow};
  color: #fff;
  padding: 10px 20px;
  margin-top: 10px;
`;

export const Message = styled.div`
  color: #f00;
`;