import gql from 'graphql-tag';

const fragTaxBody = `
    id
    customFieldTypes(owner: $owner, page: $page, perPage: $perPage) {
      id
      oid
      name
      displayName
      displayMessage
      inputType
      valueType
      defaultValue
      active
      required
      description
      failureMessage
      inputLength
      maximumValues
      ownerType
      options {
        oid
        id
        label
        value
        live
        default
      }
      validationPattern
      classifications {
        id
        title
      }
    }
`;

export const CUSTOM_FIELDS_CLASSIFICATIONS_QUERY = gql`
  query CustomFieldsClassificationsQuery($perPage:Integer, $page:Integer) {
    directories: taxonomy(name: "DIRECTORY_LISTING_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }

    events: taxonomy(name: "EVENT_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }

    products: taxonomy(name: "PRODUCT_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }

    classifieds: taxonomy(name: "CLASSIFIED_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }

    users: taxonomy(name: "USER_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }

    articles: taxonomy(name: "ARTICLE_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }
  }
`;

export const USER_CUSTOM_FIELDS_CLASSIFICATIONS_QUERY = gql`
query UserustomFieldsClassificationsQuery($perPage:Integer, $page:Integer) {
    users: taxonomy(name: "USER_CUSTOM_FIELDS_ROOT") {
      id
      children(perPage: $perPage, page:$page) {
        id
        title
      }
    }
  }
`;

export const CUSTOM_FIELD_TYPES_LIST = gql`
  query customFieldTypesList($owner: CustomizableEnum!) {
    customFieldTypes(owner: $owner) {
      oid
      name
    }
  }
`;

export const CUSTOM_FIELD_TYPES_QUERY = gql`
  query CustomFieldTypesQuery($owner: CustomizableEnum!, $taxonomy: IDType!) {
    taxonomy(id: $taxonomy) {
      id
      customFieldTypes(owner: $owner) {
        id
        oid
        name
        displayName
        displayMessage
        inputType
        valueType
        defaultValue
        active
        required
        description
        failureMessage
        inputLength
        maximumValues
        ownerType
        options {
          oid
          id
          label
          value
          live
          default
        }
        validationPattern
        classifications {
          id
          title
        }
      }
    }
  }
`;

export const CUSTOM_FIELD_TYPES = gql`
  query CustomFieldTypes($owner: CustomizableEnum!, $taxonomies: [TaxonomyID]) {
    customFieldTypes(owner: $owner, taxonomies: $taxonomies) {
      id
      oid
      name
      displayName
      displayMessage
      inputType
      valueType
      defaultValue
      active
      required
      description
      failureMessage
      inputLength
      maximumValues
      ownerType
      options {
        oid
        id
        label
        value
        live
        default
      }
      validationPattern
      classifications {
        id
        title
      }
    }
  }
`;

export const CUSTOM_FIELD_UPDATE = gql`
  mutation CustomFieldUpdate($input: CustomFieldUpdateInput!) {
    customFieldUpdate(input: $input) {
      customFields { id }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_CREATE = gql`
  mutation CustomFieldCreate($input: CustomFieldCreateInput!) {
    customFieldCreate(input: $input) {
      customField { id }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_DELETE = gql`
  mutation CustomFieldDelete($input: CustomFieldDeleteInput!) {
    customFieldDelete(input: $input) {
      customFields { id }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_UPDATE = gql`
  mutation CustomFieldTypeUpdate($input: CustomFieldTypeUpdateInput!) {
    customFieldTypeUpdate(input: $input) {
      customFieldTypes {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_CREATE = gql`
  mutation CustomFieldTypeCreate($input: CustomFieldTypeCreateInput!) {
    customFieldTypeCreate(input: $input) {
      customFieldType {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_DELETE = gql`
  mutation CustomFieldTypeDelete($input: CustomFieldTypeDeleteInput!) {
    customFieldTypeDelete(input: $input) {
      customFieldTypes {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_OPTION_CREATE = gql`
  mutation CustomFieldTypeOptionCreate($input: CustomFieldOptionCreateInput!) {
    customFieldOptionCreate(input: $input) {
      customFieldOption {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_OPTION_UPDATE = gql`
  mutation CustomFieldTypeOptionUpdate($input: CustomFieldOptionUpdateInput!) {
    customFieldOptionUpdate(input: $input) {
      customFieldOptions {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_OPTION_DELETE = gql`
  mutation CustomFieldTypeOptionDelete($input: CustomFieldOptionDeleteInput!) {
    customFieldOptionDelete(input: $input) {
      customFieldOptions {
        id
      }
      success
      errors
    }
  }
`;

export const CUSTOM_FIELD_TYPE_SET_TAXONOMIES = gql`
  mutation CustomFieldTypeSetTaxonomies($input: CustomFieldTypeSetTaxonomiesInput!) {
    customFieldTypeSetTaxonomies(input: $input) {
      customFieldType {
        id
      }
      success
      errors
    }
  }
`;

export const TAXONOMY_ADD_CUSTOM_FIELD_TYPES = gql`
  mutation TaxonomyAddCustomFieldTypes($input: TaxonomyAddCustomFieldTypesInput!) {
    taxonomyAddCustomFieldTypes(input: $input) {
      success
      errors
    }
 }
`;

export const CUSTOM_FIELD_TAXONOMY_QUERY = (ids: Array<string>) => {
  if (!ids.length) return null;

  const aliases = ids.map((id, index) => {
    return `taxonomy${index}: taxonomy(id: "${id}"){ ${fragTaxBody} }`;
  });

  return gql`query CustomFieldTaxonomyQuery($owner: CustomizableEnum!, $page: Integer!, $perPage: Integer!){ ${aliases.join(" ")} }`;
}

export const CUSTOM_FIELD_TYPES_STATS_QUERY = gql`
  query CustomFieldTypesStatsQuery($owner: CustomizableEnum!, $taxonomies: [TaxonomyID]) {
    _customFieldTypeStats(owner: $owner, taxonomies: $taxonomies){
        count
        exists
    }
  }
`;

export const CUSTOM_FIELD_TYPES_TAXONOMY_LIST_QUERY = gql`
  query CustomFieldTypesDetails($owner: CustomizableEnum!, $taxonomies: [TaxonomyID], $page: Integer!, $perPage: Integer!) {
    customFieldTypes(owner: $owner, taxonomies: $taxonomies, page: $page, perPage: $perPage) {
      id
      oid
      name
      displayName
      displayMessage
      inputType
      valueType
      defaultValue
      active
      required
      description
      failureMessage
      inputLength
      maximumValues
      ownerType
      options {
        oid
        id
        label
        value
        live
        default
        __typename
      }
      validationPattern
      classifications {
        id
        title
        __typename
      }
      __typename
    }
    __typename
  }
`;
