import {
  fetchExistingFile,
} from './fetchExistingFile';
import {
  saveFileRecord,
} from './saveFileRecord';
import {
  uploadFile,
} from './uploadFile';
import {
  RelaxedUploadFile as RelaxedUploadFileType,
  UploadTick as UploadTickType,
  FileArgs as FileArgsType,
  FetchFileArgs as FetchFileArgsType,
} from './types';
import {
  fileToObject,
  getBase64,
  getImageDimensions,
  uploadHeaders,
  imageUrlSizeUpdate,
  isRemoteUrl,
  getFileNameFromUrl,
  getReplaceFileName,
  extractFilePath,
  isValidFile,
} from './helpers';

export type FileArgs = FileArgsType;
export type UploadTick = UploadTickType;
export type RelaxedUploadFile = RelaxedUploadFileType;
export type FetchFileArgs = FetchFileArgsType;

export {
  fetchExistingFile,
  saveFileRecord,
  uploadFile,
  fileToObject,
  getBase64,
  getImageDimensions,
  uploadHeaders,
  imageUrlSizeUpdate,
  isRemoteUrl,
  getFileNameFromUrl,
  getReplaceFileName,
  extractFilePath,
  isValidFile,
};
