import gql from 'graphql-tag';

export const SHIPPING_ZONE_NAV = gql`
  query ShippingZoneNav ($page: Integer!, $perPage: Integer!) {
    shippingZones (page: $page, perPage: $perPage) {
      id
      oid
      externalId
      country
    }
  }
`;

export const SHIPPING_ZONE_STATS = gql`
  query ShippingZoneStats {
    _shippingZoneStats {
      count
    }
  }
`;

export const SHIPPING_ZONE_ADD = gql`
  mutation ShippingZoneAdd ($input: ShippingZoneCreateInput!) {
    shippingZoneCreate (input: $input) {
      shippingZone {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const SHIPPING_ZONE_UPDATE = gql`
  mutation ShippingZoneUpdate ($input: ShippingZoneUpdateInput!) {
    shippingZoneUpdate (input: $input)
    {
      shippingZones { id }
      success
      errors
    }
  }
`;

export const SHIPPING_ZONE_DELETE = gql`
  mutation ShippingZoneDelete ($input: ShippingZoneDeleteInput!) {
    shippingZoneDelete (input: $input)
    {
      shippingZones {
        id
      }
      success
      errors
    }
  }
`;

export const SHIPPING_ZONE_EDIT = gql`
  query ShippingZoneEdit ($oid: Integer!) {
    shippingZone (oid: $oid) {
      id
      oid
      externalId
      externalGroundId
      country
      _postalRangeStats {
        count
      }      
    }
  }
`;

export const SHIPPING_ZONE_POSTAL_RANGE_EDIT = gql`
  query ShippingZonePostalRangeEdit ($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    shippingZone (oid: $oid) {
      id
      postalRanges (page: $page, perPage: $perPage, sortString: ["oid asc"]) {
        id
        begin
        end
      }
    }
  }
`;

export const SHIPPING_ZONE_POSTAL_RANGE_ADD = gql`
  mutation ShippingZonePostalRangeAdd ($input: PostalRangeCreateInput!) {
    postalRangeCreate (input: $input) {
      postalRange {
        id
      }
      success
      errors
    }
  }
`;

export const SHIPPING_ZONE_POSTAL_RANGE_UPDATE = gql`
  mutation ShippingZonePostalRangeUpdate ($input: PostalRangeUpdateInput!) {
    postalRangeUpdate (input: $input) {
      postalRanges {
        id
      }
      success
      errors
    }
  }
`;

export const SHIPPING_ZONE_POSTAL_RANGE_DELETE = gql`
  mutation ShippingZonePostalRangeDelete ($input: PostalRangeDeleteInput!) {
    postalRangeDelete (input: $input) {
      postalRanges {
        id
      }
      success
      errors
    }
  }
`;