import gql from 'graphql-tag';

export const ROLES = gql`
  query Roles($page: Integer!, $perPage: Integer!) {
    roles(page: $page, perPage: $perPage) {
      id
      oid
      displayName
      name
      privileges {
        id
      }
    }
  }
`;

export const ROLE_CREATE = gql`
  mutation RoleCreate($input: RoleCreateInput!) {
    roleCreate(input: $input) {
      role {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const ROLE_UPDATE = gql`
  mutation RoleUpdate($input: RoleUpdateInput!) {
    roleUpdate(input: $input) {
      roles {
        id
      }
      success
      errors
    }
  }
`;

export const ROLE_ADD_PRIVILEGES = gql`
  mutation RoleAddPrivileges($input: RoleAddPrivilegesInput!) {
    roleAddPrivileges(input: $input) {
      privileges {
        id
      }
      success
      errors
    }
  }
`;

export const ROLE_REMOVE_PRIVILEGES = gql`
  mutation RoleRemovePrivileges($input: RoleRemovePrivilegesInput!) {
    roleRemovePrivileges(input: $input) {
      privileges {
        id
      }
      success
      errors
    }
  }
`;

export const ROLE_STATS = gql`
  query RoleStats {
    _roleStats {
      count
    }
  }
`;

export const ROLE_USERS = gql`
  query RoleUsers($oid: Integer!, $page: Integer!, $perPage: Integer!, $filterString: [Str]) {
    role(oid: $oid) {
      id
      _userStats {
        count
      }
      users(page: $page, perPage: $perPage, filterString: $filterString) {
        id
        firstName
        lastName
        email
      }
    }
  }
`