import styled from 'styled-components';
import Colors from 'styles/colors';

export const Container = styled.div`
  display: block;
  font-family: 'Lato', sans-serif;
  max-width: ${(props) => props.width ? props.width : "100%"};
  width: 100%;

  &.-is-hidden {
    display: none;
  }

  &.-has-placeholder {
    textarea {
      border:none;
      margin-top:25px;
      width:100%;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #fff;
  display: inline-block;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  position: relative;
  max-width: ${(props) => props.width ? props.width : "330px"};
  width: 100%;

  .-dark-theme & {
    background-color: ${Colors.whiteSmoke};
    box-shadow: none;
    border-bottom: 1px solid ${Colors.mercury};
  }

  .-has-error & {
    background-color: ${Colors.roseWhite};
    border-bottom: 1px solid ${Colors.sunGlow};
  }

  .-is-disabled & {
    background-color: ${Colors.mercury};
    box-shadow: none;
    border-bottom: 1px solid ${Colors.zumthor};
    cursor: not-allowed;
  }

  input {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 18px 20px 18px 10px;
    width: 100%;
    position: relative;
    font-size: 16px;

    .-has-placeholder & {
      padding: 23px 20px 5px 10px;
    }

    .-is-disabled & {
      cursor: not-allowed;
    }

    &::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
    .cost-input & {
      width: auto;
    }
  }

  .user-state & {
    max-width:100%;
  }

  &.counter{
    &:before {
      content: attr(data-counter);
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: sans-serif;
      color: #999999;
      font-size: 13px;
      padding-right: 5px;
      z-index: 99;
    }
    input {
      padding: 23px 20px 25px 10px;
    }
  }
`;

export const Placeholder = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: ${Colors.cadetBlue};
  transition: all 0.3s ease-in-out;
  font-size: 16px;

  .-is-textarea & {
    transform: translateY(-119%);
  }

  .-is-focused & {
    top: 5px;
    transform: translateY(0);
    font-size: 12px;
    font-weight: bold;
    color: #222;
  }

  .-has-error & {
    color: ${Colors.sunGlow};
  }

  .svg-inline--fa {
    margin-right: 10px;
  }
`;

export const Hint = styled.span`
  color: ${Colors.cadetBlue};
  font-size: 12px;
  display: block;
  margin-top: 2px;
`;

export const ErrorMsg = styled.span`
  color: ${Colors.sunGlow};
  margin-top: 3px;
  font-size: 12px;
  display: block;
`;

export const Optional = styled.div`
  color: #B2BCC6;
  position: absolute;
  right: 0;
  font-size: 12px;
  padding: 4px;
`;