import React from 'react';
import { Tag, Tooltip } from 'antd';
import { TreeDraggableProvided } from 'react-beautiful-dnd';
import { DragDropContextWrapper, DroppableWrapper } from "components/dnd-wrappers";
import { DraggableWrapper } from "components/dnd-wrappers";
import { FAL } from "icons/fa";
import * as css from './style';

type Result = {
  source: {
    index: string | number,
  },
  destination: {
    index: string | number,
  }
}

type TagCommonProps = {
  onCloseTag: (e: React.MouseEvent<unknown>, value: string) => void,
  selectedKeys: Array<string>,
  isDragDisabled?: boolean,
}

type Item = {
  key: string,
  title: string,
  parentTitle?: string,
}

type TagProps = TagCommonProps & {
  provided?: TreeDraggableProvided,
  index: number,
  item: Item,
}

type Props = TagCommonProps & {
  selectedTags: Array<Item>,
  allowMultipleSelection: boolean,
  onDragEnd: (result: Result) => void,
}

const TagWrapper = ({ provided, onCloseTag, index, item, selectedKeys, isDragDisabled }: TagProps) => (
  <Tooltip title={item.parentTitle} key={`hover-tag-${index}`} overlayClassName="epub-tooltip-hover">
    <Tag
      closable
      onClose={(e) => onCloseTag(e, selectedKeys[index])}
      key={`tag-${selectedKeys[index]}`}
      className="epub-tag-grid"
      tabIndex={-1}
    >
      { !isDragDisabled &&
        <css.DragDropButton>
          <div {...provided.dragHandleProps} tabIndex={-1}>
            <FAL icon="bars" />
          </div>
        </css.DragDropButton>
      }
      <css.TagLabel isDragDiabled={isDragDisabled}>
        {item.title}
      </css.TagLabel>
    </Tag>
  </Tooltip>
)

export default ({ selectedTags, selectedKeys, allowMultipleSelection, onCloseTag, onDragEnd, isDragDisabled }: Props) => {
  if (!selectedTags.length || !selectedKeys.length || !allowMultipleSelection) return null;

  return (<DragDropContextWrapper onDragEnd={(result) => onDragEnd(result)}>
    <DroppableWrapper droppableId="tags">
      {selectedTags.map((item, index) => (
        <css.TagContainer key={index} isDragDisabled={isDragDisabled}>
          <DraggableWrapper
            key={`select-tag-${index}`}
            draggableId={`select-tag-${index}`}
            index={index}
            isDragDisabled={isDragDisabled}
          >
            <TagWrapper
              index={index}
              onCloseTag={onCloseTag}
              selectedKeys={selectedKeys}
              item={item}
              isDragDisabled={isDragDisabled}
            ></TagWrapper>
          </DraggableWrapper>
        </css.TagContainer>
      ))}
    </DroppableWrapper>
  </DragDropContextWrapper>)
}
