import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'articles',
    'classifieds',
    'events',
    'relatedPolls',
    'products',
    'galleries',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';
    if (q == 'products') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const TAXONOMY_POLLS = gql`
  query TaxonomyPolls ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _pollStats {
        count
      }
      polls (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const POLL_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query PollEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      poll(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
          pick: related_Item.query,
          vars: 'page: $page, perPage: $perPage',
        })}
      }
    }`;
}

export const POLL_EDIT = gql`
  query PollEdit($oid: Integer!) {
    poll(oid: $oid) {
    oid
    id
    title
    teaser
    createdAt
    live
    active
    pullAt
    postAt
    topics {
      id
      title
      parent {
        id
        title
      }
    }
    sitePlacements {
      id
      title
      parent {
        id
        title
      }
    }
    questions {
      id
      title
      teaser
      body
      _answerStats {
        count
      }
      answers {
        body
        id
        live
        oid
        pollQuestion {
          id
        }
        sortingOrder,
        _voteStats {
          count
        }
      }
    }
    ${relatedContentFragments()}
  }
}
`;


export const POLL_CREATE = gql`
  mutation PollCreate ($input: PollCreateInput!) {
    pollCreate(input: $input)
    {
      poll {
        id
        oid
        active
        title
        createdAt
        live
        __typename
      }
      success
      errors
    }
  }
`;

export const POLL_UPDATE = gql`
  mutation PollUpdate ($input: PollUpdateInput!) {
    pollUpdate(input: $input)
    {
      polls {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_DELETE = gql`
  mutation PollDelete ($input: PollDeleteInput!) {
    pollDelete(input: $input)
    {
      success
      errors
      polls { id }
    }
  }
`;

export const POLL_QUESTION_CREATE = gql`
  mutation PollQuestionCreate ($input: PollQuestionCreateInput!) {
    pollQuestionCreate(input: $input) {
      pollQuestion {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_QUESTION_UPDATE = gql`
  mutation PollQuestionUpdate ($input: PollQuestionUpdateInput!) {
    pollQuestionUpdate(input: $input) {
      pollQuestions {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_QUESTION_DELETE = gql`
  mutation PollQuestionDelete ($input: PollQuestionDeleteInput!) {
    pollQuestionDelete(input: $input) {
      pollQuestions {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_ANSWER_CREATE = gql`
  mutation PollAnswerCreate ($input: PollAnswerCreateInput!) {
    pollAnswerCreate(input: $input) {
      pollAnswer {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_ANSWER_UPDATE = gql`
  mutation PollAnswerUpdate ($input: PollAnswerUpdateInput!) {
    pollAnswerUpdate(input: $input) {
      pollAnswers {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_ANSWER_DELETE = gql`
  mutation PollAnswerDelete ($input: PollAnswerDeleteInput!) {
    pollAnswerDelete(input: $input) {
      pollAnswers {
        id
      }
      success
      errors
    }
  }
`;

export const POLL_ADD_TAXONOMIES = gql`
  mutation PollAddTaxonomies ($input: PollAddTaxonomiesInput!) {
    pollAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const POLL_REMOVE_TAXONOMIES = gql`
  mutation PollRemoveTaxonomies ($input: PollRemoveTaxonomiesInput!) {
    pollRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;