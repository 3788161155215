import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

interface RelatedFragmentProps {
  vars?: string;
  pick?: string;
}

const relatedContentFragments = ({ vars, pick }: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = ['articles', 'companies', 'galleries', 'polls', 'relatedProducts', 'relatedEvents'];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter(q => q === pick) : queries;

  return result.map(q => {
    const statsName = pluralize.singular(q);

    const title = q === 'companies' || q === 'relatedProducts' ? 'title:name' : 'title';
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const EVENT_EDIT_RELATED = type => {
  const relatedItem = RELATED_TYPES[type];

  return gql`
    query EventEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      event(oid: $oid) {
        ${relatedItem.state} {
          count
        }
        ${relatedContentFragments({
          pick: relatedItem.query,
          vars: 'page: $page, perPage: $perPage'
        })}
      }
    }`;
};

export const EVENTS_QUERY = gql`
  query EventsQuery($perPage: Integer!, $page: Integer, $sortString: [Str], $filterString: [Str], $taxonomies: [TaxonomyID]) {
    _eventStats {
      count
    }

    events(perPage: $perPage, page: $page, sortString: $sortString, filterString: $filterString, taxonomies: $taxonomies) {
      oid
      id
      title
      state {
        abbrev
      }
      beginAt
      endAt
      active
      image {
        url
        absoluteUrl
        altText
      }
      meta {
        slug
      }
      taxonomies {
        oid
        id
        title
      }
    }
  }
`;

export const TAXONOMY_EVENTS = gql`
  query TaxonomyEvents($id: IDType!, $perPage: Integer!, $page: Integer!) {
    taxonomy(id: $id) {
      id
      _eventStats {
        count
      }
      events(perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const EVENTS_ASSOCIATED_COMPANIES = gql`
  query EventsAssociatedCompanies {
    events {
      companies {
        id
        name
      }
    }
  }
`;

export const EVENT_EDIT = gql`
  query EventEdit ($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    event (oid: $oid) {
      id
      oid
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      title
      teaser
      description
      beginAt
      endAt
      timeZone
      timeZoneIdentifier
      contactName
      email
      url
      registrationUrl
      location
      address1
      address2
      city
      postalCode
      stateName
      countryName
      province
      mailingListId
      country {
        id
        name
      }
      company {
        oid
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      eventCategories: categories {
        id
        title
        parent {
          id
          title
        }
      }
      keywords {
        id
        tag
      }
      meta {
        id
        slug
        canonicalUrl
        browserTitle
        keywords
        description
        bottomText
        headers {
          id
          name
          value
        }
      }
      searchable
      active
      _attendantStats {
        count
      }
      ${relatedContentFragments()}
    }
  }
`;

export const EVENT_EDIT_CUSTOM_FIELDS = gql`
  query EventEditCustomFields ($id: IDType!, $perPage:Integer!, $page:Integer!) {
    event(id: $id) {
      id
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
    }
  }
`;

export const EVENT_CREATE = gql`
  mutation EventCreate($input: EventCreateInput!) {
    eventCreate(input: $input) {
      event {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const EVENT_UPDATE = gql`
  mutation EventUpdate($input: EventUpdateInput!) {
    eventUpdate(input: $input) {
      events {
        id
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const EVENT_DELETE = gql`
  mutation EventDelete($input: EventDeleteInput!) {
    eventDelete(input: $input) {
      events {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_ADD_TAXONOMIES = gql`
  mutation EventAddTaxonomies($input: EventAddTaxonomiesInput!) {
    eventAddTaxonomies(input: $input) {
      taxonomies {
        id
      }
      event {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_REMOVE_TAXONOMIES = gql`
  mutation EventRemoveTaxonomies($input: EventRemoveTaxonomiesInput!) {
    eventRemoveTaxonomies(input: $input) {
      taxonomies {
        id
      }
      event {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_SET_KEYWORDS = gql`
  mutation EventSetKeywords($input: EventSetKeywordsInput!) {
    eventSetKeywords(input: $input) {
      success
      errors
    }
  }
`;

export const EVENT_PRODUCTS_EDIT = gql`
  query EventProductsEdit($oid: Integer!) {
    event(oid: $oid) {
      id
      products {
        id
        oid
        internalId
        externalId
        availability
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
          description
        }
      }
    }
  }
`;

export const EVENT_SET_PRODUCTS = gql`
  mutation EventSetProducts($input: EventSetProductsInput!) {
    eventSetProducts(input: $input) {
      success
      errors
    }
  }
`;

export const EVENT_ADD_PRODUCTS = gql`
  mutation EventAddProducts($input: EventAddProductsInput!) {
    eventAddProducts(input: $input) {
      products {
        id
      }
      event {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_REMOVE_PRODUCTS = gql`
  mutation EventRemoveProducts($input: EventRemoveProductsInput!) {
    eventRemoveProducts(input: $input) {
      products {
        id
      }
      event {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_ATTENDANTS = gql`
  query EventAttendants($oid: Integer!, $page: Integer!, $perPage: Integer!, $sortString: [Str]) {
    event(oid: $oid) {
      id
      attendants(page: $page, perPage: $perPage, sortString: $sortString) {
        id
        oid
        orderedAt
        lastName
        firstName
        company
        email
        phone
        address1
        address2
        city
        stateName
        countryName
        postalCode
        groupReference
        order {
          id
          oid
        }
      }
      _attendantStats {
        count
      }
    }
  }
`;

export const EVENT_ATTENDANT_EDIT = gql`
  query EventAttendantEdit($oid: Integer!) {
    eventAttendant(oid: $oid) {
      id
      oid
      orderedAt
      prefix
      lastName
      firstName
      middleName
      suffix
      jobTitle
      company
      email
      phone
      altPhone
      groupReference
      cost
      address1
      address2
      city
      stateName
      countryName
      postalCode
      contactLastName
      contactFirstName
      contactCompany
      contactEmail
      contactPhone
    }
  }
`;

export const EVENT_ATTENDANT_CREATE = gql`
  mutation EventAttendantCreate($input: EventAttendantCreateInput!) {
    eventAttendantCreate(input: $input) {
      eventAttendant {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_ATTENDANT_UPDATE = gql`
  mutation EventAttendantUpdate($input: EventAttendantUpdateInput!) {
    eventAttendantUpdate(input: $input) {
      eventAttendants {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_ATTENDANT_DELETE = gql`
  mutation EventAttendantDelete($input: EventAttendantDeleteInput!) {
    eventAttendantDelete(input: $input) {
      eventAttendants {
        id
      }
      success
      errors
    }
  }
`;

export const EVENT_ATTENDANTS_WITHOUT_STATS = gql`
  query EventAttendantsWithoutStats($oid: Integer!, $page: Integer!, $perPage: Integer!, $sortString: [Str]) {
    event(oid: $oid) {
      id
      attendants(page: $page, perPage: $perPage, sortString: $sortString) {
        id
        oid
        orderedAt
        prefix
        lastName
        firstName
        suffix
        jobTitle
        company
        email
        phone
        altPhone
        groupReference
        cost
        address1
        address2
        city
        stateName
        countryName
        postalCode
        contactLastName
        contactFirstName
        contactCompany
        contactEmail
        contactPhone
        order {
          id
          oid
          products {
            id
            oid
            internalId
            externalId
          }
        }
        options {
          id
          description
        }
      }
    }
  }
`;
