import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'articles',
    'classifieds',
    'events',
    'contentProducts',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';

    if (q === 'contentProducts') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};

export const ISSUE_EDIT_RELATED = (type) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query IssueEditRelated($id: IDType!, $page: Integer!, $perPage: Integer!) {
      newsletterIssue(id: $id) {
        id
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
          pick: related_Item.query,
          vars: 'page: $page, perPage: $perPage',
        })}
      }
    }`;
}

export const NEWSLETTER_ISSUE_EDIT = gql`
  query NewsletterIssue($oid: Integer!) {
    newsletterIssue(oid:$oid) {
      id
      oid
      title
      volume
      issueNumber
      creativeBody
      textBody
      published
      postAt
      preheader
      newsletter {
        id
        oid
        externalId
        zones {
          id
          oid
          refTitle
          xsltName
        }
      }
      classifications {
        id
        title
      }
      sitePlacements {
        id
        title
      }
      meta {
        id
        slug
        browserTitle
        canonicalUrl
        keywords
        description
        bottomText
        headers {
          id
          name
          value
        }
      }
      files {
        id
        oid
        url
        absoluteUrl
        title
      }
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      zoneValues {
        id
        oid
        value
        newsletterZone {
          id
          refTitle
        }
      }
      ${relatedContentFragments()}
    }
  }
`;

export const NEWSLETTER_ISSUE_UPDATE = gql`
  mutation NewsletterIssueUpdate ($input: NewsletterIssueUpdateInput!) {
    newsletterIssueUpdate(input: $input) {
      newsletterIssues {
        id
        oid
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;


export const NEWSLETTER_ISSUE_DELETE = gql`
  mutation NewsletterIssueDelete ($input: NewsletterIssueDeleteInput!) {
    newsletterIssueDelete(input: $input) {
      newsletterIssues {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_ADD_TAXONOMIES = gql`
  mutation NewsletterIssueAddTaxonomies ($input: NewsletterIssueAddTaxonomiesInput!) {
    newsletterIssueAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_REMOVE_TAXONOMIES = gql`
  mutation NewsletterIssueRemoveTaxonomies ($input: NewsletterIssueRemoveTaxonomiesInput!) {
    newsletterIssueRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_CREATE = gql`
  mutation NewsletterIssueCreate ($input: NewsletterIssueCreateInput!) {
    newsletterIssueCreate(input: $input) {
      newsletterIssue {
        oid
        id
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_PRODUCTS_EDIT_QUERY = gql`
  query NewsletterIssueProductsEditQuery($oid: Integer!) {
    newsletterIssue (oid: $oid) {
      id
      contentProducts {
        id
        internalId
        externalId
        availability
        productType
        classifications {
          id
          title
          parent {
            id
            title
          }
        }
        description
        name
        shippable
        taxable
        teaser
        title
        maxQuantity
        costs {
          id
          cost
          originalCost
          minQuantity
          maxQuantity
        }
      }
    }
  }
`;

export const NEWSLETTER_ISSUE_SET_PRODUCTS = gql`
  mutation NewsletterIssueSetContentProducts ($input: NewsletterIssueSetContentProductsInput!) {
    newsletterIssueSetContentProducts(input: $input){
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_SEND = gql`
  mutation NewsletterIssueSend ($newsletterIssueId: IDType!, $externalId: Str, $subject: Str) {
    newsletterIssueSend(newsletterIssueId: $newsletterIssueId, externalId: $externalId, subject: $subject){
      newsletterIssueDispatch {
        id
        oid
        createdAt
        externalId
        status
        subject
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_SEND_LIVE = gql`
  mutation NewsletterIssueSendLive($newsletterIssueId: IDType!, $externalId: Str, $subject: Str, $postAt: Time) {
    newsletterIssueSend(newsletterIssueId: $newsletterIssueId, subject: $subject, externalId: $externalId, postAt: $postAt) {
      newsletterIssueDispatch {
        id
        oid
        createdAt
        externalId
        status
        subject
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_SET_FILES = gql`
  mutation NewsletterIssueSetFiles ($input: NewsletterIssueSetFilesInput!) {
    newsletterIssueSetFiles(input: $input){
      files {
        id
        oid
        url
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_ADD_FILES = gql`
  mutation NewsletterIssueAddFiles ($input: NewsletterIssueAddFilesInput!) {
    newsletterIssueAddFiles(input: $input){
      files {
        id
        oid
        url
      }
      success
      errors
    }
  }
`;

export const NEWSLETTER_ISSUE_FILTER_SEARCH_QUERY = gql`
  query NewsletterIssueFilterSearchQuery ($filterString:[Str!], $perPage:Integer!, $page:Integer!) {
    newsletterIssues (filterString:$filterString, page: $page, perPage: $perPage) {
      oid
      id
      title
      parent: newsletter{
        id
        title
      }
    }
    _childStats: _newsletterIssueStats (filterString:$filterString) {
      count
    }
  }
`;

export const NEWSLETTER_ISSUE_SELECT_LIST = gql`
  query NewsletterIssueSelectList($id: IDType!, $page: Integer!, $perPage: Integer!, $filterString:[Str!]) {
    newsletter(id: $id){
      id
      title
      _childStats: _issueStats(filterString:$filterString){
        count
      }
      children: issues (page: $page, perPage: $perPage, filterString:$filterString) {
        oid
        id
        title
        parent: newsletter{
          id
        }
      }
    }
  }
`;

export const NEWSLETTER_ISSUE_BY_ID = gql`
  query NewsletterIssueById ($id: IDType!) {
    newsletterIssue (id: $id) {
      oid
      id
      title
      parent: newsletter{
        id
        title
      }
    }
  }
`;

export const NEWSLEETER_ISSUE_ADD_ARTICLES = gql`
  mutation NewsletterIssueAddArticles($input: NewsletterIssueAddArticlesInput!) {
    newsletterIssueAddArticles(input: $input) {
      success
      errors
      articles {
        oid
        id
        title
      }
    }
  }
`;

export const NEWSLEETER_ISSUE_REMOVE_ARTICLES = gql`
  mutation NewsletterIssueRemoveArticles($input: NewsletterIssueRemoveArticlesInput!) {
    newsletterIssueRemoveArticles(input: $input) {
      success
      errors
      articles {
        oid
        id
        title
      }
    }
  }
`;