import gql from 'graphql-tag';

// TODO: Videos missing meta friendlyUrl?

export const VIDEOS = gql`
  query Videos($onlyLive: Bool, $sortString: [Str], $perPage: Integer) {
    videos(onlyLive: $onlyLive, sortString: $sortString, perPage: $perPage) {
      id
      oid
      title
      teaser: description
      image {
        id
        url
        absoluteUrl
        altText
        oid
        height
        width
        fileSize
        altText
      }
    }
  }
`;

export const VIDEOS_MULTI = (ids: string[]) => {
  return gql`
    query VideosMulti {
      ${ids.map((id, index) => `
        video${index}: video(oid: ${id}) {
          id
          oid
          title
          teaser: description
          url
          image {
            id
            altText
            url
            absoluteUrl
            oid
            height
            width
            fileSize
            altText
          }
        }`).join('\n')}
    }
  `;
};
