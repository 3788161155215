import gql from 'graphql-tag';

export const SETTING = gql`
  query Setting($name: Str) {
    setting(name: $name) {
      oid
      id
      name
      description
      live
      stringValue
      restricted
    }
  }
`;

export const SETTINGS = gql`
  query Settings($onlyLive: Bool, $filterString: [Str], $sortString: [Str], $perPage: Integer, $page: Integer) {
    settings(onlyLive: $onlyLive, filterString: $filterString, sortString: $sortString, perPage: $perPage, page: $page) {
      oid
      id
      name
      description
      live
      stringValue
      restricted
    }
  }
`;

export const SETTING_QUERY  = gql`
  query SettingsQuery ($perPage:Integer!, $page:Integer!) {
    settings(perPage: $perPage, page: $page) {
      name
    }
  }
`;

export const SETTING_UPDATE = gql`
  mutation SettingUpdate ($input: SettingUpdateInput!) {
    settingUpdate (input: $input)
    {
      settings {
        id
        stringValue
      }
      success
      errors
    }
  }
`;

export const SETTING_CREATE = gql`
  mutation SettingCreate ($input: SettingCreateInput!) {
    settingCreate(input: $input) {
      setting {
        oid
        id
        name
        description
        live
        stringValue
        restricted        
      }
      success
      errors
    }
  }
`;

export const SETTING_QUERY_WITH_SORT  = gql`
  query SettingQueryWithSort ($perPage:Integer!, $page:Integer!, $sortString: [Str], $filterString: [Str]) {
    settings(perPage: $perPage, page: $page, sortString: $sortString, filterString: $filterString) {
      name
    }
  }
`;

export const SETTING_DELETE = gql`
  mutation SettingDelete ($input: SettingDeleteInput!) {
    settingDelete(input: $input)
    {
      settings { id }
      success
      errors
    }
  }
`;