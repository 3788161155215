import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react';
import DrawerProvider, { Context as DrawerContext } from 'components/drawer/context';

const initialRecords: string[][] = [ ];

interface exportVariables {
  buttonTitle: string,
  records: string[][],
  selectedExportData: Array<string>,
  totalRecords: number,
  isDataReceived: boolean,
  renderExport: number,
  renderTable: number,
  selectedIds:Array<string>,
  isAllSelect: boolean,
  showLoader: boolean,
}

interface QueryVariables {
  itemTypes?: SearchableTypeEnum[],
  page?: number,
  perPage?: number,
  terms?: string[],
  filterStrings?: string[],
  liveOnly?: boolean,
  sortStrings?: string[],
  oid?: IDType,
  filterString?: string[],
  startAt?: string | null,
  endAt?: string | null,
  articleTaxonomies?: string[],
  id?: string | null,
  authorIds?: string[],
}

export interface State {
  page: number,
  exportClicked: boolean,
  callFromContext: boolean,
  exportVariables: exportVariables,
  queryVariables: QueryVariables,
}

interface ContextType {
  state: State,
  dispatch: React.Dispatch<any>,
}

const defaultReducerState = {
  page: 1,
  exportClicked: false,
  callFromContext: false,
  exportVariables: {
    buttonTitle: 'Export',
    records: initialRecords,
    selectedExportData: [],
    totalRecords: 0,
    isDataReceived: false,
    renderExport: 0,
    renderTable: 0,
    selectedIds:[],
    isAllSelect: false,
    showLoader: false,
  },
  queryVariables: null,
};

export const Context = createContext<ContextType>({
  state: {
    ...defaultReducerState,
  },
  dispatch: () => {},
});

/**
 * The reducer to convert state and dispatch actions.
 * @param state The state of this context API.
 * @param state.isCodeView Contains Editor codeview boolean value.
 * @param action The action which includes type and payload.
 */
function reducer(state, action) {
  switch (action.type) {
    case "setMediaExportClicked":
      return {
        ...state,
        exportClicked: action.payload,
      }
    case "setMediaExportPage":
      return {
        ...state,
        page: action.payload,
      }
    case "setMediaExportVariables":
      return {
        ...state,
        exportVariables: action.payload,
      }
    case "setMediaExportQueryVariables":
      return {
        ...state,
        queryVariables: action.payload,
      }
    case "reset":
      return {
        ...state,
        ...defaultReducerState
      }
    default:
      throw Error(`The action.type ${action.type} does not exist in the Custom Field Context.`);
  }
}

const MediaExportProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, defaultReducerState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <Context.Provider value={providerValue}>
      {props.children}
    </Context.Provider>
  );
};

export default MediaExportProvider;
