import styled, { css } from 'styled-components';
import Colors from 'styles/colors';
import { sansSerif } from 'styles/mixins';
import { rotate, dash, color } from 'styles/animations';

// TODO: Finish loader animation with SVG.

export const Button = styled.button.attrs((props) => ({
  disabled: props.disabled,
  ref: props.ref,
}))`
  position: relative;
  background-color: ${(props) => Colors[props.color]};
  border-radius: 3px;
  padding: 14px 15px;
  border: none;
  color: #fff;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
  transition: all 0.25s ease;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  ${sansSerif}
  ${(props) => props.styles}

  ${(props) => props.loading && css`
    .svg-inline--fa {
      visibility: hidden;
    }
  `}

  ${(props) => props.hasIcon && !props.hasChildren && css`
    padding: 0 10px;
    height: 42px;

    .svg-inline--fa {
      font-size: ${(props) => props.iconFontSize}px;
    }
  `}

  ${(props) => props.outline && css`
    background-color: transparent;
    border: 1px solid ${(props) => Colors[props.color]};
    color: ${(props) => Colors[props.color]};

    /* Useful for nesting a Route Link */
    a {
      color: ${(props) => Colors[props.color]};
    }

    svg path {
      fill: ${(props) => Colors[props.color]};
    }
  `}

  ${(props) => props.hasIcon && css`
    svg {
      color: #fff;

      ${props.hasChildren && css`
        margin-right: 10px;
      `}
    }
  `}

  ${(props) => props.iconColor && css`
    svg path {
      fill: ${(props) => Colors[props.iconColor]};
      transition: fill 0.3s linear;
    }
  `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${Colors.greyChateau};
  }

  &:hover {
    filter: brightness(115%);
  }
  &.custom-filter {
    margin-bottom : 30px;
  }
  /* Useful for nesting a Route Link */
  a {
    color: #fff;
  }

  .circular-loader {
    animation: ${rotate} 2s linear infinite;
    transform-origin: center center;
    opacity: 0;
    position: absolute;
    left: 10px;
    margin: 0;

    &.-is-loading {
      opacity: 1;
      visibility: visible;
      display: block;
    }

    .loader-path {
      stroke-dasharray: 150,200;
      stroke-dashoffset: -10;
      animation: ${dash} 1.5s ease-in-out infinite, ${color} 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
`;
