import { useQuery } from '@apollo/react-hooks';
import { LOGGEDIN_USER } from 'graphql/entry';

interface HookReturn {
  userId: string,
  firstName: string,
  lastName: string,
  loading: boolean,
  error: any,
  tokenExpiresAt: string,
}

/**
 * @typedef {HookReturn} HookReturn
 * @property {string} userId
 * @property {boolean} loading - The useQuery loading state.
 * @property {boolean} error - The useQuery error state.
 * @returns {HookReturn}
 *
 */
export const useAuthUser = (): HookReturn => {
  const { data, loading, error } = useQuery(LOGGEDIN_USER, { fetchPolicy: 'cache-first' });
  const userId = data?.context?.user?.id;
  const firstName = data?.context?.user?.firstName;
  const lastName = data?.context?.user?.lastName;
  const tokenExpiresAt = data?.context?.tokenExpiresAt;
  return {
    userId,
    firstName,
    lastName,
    loading,
    error,
    tokenExpiresAt,
  }
}
