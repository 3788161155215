import gql from 'graphql-tag';

export const FEED_STATS = gql`
  query FeedStats {
    _feedStats {
      count
    }
  }
`;

export const FEED_NAV_QUERY = gql`
  query FeedNavQuery ($page: Integer!, $perPage: Integer!, $sortString: [Str!]) {
    feeds (page: $page, perPage: $perPage, sortString: $sortString) {
      id
      oid
      title
    }
  }
`;

export const FEED_ADD_MUTATION = gql`
  mutation FeedAddMutation ($input: FeedCreateInput!) {
    feedCreate (input: $input) {
      feed {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const FEED_EDIT_MUTATION = gql`
  mutation FeedEditMutation ($input: FeedUpdateInput!) {
    feedUpdate (input: $input)
    {
      feeds { id }
      success
      errors
    }
  }
`;

export const FEED_DELETE_MUTATION = gql`
  mutation FeedDeleteMutation ($input: FeedDeleteInput!) {
    feedDelete (input: $input)
    {
      feeds {
        id
      }
      success
      errors
    }
  }
`;

export const FEED_EDIT_QUERY = gql`
  query FeedEditQuery ($oid: Integer!) {
    feed (oid: $oid) {
      id
      oid
      title
      teaser
      active
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      timeToLive
      searchable
      copyright
      editorEmail
    }
  }
`;

export const FEED_ADD_TAXONOMIES = gql`
  mutation FeedAddTaxonomies ($input: FeedAddTaxonomiesInput!) {
    feedAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const FEED_REMOVE_TAXONOMIES = gql`
  mutation FeedRemoveTaxonomies ($input: FeedRemoveTaxonomiesInput!) {
    feedRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;