// import merge from 'lodash.merge';

export const defaults = {
  // foo: {
  //   __typename: 'foo',
  //   bar: 'hello world',
  // }
};

export const resolvers = [];
