import { css } from 'styled-components';
import Colors from 'styles/colors';
import { em, rem } from 'polished';
import { dropShadow } from 'styles/mixins';

export const AntOverrides = css`
  .epub-tooltip-hover.ant-tooltip {
    .ant-tooltip-inner {
      min-width: 22px;
      min-height: 22px;
      padding: 8px 8px;
      font-size: 12px;
    }
  }
  .epub-tag .ant-tag {
    margin-bottom: 5px;
    background-color: ${Colors.curiousBlue};
    border: 1px solid ${Colors.curiousBlue};
    color: #fff;

    .anticon { color: #fff }
  }
  .outline-menu-tooltip {
    &.ant-tooltip-placement-top {
      padding-bottom: ${em('11px')};

      .ant-tooltip-arrow {
        bottom: ${em('6px')};
      }
    }

    .ant-tooltip-content {
      font-size: ${rem('12px')};
    }

    .ant-tooltip-inner {
      box-shadow: none;
    }
  }

  .epub-table {
    width: 100%;

    .ant-table-placeholder {
      z-index: auto;
    }

    .ant-table-expanded-row .ant-table-wrapper.subscriptions {
      padding-left: 20%;
      padding-right: 20%;
    }

    .ant-table-expanded-row .ant-table-wrapper.subscriptions td.ant-table-selection-column {
      width: 5%;
    }

    .ant-table-expanded-row .ant-table-wrapper.comments {
      padding: 0;
      width: 100%;
      background-color: transparent;
    }

    .ant-table-expanded-row .ant-table-wrapper.comments th {
      background-color: transparent;
      color: ${Colors.lightSlateGrey};
      border-bottom: 1px solid ${Colors.zumthor};
    }

    .ant-table-expanded-row .ant-table-wrapper.comments tbody {
      background-color: transparent;
      color: #222222;
    }

    .ant-table-expanded-row .ant-table-wrapper.comments .ant-table {
      border:0;
    }

    .ant-table {
      border: ${em('1px')} solid ${Colors.mercury};
    }

    .ant-table-tbody {
      background-color: #fff;
      font-size: 14px;
      color: #072341;
      font-family: Lato;
      & > tr > td {
        overflow-wrap: normal;
        word-wrap: normal;
        word-break: keep-all;


        & > div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .ant-table-thead th {
      text-transform: uppercase;
      font-weight: bold;
      word-break: keep-all;
    }

    .ant-table-expanded-row .ant-table-wrapper {
      padding: 20px 70px 20px 20px;
    }

    .ant-table-row-expand-icon {
      border-color: ${Colors.lightSlateGrey};
    }

    .ant-table-expand-icon-link {
      color: ${Colors.lightSlateGrey};
    }

    .ant-table-column-title {
      word-wrap: normal;
    }

    &__action-button {
      background: none;
      border: none;
      color: ${Colors.curiousBlue};
      font-size: 14px;
      margin: 0;
      padding: 0;
      margin-right: 10px;
      cursor: pointer;
      outline: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  /* Remove padding for page-builder component UI */
  .epub-pagebuilder-properties {
    .ant-collapse-header {
      padding: 0 !important;
    }

    .ant-collapse-content {
      border-top: none;
      overflow: visible;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-item {
      border-bottom: none;
    }
  }

  .epub-border-pagebuilder {
    width: 100%;

    .ant-tabs-nav {
      width: 100%;
      & > div {
        display: flex;
      }

      .ant-tabs-tab {
        margin: 0;
        flex: 1;
        text-align: center;
        background-color: #E2E5E8;

        .anticon {
          margin-right: 0;
        }

        &.ant-tabs-tab-active {
          background-color: #F6F6F6;
        }
      }
    }

    .ant-tabs-bar {
      margin: 0;
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }

  .ant-select {
    width: 100%;
    max-width: 300px;

    .ant-select-selection {
      ${dropShadow}
      border: none;
    }

    .ant-select-selection--single {
      min-height: 32px;
      height: auto;
    }

    .ant-select-selection__placeholder {
      color: ${Colors.regentGray};
    }

    .ant-select-selection {
      padding: 10px 5px;
      display: flex;
      align-items: center;

      .ant-select-selection__rendered {
        width: 100%;
        min-width: 250px;
      }

      .ant-select-selection__choice {
        background-color: ${Colors.curiousBlue};
        border: none;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: ${rem('12px')};
        border-radius: ${em('3px')};
      }

      .ant-select-selection__choice__remove {
        color: #fff;
      }
    }
  }

  .ant-calendar-picker {
    .ant-calendar-picker-input {
      height: 50px;
    }

    .ant-calendar-range-picker-separator {
      display: inline-flex;
      align-items: center;
    }
  }

  .button-menu {
    .ant-checkbox-wrapper {
      color: ${Colors.bigStone};
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .ant-checkbox {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }

  .ant-tooltip {
    .ant-tooltip-inner {
      color: ${Colors.white};
      background-color: ${Colors.bigStone};
      padding: 10px 15px;
    }
  }

  .ant-tabs {
    width: 100%;

    &.-tabs-full-height {
      height: 100%;

      .ant-tabs-bar { margin-bottom: 0 }

      .ant-tabs-content { height: 100%; }
      .ant-tabs-tabpane { height: 100%; }
    }
  }

  .epub-search-select-wrapper {
    .ant-input-affix-wrapper {
      ${dropShadow}
      width: 100%;
      min-height: 46px;
      background-color: #fff;
      padding: 5px 15px;
      border-radius: 4px;

      .ant-input-prefix {
        position: static;
        transform: none;
      }

      input.ant-input {
        border: none;
        box-shadow: none;
        background: transparent;
        padding: 0;
      }

      .ant-tag {
        margin-bottom: 5px;
        background-color: ${Colors.curiousBlue};
        border: 1px solid ${Colors.curiousBlue};
        color: #fff;

        .anticon { color: #fff }
      }
    }

    &.-is-dark .ant-input-affix-wrapper {
      background-color: ${Colors.whiteSmoke};
      border-bottom: 1px solid ${Colors.mercury};
      box-shadow: none;
    }

    &.-has-selected-tags {
      input.ant-input {
        display: inline-block;
        width: auto;
        height: 30px;
        padding: 0;
      }
    }

    &.-no-multi-select {
      .ant-input-affix-wrapper {
        display: flex;
        align-items: center;
      }

      input.ant-input {
        height: 100%;
      }
    }
  }

  .epub-select-search-popover {
    .ant-popover-inner-content {
      padding: 12px 16px 12px 5px;
    }
  }

  .epub-pop-confirm-overlay {
    .ant-btn {
      background-color: ${Colors.cadetBlue};
      border: unset;
      color: #fff;

      &:hover, &:focus {
        color: #fff;
        background-color: ${Colors.cadetBlue};
        border: unset;
        filter: brightness(115%);
      }

      &:focus {
        outline: 0;
      }
    }

    .ant-btn-primary {
      background-color: ${Colors.chateauGreen};
      border: unset;

      &:hover, &:focus {
        color: #fff;
        background-color: ${Colors.chateauGreen};
        border: unset;
        filter: brightness(115%);
      }

      &:focus {
        outline: 0;
      }
    }
  }
  .seo-popup.epub-pop-confirm-overlay {
    .ant-btn {
      background-color: ${Colors.chateauGreen};
    }
  }
  .epub-select-folder-popover {
    padding: 0;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner-content {
      max-height: 300px;
      overflow: scroll;
    }
  }
  .ant-tooltip.newsletter-error-tooltip{
    max-width: 300px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #EDD369;
    
    .ant-tooltip-inner{
        background-color: #FFEEAB;
        color: #000000a6;
    }
  }
`;
