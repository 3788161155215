import React, { useState, useEffect } from 'react';
import * as css from './style';
import Colors from 'styles/colors';

type ToggleProps = {
  width?: number,
  height?: number,
  thumbWidth?: number,
  thumbHeight?: number,
  padding?: number,
  ballColor?: string,
  ballColorActive?: string,
  bgToggled?: string,
  bgClear?: string,
  borderColor?: string,
  initial?: boolean,
  onToggle: Function,
  borderSize?: number,
  wrapperPadding?: number,
  wrapperPaddingTop?: number,
  wrapperPaddingBottom?: number,
  outerLabel?: string,
  outerLabelSize?: number,
  outerLabelColor?: string,
  innerLabelRight?: string,
  innerLabelLeft?: string,
  innerLabelSize?: number,
  innerLabelColor?: string,
  moduleName?: string,
};

// Default colors
const lightGrey = '#f5f5f5';
const white = '#fff';
const black = '#000000';
const darkGray = '#ced3d8';


const Toggle = (props: ToggleProps) => {

  const [isToggled, setIsToggled] = useState(props.initial);

  useEffect(() => {
    setIsToggled(props.initial);
  }, [props.initial]);

  const toggle = () => {
    setIsToggled(!isToggled);
    if(props.moduleName === 'userNewsletters') {
      props.onToggle(!isToggled);
    } else {
      props.onToggle();
    }
  }

  return (
    <css.ToggleContainer className="epub-toggle-wrapper" padding={props.wrapperPadding} paddingTop={props.wrapperPaddingTop} paddingBottom={props.wrapperPaddingBottom}>

      {props.outerLabel &&
        <css.OuterLabel
          size={props.outerLabelSize}
          color={props.outerLabelColor}
        >
          {props.outerLabel}
        </css.OuterLabel>
      }

      <css.Toggle
        onClick={toggle}
        toggled={isToggled}
        {...props}
      >
        <css.ToggleThumb
          toggled={isToggled}
          {...props}
        />

        {props.innerLabelLeft &&
          <css.InnerLabel
            left
            size={props.innerLabelSize}
            color={props.innerLabelColor}
          >
            {props.innerLabelLeft}
          </css.InnerLabel>
        }
        {props.innerLabelRight &&
          <css.InnerLabel
            right
            size={props.innerLabelSize}
            color={props.innerLabelColor}
          >
            {props.innerLabelRight}
          </css.InnerLabel>
        }
      </css.Toggle>
    </css.ToggleContainer>
  );
}

// Specifies the default values for props:
Toggle.defaultProps = {
  width: 72,
  height: 38,
  thumbWidth: 30,
  thumbHeight: 30,
  padding: 5,
  ballColor: white,
  ballColorActive: lightGrey,
  bgToggled: Colors.verdigris,
  bgClear: darkGray,
  borderColor: 'transparent',
  initial: false,
  borderSize: 0,
  wrapperPadding: 14,
  wrapperPaddingTop: 14,
  wrapperPaddingBottom: 20,
  outerLabel: '',
  outerLabelSize: 16,
  outerLabelColor: black,
  innerLabelLeft: '',
  innerLabelRight: '',
  innerLabelSize: 12,
  innerLabelColor: black
};

export default Toggle;