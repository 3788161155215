import gql from 'graphql-tag';

/**
 *  Fragment for navigation body
 */
const fragmentNavigationBody = `
  id
  oid
  name
  parent {
    id
    name
  }
  _childStats{
    count
  }
  title
  url
  active
  sortingOrder
  newWindow
  image {
    id
    url
    absoluteUrl
    oid
    height
    width
    fileSize
    altText
  }
`;

/**
 * A fragment for Taxonomy Children, which has taxonomy attributes and counter of children
 */
const fragmentChildrenWithStats = () => {
  return `
    children {
      oid
      id
      parent {
        id
      }
      _childStats{
        count
      }
      active
      title
      sortingOrder
    }
  `;
};

export const MENUS = gql`
  query Menus($rootOnly: Bool, $filterString: [Str]) {
    menus(rootOnly: $rootOnly, filterString: $filterString) {
      id
      oid
      title
      url
    }
  }
`;

export const NAVIGATION_NAV_QUERY = gql`
  query NavigationNavQuery($page: Integer!, $perPage: Integer!) {
    menus(rootOnly: "true", page: $page, perPage: $perPage) {
      id
      oid
      title
    }
  }
`;

export const NAVIGATION_PARENT_NAV = gql`
  query NavigationParentNav {
    menuNames {
      id: name
      title: name
    }
  }
`;
// To support existing tree component structure to work with id and oid
// consider id and oid same name field
export const NAVIGATION_SELECTED_NAV = gql`
  query NavigationSelectedNav ($name: Str!)  {
    menuName (name:$name) {
      id: name
      oid: name
      title: name
      children: menus {
        ${fragmentNavigationBody}
      }
    }
  }
`;

export const NAVIGATION_FILTER_NAV = gql`
  query FilterMenus($filterString: [Str]) {
    menus(filterString: $filterString) {
      id
      name
    }
  }
`;

export const NAVIGATION_LIST = gql`
  query NavigationList {
    menus(rootOnly: "true", perPage: 100) {
      id
      oid
      title
    }
  }
`;

export const NAVIGATION_BY_OID = gql`
  query NavigationByOid ($oid: Integer!) {
    menu (oid:$oid) {
      ${fragmentNavigationBody}
      ${fragmentChildrenWithStats()}
    }
  }
`;

export const NAVIGATION_ADD_MUTATION = gql`
  mutation NavigationAddMutation($input: MenuCreateInput!) {
    menuCreate(input: $input) {
      menu {
        id
        oid
        title
        name
        _childStats {
          count
        }
      }
      success
      errors
    }
  }
`;

export const NAVIGATION_EDIT_MUTATION = gql`
  mutation NavigationEditMutation($input: MenuUpdateInput!) {
    menuUpdate(input: $input) {
      menus {
        id
      }
      success
      errors
    }
  }
`;

export const NAVIGATION_DELETE_MUTATION = gql`
  mutation NavigationDeleteMutation($input: MenuDeleteInput!) {
    menuDelete(input: $input) {
      menus {
        id
      }
      success
      errors
    }
  }
`;

export const MENU_NAME_DELETE = gql`
  mutation MenuNameDelete($input: MenuNameDeleteInput!) {
    menuNameDelete(input: $input) {
      success
      errors
    }
  }
`;

export const MENU_NAME_UPDATE = gql`
  mutation MenuNameUpdate($input: MenuNameUpdateInput!) {
    menuNameUpdate(input: $input) {
      success
      errors
    }
  }
`;

export const NAVIGATION_BY_ID = gql`
  query NavigationById ($id: IDType!) {
    menu (id: $id) {
      ${fragmentNavigationBody}
      sitePlacements { id }
      ${fragmentChildrenWithStats()}
    }
  }
`;

export const NAVIGATION_SET_TAXONOMIES = gql`
  mutation NavigationEditTaxonomies($input: MenuSetTaxonomiesInput!) {
    menuSetTaxonomies(input: $input) {
      success
      errors
    }
  }
`;

export const NAVIGATION_STATS = gql`
  query MenuStats {
    _menuStats {
      count
    }
  }
`;

export const NAVIGATION_LIST_QUERY_FOR_MEDIA = gql`
  query NavigationListQueryForMedia($page: Integer!, $perPage: Integer!) {
    menus(rootOnly: "true", page: $page, perPage: $perPage) {
      id
      oid
      title
      _childStats {
        count
      }
    }
  }
`;

export const NAVIGATION_BY_ID_FOR_MEDIA = gql`
  query NavigationByIdForMedia($id: IDType!, $page: Integer!, $perPage: Integer!) {
    menu(id: $id) {
      id
      oid
      title
      _childStats {
        count
      }
      children(page: $page, perPage: $perPage) {
        id
        oid
        title
        _childStats {
          count
        }
      }
    }
  }
`;
