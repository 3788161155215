import { useState, useEffect } from 'react';
import { DOMAIN_NAME } from 'constants/index';

type Props = {
  src: string,
  width?: number,
  height?: number,
}

type ImageResponse = {
  currentSrc: string,
}

/**
 * Check if passed new url and old url are matched without timestamp
 * @param {string} url
 */
function isUrlMatched (src: string, url: string) {
  const srcParts = src.split(".");
  const srcFirstPart = srcParts.slice(0, srcParts.length - 1);
  const urlParts = url.split(".");
  const urlFirstPart = urlParts.slice(0, urlParts.length - 1);

  // Does timestamp not exist ?
  if (!urlFirstPart.length || !srcFirstPart.length) return false;

  // Not a replaced file url
  if (urlFirstPart.join('.') !== srcFirstPart.join('.')) return false;

  return true;
}

/**
 * Attach current timestamp to src
 * @param {string} url
 * @param {string} newTimestamp
 */
function getUrlWithCorrectTimestamp(url: string, newTimestamp: string): string {
  const urlParts = url.split("?");
  const queryStringParts = urlParts.length > 1 ? urlParts[1].split('&') : [];
  const queryStringWithoutTimestamp = queryStringParts.length > 1 ? queryStringParts.slice(1, queryStringParts.length).join('&') : "";

  return queryStringParts.length > 1 ? `${urlParts[0]}?${newTimestamp}&${queryStringWithoutTimestamp}` : `${urlParts[0]}?${newTimestamp}`;
}

/**
 * Image loading hook to show lazy loaded image
 *
 * @param {string} src
 * @param {number} width optional, provide image width, api will generate and return image of that size
 * @param {number} height optional, provide image height, api will generate and return image of that size
 */
export function useImage({src, width, height}: Props): ImageResponse {
  const [state, setState] = useState({
    currentSrc: "",
    newSrc: "",
  });
  const { currentSrc, newSrc } = state;

  /**
   * When an image is replaced, update its URL with a new timestamp so that it gets updated in the UI.
   */
  useEffect(() => {
    const handler = (event) => {
      const decodedUrl = event.detail.url;
      if (isUrlMatched(decodeURIComponent(src), decodedUrl)) {
        setState((prevState) => ({
          ...prevState,
          newSrc: encodeURI(getUrlWithCorrectTimestamp(decodedUrl, event.detail.timeStamp)),
        }));
      }
    };
    document.addEventListener('epub.replaceImgUrlAdded', handler);

    return () => document.removeEventListener('epub.replaceImgUrlAdded', handler);
  }, []);

  /**
   *  Update the source of the image with a size attribut.
   **/
  useEffect(() => {
    let mounted = true;

    /**
     * Set width or height resize attr on image URL, if not a remote image.
     *
     * @returns {string}
     */
    function updateSrc(): string {
      const srcInUse = newSrc ? newSrc : src;
      let srcModified = srcInUse.slice();
      const isRemoteUrl = srcInUse.indexOf(DOMAIN_NAME) === -1;

      if (width && !isRemoteUrl) {
        srcModified = `${srcInUse}&width=${width}`;
      }

      if (height && !isRemoteUrl) {
        srcModified = `${srcModified}&height=${height}`;
      }

      return srcModified;
    }

    if (mounted) setState((prevState) => ({ ...prevState, loading: false, currentSrc: updateSrc() }));

    return () => {
      mounted = false;
    };
  }, [src, newSrc]);

  return {
    currentSrc,
  };
}
