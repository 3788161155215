import gql from 'graphql-tag';

export const SET_UISTYLE = gql`
  mutation UiStyleSet($input: UiStyleSetInput!) {
    uiStyleSet(input: $input)
    {
      success
      errors
    }
  }
`;
