import React, { useContext, useState, useEffect } from 'react';
import ExportCsv from 'components/export/csv';
import * as css from './style';
import useExportData from './exportData';
import useVideoExportData from './videoExport';
import useAudioExportData from './audioExport';
import { SearchableTypeEnum } from 'graphql/types/globalTypes';
import { Context } from './context';
import { FetchData } from './fetchData';


const ReportProgressBar = (props) => {

  const { state, dispatch } = useContext(Context);
  const [completed, setCompleted] = useState(0);
  const [records, setRecords] = useState([]);
  const { getData } = useExportData({setCompleted});
  const { getData: audioExportData } = useAudioExportData({setCompleted});
  const { getData: videoExportData } = useVideoExportData({setCompleted});
  const { exportClicked, exportVariables, page, queryVariables, query } = state;

  const csvLinkRef = React.useRef(null);

  useEffect(() => {
    const isCsvRefExist = csvLinkRef && csvLinkRef.current;
    if (isCsvRefExist && records.length > 0) {
      csvLinkRef.current['link'].click();
      setRecords([]);
    }
  }, [records]);

  async function translatedData(data: unknown, isAllReceived: boolean, hasError: boolean = false) {
    let newData;
    if (String(queryVariables.itemTypes[0]) === "AUDIO") {
      newData = await audioExportData(data);
    } else if (String(queryVariables.itemTypes[0]) === "VIDEO") {
      newData = await videoExportData(data);
    } else {
      newData = await getData(data);
    }
    setRecords([
      ...records,
      ...[newData[0]],
      ...newData[1]]);
    dispatch({ type: "resetReport", payload: false });
  }

  return (
    <>
      {exportClicked &&
        <>
          <css.Container>
            <div>Exporting status</div>
            <css.ProgressBarContainer grid={2} columns="260px|10px" gap="10px">
              <css.Progress>
                <css.ProgressBar xWidth={`${completed}%`}></css.ProgressBar>
              </css.Progress>
              <css.ProgressText>{`${completed}%`}</css.ProgressText>
            </css.ProgressBarContainer>
          </css.Container>
          <FetchData totalRecords={exportVariables.totalRecords} returnData={translatedData} query={query} queryVariables={queryVariables} exportDataKey={'search'} setCurrentPage={setCompleted} />
          <ExportCsv data={records} ref={csvLinkRef} fileName={'report_export.csv'} />
        </>}
    </>
  );
};

export default ReportProgressBar;