import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { SearchMediaImageExport_search_item_Image } from 'graphql/types/SearchMediaImageExport';
import { Context } from './context';
import { getFileSizeWithUnit, deepFlatten } from 'utils/helpers';
import { chunk } from 'lodash';
import { SELECTED_IMAGE_MUTATION } from 'graphql/entry';
import { Headers, ImageKeys } from './constants';
import dateTimeSplit from "utils/dateTimeSplit";
/**
 * A React Hook to handle to Image metadata export
 * @return {object.getData} getData - A callback that handles image data to manipulate for export
  */
const useExportData = () => {
  const { state: { exportVariables } } = useContext(Context);
  const client = useApolloClient();
  const { isAllSelect } = exportVariables;

  /**
   * manipulateData customise and reorder row data as per csv column order
   * @param {SearchMediaImageExport_search_item_Image} row contains particular table row data
   */
  function manipulateData(row: SearchMediaImageExport_search_item_Image) {
    const assignedTo = [];
    // Fetch userid and customer number column data
    const imageMetaData = ImageKeys.map((key: string) => {
      if (key === 'createdAt') {
        const [createdAt, createdAtFormatted] = row[key] ? dateTimeSplit(row[key], 'MM-dd-yyyy') : [null, null];
        return (row[key]) ? createdAtFormatted : '';
      }
      if (key === 'updatedAt') {
        const [updatedAt, updatedAtFormatted] = row[key] ? dateTimeSplit(row[key], 'MM-dd-yyyy') : [null, null];
        return (row[key]) ? updatedAtFormatted : '';
      }
      if (key === 'heightwidth') {
        return (row['height'] && row['width']) ? `${row['height']} X ${row['width']}` : '';
      }
      if (key === 'fileSize') {
        const fileSize = getFileSizeWithUnit(row['fileSize']);

        return (row[key]) ? `${fileSize}` : '';
      }
      if (key === 'topics') {
        const topicsArr = [];
        row[key]?.forEach(item => {
          topicsArr.push(`${item.title} (${item.oid})`);
        })
        const list = topicsArr.join("| ");
        return list;
      }
      if (key === 'sitePlacements') {
        const sitePlacements = [];
        row[key]?.forEach(item => {
          sitePlacements.push(`${item.title} (${item.oid})`);
        })
        const list = sitePlacements.join("| ");
        return list;
      }
      if (key === 'classifications') {
        const classifications = [];
        row[key]?.forEach(item => {
          classifications.push(`${item.title} (${item.oid})`);
        })
        const list = classifications.join("| ");
        return list;
      }
      if (key === 'taxonomiesDescendedFrom') {
        const taxonomiesDescendedFrom = [];
        row[key]?.forEach(item => {
          taxonomiesDescendedFrom.push(`${item.title} (${item.oid})`);
        })
        const list = taxonomiesDescendedFrom.join("| ");
        return list;
      }
      if (key === 'assignedTo') {
        if (row.articles?.length > 0) {
          const articleArr = [];
          row.articles?.forEach(item => {
            articleArr.push(`${item.title} (articles)`);
          })
          const list = articleArr.join("| ");
          assignedTo.push(list);
        }
        if (row.products?.length > 0) {
          const productArr = [];

          row.products?.forEach(item => {
            productArr.push(`${item.name} (product)`);
          })

          const list = productArr.join("| ");
          assignedTo.push(list)
        }
        if (row.events?.length > 0) {
          const eventsArr = [];

          row.events?.forEach(item => {
            eventsArr.push(`${item.title} (event)`);
          })

          const list = eventsArr.join("| ");
          assignedTo.push(list)
        }
        if (row.companies?.length > 0) {
          const companyArr = [];

          row.companies?.forEach(item => {
            companyArr.push(`${item.name} (directory listing)`);
          })

          const list = companyArr.join("| ");
          assignedTo.push(list)
        } if (row.taxonomiesByMain?.length > 0) {
          const taxArr = [];

          row.taxonomiesByMain?.forEach(item => {
            taxArr.push(`${item.title} (taxonomy)`);
          })

          const list = taxArr.join("| ");
          assignedTo.push(list)
        } if (row.galleries?.length > 0) {
          const galleryArr = [];

          row.galleries?.forEach(item => {
            galleryArr.push((item.title) ? `${item.title} (gallery)` : `Untitled (gallery)`);
          })

          const list = galleryArr.join("| ");
          assignedTo.push(list)
        } if (row.blogs?.length > 0) {
          const blogArr = [];

          row.blogs?.forEach(item => {
            blogArr.push(`${item.title} (blog)`);
          })

          const list = blogArr.join("| ");
          assignedTo.push(list)
        } if (row.editions?.length > 0) {
          const editionArr = [];

          row.editions?.forEach(item => {
            editionArr.push(`${item.title} (edition)`);
          })

          const list = editionArr.join("| ");
          assignedTo.push(list)
        } if (row.authors?.length > 0) {
          const authorArr = [];

          row.authors?.forEach(item => {
            authorArr.push(`${item.fullName} (author)`);
          })

          const list = authorArr.join("| ");
          assignedTo.push(list)
        }
        if (row.classifieds?.length > 0) {
          const classifiedArr = [];

          row.classifieds?.forEach(item => {
            classifiedArr.push(`${item.title} (classified)`);
          })

          const list = classifiedArr.join("| ");
          assignedTo.push(list)
        }
        if (row.newsletterIssues?.length > 0) {
          const issueArr = [];

          row.newsletterIssues?.forEach(item => {
            issueArr.push(`${item.title} (newsletterIssue)`);
          })

          const list = issueArr.join("| ");
          assignedTo.push(list)
        }
        return assignedTo.join("| ")
      }
      return (row && row[key]) ? row[key] : '';
    });
    const data = [...imageMetaData]
    return data;
  }

  type DataType = string[] | string[][];

  /**
   * runDataQuery handles run time query generated by seleted rows ids
   * @param {Array<{[key: string]: unknown }>} finalData contains query, parameters and variable to get data
   * @returns An array list to export into csv
   */
  async function runDataQuery(finalData) {
    let queryData = [];
    try {
      const mutatestring = gql`
        query imageQuery(${finalData.param}){
        ${finalData.query}
        } `;
      const { data } = await client.query({
        query: mutatestring,
        variables: finalData.variables,
      });
      for (let index = 0; index < finalData.counter; index++) {
        let object = data[`image${index}`]
        queryData.push(object);
      }
    } catch (err) {
      console.log(err)
    }

    return queryData;
  }

  //works as callback function into export
  async function getData(recordData, selectedRow) {
    let dynamicData = [];

    if (selectedRow && isAllSelect) {
      const chunkedList = chunk(recordData, 10);
      for (let i = 0; i < chunkedList.length; i++) {
        const finalData = SELECTED_IMAGE_MUTATION(chunkedList[i], {}, '', 0, '');
        dynamicData = [...dynamicData, ...await runDataQuery(finalData)];
      }
    }
    if (selectedRow && !isAllSelect) {
      const finalData = SELECTED_IMAGE_MUTATION(recordData, {}, '', 0, '');
      dynamicData = await runDataQuery(finalData);
    }
    const data: DataType = (selectedRow) ? (
      dynamicData.map((value) => manipulateData(value))
    ) : (
      recordData.map((value) => manipulateData(value.item))
    );
    const headersState = [...Headers];
    const exportData = deepFlatten(data, headersState.length);
    return [headersState, exportData];
  }

  return {
    getData
  }
}

export default useExportData;