import React from 'react';
import SearchSelect from '../search';
import { KEYWORDS_LIST } from 'graphql/entry';
import * as css from './style';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  name: string,
  allowEnterNewValue?: boolean,
  theme?: 'light' | 'dark',
  value: string[],
  notAllowedIdForSelection?: string[],
  defaultSelectedTags?: Array<{ key: string, title: string }>,
  showTitle?: boolean,
  maxWidth?: string,
  allowTypeaheadSearch?: boolean,
  allowKeywordSuggestion?: boolean,
  openAIAPIEnabled?: boolean,
}

/**
 * The initial query on a taxonomy select component is to determine what kind of parent taxonomy
 * the results start from.
 * @param {object} props The React component props.
 * @param {string} props.name The name of the taxonomy to begin our search.
 * @param {string} props.placeholder Customize the input field placeholder.
 * @param {Array} props.value Default values to show as having been selected.
 * @param {boolean} props.showTitle indicates to show the title or not.
 * @param {maxWidth} props.maxWidth maximum width select box can have.
 * @param {boolean} props.allowKeywordSuggestion indicate allow keyword suggestion or not
 * @param {boolean} props.openAIAPIEnabled indicate open AI API setting is enabled
 */
const KeywordSelect = ({ allowEnterNewValue=true, name="keywords", defaultSelectedTags=[], showTitle=false, allowTypeaheadSearch = false, allowKeywordSuggestion = false, openAIAPIEnabled = false, ...props }: Props) => {
  return (
    <>
      { showTitle && <css.FieldLabel>Keywords</css.FieldLabel> }
      <SearchSelect
        allowEnterNewValue={allowEnterNewValue}
        name={name}
        placeholder="Keywords"
        defaultValue={props.value}
        defaultSelectedTags={defaultSelectedTags}
        onChange={props.onChange}
        theme={props.theme}
        notAllowedIdForSelection={props.notAllowedIdForSelection}
        queryName={KEYWORDS_LIST}
        queryVariables={{}}
        queryDataType="keywords"
        querySearchName={KEYWORDS_LIST}
        querySearchVariables={{}}
        querySearchDataType="keywords"
        queryStatsType="_childStats"
        querySearchStatsType="_childStats"
        querySearchField="tag"
        maxWidth={props.maxWidth}
        hasTreeStructure={false}
        allowTypeaheadSearch={allowTypeaheadSearch}
        allowKeywordSuggestion={allowKeywordSuggestion}
        openAIAPIEnabled={openAIAPIEnabled}
      />
    </>
  )
}

export default KeywordSelect;
