import gql from 'graphql-tag';

export const EDITION_EDIT_RELATED = gql`
  query EditionEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    edition(oid: $oid) {
      editionArticles (page: $page, perPage: $perPage) {
        id
        oid
        sortingOrder
      }
      articles (page: $page, perPage: $perPage) {
        oid
        id
        title
      }
      _articleStats {
        count
      }
    }
  }
`;

export const EDITION_CREATE = gql`
  mutation EditionCreate ($input: EditionCreateInput!) {
    editionCreate(input: $input) {
      edition {
        oid
        id
        legacyId
      }
      success
      errors
    }
  }
`;

export const EDITION_ADD_TAXONOMIES = gql`
mutation EditionAddTaxonomies ($input: EditionAddTaxonomiesInput!) {
  editionAddTaxonomies (input: $input)
  {
    success
    errors
  }
}
`;

export const EDITION_REMOVE_TAXONOMIES = gql`
mutation EditionRemoveTaxonomies ($input: EditionRemoveTaxonomiesInput!) {
  editionRemoveTaxonomies (input: $input)
  {
    success
    errors
  }
}
`;

export const EDITION_EDIT = gql`
  query EditionEdit($oid: Integer!) {
    edition(oid:$oid) {
      id
      oid
      title
      subTitle
      teaser
      live
      publishAt
      pullAt
      postAt
      volume
      editionNumber
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      printVersion {
        id
        oid
        url
        absoluteUrl
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      meta {
        id
        slug
        browserTitle
        keywords
        description
        bottomText
        canonicalUrl
        headers {
          id
          name
          value
        }
      }
      publication {
        id
      }
    }
  }
`;

export const EDITION_ARTICLES = gql`
  query EditionArticles ($id: IDType!) {
    edition(id: $id) {
      id
      publication {
        id
        sections {
          id
          title
        }
      }
      editionArticles(sortString: ["sortingOrder asc"], perPage: 100) {
        id
        oid
        sortingOrder
        article {
          oid
          id
          title
        }
        section {
          id
        }
      }
    }
  }
`;

export const EDITION_UPDATE = gql`
  mutation EditionUpdate ($input:  EditionUpdateInput!) {
    editionUpdate(input: $input) {
      editions {
        id
        oid
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
        printVersion {
          id
          url
          absoluteUrl
          oid
        }
      }
      success
      errors
    }
  }
`;

export const EDITION_ARTICLE_CREATE = gql`
  mutation EditionArticleCreate ($input:  EditionArticleCreateInput!) {
    editionArticleCreate(input: $input) {
      success
      errors
      editionArticle {
        id
        oid
        sortingOrder
        article {
          oid
          id
          title
        }
        section {
          id
          title
          articles {
            title
            id
            oid
          }
          _articleStats {
            count
          }
        }
      }
    }
  }
`;

export const EDITION_ARTICLE_UPDATE = gql`
  mutation EditionArticleUpdate ($input:  EditionArticleUpdateInput!) {
    editionArticleUpdate(input: $input) {
      success
      errors
      editionArticles {
        id
        oid
        article {
          oid
          id
          title
        }
        section {
          id
          title
        }
      }
    }
  }
`;

export const EDITION_ARTICLE_DELETE = gql`
  mutation EditionArticleDelete ($input: EditionArticleDeleteInput!) {
    editionArticleDelete(input: $input) {
      success
      errors
      editionArticles {
        id
        oid
        article {
          oid
          id
          title
        }
        section {
          id
          title
        }
      }
    }
  }
`;

export const EDITION_DELETE = gql`
  mutation EditionDelete ($input: EditionDeleteInput!) {
    editionDelete(input: $input)
    {
      success
      errors
    }
  }
`;

export const EDITION_PRINT_PAGES = gql`
  query EditionPrintPages($id: IDType!, $perPage: Integer!) {
    edition(id: $id) {
      id
      printPages(perPage: $perPage) {
        id
        name
      }
    }
  }
`;

export const EDITION_SECTIONS_LIST = gql`
  query EditionSectionsList($id: IDType!) {
    edition(id: $id) {
      id
      title
      publication {
        id
        title
        children: sections {
          id
          title
        }
      }
    }
  }
`;

export const EDITION_FILTER_SEARCH_QUERY = gql`
  query EditionFilterSearchQuery ($filterString:[Str!], $perPage:Integer!, $page:Integer!) {
    editions (filterString:$filterString, page: $page, perPage: $perPage) {
      oid
      id
      title
      parent: publication {
        id
        title
        sections {
          id
          title
        }
      }
    }
    _childStats: _editionStats (filterString:$filterString) {
      count
    }
  }
`;