import gql from 'graphql-tag';

export const AUTHORS_SELECT_ALL = gql`
  query Authors_Select_All ($perPage:Integer!, $page:Integer!, $filterString: [Str]) {
    
    _authorStats(filterString: $filterString) {
      count
    }

    authors(perPage: $perPage, page: $page, sortString:["lastName asc", "firstName asc"], filterString: $filterString) {
      oid
      id
      firstName
      lastName
    }
  }
`;

export const AUTHORS_STATS = gql`
  query AuthorStats {
    _authorStats {
      count
    }
  }
`;