import React, { useContext } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useAuthPrivilege } from 'hooks/useAuthPrivilege';
import { Context } from 'components/auth/context';
import Button from 'components/button';
import Colors from 'styles/colors';
import { environmentOptions } from 'utils/helpers';
import { ReactComponent as BeamLockSVG } from 'svg/beam-graphic.svg';
import { sendMessage } from "registerServiceWorker";
import { AUTH_TOKEN, LOCAL_STORAGE } from '../constants';
import useBeamLockAnim from './useBeamLockAnim';
import { getCookieByName } from 'utils/cookiesHelper';

export const CssPermissionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #F6F6F6;
  height: 100vh;
`;

export const CssSvgContainer = styled.div`
  margin: -40px auto 0;
  text-align: center;

  .auth-anime-message {
    opacity: 0;
    transition: all 300ms ease-in-out;

    &.-auth-anime-exit-done {
      opacity: 1;
    }

    &.-auth-anime-enter,
    &.-auth-anime-enter-done {
      opacity: 1;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0 0;
    color: ${Colors.oxfordBlue};
  }

  p {
    font-size: 18px;
    font-weight: 300;
    color: ${Colors.oxfordBlue};
    margin: 0 0 15px 0;
  }

  .go-back-button {
    margin-right: 10px;
  }

  #Oval { opacity: 0 }
  #ring { opacity: 0 }
`;

export const AuthPermissions = ({ privilege, history, children }) => {
  const { hasPrivilege, loading, error } = useAuthPrivilege({ privilege });
  const { dispatch } = useContext(Context);

  useBeamLockAnim();

  function logout() {
    dispatch({ type: "logout" });
    const newData = { credentials: {}};
    sendMessage(newData);
    history.push('/login');
  }

  // Do not redirect to the API error page on a 403 error.
  // We let a login modal handle this error.
  if (error && error?.networkError?.statusCode !== 403) {
    return (
      <Redirect to={{
        pathname: '/api-error',
      }} />
    );
  }

  if (hasPrivilege || !privilege) return children;

  return (
    <CssPermissionsContainer>
      <CssSvgContainer>
        <BeamLockSVG />
        {loading && <p>Checking permissions...</p>}
        {!loading && (<>
          <h1>Hold up!</h1>
          <p>You don't have the correct permissions to access this page.</p>
          <Button color="havelockBlue" icon="arrow-left" onClick={() => history.goBack()} className="go-back-button">Go Back</Button>
          <Button color="greyChateau" icon="sign-out" onClick={logout}>Sign Out</Button>
        </>)}
      </CssSvgContainer>
    </CssPermissionsContainer>
  );
};

/**
 * Clear the auth token from localStorage.
 */
function clearToken() {
  const ENV_OPTIONS = environmentOptions();
  const env = ENV_OPTIONS.X_ENV;

  localStorage.removeItem(env === "stage" ? `${AUTH_TOKEN}:stage:${ENV_OPTIONS.X_HOST_SHORTNAME}` : `${AUTH_TOKEN}:production:${ENV_OPTIONS.X_HOST_SHORTNAME}`);
}

/**
 * Set cookie data to local storage
 * @returns
 */
function storeCookieToLocalStorage(cookieData) {
  const ENV_OPTIONS = environmentOptions();
  const host = ENV_OPTIONS.X_HOST_SHORTNAME;
  const decodedCookie = JSON.parse(cookieData);
  const { sites, token, env } = decodedCookie;

  if (!sites.includes(host) || !token) return false;

  localStorage.setItem(`${AUTH_TOKEN}:${env}:${host}`, token);
  localStorage.setItem(`${LOCAL_STORAGE.X_ENV}:${host}`, env);

  return token;
}

/**
 * Check if the user has a token in local storage that has not expired.
 */
const Auth = {
  isUserAuthenticated() {
    const ENV_OPTIONS = environmentOptions();
    const env = ENV_OPTIONS.X_ENV;

    const authToken = JSON.parse(localStorage.getItem(env === "stage" ? `${AUTH_TOKEN}:stage:${ENV_OPTIONS.X_HOST_SHORTNAME}` : `${AUTH_TOKEN}:production:${ENV_OPTIONS.X_HOST_SHORTNAME}`));
    if (!authToken) return false;

    const { token, expiresAt } = authToken.tokenCreate;

    if (token && expiresAt) {
      if (Date.parse(expiresAt) < new Date().getTime()) {
        clearToken();
        return false;
      }
      return true;

    }

    clearToken();
    return false;
  },

  /**
   * Useful for accessing the token, such as in a Viewer Query.
   */
  getToken: () => {
    const ENV_OPTIONS = environmentOptions();
    const env = ENV_OPTIONS.X_ENV;

    const authToken = JSON.parse(localStorage.getItem(env === "stage" ? `${AUTH_TOKEN}:stage:${ENV_OPTIONS.X_HOST_SHORTNAME}` : `${AUTH_TOKEN}:production:${ENV_OPTIONS.X_HOST_SHORTNAME}`));
    if (!authToken) return '';
    return authToken.tokenCreate.token;
  },
  clearToken,
  storeCookieToLocalStorage,

  /**
   * Useful for accessing the full token for passing cookie data to sibling sites.
   */
   getFullToken: () => {
    const ENV_OPTIONS = environmentOptions();
    const env = ENV_OPTIONS.X_ENV;

    const authToken = localStorage.getItem(env === "stage" ? `${AUTH_TOKEN}:stage:${ENV_OPTIONS.X_HOST_SHORTNAME}` : `${AUTH_TOKEN}:production:${ENV_OPTIONS.X_HOST_SHORTNAME}`);
    if (!authToken) return '';
    return authToken;
  },
};

export default Auth;
