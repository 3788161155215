import gql from 'graphql-tag';
import { RELATED_TYPES } from '../constants';
import pluralize from 'pluralize';

type RelatedFragmentProps = {
  vars?: string,
  pick?: string,
};

const relatedContentFragments = ({
  vars,
  pick,
}: RelatedFragmentProps = { vars: '', pick: null }) => {
  const queries = [
    'articles',
    'galleries',
    'polls',
    'companies',
  ];

  // Filter to show just the "pick" if pick is present, otherwise return queries.
  const result = pick ? queries.filter((q) => q === pick) : queries;

  return result.map((q) => {
    const statsName = pluralize.singular(q);

    let title = 'title';
    if (q === 'companies') {
      title = 'title:name';
    }
    const withVars = vars ? `(${vars})` : '';
    return `
      ${q}${withVars} {
        oid
        id
        ${title}
      }
      _${statsName}Stats{
        count
      }
    `;
  });
};


export const TAXONOMY_CLASSIFIEDS = gql`
  query TaxonomyClassifieds ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _classifiedStats {
        count
      }
      classifieds (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
 `;

export const CLASSIFIEDS_CREATE = gql`
  mutation ClassifiedCreate ($input: ClassifiedCreateInput!) {
    classifiedCreate(input: $input)
    {
      classified {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const CLASSIFIED_ADD_TAXONOMIES = gql`
  mutation ClassifiedAddTaxonomies ($input: ClassifiedAddTaxonomiesInput!) {
    classifiedAddTaxonomies(input: $input) {
      taxonomies { id }
      classified { id }
      success
      errors
    }
  }
`;

export const CLASSIFIED_REMOVE_TAXONOMIES = gql`
  mutation ClassifiedRemoveTaxonomies ($input: ClassifiedRemoveTaxonomiesInput!) {
    classifiedRemoveTaxonomies(input: $input) {
      taxonomies { id }
      classified { id }
      success
      errors
    }
  }
`;

export const CLASSIFIED_EDIT_RELATED = (type: keyof typeof RELATED_TYPES) => {
  const related_Item = RELATED_TYPES[type];
  return gql`
    query ClassifiedEditRelated($oid: Integer!, $page: Integer!, $perPage: Integer!) {
      classified(oid: $oid) {
        ${related_Item.state} {
          count
        }
        ${relatedContentFragments({
          pick: related_Item.query,
          vars: 'page: $page, perPage: $perPage',
        })}
      }
    }`;
}


export const CLASSIFIED_EDIT = gql`
  query ClassifiedEdit($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    classified(oid: $oid) {
      oid
      id
      title
      body
      company
      contactEmail
      contactName
      postAt
      pullAt
      active
      searchable
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      classifiedCategories:categories {
        id
        title
        parent {
          id
          title
        }
      }
      keywords {
        id
        tag
      }
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
      audios {
        id
      }
      videos {
        id
      }
      images {
        id
      }
      files {
        id
      }
      ${relatedContentFragments()}
    }
  }
`;

export const CLASSIFIED_EDIT_CUSTOM_FIELDS = gql`
  query ClassifiedEditCustomFields ($id: IDType!, $perPage:Integer!, $page:Integer!) {
    classified(id: $id) {
      id
      _customFieldStats{
        count
      }
      customFields(page: $page, perPage: $perPage) {
        id
        value
        optionSelectionPath
        type {
          id
          name
          inputType
        }
      }
    }
  }
`;

export const CLASSIFIED_UPDATE = gql`
  mutation ClassifiedUpdate ($input: ClassifiedUpdateInput!) {
    classifiedUpdate(input: $input)
    {
      classifieds {
        id
        oid
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const CLASSIFIED_DELETE = gql`
  mutation ClassifiedDelete ($input: ClassifiedDeleteInput!) {
    classifiedDelete(input: $input)
    {
      success
      errors
      classifieds { id }
    }
  }
`;

export const CLASSIFIED_SET_KEYWORDS = gql`
  mutation ClassifiedSetKeywords ($input: ClassifiedSetKeywordsInput!) {
    classifiedSetKeywords(input: $input) {
      success
      errors
    }
  }
`;