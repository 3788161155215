import gql from 'graphql-tag';


export const orderData = () => {
  return `
    oid
    id
    createdAt
    submittedAt
    address1
    address2
    company
    firstName
    lastName
    city
    phone
    note
    totalSalesTax
    totalShippingCost
    amountPaid
    country {
      iso3
      iso
    }
    products {
      name
    }
    user {
      oid
      legacyId
    }
    countryName
    postalCode
    province
    email
    shippingAddress {
      lastName
      firstName
      id
      oid
      address1
      address2
      country
      postalCode
      phone
      company
      fax
      state
      email
      province
      city
    }
    note
    trackingCode
    referralCode
    campaignCode
    status
    invoicedAt
    filledAt
    items(productExists: true) {
      id
      product {
        oid
        name
        internalId
        externalId
        weight
        weightUnit
        productType
        bundledProducts {
          oid
          id
          title
          name
          weight
          internalId
          externalId
          weightUnit
        }
      }
      filledAt
      type
      quantity
      cost
      salesTax
    }
    payment {
      cardNumberLastFourDigits
      transactionId
    }
    state {
      id
      name
    }
    offers {
      id
      name
      code
    }
    shippingType
    totalShippingCost
  `;
}

export const SELECTED_ORDER_MUTATION = (ids: Array<string>, variables: {}, param: string, counter: number, query: string) => {
  for (let index = 0; index < ids.length; index++) {
    let key = `order${counter}`;
    let value = ids[counter];
    variables[key] = value;
    if (param != '') param += ',';
    param += "$" + key + ":IDType";
    query += `order${counter}: order(id: $${key} ) {
        ${orderData()}
      }`
    counter++;
  }
  return { query, variables, param, counter }
};
export const ORDER_EDIT = gql`
  query OrderEdit($oid: Integer!) {
    order (oid: $oid) {
      ${orderData()}
    }
  }
`;

export const ORDER_ITEMS = gql`
  query OrderItems($oid: Integer!, $page: Integer!, $perPage: Integer!) {
    order(oid: $oid) {
      id
      totalSalesTax
      totalShippingCost
      items(page: $page, perPage: $perPage) {
        id
        oid
        product {
          name
          internalId
        }
        type
        externalId
        quantity
        discount
        cost
        filledAt
        status
        salesTax
        shippingCost
      }
      _itemStats {
        count
      }
    }
  }
`;

export const ORDER_UPDATE = gql`
  mutation OrderUpdate ($input: OrderUpdateInput!) {
    orderUpdate(input: $input)
    {
      orders {
        id
      }
      success
      errors
    }
  }
`;

export const ORDER_ITEM_DELETE = gql`
  mutation OrderItemDelete($input: OrderItemDeleteInput!) {
    orderItemDelete (input: $input) {
      orderItems {
        id
      }
      success
      errors
    }
  }
`;

export const ORDER_ITEM_UPDATE = gql`
  mutation OrderItemUpdate($input: OrderItemUpdateInput!) {
    orderItemUpdate (input: $input) {
      orderItems {
        id
      }
      success
      errors
    }
  }
`;

export const ORDER_CREATE = gql`
  mutation OrderCreate ($input: OrderCreateInput!) {
    orderCreate(input: $input)
    {
      order {
        id
      }
      success
      errors
    }
  }
`;

export const SHIPPING_ADDRESS_CREATE = gql`
  mutation shippingAddressCreate ($input: ShippingAddressCreateInput!) {
    shippingAddressCreate(input: $input)
    {
      shippingAddress {
        id
      }
      success
      errors
    }
  }
`;

export const ORDER_ITEM_CREATE = gql`
  mutation OrderItemCreate ($input: OrderItemCreateInput!) {
    orderItemCreate(input: $input)
    {
      orderItem {
        id
      }
      success
      errors
    }
  }
`;