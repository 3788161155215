import gql from 'graphql-tag';

export const TAXONOMY_PUBLICATIONS = gql`
  query TaxonomyPublications ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _publicationStats {
        count
      }
      publications (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

export const PUBLICATIONS_QUERY = gql`
  query PublicationsQuery {
    publications {
      id
      oid
      live
      title
      editions {
        oid
        id
        title
      }
    }
  }
`;

export const PUBLICATION_STATS = gql`
  query PublicationStats {
    _publicationStats {
      count
    }
  }
`;

export const PUBLICATION_NAV_QUERY = gql`
  query PublicationNavQuery ($page: Integer!, $perPage: Integer!) {
    publications (page: $page, perPage: $perPage) {
      id
      oid
      title
      canonicalUrl
    }
  }
`;

export const PUBLICATION_ADD_MUTATION = gql`
  mutation PublicationAddMutation ($input: PublicationCreateInput!) {
    publicationCreate (input: $input) {
      publication {
        id
        oid
        title
        canonicalUrl
      }
      success
      errors
    }
  }
`;

export const PUBLICATION_EDIT_MUTATION = gql`
  mutation PublicationEditMutation ($input: PublicationUpdateInput!) {
    publicationUpdate (input: $input)
    {
      publications { id }
      success
      errors
    }
  }
`;

export const PUBLICATION_EDIT_QUERY = gql`
  query PublicationEditQuery ($oid: Integer!) {
    publication (oid: $oid) {
      id
      oid
      title
      description
      teaser
      publicationFrequency
      active
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      sections(sortString: ["sortingOrder asc"]) {
        id
        title
        subTitle
        teaser
        sortingOrder
      }
    }
  }
`;

export const PUBLICATION_ADD_TAXONOMIES = gql`
  mutation PublicationAddTaxonomies ($input: PublicationAddTaxonomiesInput!) {
    publicationAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const PUBLICATION_REMOVE_TAXONOMIES = gql`
  mutation PublicationRemoveTaxonomies ($input: PublicationRemoveTaxonomiesInput!) {
    publicationRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const PUBLICATION_DELETE_MUTATION = gql`
  mutation PublicationDeleteMutation ($input: PublicationDeleteInput!) {
    publicationDelete (input: $input)
    {
      publications {
        id
      }
      success
      errors
    }
  }
`;

export const PUBLICATION_SECTION_ADD_MUTATION = gql`
  mutation PublicationSectionAddMutation($input: PublicationSectionCreateInput!) {
    publicationSectionCreate(input: $input) {
      publicationSection {
        id
      }
      success
      errors
    }
  }
`;

export const PUBLICATION_SECTION_EDIT_MUTATION = gql`
  mutation PublicationSectionEditMutation($input: PublicationSectionUpdateInput!) {
    publicationSectionUpdate(input: $input) {
      success
      errors
    }
  }
`;

export const PUBLICATION_SECTION_DELETE_MUTATION = gql`
  mutation PublicationSectionDeleteMutation($input: PublicationSectionDeleteInput!) {
    publicationSectionDelete(input: $input) {
      success
      errors
    }
  }
`;

export const PUBLICATIONS_SECTIONS = gql`
  query PublicationsSections ($id: IDType!) {
    publication (id: $id) {
      id
      sections {
        id
        title
      }
    }
  }
`;

export const PUBLICATIONS_LIST_QUERY = gql`
  query PublicationsListQuery ($filterString:[Str!]) {
    publications {
      id
      title
      _childStats: _editionStats (filterString: $filterString) {
        count
      }
    }
    _publicationStats {
      count
    }
  }
`;

export const PUBLICATION_EDITION_SELECT_LIST = gql`
  query PublicationEditionSelectList($id: IDType!, $page: Integer!, $perPage: Integer!, $filterString:[Str!]) {
    publication(id: $id) {
      id
      title
      sections {
        id
        title
      }
      _childStats: _editionStats {
        count
      }
      children: editions(page: $page, perPage: $perPage, filterString: $filterString) {
        id
        title
        parent: publication {
          id
        }
      }
    }
  }
`;