import gql from 'graphql-tag';

type CommentableEnum = number;

export const COMMENTS_QUERY = gql`
  query CommentsQuery($owner: CommentableEnum!, $perPage: Integer!, $page: Integer, $filterString: [Str]) {

    _commentStats(owner: $owner) {
      count
    }

    comments(owner: $owner, perPage: $perPage, page: $page, sortString: "createdAt desc", filterString: $filterString,) {
      id
      oid
      body
      title
      active
      createdAt
      updatedAt
      userName
      userEmail
      article: owner {
        ... on Article {
          id
          oid
          title
        }
      }
      company: owner {
        ... on Company {
          id
          oid
          name
        }
      }
    }
  }
`;

export const COMMENT_EDIT_QUERY = (type) => {
  const votes = () => {
    if (type.toLowerCase() === 'article' || type === 'product') {
      return `vote {
        rating
      }`;
    }
    return ``;
  }
  return gql`
  query CommentEditQuery($id: IDType!) {
    comment(id: $id) {
      id
      oid
      title
      body
      userName
      userEmail
      createdAt
      remoteAddr
      active
      spam
      offensive
      ${votes()}
      article: owner {
        ... on Article {
          id
          oid
          articleLabelTitle: title
        }
        ... on Product {
          id
          oid
          productLabelTitle: name
        }
        ... on Company {
          id
          oid
          companyLabelTitle: name
        }
        ... on Gallery {
          id
          oid
          galleryLabelTitle: title
        }
      }
  }
}
`;
}

export const COMMENT_EDIT_MUTATION = gql`
  mutation CommentUpdate ($input: CommentUpdateInput!) {
    commentUpdate(input: $input)
    {
      comments {
        id
      }
      success
      errors
    }
  }
`;

export const COMMENT_DELETE_MUTATION = gql`
  mutation CommentDelete ($input: CommentDeleteInput!) {
    commentDelete(input: $input)
    {
      comments {
        id
      }
      success
      errors
    }
  }
`;
