import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Context as SettingsContext } from 'components/setting/context';
import Select from "components/forms/select/index";
import Textarea from "components/forms/textarea";
import Button from "components/button/index";
import openNotificationWithIcon from 'utils/notification';
import * as css from './style';
import { OPENAI_CHAT_API_BASE_URL } from 'constants/index';

interface Props {
  handleUpdateKeywords: (keywords) => void ,
}

const KeywordSuggestionForm = ({ handleUpdateKeywords }: Props) => {
  const { settings } = useContext(SettingsContext);
  const chatGPTKey = settings['openai.api.key']?.stringValue;

  const [loader, setLoader] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [selectedTags, setTags] = useState([]);

  /**
   * Handle on change of prompt field
   */
  const handleChange = (event) => {
    setPromptText(event.target.value);
  };

  function onChangeField(event) {
    setTags(event.target.value)
  }

  /**
   * Manage openAI call for image creation based on selected content
   */
  const handleClick = async () => {
    if (!chatGPTKey) {
      openNotificationWithIcon({
        type: 'error',
        title: 'Please set system settings openai.api.key to access chatGPT',
      }, () => { });
      return;
    }
    if (!promptText.trim()) {
      openNotificationWithIcon({
        type: 'error',
        title: 'Please enter text to process with chatGPT',
      }, () => { });
      return;
    }
    if (promptText.length > 1000) {
      openNotificationWithIcon({
        type: 'error',
        title: 'Maximum 1000 characters are allowed in prompt.',
      }, () => { });
      return;
    }

    setLoader(true);
    const data = {
      model: 'gpt-3.5-turbo', // refers to a particular GPT-3 model release of ChatGPT.
      max_tokens: 256, // responsible for setting an upper limit for the number of tokens that the API will return.
      temperature: 0.5, // refers to the sampling of the output
      messages: [
        {
          "role": "system",
          "content": "You will be provided with a block of text, and your task is to extract a list of comma separated keywords from it."
        },
        {
          "role": "user",
          "content": promptText
        }
      ]
    };

    //Request for a query from the OpenAI API
    const responseData = await axios.request({
      url: OPENAI_CHAT_API_BASE_URL,
      method: 'post',
      headers: { 'Authorization': `Bearer ${chatGPTKey}` },
      data: data
    })
      .catch((err) => {
        return err?.response?.data;
      });

    if (responseData?.data?.choices?.length > 0) {
      let keywords = (responseData?.data?.choices[0]?.message?.content)?.split(', ');
      keywords = keywords.map((keyword) => {
        return { label: keyword, value: keyword };
      });

      setSuggestedKeywords(keywords);

    } else if (responseData?.error) {
      openNotificationWithIcon({
        type: 'error',
        title: responseData?.error?.message || 'Server responds with error!',
      }, () => { });
    } else { }
    setLoader(false);
  };

  /**
   * Process selected keyword to set
   */
  const handleUpdateKeyword = () => {
    if (!selectedTags.length) {
      openNotificationWithIcon({
        type: 'error',
        title: 'Please select keywords.',
      }, () => { });
      return;
    }

    handleUpdateKeywords(selectedTags);
  };

  return (
    <css.KeywordSuggestWarpper>
      <css.Field>
        <Textarea
          theme="dark"
          ptext="Prompt"
          name="prompt"
          defaultValue={promptText}
          onChange={handleChange}
        />
      </css.Field>
      <Button
        icon="cloud-upload"
        color="chateauGreen"
        className='generate-button'
        loading={loader}
        disabled={loader}
        onClick={handleClick}
      >
        Generate Keyword
      </Button>
      {(suggestedKeywords.length > 0) &&
        <>
          <css.Field>
            <Select
              label="Suggested Keywords"
              placeholder="Keywords"
              mode="multiple"
              name="keywords"
              options={suggestedKeywords}
              defaultValue={selectedTags}
              onChange={onChangeField}
              maxWidth="100%"
            />
          </css.Field>
          <css.AddButtonWrapper>
            <Button
              color="pictonBlue"
              icon="plus-circle"
              onClick={handleUpdateKeyword}
            >
              Add
            </Button>
          </css.AddButtonWrapper>
        </>
      }
    </css.KeywordSuggestWarpper>
  );
};

export default KeywordSuggestionForm;