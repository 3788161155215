type Args = {
  selectedKeys: Array<string>,
  selectedTags: Array<{
    key: string,
    title: string,
  }>,
  searchValue: string,
}

/**
 * Add new searchValue to selected keys and tags so long as it's not a duplicate title.
 * Sets the new tag with a unique key, which can be used to identify the new tags.
 * 
 * @param {Array} selectedKeys The key of the selected value.
 * @param {Array} selectedTags The tags of the selected values.
 * @param {Array} searchValue The searched value.
 * @returns {{boolean, Array, Array}} A flag if the update is a new tag; the updates tags; the updated keys.
*/
export default ({ selectedKeys, selectedTags, searchValue }: Args) => {
  const tags = [];
  const keys = [];
  let shouldUpdate: boolean = false;
  let newCount = 0;
  const findLastNewTag = selectedTags.slice().reverse().find((st) => {
    return String(st.key).split('_')[0] === "NEW";
  });
  const newTags = selectedTags.findIndex((st) => st.title === searchValue);
  
  // Determine what the NEW key increment number should be. If others exist.
  if (findLastNewTag) {
    newCount = Number(findLastNewTag.key.split('_')[1]) + 1;
  }
  
  // If not a duplicate, add new tag / key and toggle that the new value should be updated.
  // Remove whitespace from search value.
  if (newTags === -1) {
    tags.push({ key: `NEW_${newCount}`, title: searchValue.trim() });
    keys.push(`NEW_${newCount}`);
    shouldUpdate = true;
  }

  return {
    shouldUpdate,
    tags: selectedTags.concat(tags),
    keys: selectedKeys.concat(keys),
  };
};
