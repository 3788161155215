import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import { SETTINGS } from 'graphql/entry';
import { Settings, SettingsVariables } from 'graphql/types/Settings';

const useSettings = (names, fetchPolicy) => {
  const [state, setState] = useState({ settings: {}});
  const systemSettingsStr = names.map((setting) => (`name = ${setting}`)).join(" %OR% ");
  const defaultVariables = {
    "filterString": [`${systemSettingsStr}`],
  }

  const { loading, data, error } = useQuery<Settings, SettingsVariables>(SETTINGS, { variables: { ...defaultVariables }, notifyOnNetworkStatusChange: true, fetchPolicy });
  const { settings } = state;

  useDeepCompareEffect(() => {
    if (!data || !data?.settings || loading) return;

    const updatedState: any = {};
    data.settings.forEach((item) => {
      updatedState[item.name] = item;
    });

    setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        ...updatedState,
      },
    }));
  }, [data]);

  return {
    loading,
    error,
    settings
  }
}

export default useSettings;