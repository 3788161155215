import styled from 'styled-components';
import Colors from './colors';
import { smoothAnimation } from 'styles/mixins';

export const AppWrapper = styled.div`
  display: grid;
  grid-template-columns: 240px auto;
  overflow-y: auto;
`;

export const Body = styled.div`
  background-color: ${Colors.whiteSmoke};
  display: grid;
  grid-template-rows: auto auto 1fr;
  z-index: 1;
  transition: all ${smoothAnimation};
  align-self: flex-start;
  
  .-nav-collapsed & {
    margin-left: -181px;
  }
  
  .appWrapperScroll & {
    overflow-y: auto;
  }
  /* .-nav-mouseenter & {
    margin-left: 0;
  } */
`;
