import gql from 'graphql-tag';

export const TAXONOMY_EDITORIALS = gql`
  query TaxonomyEditorials ($id:  IDType!, $perPage:Integer!, $page:Integer!) {
    taxonomy(id: $id) {
      id
      _editorialContentStats {
        count
      }
      editorialContents (perPage: $perPage, page: $page) {
        id
        oid
        title
      }
    }
  }
`;

 export const EDITORIAL_QUERY  = gql`
 query editorialContents ($perPage:Integer!, $page:Integer!) {
    editorialContents(perPage: $perPage, page: $page) {
      name
    }
 }
`;

export const EDITORIAL_CONTENT_BLURB  = gql`
  query EditorialContentBlurb($oid: Integer, $name: Str) {
    editorialContent (oid: $oid, name: $name) {
      id
      oid
      name
      body
      active
      updatedAt
    }
  }
`;

export const EDITORIAL_CONTENT_EDIT_QUERY  = gql`
  query EditorialContentEditQuery($oid: Integer, $name: Str) {
    editorialContent (oid: $oid, name: $name) {
      id
      oid
      name
      title
      subTitle
      linkTitle
      linkUrl
      active
      sitePlacements {
        id
        title
        parent {
          id
          title
        }
      }
      topics {
        id
        title
        parent {
          id
          title
        }
      }
      classifications {
        id
        title
        parent {
          id
          title
        }
      }
      body
      image {
        id
        url
        absoluteUrl
        oid
        height
        width
        fileSize
        altText
      }
    }
  }
`;

export const EDITORIAL_CONTENT_EDIT_MUTATION = gql`
  mutation EditorialContentUpdate ($input: EditorialContentUpdateInput!) {
    editorialContentUpdate (input: $input)
    {
      editorialContents {
        id
        image {
          id
          url
          absoluteUrl
          oid
          height
          width
          fileSize
          altText
        }
      }
      success
      errors
    }
  }
`;

export const EDITORIAL_CONTENT_ADD_MUTATION = gql`
  mutation EditorialContentCreate ($input: EditorialContentCreateInput!) {
    editorialContentCreate (input: $input)
    {
      editorialContent {
        id
        oid
      }
      success
      errors
    }
  }
`;

export const EDITORIAL_CONTENT_DELETE_MUTATION = gql`
  mutation EditorialContentDelete ($input: EditorialContentDeleteInput!) {
    editorialContentDelete (input: $input)
    {
      editorialContents { id }
      success
      errors
    }
  }
`;

export const EDITORIAL_CONTENT_ADD_TAXONOMIES = gql`
  mutation EditorialContentAddTaxonomies ($input: EditorialContentAddTaxonomiesInput!) {
    editorialContentAddTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;

export const EDITORIAL_CONTENT_REMOVE_TAXONOMIES = gql`
  mutation EditorialContentRemoveTaxonomies ($input: EditorialContentRemoveTaxonomiesInput!) {
    editorialContentRemoveTaxonomies (input: $input)
    {
      success
      errors
    }
  }
`;
