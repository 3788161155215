import styled, { css } from 'styled-components';
import Colors from 'styles/colors';

export const Wrapper = styled.div`
  position: relative;
  align-self: start;
  width: auto;
`;

export const Outline = styled.div`
   &:before {
    content: "";
    position: absolute;
    top: ${(props) => props.borderPosition === 'outer' ? '-1px' : '0'};
    left: ${(props) => props.borderPosition === 'outer' ? '-1px' : '0'};
    right: ${(props) => props.borderPosition === 'outer' ? '-1px' : '0'};
    bottom: ${(props) => props.borderPosition === 'outer' ? '-1px' : '0'};
    border: ${(props) => `${props.borderSize}px`} solid ${Colors.curiousBlue};
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in-out;
    z-index: 4;
    pointer-events: none; /* Critical to allow pointer events to pass through to child elements */

    ${(props) => props.isHovering && css`
      opacity: 1;
      visibility: visible;
    `}
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 36px;
  padding: 5px;
  background-color: ${Colors.curiousBlue};
  display: flex;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out;

  ${(props) => props.menuPosition === 'inner' && css`
    top: 0;
  `}

  ${(props) => props.menuPosition === 'outer' && css`
    top: -36px;
  `}

  ${(props) => props.isHovering && css`
    opacity: 1;
    visibility: visible;
    z-index: 5;
  `}
`;

export const Item = styled.li`
  list-style: none;
  padding: 0;
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;

  &:hover .svg-inline--fa path {
    fill: ${Colors.yellow};
  }

  .svg-inline--fa {
    width: 16px;
    height: 16px;

    path {
      fill: white;
    }
  }

  &.epub-outline-action-move {
    cursor: move;
  }
`;

export const PopConfirmWrapper = styled.div`
  display: inline-block;
`;