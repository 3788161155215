import Auth from 'auth';
import {
  IMAGE_EXTENSIONS,
  AUDIO_EXTENSIONS,
  VIDEO_EXTENSIONS
} from 'constants/index';
import { getExtension, environmentOptions } from 'utils/helpers';
import { MediaTypes } from '../types';

/**
 * File cannot be spread as an object; no error is thrown when attempting.
 * Convert File to Object first before spreading.
 * @param file The file to convert to an object.
 * @returns An object representing the File.
 */
export function fileToObject(file: File) {
  return {
    name: file.name,
    lastModified: file.lastModified,
    size: file.size,
    type: file.type,
    originFileObj: file
  };
}

/**
 * Get the base64 output of a file.
 * @param {File.name} file.name The name of the image.
 * @param {File.originFileObj} file.originFileObj The original file.
 */
export function getBase64(file: File): Promise<unknown> {
  const p = new Promise<unknown>((resolve, _reject) => {
    const extension = getExtension(file.name);
    const extLower = (extension || '').toLowerCase();
    if (IMAGE_EXTENSIONS.indexOf(extLower) === -1) resolve(file);

    const reader = new FileReader();
    reader.addEventListener('load', () =>
      resolve({ ...fileToObject(file), url: reader.result })
    );
    reader.readAsDataURL(file);
  });
  return p;
}

/**
 * Get the dimensions of an image.
 * @param {string} img image url
 * @returns A Promise that resolves to { w, h }
 */
export function getImageDimensions(img: string) {
  return new Promise(function (resolved, _rejected) {
    const i = new Image();
    i.onload = function () {
      resolved({ w: i.width, h: i.height });
    };
    i.onerror = function () {
      resolved({ w: null, h: null });
    };
    i.src = img;
  });
}

/**
 * Headers for uploading files.
 */
export function uploadHeaders() {
  const { X_ENV, X_HOST_SHORTNAME } = environmentOptions();
  const token = Auth.getToken();

  return {
    Authorization: `token ${token}`,
    'X-Environment': X_ENV,
    'X-Host-Shortname': X_HOST_SHORTNAME,
    'Content-Type': 'multipart/form-data',
    'Accept': "application/vnd.epublishing.media.resource+json",
  };
}

/**
 * Update the image dimensions within the URL.
 * @param imageSrc The image src.
 * @param width The new image width.
 * @param height The new image height.
 */
export function imageUrlSizeUpdate(
  imageSrc: string,
  width: string,
  height: string
): string {
  const src = new URL(imageSrc);
  src.searchParams.set('width', width);
  src.searchParams.set('height', height);
  return src.href;
}

/**
 * Decide if given url is remote url or not
 * @param {string} url
 */
export function isRemoteUrl(url: string) {
  return url?.indexOf('epublishing.com') === -1;
}

/**
 * Get filename with extension from url
 * @param {string} url
 */
export function getFileNameFromUrl(url: string) {
  return url
    .split('#')
    .shift()
    .split('?')
    .shift()
    .split('/')
    .pop();
}

/**
 * Get replaced file name as combination of old file name and new file extension
 * @param {string} existingFileName
 * @param {string} newFileName
 */
export function getReplaceFileName(
  existingFileName: string,
  newFileName: string
): string {
  let fileName = existingFileName;
  const existingFileNameWithoutExt = existingFileName.split('.').shift();
  const newFileExt = getExtension(newFileName);
  const existingFileExt = getExtension(existingFileName);

  if (newFileExt !== existingFileExt) {
    fileName = `${existingFileNameWithoutExt}.${newFileExt}`;
  }

  return fileName;
}

/**
 * Get file path excluding  {domain}/ext/resources/ and file name
 * @param {string} url
 */
export function extractFilePath(url: string) {
  const resourcesIndex = String(url).indexOf('resources/');
  let urlParts = `${String(url).substring(resourcesIndex + 9)}`.split('/');
  if (urlParts.length > 1) {
    urlParts = urlParts.slice(0, urlParts.length - 1);
  }
  return `${urlParts.join('/')}/`;
}

/**
 * Check whether file belongs to given media type or not
 * @param {string} fileName
 * @param {MediaTypes} type
 */
export function isValidFile(fileName: string, type: MediaTypes): boolean {
  const extension = getExtension(fileName);
  const extLower = (extension || '').toLowerCase();
  let valid = true;

  switch (type) {
    case 'Audio':
      valid = AUDIO_EXTENSIONS.indexOf(extLower) !== -1;
      break;
    case 'Video':
      valid = VIDEO_EXTENSIONS.indexOf(extLower) !== -1;
      break;
    case 'Image':
      valid = IMAGE_EXTENSIONS.indexOf(extLower) !== -1;
      break;
    case 'File':
      valid =
        AUDIO_EXTENSIONS.indexOf(extLower) === -1 &&
        VIDEO_EXTENSIONS.indexOf(extLower) === -1 &&
        IMAGE_EXTENSIONS.indexOf(extLower) === -1;
      break;
    default:
      valid = true;
      break;
  }

  return valid;
}

/**
 * Get file name with lower case and replaced string with underscores
 * @param {string} fileName
 */
export function getFormattedFileName(fileName: string) {
  return fileName.replace(/\s+/g, '-').replace(/[^0-9A-Za-z\-\.\_\(\)\[\]]/g, '');
}
