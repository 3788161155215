import styled from 'styled-components';


export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding}px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
`;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  background-color: ${(props) => props.toggled ? props.bgToggled : props.bgClear};
  transition: background-color 10ms ease;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: ${(props) => props.width / 4}px;
  padding: ${(props) => props.padding}px;
  border: ${(props) => props.borderSize}px solid ${(props) => props.toggled ? props.bgToggled : props.borderColor};
`;

export const ToggleThumb = styled.div`
  z-index: 2;
  border-radius: 50%;
  background-color: ${(props) => props.ballColor};

  transition: transform 10ms cubic-bezier(1,.19,.15,.7);
  transition-delay: 10ms;
  will-change: transform;
  border: 1px solid ${(props) => props.borderColor};
  height: ${(props) => props.thumbHeight}px;
  width: ${(props) => props.thumbWidth}px;
  transform: ${(props) => props.toggled ?
    `translateX(${props.width - (props.thumbWidth + 10)}px)` :
    'translateX(0px)'
};
  &:active {
    background-color: ${(props) => props.ballColorActive};
  }
`;

export const InnerLabel = styled.span`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  position: absolute;
  z-index: 2;
  ${(props) => props.left && 'left: 10px;'};
  ${(props) => props.right && 'right: 10px;'};
`;

export const OuterLabel = styled.span`
  font-size: ${(props) => props.size}px;
  color: ${(props) => props.color};
  top: 0px;
  transform: translateY(-110%);
  width: 100%;
  text-align: center;
`;