import gql from 'graphql-tag';

export const DIRECTORY_ADD_COMPANIES = gql`
mutation DirectoryAddCompanies ($input: DirectoryAddCompaniesInput!) {
  directoryAddCompanies(input: $input) {
		companies {
      id
    }
    directory {
      id
    }
    success
    errors
  }
}
`;